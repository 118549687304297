import PaymentSummeryView from "./paymentSummery.jsx";
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

const PaymentSummery = () => {
    const history = useHistory();
    const paymentData = JSON.parse(localStorage.getItem('paymentData'))

    useEffect(() => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        if (!token) {
            history.push("/");
        }
    }, [])

    const clickContinue = () => {
        const adminToken = sessionStorage.getItem('adminOldToken');

        let flowName = localStorage.getItem('AdminFlow');
        if (flowName === 'institute' || flowName === 'expert') {

            let comingFromInstitute = sessionStorage.getItem("comingFromInstitute")
            if (comingFromInstitute && comingFromInstitute) {
                let oldToken = localStorage.getItem("instituteExpertToken")
                sessionStorage.setItem("token", oldToken);
                localStorage.removeItem("instituteExpertToken");
                localStorage.setItem('flowName', 'institute')
                localStorage.setItem('flowType', 'institute')
                localStorage.removeItem("adInsExpert");
                localStorage.removeItem("paymentData");
                localStorage.removeItem("instituteExpert");

                window.location.href = '/dashBoard'
                sessionStorage.removeItem("comingFromInstitute");

            }
            else {

                sessionStorage.setItem("token", JSON.stringify(adminToken));
                localStorage.removeItem("AdminFlow")
                // sessionStorage.removeItem("adminOldToken")
                // sessionStorage.removeItem("loginData")
                sessionStorage.removeItem("expertId")
                sessionStorage.removeItem("instituteId")
                localStorage.removeItem("instituteExpert");
                localStorage.removeItem("adInsExpert");

                localStorage.removeItem("instituteExpertToken");
                window.location.href = '/admin/opsDashboardHome'
                localStorage.setItem('flowName', 'admin')
                localStorage.setItem('flowType', 'admin')
            }

        } else {

            sessionStorage.removeItem("token");
            sessionStorage.removeItem("auth");
            sessionStorage.removeItem("masterID");
            sessionStorage.removeItem("token");
            localStorage.removeItem("paymentData")
            window.location.href = '/'
        }
    }

    return (
        <PaymentSummeryView
            paymentData={paymentData}
            clickContinue={clickContinue}
        />
    )
}

export default PaymentSummery;