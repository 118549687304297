import React from 'react'
import { Component } from 'react';
import RateApprovalJsx from './rateApproval.jsx'

export default class RateApproval extends Component{
    render(){
        return(
            <div>
                <RateApprovalJsx />
            </div>
        )
    }
}