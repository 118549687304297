import React from 'react'
import { useEffect, lazy } from 'react';
import { Footer } from '../../common/footer/footer';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// const GetInTouchSection = lazy(() => import('../institute/getInTouchSection'));
// const ExpertBlogSection = lazy(() => import('./expertBlogSection'));
// const ExpertSpecialization = lazy(() => import('./expertSpecialization'));
// const AdsSection = lazy(() => import('../institute/adsSection'));
// const ExpertDetails = lazy(() => import('./expertDetail'));



// {my code updated}

import { ExpertDetails } from './expertDetail';
import {AdsSection} from '../institute/adsSection';
import { ExpertSpecialization } from './expertSpecialization';
import { ExpertBlogSection } from './expertBlogSection';
import { GetInTouchSection } from '../institute/getInTouchSection';


const ExpertPpvJsx = (props) => {
  const { profileData,
    slugUrl,
    blogData,
    userData,
    formError,
    onInputChangeHandler,
    onTouchHandler,
    onSendMessageClick,
    formSubmitButtonDisable
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <section className="publicviewprofiledetails">
        <ExpertDetails profileData={profileData} />
      </section>

      {profileData?.advertisements?.length > 0 && <AdsSection profileData={profileData} legalName={slugUrl} />}

      {<ExpertSpecialization profileData={profileData} />}

      <section className="product-offer service-offer aboutppv mt-5">
        <div className="container">

          <div class="heading"><h2>About Me</h2></div>
          <div className="product-offer-inner backwhite">
            <p>{profileData?.description}</p>
          </div>
        </div>
      </section>

      <GetInTouchSection
        profileData={profileData}
        userData={userData}
        formError={formError}
        onInputChangeHandler={onInputChangeHandler}
        onTouchHandler={onTouchHandler}
        onSendMessageClick={onSendMessageClick}
        formSubmitButtonDisable={formSubmitButtonDisable}
      />

      {blogData?.body?.length > 0 && <ExpertBlogSection blogData={blogData} />}

      <Footer />
    </>

  );
};

export default ExpertPpvJsx;