import React from 'react';
import Sidebar from "../../sidebar/sidebar";

export const FinalAsktheExpertScreenJSX = (props) => {

    const { onHomebuttonClick } = props;
    return (
        <>
            <section class="">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3">


                            <div className="dashboardleftpanel martop40">

                                <div className="dashboardlinkpop martop40">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-9">
                            <div style={{ marginBottom: '50px' }}>
                                <section className="herosection heroasktheexpert heroconfirmexpert">
                                    <div className="container">
                                        <div className="confirmtion">
                                            <h2 className="text-center h2_style">Thank you for your question!</h2>
                                            <h5 className="text-center h5_style fst-italic">Conscious finance boosts overall well-being. </h5>
                                            <h5 className="text-center h5_style fst-italic">Your question has been assigned to the experts. </h5>
                                            <h5 className="text-center h5_style fst-italic">Expect tailored & personalized solutions shortly!</h5>
                                            <div className="herobutton mt-4 d-flex"><button type="button" className="ctatwobutton" onClick={onHomebuttonClick}>Home</button><button type="button" className="ctaonebutton" onClick={onHomebuttonClick}>Ask Another Query</button></div>
                                        </div>
                                    </div>
                                    <div className="waveWrapper waveAnimation">
                                        <div className="waveWrapperInner bgTop">
                                            <div className="wave waveBottom" ></div>
                                        </div>
                                        <div className="waveWrapperInner bgMiddle">
                                            <div className="wave waveMiddle" ></div>
                                        </div>

                                    </div>
                                </section>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}