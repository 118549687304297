import React from 'react'
import { useTranslation } from "react-i18next";
import leftarrow from "../../../../assets/images/left-arrow.svg";
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { useHistory } from "react-router-dom";
import * as moment from 'moment'

const PLansSummaryJsx = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    promoCode,
    onPromoCodeChnage,
    onApplyButtonClick,
    discount,
    onInvoiceButtonClick,
    onViaCard
  } = props;

  const { period, planType,rate } = JSON.parse(sessionStorage.getItem('planData'));

 
  let flowName = localStorage.getItem('AdminFlow');

  const date = new Date();

  
  const getDiscount = () => rate * discount/100
  const  final_rate = rate - getDiscount()
  // const getTaxRate = () => final_rate * .13
  // const subTotal = () => parseFloat(final_rate) + parseFloat(final_rate * .13)
  const subTotal = () => parseFloat(final_rate)


  return (
    <>
      <section className="loginscreeninstitute registrationinstitute planss">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
                <div className="back_btn" onClick={() => history.goBack()}><img src={leftarrow} alt={' '} /></div>
                <div className="detailsleft plaindetailsleft">
                  <h1>{t("PLANSUMMARY.TITLE")}</h1>
                  <h5 className="h5_style color2a">{t("PLANSUMMARY.PAYINGFOR")}</h5>
                  <h5 className="h5_style fw-lighter">{t("PLANSUMMARY.SELECTIONS")}</h5>

                  {/* <div className="passtext martop60">

                    <ul className="plansummeryul">
                      <li>{t("PLANSUMMARY.PLANTYPE")}<sapn>{planType}</sapn></li>
                      <li>{t("PLANSUMMARY.PLANDURATION")}<sapn>{period.split(' ')[0]}</sapn></li>
                      <li>{t("PLANSUMMARY.STARTDATE")}<sapn> {moment(date).format('DD MMM YYYY')}</sapn></li>
                      <li>{t("PLANSUMMARY.ENDDATE")} <sapn>{moment(date.setMonth(date.getMonth() + (period === 'Annual Billing' ? 12 : 3))).format('DD MMM YYYY')}</sapn></li>
                    </ul>
                  </div> */}

                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext passwordauthorization martop60 paddingbot60">
                {/* <div className="planbuttonouter">
                  <h2 className="h2_style">{t("PLANSUMMARY.APPLYPROMO")}</h2>
                  <h6 className="h4_style">{t("PLANSUMMARY.SAVEMORE")} </h6>
                  <p className="smalltext mt-3">{t("PLANSUMMARY.HAVINGPROMO")}</p>
                </div> */}
                <div className="signout">
                  <img src={Sign_out_squre}  alt={' '} />
                  <span className="title">{t("PLANSUMMARY.LOGOUT")}</span>
                </div>
               
                {(flowName === 'expert'||flowName === 'institute') && <div className="promosection">
                  <input type="text" className="promo-input form-control" placeholder="Enter Promo Code" value={promoCode} onChange={onPromoCodeChnage} />
                  <input type="buton" className="promo-button form-control" value="Apply" onClick={onApplyButtonClick} />
                </div>}
                <div className="plansummerytop">
                  <ul>
                      <li>{t("PLANSUMMARY.PLANTYPE")}<sapn>{planType}</sapn></li>
                      <li>{t("PLANSUMMARY.PLANDURATION")}<sapn>{period.split(' ')[0]}</sapn></li>
                      <li>{t("PLANSUMMARY.STARTDATE")}<sapn> {moment(date).format('DD MMM YYYY')}</sapn></li>
                      <li>{t("PLANSUMMARY.ENDDATE")} <sapn>{moment(date.setMonth(date.getMonth() + (period === 'Annual Billing' ? 12 : 1))).format('DD MMM YYYY')}</sapn></li>
                  </ul>
                </div>
                <div className="plansummerypop mt-4">
                  {/* <ul>
                    <li>{t("PLANSUMMARY.PLANPRICING")}<span>${getRateBeforeTax()}</span></li>
                    <li>{t("PLANSUMMARY.TAXES")}<span>${rate-getRateBeforeTax()}</span></li>
                    {discount ?<li>{t("PLANSUMMARY.DISCOUNT")} <span></span>{`${discount}%`}</li> : ""}
                    <li>{t("PLANSUMMARY.TOTAL")}<span>{`$${rate-(rate*discount/100)}`}</span></li>
                  </ul> */}
                  {/* new logic */}
                  <ul>
                    <li>{t("PLANSUMMARY.PLANPRICING")}<span>${rate}</span></li>
                    {/* <li>{t("PLANSUMMARY.TAXES")}<span>${getTaxRate()}</span></li> */}
                    {discount ?<li className="text-danger">{t("PLANSUMMARY.DISCOUNT")} <span></span>- ${getDiscount()} ({`${discount}%`})</li> : ""}
                    <li>{t("PLANSUMMARY.TOTAL")}<span>{`$${subTotal()}`}</span></li>
                  </ul>
                </div>

                {(flowName === 'expert'||flowName === 'institute') && <><button type="submit" className="mt-4 ctaonebutton" onClick={()=> onInvoiceButtonClick()}>{"Via Invoice"}</button>
                <div className="mt-2">Or</div></>}
                {/* <button type="submit" className="mt-2 ctaonebutton" onClick={()=> onContinueButtonClick()}>{"Via Card"}</button> */}
                <button type="submit" className="mt-2 ctaonebutton" onClick={()=> onViaCard()}>{"Via Online"}</button>

              </div>

            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default PLansSummaryJsx;
