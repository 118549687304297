import React from 'react'
import { useTranslation } from "react-i18next";
import leftarrow from "../../../assets/images/left-arrow.svg";
import passleftgraphics from "../../../assets/images/passleftgraphics.svg";
import { Timer } from '../timer/timer';
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

const PassJsx = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    OTP,
    timerStart,
    OnInputChange,
    onTimerComplete,
    onResendOTPClick,
    formSubmitHandler
  } = props;

  return (
    <>
      <section className="loginscreeninstitute registrationinstitute passw">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
                <div className="back_btn" onClick={() => history.goBack()}><img src={leftarrow} alt={''} /></div>
                <div className="detailsleft">
                  {localStorage.getItem('flowName') === 'login' ?
                    <h1>OTP Authentication</h1> :
                    <h1>{t("PASSWORDAUTHORIZATION.TITLE")}</h1>
                  }

                  <div className="instituteleft-illustration martop60">
                    <img src={passleftgraphics} alt={''} />
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext passwordauthorization martop60 paddingbot60">
                {localStorage.getItem('flowName') === 'login' ?
                  <h2 className="h2_style">Enter OTP
               <span className="noblock">
                      It’s time to check your registered email! We have sent you an OTP</span> </h2>
                  :
                  <h2 className="h2_style">{t("PASSWORDAUTHORIZATION.SECUREACCOUNT")}
                    <span className="noblock">{t("PASSWORDAUTHORIZATION.SETPASSWORD")}</span> </h2>
                }
                {localStorage.getItem('flowName') === 'login' ?
                  <h5 className="h5_style">Check your email and enter the One Time <br />Password (OTP) below.
                 </h5>
                  :
                  <h5 className="h5_style">{t("PASSWORDAUTHORIZATION.CHECKMAIL")}<br />
                  {t("PASSWORDAUTHORIZATION.SENDOTP")} <br />
                  {t("PASSWORDAUTHORIZATION.PLEASEENTER")}</h5>
                }

               

                <div className="mb-4 mt-4 input-container form-group">
                  <input maxLength="4" type="password" className="input form-control" placeholder=" " value={OTP} onChange={OnInputChange} />

                  <label className="placeholder">{t("PASSWORDAUTHORIZATION.ENTEROTP")}</label>
                </div>
                <div className="checkspam">{t("PASSWORDAUTHORIZATION.DONTFORGET")}</div>
                <button type="submit" className={
                  !OTP
                    ? "btn-secondary btn mt-4 ctatwobutton"
                    : "mt-4 ctaonebutton"
                } onClick={formSubmitHandler} disabled={!OTP}>{t("PASSWORDAUTHORIZATION.CONTINUE")}</button>
                {
                  timerStart ? <div className="otptime"><Timer show={timerStart} onCompletion={() => onTimerComplete()} /></div>
                    :
                    <div className="otptime" onClick={onResendOTPClick}> {t("PASSWORDAUTHORIZATION.RESENDOTP")}</div>
                }
              </div>

            </div>
          </div>
        </div>
      </section>
      <ToastContainer />

    </>

  );
};

export default PassJsx;
