import React from 'react'
import mywork from "../../../../assets/images/mywork.svg";
import manageexpert from "../../../../assets/images/manageexpert.svg";
import manageinstitute from "../../../../assets/images/manageinstitute.svg";
import assignquestions from "../../../../assets/images/assignquestions.svg";
import manageunverifieduser from "../../../../assets/images/manageunverifieduser.svg";
import manageoffer from "../../../../assets/images/manageoffer.svg";
import manageproduct from "../../../../assets/images/manageproduct.svg";
import logoutIcon from "../../../../assets/images/logout.svg";
import { Link } from "react-router-dom";
import { uploadURL,NEW_URL } from '../../../../constants/applicationConstants';import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const SideBarsJsx = (props) => {
  const history = useHistory();

  const {
    response
  } = props;

  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("legalName"); 
        sessionStorage.removeItem("isLoggedIn");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  // {t("PRINT.TITLE")}
  return (
    <>
     <div className="dashboardleftpanel martop40">
                <div className="userdetails"> 
                  <div className="dashboardlogo"><img  src={`${NEW_URL}/${response?.imageUrl}`} alt={''}></img></div>
                 <div className="responsename">{response?.name} <Link to={`/expertPpv/${response?.slugUrl}`} target="_blank"  rel="noreferrer"  className="viewprofilebtn">View Profile</Link></div> 
                </div>
                <div className="dashboardlink martop40">
                  <ul>
                  <li className={`${window.location.href?.includes('opsDashboardHome') ? 'active' : ""}`}><Link to={'/admin/opsDashboardHome'}><img src={mywork}  alt={''}/>My Work</Link></li>
                  <li className={`${window.location.href?.includes('experts') ? 'active' : ""}`}><Link to={'/admin/experts'}><img src={manageexpert}  alt={''}/>Manage Experts</Link></li>
                    <li className={`${window.location.href?.includes('institutes') ? 'active' : ""}`}><Link to={'/admin/institutes'}><img src={manageinstitute}  alt={''}/>Manage Institutions</Link></li>
                    <li className={`${window.location.href?.includes('pendingQuestion') ? 'active' : ""}`}><Link to={'/pendingQuestion'}><img src={assignquestions}  alt={''}/>Assign Questions</Link></li>
                    {/* <li className={`${window.location.href?.includes('myads') ? 'active' : ""}`}><Link to={'/admin/#'}><img src={managepayments}  alt={''}/>Manage Payments</Link></li> */}
                    <li className={`${window.location.href?.includes('manageUnverifiedInstitute') ? 'active' : ""}`}><Link to={'/admin/manageUnverifiedInstitute'}><img src={manageunverifieduser}  alt={''}/>Manage Unverified Institute</Link></li> 
                    <li className={`${window.location.href?.includes('manageUnverifiedExpert') ? 'active' : ""}`}><Link to={'/admin/manageUnverifiedExpert'}><img src={manageunverifieduser}  alt={''}/>Manage Unverified Expert</Link></li> 
                    <li className={`${window.location.href?.includes('offers') ? 'active' : ""}`}><Link to={'/admin/offers'}><img src={manageoffer}  alt={''}/>Manage Offers</Link></li>

                    <li className={`${window.location.href?.includes('products') ? 'active' : ""}`}><Link to={'/admin/products'}><img src={manageproduct}  alt={''}/>Manage Products</Link></li>
                    <li className={`${window.location.href?.includes('advertisements') ? 'active' : ""}`}><Link to={'/admin/advertisements'}><img src={manageproduct}  alt={''}/>Manage Advertisements</Link></li>
                    {/* <li className={`${window.location.href?.includes('expertPayment') ? 'active' : ""}`}><Link to={'/admin/#'}><img src={helpmanagement} />Help Management</Link></li> */}
                    {/* <li className={`${window.location.href?.includes('expertPayment') ? 'active' : ""}`}><Link to={'/admin/#'}><img src={manageunverifieduser} />Bulk Upload</Link></li> */}
                    <li className={`${window.location.href?.includes('adminAskTheExpert') ? 'active' : ""}`}><Link to={'/admin/adminAskTheExpert'}><img src={manageproduct}  alt={''}/>Ask The Expert</Link></li>
                    <li className={`${window.location.href?.includes('searchQuestion') ? 'active' : ""}`}><Link to={'/admin/searchQuestion'}><img src={manageproduct}  alt={''}/>Search The Expert Question</Link></li>



                    <li onClick={logout}><a  ><img src={logoutIcon}  alt={''}/>Logout</a></li>
                  </ul>
                </div>
              </div>
    </>

  );
};

export default SideBarsJsx;