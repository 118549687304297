import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Sidebar from "../sidebar/sidebar.js";
import MyAds from '../../dashboardCommon/myAds/myAds.js';
import MyOffer from '../../dashboardCommon/myOffer/myOffer.js';
import MyProduct from '../../dashboardCommon/myProducts/products.js'
import MyServices from "../../dashboardCommon/myServices/myServices.js"


const MyWorkspaceJsx = (props) => {
  const {
    activeTab,
    onTabClick
  } = props;

  return (
    <>
      <section className="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">
            <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">My Workspace</h2>
                    <h5 className="h5_style">This is your maintenance section. Use this workspace to create and manage your :  </h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre}  alt={''}/><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" className="modal-window">
                      <div>
                        <a  title="Close" className="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="myexpertnav martop60">
                  <ul>
                    <li className={`${activeTab === 'myAds' ? 'active':''}`} onClick={()=> onTabClick('myAds')}>My Ads</li>
                    {/* <li style={{pointerEvents:'none'}} className={`${activeTab === 'myList' ? 'active':''}`} onClick={()=> onTabClick('myList')}>My Lisitings</li> */}
                    <li className={`${activeTab === 'myProduct' ? 'active':''}`} onClick={()=> onTabClick('myProduct')}>My Products</li>
                    <li className={`${activeTab === 'myOffer' ? 'active':''}`} onClick={()=> onTabClick('myOffer')}>My Offers</li>
                    <li className={`${activeTab === 'myService' ? 'active':''}`} onClick={()=> onTabClick('myService')}>My Services</li>
                  </ul>
                </div>
               </div>

              {
                activeTab === 'myAds' ? <MyAds /> : activeTab === 'myOffer' ? <MyOffer />: activeTab === 'myService' ? <MyServices />:<MyProduct />
              }
               

            </div>
            
          </div>
          
        </div>

      </section>

    </>

  );
};

export default MyWorkspaceJsx;
