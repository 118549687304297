import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import realestateicon from "../../../assets/images/realestateicon.svg";
import insurenceicon from "../../../assets/images/insurenceicon.svg";
import mortgageicon from "../../../assets/images/mortgageicon.svg";
import bankingicon from "../../../assets/images/bankingicon.svg";
import investmenticon from "../../../assets/images/investmenticon.svg";
import realestateicontwo from "../../../assets/images/realestateicontwo.svg";
import insurenceicontwo from "../../../assets/images/insurenceicontwo.svg";
import mortgageicontwo from "../../../assets/images/mortgageicontwo.svg";
import bankingicontwo from "../../../assets/images/bankingicontwo.svg";
import investmenticontwo from "../../../assets/images/investmenticontwo.svg";
import videoimage from "../../../assets/images/videoimage.svg";
import videopop from "../../../assets/images/videopop.svg";
import { Footer } from '../../common/footer/footer';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import InputMask from 'react-input-mask';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const getIconOne = (label) => {
  if (label === 'Real Estate')
    return realestateicon
  else if (label === 'Mortgage')
    return mortgageicon
  else if (label === 'Insurance')
    return insurenceicon
  else if (label === 'Banking')
    return bankingicon
  else if (label === 'Investment')
    return investmenticon
  else return ''
}

const AskTheExpertJsx = (props) => {
const { t } = useTranslation();
  const {
    categoryData,
    onCategoryClick,
    categoryName,
    subCategoryData,
    onSubcategoryClick,
    subCategoryName,
    questionAsked,
    formData,
    onInputChange,
    onTextAreaChange,
    onTouchHandler,
    formError,
    formSubmitButtonDisable,
    onQuestionClick,
    onSubmitButtonClick,
    expertQuestions,
    onclickFocus
  } = props;

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const getMuskPattern = (name) =>
    name === 'phone' ? '(999) 999 9999' : 'a9a 9a9'

  const getIconTwo = (label) => {
    if (label === 'Real Estate')
      return realestateicontwo
    else if (label === 'Mortgage')
      return mortgageicontwo
    else if (label === 'Insurance')
      return insurenceicontwo
    else if (label === 'Banking')
      return bankingicontwo
    else if (label === 'Investment')
      return investmenticontwo
    else return ''
  }

  return (
    <>
      <section className="herosection heroasktheexpert">

        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="heroleftpanel" data-aostest="fade-right">
                <h2>{t("ASKTHEEXPERT.HEADING")} </h2>
                <p><i>{t("ASKTHEEXPERT.SUBHEADING")}</i></p>
                <p>{t("ASKTHEEXPERT.SUBHEADING1")}</p>
                <p><b>{t("ASKTHEEXPERT.SUBHEADING2")}</b></p>
                <ul>
                  <li>{t("ASKTHEEXPERT.ULLIHEADING1")}</li>
                  <li>{t("ASKTHEEXPERT.ULLIHEADING2")}</li>
                  <li>{t("ASKTHEEXPERT.ULLIHEADING3")}</li>
                </ul>
                <p><b>{t("ASKTHEEXPERT.SUBHEADING3")}</b></p>
                <p>{t("ASKTHEEXPERT.SUBHEADING4")}</p>
                <p>{t("ASKTHEEXPERT.SUBHEADING5")}</p>

                <div className="heroleftbutton d-flex mt-4">
                  <a href="javascript:void(0)" className="ctaonebutton" onClick={(e) => onclickFocus("ask")}>I want to ask now</a>
                  <a href="javascript:void(0)" className="ctatwobutton" onClick={(e) => onclickFocus("faq")}>FAQ</a>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 d-none">
              <div className="herorightpanel" data-aostest="fade-left">
              <div class="wrapper">
  <div class="video-main">
    <div class="promo-video">
      <div class="waves-block">
        <div class="waves wave-1"></div>
        <div class="waves wave-2"></div>
        <div class="waves wave-3"></div>
      </div>
    </div>
    <a  class="video video-popup mfp-iframe" data-lity><img src={videopop}  alt={''}/></a>
  </div>
</div>
                <img src={videoimage}  alt={''} />
              </div>
            </div>
          </div>
        </div>
        <div className="waveWrapper waveAnimation">
          <div className="waveWrapperInner bgTop">
            <div className="wave waveBottom" ></div>
          </div>
          <div className="waveWrapperInner bgMiddle">
            <div className="wave waveMiddle" ></div>
          </div>

        </div>
      </section>
      <section className="asktheexpertcategorysection mt-5 mb-5" id="ask">
        <div className="container">
          <div className="asktheexpertcategorysectioninner asktheexpertcategorysectioninnerline">
            <div className="catstart clearfix"  data-aostest="fade-up-right">
              <div className={`categoryonesetfirst ${categoryName && 'categoryoneset'}`}>
                <div className="disgrid grid-3">
                  <div className="categoryonesetone" >
                    <p>{t("ASKTHEEXPERT.SELECT")}</p>
                    <h4>{t("ASKTHEEXPERT.CATEGORY")}</h4>
                    <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.TEXT1")}</b> {t("ASKTHEEXPERT.TEXT2")}</p>
                  </div>
                  {
                    categoryData?.map(catDT => {
                      return <div className={`categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`}  onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                        <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                        <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>
                        <h5>{catDT.name}</h5>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
            {categoryName && <div className="catstart clearfix"  data-aostest="fade-up-left">
              <div className={`categorytwosetfirst ${subCategoryName && 'categorytwoset'}`}>
              
                <div className="disgrid grid-4" id="askts" onClick={(e) => onclickFocus("askQus")}>
                    <div className="categoryonesetone anotherexp">
                            <p>{t("ASKTHEEXPERT.SELECT2")}</p>
                            <h4>{t("ASKTHEEXPERT.CATEGORY2")} </h4>
                            <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.TEXT3")}</b> {t("ASKTHEEXPERT.TEXT4")}</p>
                          </div>
                  {
                    subCategoryData?.map(subCatDT => {
                      return <div id="askqw" className={`categoryonesettwo categoryonesetwidth ${subCategoryName === subCatDT?.name ? 'active' : ''}`} onClick={() => onSubcategoryClick(subCatDT.institutionCategoryId, subCatDT.name)}>
                        <div className="iconone"><img src={`/${(subCatDT?.name).replace(/\s/g,'')}.png`} alt={''} /></div>
                        <div className="icontwo"><img src={`/${(subCatDT?.name).replace(/\s/g,'')}.png`} alt={''} /></div>
                        <h5>{subCatDT.name}</h5>
                      </div>
                    })
                  }
                  
                </div>
              </div>
            </div>
            }
            {subCategoryName && <div id="askQus">
              <div className="catstart clearfix"  data-aostest="fade-up-up">
                <div className="categorythreeset">
                  <div className="categoryonesetone">

                    <h4>{t("ASKTHEEXPERT.QUESTIONHEADING")}</h4>
                    <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.QUESTIONTEXT1")} </b>
                    {t("ASKTHEEXPERT.QUESTIONTEXT2")}</p>

                  </div>
                </div>
              </div>
              <div className="catstart clearfix"  data-aostest="fade-up-bottom">
                <div className="categoryfourset">
                  <div className="categoryonesetone">
                    <p>{t("ASKTHEEXPERT.QUESTIONHEADING1")} </p>
                    <h4>{t("ASKTHEEXPERT.QUESTIONTEXT3")} </h4>
                    <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.QUESTIONTEXT4")}</b> {t("ASKTHEEXPERT.QUESTIONTEXT5")}</p>
                  </div>
                  <AwesomeSlider>
                  {
                    expertQuestions.length > 0 ? expertQuestions.map(que => {
                      return <div onClick={() => onQuestionClick(que)}>{que.question}</div>
                      
                    }) :

                      <div>No Question Found</div>
                  }
                  </AwesomeSlider>
                </div>
              </div>
              <div className="textarecs mt-5" data-aostest="fade-bottom">
                <div className="input-container form-group">
                  <textarea className="input form-control textareaform" value={questionAsked} name="message" onChange={onTextAreaChange} placeholder=" "></textarea>
                  <div className="placeholder">Please type your question here... </div>
                </div>
              </div>
              <div className="mainexpform mb-5 mt-5"  data-aostest="fade-up-right">
                <h4 className="text-center">Sit tight! Our experts know exactly what you need. </h4>
                <h5 className="text-center">Just enter the details below. Get precise, valuable & objective responses.  </h5>
                <div className="mainexpforminner mt-5">
                  <div className="disgrid grid-4">
                    <div className="input-container form-group">
                      <input
                        type="text"
                        name='name'
                        value={formData.name}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        className={
                          formError['name']
                            ? "input form-control  is-invalid"
                            : "input form-control"
                        }
                        placeholder=" " />
                      <div className="placeholder">Full Name* </div>
                      <span className="text-danger">
                        {formError['name'] &&
                          (formError['name'] === "req"
                            ? 'Name is required'
                            : 'Enter a valid Name')}
                      </span>
                    </div>
                    <div className="input-container form-group">
                      <input
                        type="email"
                        name='email'
                        value={formData.email}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        className={
                          formError['email']
                            ? "input form-control  is-invalid"
                            : "input form-control"
                        }
                        placeholder=" " />
                      <div className="placeholder">Email Address* </div>
                      <span className="text-danger">
                        {formError['email'] &&
                          (formError['email'] === "req"
                            ? 'Email is required'
                            : 'Enter a valid Email')}
                      </span>
                    </div>
                    <div className="input-container form-group">
                      <InputMask mask={getMuskPattern('postalCode')} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                        {(inputProps) => <input {...inputProps}
                          type="text"
                          className={
                            formError['postalCode']
                              ? "input form-control  is-invalid"
                              : "input form-control"
                          }
                          placeholder=" "
                          disableUnderline />}
                      </InputMask>
                      <div className="placeholder">Postal Code* </div>
                      <span className="text-danger">
                        {formError['postalCode'] &&
                          (formError['postalCode'] === "req"
                            ? 'Postal code is required'
                            : 'Enter a valid Postal Code')}
                      </span>
                    </div>
                    <div className="input-container form-group">
                      <InputMask mask={getMuskPattern('phone')} value={formData.phoneNumber} name="phoneNumber" onChange={onInputChange} onBlur={onTouchHandler}>
                        {(inputProps) => <input {...inputProps}
                          type="tel"
                          className={
                            "input form-control"
                          }
                          placeholder=" "
                          disableUnderline />}
                      </InputMask>
                      <div className="placeholder">Phone Number </div>
                      <span className="text-danger">
                        {formError['phoneNumber'] &&
                          (formError['phoneNumber'] === "req"
                            ? ''
                            : 'Enter a valid Phone Number')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {/* <Recaptcha
                  key={Math.random()}
                  sitekey="6LdcBSEgAAAAAIs7aSdegby02xHlo_C8BNH6z-tX"
                  onChange={onChange}
                /> */}
                </div>
                <div className="text-center">
                  <button disabled={formSubmitButtonDisable} type="button" className="ctaonebutton widthhalf mt-3" onClick={onSubmitButtonClick}>Submit</button>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </section>
      <section className="faqsection" id="faq">
        <div className="container">
          <h3 className="text-center  mb-5">Frequently Asked Questions</h3>

          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                What is Ask the Expert
      </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>It's a feature that allows you to ask financial questions. All your queries are resolved by accredited domain experts without any charge or fee. </p>
      </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                How to Submit a Query through Ask the Expert?
      </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                 <p>
                 Go to “Ask the Expert” </p>
                 <ul>
                   <li>Select the “Main Category” for your query </li>
                   <li>Select the “Subcategory” of your query </li>
                   <li>Browse the several common questions. You can select any one question.</li>
                   <li>Type your specific question or modify the selected common question as per your need </li>
                   <li>Click on Submit.</li>
                 </ul>                 
      </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Can we choose our preferred experts to get answers? 

      </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p><strong>NO.</strong> This option is not available in "Ask the Expert". <br/> 

However, you can search for your preferred experts using “Search the Expert”. All you have to do is enter your postal code. Post this, you will get a complete list of experts located near you. </p>
      </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                What information do I need to provide to have my question answered?

      </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>You are only required to submit:</p>
                  <ul>
                    <li>Your Name </li>
                    <li>Email address </li>
                    <li>Phone No. and </li>
                    <li>Postal Code</li>
                  </ul>
      </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                How will I know if my query has been submitted?

      </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>You will be notified via email that your query has been submitted.</p>
      </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                Who are the experts answering our queries?

      </button>
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>All your queries are answered by experts, who are certified finance professionals with extensive experience in their fields. They have requisite knowledge, skills and understanding to offer you the best resolutions. </p>
      </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSeven">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                Do we have to pay to have our questions answered?

      </button>
              </h2>
              <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p><strong>NO.</strong> We do not charge anything from our customers for using "Ask the Expert". You can seek answers to all your financial queries for free.</p>
      </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEight">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                Can I submit queries in more than one category?

      </button>
              </h2>
              <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p><strong>YES. </strong>Our customers can ask multiple queries in different categories. There is no limit on number of query submissions. </p>
      </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <Footer/>

    </>

  );
};

export default AskTheExpertJsx;