import React from 'react'
import { Component } from 'react';

import SearchMainJsx from './searchMain.jsx'

export default class SearchMain extends Component {

    constructor(props){
        super(props);
        this.state={
            category:'searchExpert'
        }
    }

    onDropDownChange = (e) =>{
        this.setState({category:e.target.value})
    }

    onSearchButtonClick = () =>{
        this.props.history.push(`/${this.state.category}`)
    }

    render(){
        return(
            <div>
                <SearchMainJsx 
                onDropDownChange={this.onDropDownChange}
                onSearchButtonClick={this.onSearchButtonClick}
                />
            </div>
        )
    }
}