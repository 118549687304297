import React from 'react';
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";

import { useTranslation } from "react-i18next";
import InputMask from 'react-input-mask';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'

export const ManageUserJSX = (props) => {
  const {
    showModal,
    onCreateButtonClick,
    onInputChangeHandler,
    onTouchHandler,
    userData,
    formSubmitButtonDisable,
    onSubmitButtonClick,
    data,
    formError,
    onEditButtonClick,
    onDeleteButtonClick,
    instituteUserID
  } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className="myaccountsectionheading manageuser">
        <h3 className="h3_style martop60">{t("MANAGEUSER.TITLE")}</h3>
        <p>{t("MANAGEUSER.CONFIRM")}</p>
      </div>
      <div className="addexpert martop40">
        <div className="addexpertleft">
          <p>{t("MANAGEUSER.QUICKUSERCREATE")}</p>
          <div className="userguide">
            <span class="plantooltip custooltip fst-italic">
              <img src={planpricingtooltip} className="tooltipim"  alt={''}/> {t("MANAGEUSER.HOWTOCREATEUSER")}
              <div className="tooltipcontent">
                <ul className="tcon">
                  <li className="tconitem">{t("MANAGEUSER.CLICK")}</li>
                  <li className="tconitem">{t("MANAGEUSER.ENTERDETAIL")}</li>
                  <li className="tconitem">{t("MANAGEUSER.CLICKBUTTON")}</li>

                </ul>
              </div>
            </span>

          </div>
          <div className="userguide">
            <span class="plantooltip custooltip fst-italic">
              <img src={planpricingtooltip} className="tooltipim"  alt={''}/> {t("MANAGEUSER.WHATUSERDO")}
              <div className="tooltipcontent">
                <ul className="tcon">
                  <li className="tconitem">{t("MANAGEUSER.CONFIRMTYPE")}</li>
                  <li className="tconitem">{t("MANAGEUSER.LOGINCRED")}  </li>
                  <li className="tconitem">{t("MANAGEUSER.PERFORMACTION")}

                  </li>

                </ul>
              </div>
            </span>

          </div>

        </div>
        <div className="addexpertright">
          <button type="button" className="ctaonebutton" disabled={data?.length >= 5} onClick={() => onCreateButtonClick(true)} >{t("MANAGEUSER.CREATENEWUSER")}</button>
          {data?.length >= 5 && <p className="text-danger">Max User Limit Reached !</p>}
         
          {/* disabled={data?.length === limitData?.totalExpertsLimit} */}
          {/* {data?.length === limitData?.totalExpertsLimit && <p className="text-danger">Max User Limit Reached !</p>} */}
        </div>
      </div>

      {showModal && <section>
        <div className="myaccountsectionheading manageuser">
          <h3 className="h3_style martop60">{instituteUserID ? t("MANAGEUSER.EDITUSERS") : t("MANAGEUSER.USERCREATION")}</h3>
        </div>
        <div className="userlist wholepad20">
          {/* <img src={del_alt_fill} onClick={()=>onCreateButtonClick(false)} /> */}
          {
            instituteUserID ? <>
              <h3 className="h3_style">Oops! Missed something?</h3>
              <h3 className="h3_style">You can enter the updated information below</h3>
            </> :
              <>
                <h3 className="h3_style">{t("MANAGEUSER.USERDETAIL")}</h3>
                {t("MANAGEUSER.ENTERINFO")}
                <h3 className="h5_style">{t("MANAGEUSER.MANDATORY")}</h3>
              </>
          }
          <div class="man">

            {/* <span>{t("MANAGEUSER.MANDATORY")}</span> */}
            <div className="accinner mt-4">
              <div className="disgrid grid-2">
                <div className="input-container form-group">
                  <input type="text"
                    onBlur={onTouchHandler}
                    onChange={onInputChangeHandler}
                    value={userData.firstName}
                    name="firstName"
                    className="input form-control"
                    placeholder=" "
                    maxLength={60}
                  />
                  <div className="placeholder">{t("MANAGEUSER.FIRSTNAME")}</div>
                  <span className="text-danger">
                    {formError['firstName'] &&
                      (formError['firstName'] === "req"
                        ? 'First Name is required'
                        : 'Enter a valid First Name')}
                  </span>
                </div>
                <div className="input-container form-group">
                  <input type="text"
                    onBlur={onTouchHandler}
                    onChange={onInputChangeHandler}
                    value={userData.lastName}
                    name="lastName"
                    className="input form-control"
                    placeholder=" "
                    maxLength={60}
                  />
                  <div className="placeholder">{t("MANAGEUSER.LASTNAME")}</div>
                  <span className="text-danger">
                    {formError['lastName'] &&
                      (formError['lastName'] === "req"
                        ? 'Last Name is required'
                        : 'Enter a valid Last Name')}
                  </span>
                </div>

              </div>
              <div className="input-container form-group mt-3">
                <input type="text"
                  onBlur={onTouchHandler}
                  onChange={onInputChangeHandler}
                  value={userData.email}
                  name="email"
                  className="input form-control"
                  placeholder=" "
                  maxLength={60}
                />
                <div className="placeholder">{t("MANAGEUSER.EMAIL")}</div>
                <span className="text-danger">
                  {formError['email'] &&
                    (formError['email'] === "req"
                      ? 'Email is required'
                      : 'Enter a valid Email')}
                </span>
              </div>
              <div className="input-container form-group mt-4">
                <InputMask mask={'(999) 999 9999'} value={userData.phone} name="phone" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                  {(inputProps) => <input {...inputProps} type="tel" className="input form-control" placeholder=" " disableUnderline />}
                </InputMask>
                <div className="placeholder">{t("MANAGEUSER.PHONE")}</div>
                <span className="text-danger">
                  {formError['phone'] &&
                    (formError['phone'] === "req"
                      ? 'Contact Number is required'
                      : 'Enter a valid Contact Number')}
                </span>
              </div>
              <button type="button" className="ctaonebutton widthhalf mt-4" disabled={formSubmitButtonDisable} onClick={() => onSubmitButtonClick()}>{t("MANAGEUSER.CREATENOW")}</button>

            </div>
          </div>
        </div>
      </section>}

      <p className="acctext">{t("MANAGEUSER.USERINFO")}</p>
      <div className="userlist">
        {
          data?.map(data => {
            return <div className="userlistinner">
              <div className="username">{`${data.firstName} ${data.lastName}`}</div>
              <div className="useremail">{data.email}</div>
              <div className="usertel">{data.contactNumber && formatCanadianPhoneNumber(data.contactNumber)}</div>
              <div className="mybtn">
                <button type="button" className="myadbtn" onClick={() => onEditButtonClick(data)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>
                <button type="button" className="myadbtn" onClick={() => onDeleteButtonClick(data)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg></button>
              </div>
            </div>
          })
        }
      </div>
    </div>
  )
}
