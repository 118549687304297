import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import question from "../../../../assets/images/question.svg"
import Sidebar from "../../../../components/dashboard/institute/sidebar/sidebar.js";
import { format } from 'date-fns';
import { decrypt } from '../../../../shared/helper';
import { Link } from 'react-router-dom';
import leftarrow from "../../../../assets/images/left-arrow.svg";

const MyPaymentsJsx = (props) => {

  const {
    paymentData,
    formData,
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    isLoading,
    isShowCard,
    onReplaceCardClick,
    isCardSummary,
    isPaymentSummary,
    onCompletePayment, invoiceDownload,backToPayment,onBackCardClick,backToAddCard
  } = props;

  const cardDetails = JSON.parse(sessionStorage.getItem("addCard"))

  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">My Payments</h2>
                    <h5 className="h5_style">This section shows your added cards, subscription details & payment history</h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">g
                        <img src={question} alt={''} /><span className="title">Help</span>
                      </a>
                    </div>
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" className="modal-window">
                      <div>
                        <a  title="Close" className="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!isShowCard && !isCardSummary && !isPaymentSummary && <section>
                  <div className="cardsubscription_details d-flex mt-5">
                    <div className="cardsubscriptionleft">
                      <h4>Your Cards:</h4>
                      <p>Manage your saved card from below.</p>
                      <div className="previewcardinner mb-4">
                        <div className="namecardholder text-white">{paymentData?.name}</div>
                        <div className="cardnumber text-white"><div>XXX XXX XX{paymentData?.cardLast4Digits}</div><div className="cardde"></div></div>
                        <div className="carddate text-white">{paymentData?.expiryMonth}/{paymentData?.expiryYear}</div>
                      </div>
                      <div className="replacelogo2 ctaonebutton" onClick={onReplaceCardClick}>
                        Replace Card
                      </div>
                    </div>
                    <div className="cardsubscriptionright">
                      <h4>Subscription Details</h4>
                      <p>View all important details related to your subscription.</p>
                      <div className="subscribtionsummery">
                        <div className="subscribtionsummeryheading">
                          <h4>Customer since {paymentData && format(new Date(paymentData?.customerJoiningDate), 'dd MMM yyyy')}</h4>
                        </div>
                        <div className="d-flex subscriptiondetails">
                          <div className="subscriptiondetailsleft">
                            <div className="substartdates">
                              Subscription Start Date
                            </div>
                            <div className="substartdatesdetail">
                              <h4>{paymentData && format(new Date(paymentData?.subscriptionStartDate), 'dd MMM yyyy')}</h4>
                            </div>
                            <div className="substartdates mt-3">
                              Subscription End Date
                            </div>
                            <div className="substartdatesdetail">
                              <h4>{paymentData && format(new Date(paymentData?.subscriptionEndDate), 'dd MMM yyyy')}</h4>
                            </div>
                          </div>
                          <div className="subscriptiondetailsright">
                            <div className="currentplan text-center">
                              <h4>Current Plan</h4>
                              <h2>{paymentData?.planName}</h2>
                              {paymentData?.planName === 'Premium' &&
                                <Link
                                  to={{
                                    pathname: "/changeplan"
                                  }}
                                  className="ctaonebutton">
                                  Change Plan
                               </Link>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="paymenthistory mt-5">
                    <h3>Payment History</h3>
                    <p>Check out the last five successful payments. You can also download your payment invoice from here.</p>
                    <ul className="paymentstructure">
                      <li><span>Period</span> <span>Amount</span> <span>Status</span> <span>Invoice</span></li>
                      {paymentData?.subscriptions.map((item) => {
                        return (
                          <>
                            <li>
                              <span>{format(new Date(item.startDate), ' MMM-yyyy')} - {format(new Date(item.endDate), ' MMM-yyyy')}</span>
                              <span>${parseFloat((item?.amount ?? 0)/100).toFixed(2)}</span>
                              <span><button type="button" className="ctaonebutton">Paid</button></span>
                              <span><button type="button" className="ctaonebutton" onClick={() => invoiceDownload(item)}>Download Invoice</button></span>
                            </li>                                      </>
                        )
                      })}

                      {/* <li>
                        <span>May 2022 - May 2023</span>
                        <span>$123</span>
                        <span><button type="button" className="ctaonebutton">Paid</button></span>
                        <span><button type="button" className="ctaonebutton">Download Icon</button></span>
                      </li>
                      <li>
                        <span>May 2022 - May 2023</span>
                        <span>$123</span>
                        <span><button type="button" className="ctaonebutton">Paid</button></span>
                        <span><button type="button" className="ctaonebutton">Download Icon</button></span>
                      </li> */}
                    </ul>
                  </div>
                </section>}
                {/* <section>
                  <div className="cardsubscription_details d-flex mt-5">
                    <a href="#down" className="cardsubscriptionleft">
                      <h4>Your Cards:</h4>
                      <p>Manage your saved card from below.</p>
                      <div className="cardas" onClick={replaceCard}>
                        <div className="previewcardinner mb-4 replace">
                          <div className="namecardholder text-white">Name of the customer</div>
                          <div className="cardnumber text-white"><div>XXX XXX XX9 012</div><div className="cardde"></div></div>
                          <div className="carddate text-white">11/33</div>
                        </div>
                        <div className="replacelogo">
                          Replace
                      </div>
                      </div>
                    </a>
                    <div className="cardsubscriptionright">
                      <h4>Subscription Details</h4>
                      <p>View all important details related to your subscription.</p>
                      <div className="subscribtionsummery">
                        <div className="subscribtionsummeryheading">
                          <h4>Customer since {paymentData && format(new Date(paymentData?.customerJoiningDate), 'dd MMM yyyy')}</h4>
                        </div>
                        <div className="d-flex subscriptiondetails">
                          <div className="subscriptiondetailsleft">
                            <div className="substartdates">
                              Subscription Start Date
                            </div>
                            <div className="substartdatesdetail">
                              <h4>{paymentData && format(new Date(paymentData?.subscriptionStartDate), 'dd MMM yyyy')}</h4>
                            </div>
                            <div className="substartdates mt-3">
                              Subscription End Date
                            </div>
                            <div className="substartdatesdetail">
                              <h4>{paymentData && format(new Date(paymentData?.subscriptionEndDate), 'dd MMM yyyy')}</h4>
                            </div>
                          </div>
                          <div className="subscriptiondetailsright">
                            <div className="currentplan text-center">
                              <h4>Current Plan</h4>
                              <h2>EBA {paymentData?.planName}</h2>
                              <button type="button" className="ctaonebutton">Change Plan</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> */}
                <section>
                  {/* <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">My Payments</h2>
                      <h5 className="h5_style">This section shows your added cards, subscription details & payment history</h5>
                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={question}  alt={''}/><span className="title">Help</span>
                        </a>
                      </div>
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre}  alt={''}/><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a  title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
                        Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {
                    isShowCard ?

                      <div id="down" className="sectioncardadd mt-4">
                        <div className="back_btn" onClick={() => onBackCardClick()}><img src={leftarrow} alt={'icon'} /></div>

                        <h2 className="text-center font-we-400">Replace your Existing Card</h2>
                        <h4 className="text-center">Do you wish to add another credit card? Do it easily from below!
                            Please make sure you are correctly entering the card details.</h4>
                        <div className="detailscardenter">
                          <p>Please enter the following card information:</p>
                          <div className="mt-4 mb-4 input-container form-group">
                            <input
                              onChange={onInputChangeHandler}
                              onBlur={onTouchHandler}
                              required
                              type="text"
                              value={(formData) ? formData.name : null}
                              placeholder=" "
                              maxLength="50"
                              className={
                                formError["name"]
                                  ? "input form-control  is-invalid"
                                  : "input form-control"
                              }
                              id="name"
                              name="name"
                            />
                            <div className="placeholder">Name of the Cardholder</div>
                            <span className="text-danger">

                              {formError["name"] &&
                                (formError["name"] === "req"
                                  ? "Card member name is required!"
                                  : "Please enter a proper name!")}
                            </span>
                          </div>
                          <div className="mb-4 input-container form-group">
                            <input
                              onChange={onInputChangeHandler}
                              onBlur={onTouchHandler}
                              required
                              type="text"
                              maxLength="16"
                              value={(formData) ? formData.card : null}
                              placeholder=" "
                              className={
                                formError["card"]
                                  ? "input form-control  is-invalid"
                                  : "input form-control"
                              }
                              id="card"
                              name="card"
                            />
                            <div className="placeholder">Card Number</div>
                            <span className="text-danger">
                              {formError["card"] &&
                                (formError["card"] === "req"
                                  ? "Card Number is required!"
                                  : "Please enter a proper Card Number!")}
                            </span>
                          </div>
                          <div className="disgrid grid-2 cardvalid">

                            <div className="inlinecard">
                              <div className="input-container form-group">
                                <span className="validth">Valid Thru</span>
                                <input
                                  onChange={onInputChangeHandler}
                                  onBlur={onTouchHandler}
                                  value={(formData) ? formData.month : null}
                                  required
                                  type="text"
                                  placeholder=" "
                                  className={
                                    formError["month"]
                                      ? "input form-control  is-invalid"
                                      : "input form-control"
                                  }
                                  id="month"
                                  name="month"
                                  maxLength="2"
                                />
                                <div className="placeholder">Month</div>
                                <span className="text-danger">
                                  {formError["month"] &&
                                    (formError["month"] === "req"
                                      ? "Month is required!"
                                      : "Please enter a proper Month!")}
                                </span>
                              </div>
                              <div className="input-container form-group">
                                <input
                                  onChange={onInputChangeHandler}
                                  onBlur={onTouchHandler}
                                  required
                                  type="text"
                                  placeholder=" "
                                  value={(formData) ? formData.year : null}
                                  maxLength="4"
                                  className={
                                    formError["year"]
                                      ? "input form-control  is-invalid"
                                      : "input form-control"
                                  }
                                  id="year"
                                  name="year" />
                                <div className="placeholder">Year</div>
                                <span className="text-danger">
                                  {formError["year"] &&
                                    (formError["year"] === "req"
                                      ? "year  is required!"
                                      : "Please enter a proper year !")}
                                </span>
                              </div>
                            </div>
                            <div className="input-container form-group">
                              <input
                                onChange={onInputChangeHandler}
                                onBlur={onTouchHandler}
                                required
                                type="text"
                                placeholder=" "
                                maxLength="3"
                                value={(formData) ? formData.cvc : null}
                                className={
                                  formError["cvc"]
                                    ? "input form-control  is-invalid"
                                    : "input form-control"
                                }
                                id="cvc"
                                name="cvc" />
                              <div className="placeholder">CVC</div>
                              <span className="text-danger">
                                {formError["cvc"] &&
                                  (formError["cvc"] === "req"
                                    ? "cvc  is required!"
                                    : "Please enter a proper cvc !")}
                              </span>
                            </div>
                          </div>
                          <button disabled={formSubmitButtonDisable} onClick={formSubmitHandler} type="button" className={
                            formSubmitButtonDisable
                              ? "btn-secondary btn mt-4 ctatwobutton"
                              : "mt-4 ctaonebutton"
                          }> {isLoading ? "Processing........" : "Add Card"}</button>
                        </div>
                      </div>

                      : null
                  }
                </section>


                {isCardSummary && <section>
                  <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">Make Payment</h2>

                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={question} alt={''} /><span className="title">Help</span>
                        </a>
                      </div>
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a  title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {cardDetails &&
                    <div className="sectioncardadd mt-4">
                      <div className="back_btn" onClick={() => backToAddCard()}><img src={leftarrow} alt={'icon'} /></div>

                      <h2 className="text-center font-we-400">Pay Easily with Your Added Credit Card</h2>
                      <h4 className="text-center">Just tap on the card below to make payment</h4>
                      <div className="paymentchange">
                        <div className="previewcardinner mt-4">
                          <div className="namecardholder">{cardDetails?.name}</div>
                          <div className="cardnumber"><div>{cardDetails && decrypt(cardDetails?.card).replace(/[^a-z0-9]+/gi, '').replace(/(.{4})/g, '$1 ')}</div><div className="cardde"></div></div>
                          <div className="carddate">{cardDetails?.month}/{cardDetails?.year}</div>
                        </div>
                        <button type="button" className="ctaonebutton mt-4" onClick={onCompletePayment}>COMPLETE PAYMENT</button>
                      </div>

                    </div>}
                </section>}
                {isPaymentSummary && <section>
                  <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">Payment Summary</h2>

                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={question} alt={''} /><span className="title">Help</span>
                        </a>
                      </div>
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a  title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {<div className="sectioncardadd mt-4">
                    <div className="back_btn" onClick={() => backToPayment()}><img src={leftarrow} alt={'icon'} /></div>

                    <h2 className="text-center font-we-400 voila">Voila! Your transaction was successful!</h2>
                    <h4 className="text-center fw-lighter">Thank You for Choosing Us Again!</h4>
                    <h4 className="text-center fst-italic fw-lighter">Thank You for Choosing Us Again!</h4>
                    <div className="Paymentsummeryinner">
                      <div class="paymentsummery">
                        <li>STATUS:<span>SUCCESS</span></li>
                        <li>Transaction ID:<span>XXXXXXXXXXX</span></li>
                        <li>Date of Transaction:<span>DD/MM/YY</span></li>
                        <li>Amount Paid:<span>$1000</span></li>
                        <li> Paid via Card ending XXXX (last four digits) </li>
                      </div>
                      <button type="button" className="ctaonebutton mt-4" >Download Invoice</button>
                      <button type="button" className="ctaonebutton mt-4" onClick={() => backToPayment()}>Continue To Payment-Screen</button>
                    </div>

                  </div>}
                </section>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default MyPaymentsJsx;