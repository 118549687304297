import React, { useState } from 'react'
import leftarrow from "../../../assets/images/left-arrow.svg";
import receiveotp from "../../../assets/images/receive_otp.png";
import ResetPassJson from "./otpLogin.json";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Timer } from '../timer/timer';

const NewPaawordJsx = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState({});

  const toggleShowPassword = (fieldName) => {
    setShowPassword(prevState => ({
      ...prevState,
      [fieldName]: !prevState[fieldName]
    }));
  };

  let {
    formData,
    onInputChangeHandler,
    timerStart,
    onTimerComplete,
    abhi,
    onResendOTPClick,

    onTouchHandler,
    
    formError,
    formSubmitHandler,
  } = props;

  return (
    <>
      <section className="loginscreeninstitute registrationinstitute passw">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
                <div className="back_btn" onClick={() => history.goBack()}><img src={leftarrow} alt={''} /></div>
                <div className="detailsleft plaindetailsleft">
                  <h1>Enter the OTP</h1>

                  <div className="passtext mt-3">
                    <h4 className="italic h4_style color-light-blue">A OTP shared to email, please use the OTP to continue </h4>
                    {/* <h4 className="h4_style p-text">{t("NEWPASSWORD.STRONGPASSWORD")}</h4> */}
                    <div className="otpimage">
                      <img src={receiveotp} alt="" />
                    </div>
                    {/* <ul className="martop20">
                      <li>{t("NEWPASSWORD.CHARACTER")}</li>
                      <li>{t("NEWPASSWORD.ONENUMBER")}</li>
                      <li>{t("NEWPASSWORD.SPECIALCHAR")}</li>
                      <li>{t("NEWPASSWORD.UPPERCHAR")}</li>
                    </ul> */}
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext NEWPASSWORD martop60 paddingbot60">
                <div className="forgetpassinner">

                  <h5 className="h2_style color-light-blue">Don't forget to check your spam / Junk</h5>
                  <div className="ft-inner otpauthentication">
                    {ResetPassJson.map((item, i) => (
                      <>
                        <div className="input-container form-group mb-4">
                          <input
                            type="text"
                            className={
                              item.isRequired && formError[item.name]
                                ? "input form-control  is-invalid"
                                : "input form-control"
                            }
                            id={item.name}
                            name={item.name}
                            placeholder=" "
                            defaultValue={formData[item.name]}
                            onChange={onInputChangeHandler}
                            onBlur={onTouchHandler}
                            maxLength={item.maxLength}
                          />
                          <div className="cut"></div>
                          {/* <div className="input-icon-button" onClick={() => toggleShowPassword(item.name)}>
                            {
                              showPassword[item.name] ? (
                                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIuMDE1IDdjNC43NTEgMCA4LjA2MyAzLjAxMiA5LjUwNCA0LjYzNi0xLjQwMSAxLjgzNy00LjcxMyA1LjM2NC05LjUwNCA1LjM2NC00LjQyIDAtNy45My0zLjUzNi05LjQ3OC01LjQwNyAxLjQ5My0xLjY0NyA0LjgxNy00LjU5MyA5LjQ3OC00LjU5M3ptMC0yYy03LjU2OSAwLTEyLjAxNSA2LjU1MS0xMi4wMTUgNi41NTFzNC44MzUgNy40NDkgMTIuMDE1IDcuNDQ5YzcuNzMzIDAgMTEuOTg1LTcuNDQ5IDExLjk4NS03LjQ0OXMtNC4yOTEtNi41NTEtMTEuOTg1LTYuNTUxem0tLjAxNSAzYy0yLjIwOSAwLTQgMS43OTItNCA0IDAgMi4yMDkgMS43OTEgNCA0IDRzNC0xLjc5MSA0LTRjMC0yLjIwOC0xLjc5MS00LTQtNHoiLz48L3N2Zz4="></img>
                              ) : (
                                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkuNjA0IDIuNTYybC0zLjM0NiAzLjEzN2MtMS4yNy0uNDI4LTIuNjg2LS42OTktNC4yNDMtLjY5OS03LjU2OSAwLTEyLjAxNSA2LjU1MS0xMi4wMTUgNi41NTFzMS45MjggMi45NTEgNS4xNDYgNS4xMzhsLTIuOTExIDIuOTA5IDEuNDE0IDEuNDE0IDE3LjM3LTE3LjAzNS0xLjQxNS0xLjQxNXptLTYuMDE2IDUuNzc5Yy0zLjI4OC0xLjQ1My02LjY4MSAxLjkwOC01LjI2NSA1LjIwNmwtMS43MjYgMS43MDdjLTEuODE0LTEuMTYtMy4yMjUtMi42NS00LjA2LTMuNjYgMS40OTMtMS42NDggNC44MTctNC41OTQgOS40NzgtNC41OTQuOTI3IDAgMS43OTYuMTE5IDIuNjEuMzE1bC0xLjAzNyAxLjAyNnptLTIuODgzIDcuNDMxbDUuMDktNC45OTNjMS4wMTcgMy4xMTEtMi4wMDMgNi4wNjctNS4wOSA0Ljk5M3ptMTMuMjk1LTQuMjIxcy00LjI1MiA3LjQ0OS0xMS45ODUgNy40NDljLTEuMzc5IDAtMi42NjItLjI5MS0zLjg1MS0uNzM3bDEuNjE0LTEuNTgzYy43MTUuMTkzIDEuNDU4LjMyIDIuMjM3LjMyIDQuNzkxIDAgOC4xMDQtMy41MjcgOS41MDQtNS4zNjQtLjcyOS0uODIyLTEuOTU2LTEuOTktMy41ODctMi45NTJsMS40ODktMS40NmMyLjk4MiAxLjkgNC41NzkgNC4zMjcgNC41NzkgNC4zMjd6Ii8+PC9zdmc+"></img>
                              )
                            }
                          </div> */}
                          <label className="placeholder">{t(`${item.label}`)}</label>
                          <span className="h6 text-danger">
                            {item.isRequired &&
                              formError[item.name] &&
                              (formError[item.name] === "req"
                                ? t(item.error)
                                : (formError[item.name] === "regx") ? t(item.type_error) : "Password did not match")}
                          </span>
                        </div>

                      </>
                    ))}
                    <button type="submit" className={
                      !formData.newpassword
                        ? "btn-secondary btn mt-4 ctatwobutton"
                        : "mt-4 ctaonebutton"
                    } onClick={formSubmitHandler} disabled={!formData.newpassword}>{t("PASSWORDAUTHORIZATION.CONTINUE")}</button>
                    {
                      timerStart ? <div className="otptime"><Timer show={timerStart} onCompletion={() => onTimerComplete()} /></div>
                        :
                        <div className="otptime" onClick={onResendOTPClick}> {t("PASSWORDAUTHORIZATION.RESENDOTP")}</div>
                    }


                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
      <ToastContainer />


    </>

  );
};

export default NewPaawordJsx;
