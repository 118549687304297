import React, { Component } from 'react';
import MyPlansJsx from "./myPlane.jsx"


export class MyPlansJsxclass extends Component {

    render() {
      return (
        <div>
          <MyPlansJsx/>
        </div>
      );
    }
  }

  export default MyPlansJsxclass;