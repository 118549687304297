import axios from "axios";
import React, { Component } from "react";
import { baseURL } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";
import { nameRegex, cardRegex, cvcRegex, encrypt } from '../../../../shared/helper.js';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import MyPaymentsJsx from "./myPayment.jsx"

export class MyPaymentsJsxclass extends Component {

  constructor(props) {
    super(props);
    // const cardDetails = JSON.parse(sessionStorage.getItem("addCard"))

    this.state = {
        userData: {
            answer: ''
        },
        // formData: {
        //     name: cardDetails?.name,
        //     card: cardDetails ? decrypt(cardDetails?.card) : cardDetails?.card,
        //     month: cardDetails?.month,
        //     year: cardDetails?.year,
        //     cvc: cardDetails ? decrypt(cardDetails?.cvc) : cardDetails?.cvc,
        // },
        formData: {
            name: '',
            card: '',
            month:'',
            year:'',
            cvc:'',
        },
        paymentData: null,
        answerData: null,
        isLoading: false,
        formError: {},
        isShowCard: false,
        isCardSummary:false,
        isPaymentSummary:false,
        formSubmitButtonDisable:true
    }
}
replaceCard = () => {
    this.setState({ isShowCard: true })

}
componentDidMount = () => {
    this.getPaymentData();
    
}
getPaymentData = () => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    this.setState({ isLoading: true })

    axios.get(`${baseURL}/institute/${instituteId}/subscriptionHistory`, {
        headers: { 'x-access-token': token }
    }).then(res => {
        this.setState({ paymentData: res.data.data, isLoading: false })
    }).catch(err=>{
        this.setState({ isLoading: false })
      })
}
onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
        if (!this.inputRegexValidation(name, value)) {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: name === 'month' ? (value > 12 ? 12 : value) : value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    } else {
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                formError: {
                    ...this.state.formError,
                    [name]: "",
                },
            },
            () => this.isFromSubmissionBlock()
        );
    }
};
onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
        this.setState({
            formError: {
                ...this.state.formError,
                [name]: "req",
            },
        });
    } else {
        if (!this.inputRegexValidation(name, value)) {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "regx",
                },
            });
        }
    }
};

inputRegexValidation = (name, value) => {
    // if (someday < today) {
    // alert("The expiry date is before today's date. Please select a valid expiry date");
    // return false;
    // }


    if (name === "name") {
        return nameRegex.test(value);
    } else if (name === "card") {
        return cardRegex.test(value)
    } else if (name === "month") {
        if (value > 12) {
            return false;
        }

        let today, someday
        let exMonth = value;
        let exYear = this.state.formData.year ? this.state.formData.year : (new Date().getFullYear() + 1);
        today = new Date();
        someday = new Date();
        someday.setFullYear(exYear, exMonth, 0);

        if (!isNaN(someday.getTime())) {
            // date is valid
            if (!(someday < today)) {
                this.setState(
                    {
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                            'year': ""
                        },
                    }
                );

                return true
            }
        }

        //return monthRegex.test(value)

    } else if (name === "year") {

        let today, someday
        let exMonth = this.state.formData.month;

        if (exMonth > 12) {
            return false;
        }

        let exYear = value;
        today = new Date();
        someday = new Date();
        someday.setFullYear(exYear, exMonth, 0);

        if (!isNaN(someday.getTime())) {
            // date is valid
            if (!(someday < today)) {
                this.setState(
                    {
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                            'month': ""
                        },
                    },
                );


                return true
            }
        }
        //return yearRegex.test(value) && value >= current_year


    } else if (name === "cvc") {
        return cvcRegex.test(value)
    }
};

isFromSubmissionBlock = () => {
    let { name, card, month, year, cvc } = this.state.formData;
    if (name) {
        if (
            !this.inputRegexValidation("name", name) ||
            !this.inputRegexValidation("card", card) ||
            !this.inputRegexValidation("month", month) ||
            !this.inputRegexValidation("year", year) ||
            !this.inputRegexValidation("cvc", cvc)
        ) {
            this.setState({
                formSubmitButtonDisable: true,
            });
        } else {
            this.setState({
                formSubmitButtonDisable: false,
            });
        }
    } else {
        this.setState({
            formSubmitButtonDisable: true,
        });
    }
};

formSubmitHandler = (e) => {
    this.setState({
        isShowCard: false,
        isCardSummary:true
    })
    // e.preventDefault();
    const { name, card, month, year, cvc } = this.state.formData;
    const payloadData = {
        "name": name,
        "card": encrypt(card),
        "month": month,
        "year": year,
        "cvc": encrypt(cvc),
    }
    sessionStorage.setItem("addCard", JSON.stringify(payloadData))
};
backToAddCard = (e) => {
    this.setState({
        isShowCard: true,
        isCardSummary:false
    })
   
};
resetState = () =>{
    this.setState({
        formData: {
            name: '',
            card: '',
            month:'',
            year:'',
            cvc:'',
        },
    })
}
onBackCardClick = () =>{
    this.setState({isShowCard:false})
    this.resetState()

}
backToPayment = () =>{
    this.setState({isShowCard:false,isCardSummary:false,isPaymentSummary:false})
    this.resetState()
    sessionStorage.removeItem("addCard")

    
}


// handleSubmit = () => {
//   this.props.history.push("/otpGeneration");
// }
onBackClick = () => {
    this.props.history.goBack()
}

onReplaceCardClick = () =>{
    this.setState({isShowCard:true})
}

onCompletePayment = () =>{
    this.setState({isLoading:true})
    const expertId = JSON.parse(sessionStorage.getItem("masterID"));
    let token = JSON.parse(sessionStorage.getItem('token'));
    const { card, month, year, cvc } = this.state.formData;
    const payloadData = {
        instituteId:expertId,
        "cardNumber": encrypt(card),
        "expiryMonth": encrypt(month),
        "expiryYear": encrypt(year),
        "cvc": encrypt(cvc),
    }
    axios.post(`${baseURL}/subscription/replaceCard`,payloadData,{
        headers: { 'x-access-token': token }
    }).then(res=>{
        this.setState({isLoading:false, isPaymentSummary:true,isCardSummary:false})

    }).catch(err=>{
        this.setState({isLoading:false});
        toast.error(err.response.data.error.message);
    })
}
invoiceDownload = (invoiceId) =>{
    
    let token = JSON.parse(sessionStorage.getItem('token'));

    this.setState({isLoading:true})
    const expertId = JSON.parse(sessionStorage.getItem("masterID"));
    
   
    axios.get(`${baseURL}/invoice/${invoiceId?.stripeInvoiceId}`,{
        headers: { 'x-access-token': token }
    }).then(res=>{
        this.setState({isLoading:false})
        window.open(res?.data?.data?.invoiceUrl)

    }).catch(err=>{
        this.setState({isLoading:false});
        toast.error(err.response.data.error.message);
    })
}

render() {
    return (
      <div>
        <MyPaymentsJsx
         paymentData={this.state.paymentData}
         handleSubmit={this.handleSubmit}
         onBackClick={this.onBackClick}
         isShowCard={this.state.isShowCard}
         formData={this.state.formData}
         onInputChangeHandler={this.onInputChangeHandler}
         onTouchHandler={this.onTouchHandler}
         formSubmitButtonDisable={this.state.formSubmitButtonDisable}
         formError={this.state.formError}
         formSubmitHandler={this.formSubmitHandler}
         isLoading={this.state.isLoading}
         replaceCard={this.replaceCard}
         onReplaceCardClick={this.onReplaceCardClick}
         isCardSummary={this.state.isCardSummary}
         isPaymentSummary={this.state.isPaymentSummary}
         onCompletePayment={this.onCompletePayment}
         invoiceDownload={this.invoiceDownload}
         onBackCardClick={this.onBackCardClick}
         backToPayment={this.backToPayment}
         backToAddCard={this.backToAddCard}

     />
     {this.state.isLoading && <Loader />}
     <ToastContainer />
 </div>
);
}
}

export default MyPaymentsJsxclass;