import React from 'react'
import leftarrow from "../../../assets/images/left-arrow.svg";
import passleftgraphics from "../../../assets/images/passleftgraphics.svg";
import { useTranslation } from "react-i18next";
import login1json from "./forgotPassword.json";

const ForgotPassJsx = (props) => {
  const { t } = useTranslation();
  const {
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    isLoading
  } = props;
  return (
    <>

      <section className="loginscreeninstitute registrationinstitute passw">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
                <div className="back_btn" onClick={() => props.onBackClick()}><img src={leftarrow} alt={''} /></div>
                <div className="detailsleft plaindetailsleft">
                  <h1>Enter Your Email</h1>
                  <div className="instituteleft-illustration martop60">
                    <img src={passleftgraphics} alt={''} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext forgotpass passwordauthorization martop60 paddingbot60">
                <div className="forgetpassinner">
                  <h2 className="h2_style">Enter Email</h2>
                  <h5 className="h2_style color-light-blue">You will receive an OTP in your email</h5>


                  <div className="ft-inner otpauthentication">
                    <h5 className="h5_style text-left martop60">Enter your email</h5>
                    {/* <div className="mb-4 mt-2 input-container form-group">
                    <input type="text" className="input form-control" placeholder=" " />
                    <div className="placeholder">Enter your E-mail</div>
                  </div> */}
                    {login1json.map((item, i) => (
                      <>
                        {item.fieldType === "input" &&
                          <div className="mb-4 input-container form-group">
                            <input
                              onChange={onInputChangeHandler}
                              onBlur={onTouchHandler}
                              required
                              type={item.type}
                              placeholder=" "
                              className={
                                item.isRequired && formError[item.name]
                                  ? "input form-control  is-invalid"
                                  : "input form-control"
                              }
                              id={item.name}
                              name={item.name}

                            />
                            <div className="cut"></div>
                            <label class="placeholder">Enter your E-mail</label>

                            <span className="text-danger">
                              {item.isRequired &&
                                formError[item.name] &&
                                (formError[item.name] === "req"
                                  ? t(item.error)
                                  : t(item.type_error))}
                            </span>
                          </div>
                        }
                      </>
                    ))}
                    <button disabled={formSubmitButtonDisable} onClick={formSubmitHandler}
                      type="button"
                      className={
                        formSubmitButtonDisable
                          ? "btn-secondary btn mt-3 ctatwobutton"
                          : "mt-3 ctaonebutton"
                      }

                    > {isLoading ? "Processing........" : "Continue"}</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>

  );
};

export default ForgotPassJsx;
