import axios from "axios";
import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { baseURL } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";
import MyServicesJsx from "./myServices.jsx"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
export class MyServicesJsxclass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      serviceData: null,
      isLoading: false
    }
  }

  componentDidMount = () => {
    this.getServiceData();
  }

  getServiceData = () => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    this.setState({ isLoading: true })

    axios.get(`${baseURL}/institute/${instituteId}/services`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ serviceData: res.data.data, isLoading: false })
    }).catch(err => {
      this.setState({ isLoading: false })
    })
  }

  onServiceSave = (data, catId) => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));

    const payload = {
      institutionServices: data || null,
      institutionCategoryId: catId
    }
    if (data.length === 0) {
      swal("Cancelled", "At-least one service to be selected.", "error");
      this.getServiceData();

    } else {
      this.setState({ isLoading: true })

      axios.post(`${baseURL}/institute/${instituteId}/services`, payload, {
        headers: { 'x-access-token': token }
      }).then(res => {
        this.setState({ isLoading: false })
        this.getServiceData();
      }).catch(err => {
        this.setState({ isLoading: false })
        toast.error(err?.response.data.error.message)
  
      })
    }
    
  }

  render() {
    return (
      <div>
        <MyServicesJsx
          serviceData={this.state.serviceData}
          onServiceSave={this.onServiceSave}
        />
        {this.state.isLoading && <Loader />}
        <ToastContainer />
      </div>
    );
  }
}

export default MyServicesJsxclass;
