import React from 'react'
import leftarrow from "../../../../assets/images/left-arrow.svg";
import { NEW_URL } from '../../../../constants/applicationConstants';
import { Pagination } from '../../../common/pagination/pagination';
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { Footer } from '../../../common/footer/footer'

const ListOfProductsJsx = (props) => {
  const history = useHistory();
  const location = useLocation()
  const { state: locationState } = location

  const {
    data,
    onPaginationButtonClick,
    onOfferNameChange,
    onSearchButtonClick,
    category,
    subCategory
  } = props;

  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="listofoffermainsection">
        <div className="container">
          <div className="leftsightbackdetails listofofferleft padtop60">
            <div className="back_btn" onClick={() => locationState?.frompage ? history.push(`${locationState?.pagePath ?? '/'}#${locationState?.frompage}`) : history.goBack()}><img src={leftarrow} alt={' '} /></div>
            <div className="plaindetailsleft">
              <h1 className="h2_style font-weight-bold">List of Products</h1>
              <h5 className="h5_style">We collect the best products for you! Explore our top offerings & bolster your finances. </h5>
              <h5 className="h5_style font-weight-bold">Click on “View Details” for a detailed view. </h5>
              <h5 className="h5_style font-weight-bold">You can also refine your search by entering the name of the product you're looking for.  </h5>
              {/* <h5 className="h5_style">Have you tried it yet? Just tap on any icon below!</h5> */}
            </div>
          </div>
          <div className="myexpertformarea myexpertformareaproduct martop40">

            <div className="input-container form-group psenter">
              <input type="text" className="form-control input" placeholder=" " onChange={onOfferNameChange} />
              <div className="placeholder">Enter the product name you want to search for</div>
            </div>

            <div className="input-container form-group"><button className="ctaonebutton" onClick={() => onSearchButtonClick()}>Search</button></div>
          </div>
          <div className="listofproduct mt-5 mb-5">
            <div className="row">
              {
                data?.data?.map(data => {
                  return <div className="col-lg-6 col-sm-6">
                    <div className="listofadinner mb-4">
                      <div className="listofimage">
                        <img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '} />
                      </div>
                      <div className="listofadinnerpop">
                        <div className="listofadtext">
                          <h3>{data.productName}</h3>
                        </div>
                        <div className="listofadbutton">
                          {data?.externalLink && <button className="ctatwobutton" onClick={() => window.open(data?.externalLink, '_blank')}>Apply Now</button>}
                          <Link
                            to={{
                              pathname: "/detailedProduct",
                              state: { data: data, category: category, subCategory: subCategory }
                            }}
                            className="ctatwobutton ctanewtwobutton"
                            onClick={() => window.scrollTo(0, 0)}>
                            View Details
                            </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>

            {data?.data?.length === 0 && <div>No Record Found</div>}

            {data?.data?.length > 0 && <Pagination
              limit={10}
              totalCount={data?.meta?.totalCount}
              onPaginationButtonClick={onPaginationButtonClick}
            />}
          </div>
        </div>
      </section>
      <Footer />
    </>

  );
};

export default ListOfProductsJsx;