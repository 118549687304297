import React from 'react'
import { Component } from 'react';
import HelocJsx from './heloc.jsx'

export default class Heloc extends Component{
    render(){
        return(
            <div>
                <HelocJsx />
            </div>
        )
    }
}