import React from 'react';
import { useTranslation } from "react-i18next";
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg"
import PlanJSON from '../../../registration/registrationCommon/plans/plans.json';
import PlanJSON1 from '../../../registration/registrationCommon/plansExpert/plans.json';


const MyPlansJsx = () => {

  const { t } = useTranslation();

  const data = sessionStorage.getItem("planName");
  const isExpert = localStorage.getItem("flowType");
  const PlansToggle = isExpert === "expert" ? PlanJSON1  :PlanJSON

  return (
    <>
      <section className="myplandashboard">
        
              
              <div className="mainplan">
              <p>These features are available with your current plan</p>
                <div className="planwhole mt-4">
                <div className="planesall">
                  <div className="row">
                    <div className="col-lg-9 col-sm-9">
                      <div className="planesinner">
                        <h2 className="h3_style text-center">{t("PLANS.BOOSTBUSSINESS")}</h2>
                        <hr></hr>
                        <ul>
                          {
                            PlansToggle.Plans.map(plans => {
                              return <li>{plans.name} <span className="plantooltip custooltip" ><img src={planpricingtooltip}  alt={''} className="tooltipim" />
                                <div class="tooltipcontent"> {plans.tooltipTitle}</div>
                              </span></li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 no-pad-left">
                      <div className={`planesinnerright elite broadtp`} >
                        <h2 className="text-center h3_style">{data === 'Elite' ? 'Elite' : 'Premium'}</h2>
                        <hr></hr>
                        <ul>
                          {
                            PlansToggle.Plans.map(plans => {
                              return <li>{data==='Elite' ? plans.Elite : plans.Premium}</li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                 </div>
              </div>
            
      </section>
    </>
  )
}
export default MyPlansJsx;