import React from 'react'
import { Component } from 'react';
import TermsConditionJsx from './termsCondition.jsx'

export default class TermsCondition extends Component{
    render(){
        return(
            <div>
                <TermsConditionJsx />
            </div>
        )
    }
}