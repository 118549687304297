import React, { Component } from "react";
import DetailedOffersJsx from "./detailedOffer.jsx"

export class DetailedOffersJsxclass extends Component {

  constructor(props){
    super(props);
    this.state={
      data:null
    }
  }

  componentDidMount = () =>{
    if(this.props.location?.state?.data){
      const {data} = this.props.location?.state;
      data && this.setState({data:data});
    }
  }

    render() {
      return (
        <div>
          <DetailedOffersJsx data={this.state.data}/>
        </div>
      );
    }
  }

  export default DetailedOffersJsxclass;