import React, { Component } from "react";
import AccountSettingJsx from "./accountSetting.jsx";
import {
  baseURL, uploadURL
} from "../../../../constants/applicationConstants.js";
import axios from 'axios'
import { Loader } from '../../../common/loader/loader.jsx';
import { nameRegex, websiteRegex, allRegex, getProperDateMonth, emailRegex, postalCodeRegex, alphaNumericRegex } from "../../../../shared/helper.js";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import swal from "sweetalert";

export class AccountSettingJsxclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        description: '',
        firstName: '',
        lastName: '',
        email: '',
        alternativeEmail: '',
        phone: '',
        secondaryPhone: '',
        dateOfBirth: null,
        occupation: '',
        annualIncome: '',
        interest: '',
        netWorth: '',
        occupationCountry: '',
        annualHouseHoldIncome: '',
        gender: '',
        facebook: "",
        insta: "",
        twitter: "",
        linkedin: "",
        certification: [],
        awards: [],
        institutionName: '',
        institutionCategory: '',
        institutionSubCategory: '',
        businessName: '',
        licenseNo: '',
        businessPhone: '',
        businessEmail: '',
        yearsOfEperience: '',
        businessWebsite: '',
        street: '',
        province: '',
        city: '',
        postalCode: '',
        country: '',
        institutionCategoryId: '',
        institutionSubCategoryId: '',
        expertSpecializationId: '',
        instituteId: ''


      },
      provinceList: null,
      countryList: null,
      sizeList: null,
      segmentList: null,
      categoryList: null,
      isProcessing: false,
      formError: {},
      editInstitite: false,
      editLogo: false,
      editContact: false,
      editAddress: false,
      editOther: false,
      editSpecial: false,
      editSocial: false,
      formSubmitButtonDisable: true,
      isLoading: false,
      _URL: window.URL || window.webkitURL,
      image: null,
      imageName: null,
      imageUrl: "",
      fileInfo: null,
      profileImagePreviewUrl: null,
      isEditMode: false,
      responseData: null
    };

  }
  onNextButtonClick1 = () => {
    this.setState({
      isLoading: true
    })

    let image = this.state.image;

    const payloadData1 = {
      imageUrl: image
    }

    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let customerId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))



    if (customerId) {
      axios.put(`${baseURL}/customer/${customerId}/profilePic`, payloadData1, {
        headers: { 'x-access-token': token }
      }).then(res => {
        if (res.data.success) {
          toast.success("Data Updated Successfully!")
          this.setState({
            isLoading: false
          })
          this.setState({ editLogo: false })
          this.setState({ profileImagePreviewUrl: null })
          this.callDetails()
          window.location.reload()
        }
        else {
          toast.error(res.data.error.message)
          this.setState({
            isLoading: false
          })
        }

      }).catch(err => {
        toast.error(err.response.data.error.message)
        this.setState({
          isLoading: false
        })
      })
    }
  }

  onNextButtonClick = () => {
    this.setState({
      isLoading: true
    })
    let formData1 = new FormData();

    if (this.state.fileInfo) {
      formData1.append('image', this.state.fileInfo)
      formData1.append('imageCategory', "customer")

      axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {
        if (resp.status === 200) {
          this.setState({ image: resp.data.filename })
          toast.success("Image uploaded successfully");
          setTimeout(() => {
            this.onNextButtonClick1()
          }, 2000)
        }
      }).catch(() => {
        this.setState({
          isLoading: false
        })
      })
    }
    else this.onNextButtonClick1();
  };

  componentDidMount = () => {
    this.setState({ isLoading: true })
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let instituteId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    if (!token) {
      this.props.history.push("/");
    }
    if (token && instituteId) {
      this.callDetails()
    }


    // next part
    axios.get(`${baseURL}/instituteSegments`).then(res => {
      this.getUpdateJSON('segment', res.data.data);
      let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
      let instituteId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))

      if (token && instituteId) {
        const category = res.data.data.filter(item => item.institutionSegmentId == 1)[0]?.institutionCategories;
        this.getUpdateJSON('category', category);
        let tempArr = [];

        category?.map(cat => {
          this.state.formData.tempCat?.map(abc => {
            if (cat.institutionCategoryId === abc.institutionCategoryId)
              tempArr.push({ label: cat.name, value: cat.institutionCategoryId });
          })
        })
        this.setState({ formData: { ...this.state.formData, category: [...tempArr] } })
      }
    })

    axios.get(`${baseURL}/countries`).then(res => {
      this.getUpdateJSON('country', res.data.data);
    })

    axios.get(`${baseURL}/states`).then(res => {
      this.getUpdateJSON('province', res.data.data);
      // this.setState({provinceList: res.data.data})
    })

    axios.get(`${baseURL}/institutionSizes`).then(res => {
      this.getUpdateJSON('sizeOfInstitution', res.data.data);
      this.setState({ isLoading: false })
    })
  }

  // get specialization
  getSpecialization = (data) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          institutionName: data?.institute.name,
          institutionCategory: data?.parentInstitutionCategory,
          institutionSubCategory: data?.subInstitutionCategory.name,
          businessName: data?.businessName,
          licenseNo: data?.businessLicenseNumber,
          businessPhone: data?.businessPhoneNumber,
          businessEmail: data?.businessEmail,
          yearsOfEperience: data?.experienceInYears,
          businessWebsite: data?.businessWebsite,
          street: data?.address,
          province: data?.state?.stateCode,
          city: data?.city,
          postalCode: data?.postalCode,
          country: data?.country?.countryCode,
          institutionCategoryId: data?.institutionCategoryId,
          institutionSubCategoryId: data?.institutionSubCategoryId,
          expertSpecializationId: data?.expertSpecializationId,
          instituteId: data?.instituteId


        },

      }
    );
  }
  onDeleteButtonClick = (data) => {

    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this Specialization",
      icon: "warning",
      buttons: [
        'Do not cancel it!',
        'Yes, Delete it!'
      ],
      dangerMode: true,
    }).then(isConfirm => {
      if (isConfirm) {
        this.deleteSpecialization(data)
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })
  }

  deleteSpecialization = (data) => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    this.setState({ isLoading: true })
    axios.delete(`${baseURL}/expertSpecialization/${data}`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({
        isLoading: false
      })
      this.callDetails()
    })
  }

  changeHideState = (action) => {
    this.setState({ editInstitite: action })
    this.setState({ formError: {} })
    if (action === false) {
      this.callDetails()
    }
    // this.setState({formData: {}})
  }
  changeLogoState = (action) => {
    this.setState({ editLogo: action })
    this.setState({ formError: {} })
    if (action === false) {
      this.callDetails()
    }
  }
  changeContactState = (action) => {
    this.setState({ editContact: action })
    this.setState({ formError: {} })
    if (action === false) {
      this.callDetails()
    }
  }
  changeSocialState = (action) => {
    this.setState({ editSocial: action })
    this.setState({ formError: {} })
    if (action === false) {
      this.callDetails()
    }
  }
  changeAddressState = (action) => {
    this.setState({ editAddress: action })
    this.setState({ formError: {} })
    if (action === false) {
      this.callDetails()
    }
  }
  changeOtherState = (action) => {
    this.setState({ editOther: action })
    this.setState({ formError: {} })
    if (action === false) {
      this.callDetails()
    }
  }
  changeSpecialState = (action) => {
    this.setState({ editSpecial: action })
    this.setState({ formError: {} })
    if (action === false) {
      this.callDetails()
    }
  }
  getUpdateJSON = (label, values) => {
    let dropdownOptions = [{}];

    if (label === 'segment') {
      values.map(value => {
        dropdownOptions.push({ label: value.name, value: value.institutionSegmentId, category: value.institutionCategories })
      })
      this.setState({ segmentList: dropdownOptions })
    }
    else if (label === 'category') {
      dropdownOptions = []
      values?.map(value => {
        dropdownOptions.push({ label: value.name, value: value.institutionCategoryId })
      })
      this.setState({ categoryList: dropdownOptions });
    }
    else if (label === 'sizeOfInstitution') {
      values.map(value => {
        dropdownOptions.push({ label: value.size, value: value.institutionSizeId })
      })
      this.setState({ sizeList: dropdownOptions })
    }
    else if (label === 'country') {
      values.map(value => {
        dropdownOptions.push({ label: value.name, value: value.countryCode })
      })
      this.setState({ countryList: dropdownOptions })
    }
    else if (label === 'province') {
      values.map(value => {
        dropdownOptions.push({ label: value.name, value: value.stateCode })
      })
      this.setState({ provinceList: dropdownOptions })
    }
  }
  callDetails = () => {
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let customerId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    axios.get(`${baseURL}/customer/${customerId}`, {
      headers: { 'x-access-token': token }
    }).then(res => {

      const {

        // description, firstName, lastName, email, alternativeEmail,
        // imageUrl, socialMedia, phoneNumber, secondaryPhoneNumber,
        // dateOfBirth, gender, certificates, awards
        firstName, lastName, email, alternativeEmail, phoneNumber, alternatePhoneNumber, dateOfBirth, gender, occupation, annualIncome,
        interests,
        netWorth,
        occupationCountry,
        annualHouseHoldIncome, country, imageUrl
      } = res.data.data;
      // const { facebookUrl, instagramUrl, linkedinUrl, twitterUrl } = socialMedia;
      // const certificateList = []
      // certificates?.map(value => {
      //   certificateList.push(value.certificate)
      // })
      // const awardList = []
      // awards?.map(value => {
      //   awardList.push(value.award)
      // })

      const date = dateOfBirth && dateOfBirth.split('-');
      this.setState({ responseData: res.data.data })
      this.setState({
        formData: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          alternativeEmail: alternativeEmail,
          phone: phoneNumber,
          secondaryPhone: alternatePhoneNumber,
          dateOfBirth: date && {
            day: parseInt(date && date[2]),
            month: parseInt(date && date[1]),
            year: parseInt(date && date[0])
          },

          gender: gender,
          occupation: occupation,
          annualIncome: annualIncome,
          interest: interests,
          netWorth: netWorth,
          occupationCountry: occupationCountry,
          annualHouseHoldIncome: annualHouseHoldIncome,
          // facebook: facebookUrl,
          // insta: instagramUrl,
          // twitter: twitterUrl,
          // linkedin: linkedinUrl,
          // certification: certificateList,
          // awards: awardList
          country: country.name


        },
        isLoading: false,
        isEditMode: true,
        imageUrl: imageUrl,
      })

      this.setState({ isLoading: false })
    })
  }
  getProperPhoneNumber = (value) => {

    if (!isNaN(value)) {
      return value
    }

    const number = value.split(' ');
    return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
  }
  inputRegexValidation = (name, value) => {

    if (name === "institutionName") {
      return alphaNumericRegex.test(value);
    } else if (name === "benefits") {
      return nameRegex.test(value);
    }
    else if (name === "feature") {
      return nameRegex.test(value);
    }
    else if (name === "street" || name === "yearsOfEperience") {
      return allRegex.test(value);
    }
    else if (name === 'licenseNo' || name === "regulatory") {
      return alphaNumericRegex.test(value);
    }
    else if (name === "postalCode") {
      return postalCodeRegex.test(value);
    }
    else if (name === "website" || name === "businessWebsite")
      return websiteRegex.test(value);
    else if (name === "description") {
      return allRegex.test(value);
    } else if (name === "email" || name === "alternativeEmail" || name === "businessEmail") {
      return emailRegex.test(value);
    } else if (name === "phone" || name === "secondaryPhone" || name === "businessPhone") {
      return !this.getProperPhoneNumber(value).includes('_');
    }
    else if (name === 'facebook')
      return value.includes('facebook')
    else if (name === 'insta')
      return value.includes('instagram')
    else if (name === 'twitter')
      return value.includes('twitter')
    else if (name === 'linkedin')
      return value.includes('linkedin')

    else {
      return nameRegex.test(value)
    }
  };
  onMetaTagChange = (e) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          awards: e
        },

      }
    );


  }
  onMetaTagChange1 = (e) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          certification: e
        },

      }
    );
  }

  onInputChangeHandler = (e) => {
    let token = JSON.parse(sessionStorage.getItem('token'));

    let name = e.target.name;
    let value = e.target.value;

    if (name === "category") {
      this.getSubCategories(value)
    }
    if (name === "subCategory") {
      this.setState({ isLoading: true })
      axios.get(`${baseURL}/productTypes?institutionSubCategoryId=${value}`, {
        headers: { 'x-access-token': token }
      }).then(res => {
        this.setState({ productTypes: res.data.data })
        this.setState({ isLoading: false })

      })
    }
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };
  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };
  handleChange = (e, name) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          [name]: e.value,
          category: []
        },
        formError: {
          ...this.state.formError,
          [name]: "regx",
        },
      },
      () => this.isFromSubmissionBlock()
    );
  }
  isFromSubmissionBlock = () => {
    let { institutionName, legalInstitutionName, sizeOfInstitution } = this.state.formData;

    if (institutionName && legalInstitutionName && sizeOfInstitution) {

      if (
        this.inputRegexValidation("institutionName", institutionName) &&
        this.inputRegexValidation("legalInstitutionName", legalInstitutionName) &&
        this.inputRegexValidation("sizeOfInstitution", sizeOfInstitution)
      ) {
        this.setState({
          formSubmitButtonDisable: false,
        });
      } else {
        this.setState({
          formSubmitButtonDisable: true,
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };
  onDateChange = (date) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          dateOfBirth: date,
        }
      },

    );
  }
  getProperPhoneNumber = (value) => {

    if (!isNaN(value)) {
      return value
    }

    const number = value && value.split(' ');
    return number && number[0].replace('(', '').replace(')', '') + number[1] + number[2]
  }
  onSubmitButtonClick = () => {
    this.setState({
      isLoading: true
    })
    const { facebook, insta, twitter, linkedin } = this.state.formData;
    const socialPayload = {
      "instagramUrl": insta,
      "facebookUrl": facebook,
      "twitterUrl": twitter,
      "linkedinUrl": linkedin,
    }
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let customerID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))


    axios.put(`${baseURL}/customer/${customerID}/socialMedia`, socialPayload, {
      headers: { 'x-access-token': token }
    }).then(res => {
      if (res.data.success) {
        toast.success("Data Updated Successfully!")
        this.setState({
          isLoading: false
        })
        this.setState({ editSocial: false })
        this.callDetails()
      }
      else {
        toast.error(res.data.error.message)
        this.setState({
          isLoading: false
        })
      }

    }).catch(err => {
      toast.error("something went wrong")
      this.setState({
        isLoading: false
      })
    })


  }
  onInstituteButtonClick = () => {
    this.setState({
      isLoading: true
    })
    const { firstName,
      description,
      lastName,
      email,
      alternativeEmail,
      phone,
      secondaryPhone,
      dateOfBirth,
      gender } = this.state.formData;
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))

    const instituteDetailsPayload = {
      // "tokenValidate": token,
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "alternativeEmail": alternativeEmail,
      "phoneNumber": this.getProperPhoneNumber(phone),
      "secondaryPhoneNumber": this.getProperPhoneNumber(secondaryPhone),
      "dateOfBirth": dateOfBirth && `${dateOfBirth?.year}-${getProperDateMonth(dateOfBirth?.month)}-${getProperDateMonth(dateOfBirth.day)}`,
      "gender": gender,
      "description": description
    }
    let customerId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    axios.put(`${baseURL}/customer/${customerId}`, instituteDetailsPayload, {
      headers: { 'x-access-token': token }
    }).then(res => {
      if (res.data.success) {
        toast.success("Data Updated Successfully!")
        this.setState({
          isLoading: false
        })
        this.setState({ editInstitite: false })
        this.callDetails()
      }
      else {
        toast.error(res.data.error.message)
        this.setState({
          isLoading: false
        })
      }

    }).catch(err => {
      toast.error("something went wrong")
      this.setState({
        isLoading: false
      })
    })


  }
  onContactButtonClick = () => {
    this.setState({
      isLoading: true
    })
    const { awards, certification } = this.state.formData;

    const primaryUserPayload = {
      "awards": awards,
      "certificates": certification,

    }
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let expertID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    axios.put(`${baseURL}/expert/${expertID}/awardsAndCertificates`, primaryUserPayload, {
      headers: { 'x-access-token': token }
    }).then(res => {
      if (res.data.success) {
        toast.success("Data Updated Successfully!")
        this.setState({
          isLoading: false
        })
        this.setState({ editContact: false })
        this.callDetails()
      }
      else {
        toast.error(res.data.error.message)
        this.setState({
          isLoading: false
        })
      }

    }).catch(err => {
      toast.error("something went wrong")
      this.setState({
        isLoading: false
      })
    })


  }
  onAddressButtonClick = () => {
    this.setState({
      isLoading: true
    })
    const { city, postalCode, street, province, country } = this.state.formData;

    const registerAddPayload = {
      "address": street,
      "city": city,
      "stateCode": province,
      "countryCode": country,
      "postalCode": postalCode.split(' ').toString().replace(',', ''),
    }
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let instituteId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    axios.put(`${baseURL}/institute/${instituteId}/account`, registerAddPayload, {
      headers: { 'x-access-token': token }
    }).then(res => {
      if (res.data.success) {
        toast.success("Data Updated Successfully!")
        this.setState({
          isLoading: false
        })
        this.setState({ editAddress: false })
        this.callDetails()
      }
      else {
        toast.error(res.data.error.message)
        this.setState({
          isLoading: false
        })
      }

    }).catch(err => {
      toast.error("something went wrong")
      this.setState({
        isLoading: false
      })
    })


  }

  onOthersButtonClick = () => {
    this.setState({
      isLoading: true
    })
    const {
      occupation, annualHouseHoldIncome, annualIncome, netWorth, country } = this.state.formData;

    const othersPayload = {


      "occupation": occupation,
      "annualIncome": annualIncome,
      "interests": [1, 3],
      "netWorth": netWorth,
      "occupationCountry": country,
      "annualHouseHoldIncome": annualHouseHoldIncome

    }
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let customerId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    axios.put(`${baseURL}/customer/${customerId}/occupation`, othersPayload, {
      headers: { 'x-access-token': token }
    }).then(res => {
      if (res.data.success) {
        toast.success("Data Updated Successfully!")
        this.setState({
          isLoading: false
        })

        this.setState({ editOther: false })
        this.callDetails()
      }
      else {
        toast.error(res.data.error.message)
        this.setState({
          isLoading: false
        })
      }

    }).catch(err => {
      toast.error(err.response.data.error.message)
      this.setState({
        isLoading: false
      })
    })


  }

  validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png');

  onFileChange = (e) => {
    if (e.target.files[0]?.size) {
      if (e.target.files[0]?.size < 512000) {
        if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
          // if ((file = e.target.files[0])) {
          //     img = new Image();
          //     img.onload = function () {
          //         if (this.height > 800) {
          //             toast.error("big height")
          //         }
          //         else{
          //             toast.error("small height")
          //             img.src = this.state._URL.createObjectURL(file);
          //             this.setState({ fileInfo: e.target.files[0] })
          //             this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
          //             this.setState({ imageName: e.target.files[0].name },()=> this.isFromSubmissionBlock())
          //         }
          //     };
          //     img.onerror = function () {
          //         toast.error("not a valid file: " + file.type);
          //     };
          // }
          this.setState({ fileInfo: e.target.files[0] }, () => this.isFromSubmissionBlock())
          this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
          this.setState({ imageName: e.target.files[0].name })
        }
        else
          toast.error('.jpeg/.jpg/.png format required')
      }
      else
        toast.error('File should be less than 500 kb.')
    }
  }

  render() {

    return (
      <div>

        <AccountSettingJsx
          callDetails={this.callDetails}
          formData={this.state.formData}
          responseData={this.state.responseData}
          onInputChangeHandler={this.onInputChangeHandler}
          onSubmitButtonClick={this.onSubmitButtonClick}
          onInstituteButtonClick={this.onInstituteButtonClick}
          onContactButtonClick={this.onContactButtonClick}
          onAddressButtonClick={this.onAddressButtonClick}
          onOthersButtonClick={this.onOthersButtonClick}
          provinceList={this.state.provinceList}
          countryList={this.state.countryList}
          sizeList={this.state.sizeList}
          handleChange={this.handleChange}
          formError={this.state.formError}
          onDateChange={this.onDateChange}
          changeHideState={this.changeHideState}
          segmentList={this.state.segmentList}
          categoryList={this.state.categoryList}
          editInstitite={this.state.editInstitite}
          editLogo={this.state.editLogo}
          editOther={this.state.editOther}
          editSpecial={this.state.editSpecial}
          onDeleteButtonClick={this.onDeleteButtonClick}

          changeSpecialState={this.changeSpecialState}
          editContact={this.state.editContact}
          editSocial={this.state.editSocial}
          editAddress={this.state.editAddress}
          changeAddressState={this.changeAddressState}
          changeContactState={this.changeContactState}
          changeLogoState={this.changeLogoState}
          changeOtherState={this.changeOtherState}
          changeSocialState={this.changeSocialState}
          onTouchHandler={this.onTouchHandler}
          onMetaTagChange={this.onMetaTagChange}
          onMetaTagChange1={this.onMetaTagChange1}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          imgURL={this.state.imageUrl}
          onFileChange={this.onFileChange}
          imageName={this.state.imageName}
          onNextButtonClick={this.onNextButtonClick}
          profileImagePreviewUrl={this.state.profileImagePreviewUrl}
          getSpecialization={this.getSpecialization}
        />
        {this.state.isLoading && <Loader />}
        <ToastContainer />
      </div>
    );
  }
}

export default AccountSettingJsxclass;
