import axios from 'axios';
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { emailRegex, nameRegex } from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';
import { ManageUserJSX } from './manageUser.jsx';
import swal from "sweetalert";

export default class ManageUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {
                firstName: "",
                lastName: "",
                email: "",
                phone: ""
            },
            data: null,
            formError: {},
            showModal: false,
            formSubmitButtonDisable: true,
            isLoading: false,
            instituteUserID:null
        }
    }

    componentDidMount = () => {
        this.getUserData();
    }

    getUserData = () => {
        this.setState({ isLoading: true });
        let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institute/${instituteId}/secondaryUsers`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ data: res.data.data, isLoading: false })
        }).catch(err=>{
        this.setState({ isLoading: false });
        })
    }

    onInputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        userData: {
                            ...this.state.userData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        userData: {
                            ...this.state.userData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    userData: {
                        ...this.state.userData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    };

    resetState = () =>{
        this.setState({
            userData: {
                firstName: "",
                lastName: "",
                email: "",
                phone: ""
            }
        })
    }

    isFromSubmissionBlock = () => {
        let { firstName, lastName, email, phone } = this.state.userData;

        if (firstName && lastName && email && phone) {
            if( this.inputRegexValidation("firstName", firstName) &&
            this.inputRegexValidation("lastName", lastName) &&
            this.inputRegexValidation("email", email) &&
            this.inputRegexValidation("phone", phone))
            this.setState({
                formSubmitButtonDisable: false
            });
            
            else
            this.setState({
                formSubmitButtonDisable: true
            });
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    inputRegexValidation = (name, value) => {
        if (name === 'email')
            return emailRegex.test(value);
        else if (name === 'firstName' || name === 'lastName')
            return nameRegex.test(value);
        else if (name === 'phone')
        return !this.getProperPhoneNumber(value).includes('_');

        return false;
    };

    getProperPhoneNumber = (value) => {

        if (!isNaN(value)) {
            return value
        }

        const number = value.split(' ');
        return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
    }

    onSubmitButtonClick = () => {
        this.setState({isLoading:true})
        const { firstName, lastName, email, phone } = this.state.userData;
        const payload = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "contactNumber": this.getProperPhoneNumber(phone)
        }

        let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
        let token = JSON.parse(sessionStorage.getItem('token'));
        if(this.state.instituteUserID){
            axios.put(`${baseURL}/institute/${instituteId}/instituteUser/${this.state.instituteUserID}`, payload, {
                headers: { 'x-access-token': token }
            }).then(res => {
                this.setState({isLoading:false})
                this.getUserData();
                this.setState({isLoading:false,showModal: false})
                this.resetState();
            }).catch(err => {
                toast.error(err.response.data.error.message);
                this.setState({isLoading:false})
            })
        }
        else{
            axios.post(`${baseURL}/institute/${instituteId}/secondaryUser`, payload, {
                headers: { 'x-access-token': token }
            }).then(res => {
                this.setState({isLoading:false})
                swal({
                    title: "Congratulations!",
                    text:"You have successfully created a new secondary user. We have sent an email to your secondary user having a temporary password. Kindly use it to log in.",
                    buttons: 'Ok',
                    dangerMode: true,
                  }).then(isConfirm => {
                    if (isConfirm) {
                        this.getUserData();
                        this.setState({isLoading:false,showModal: false})
                        this.resetState();
                    } 
                  })
            }).catch(err => {
                toast.error(err.response.data.error.message);
                this.setState({isLoading:false})
            })
        }
    }

    onCreateButtonClick = (status) => {
        this.setState({ showModal: status,instituteUserID:null,formSubmitButtonDisable:true });
        this.resetState();
        window.scrollTo({
            top: 1000,
            left: 100,
            behavior: 'smooth'
          })
    }

    onEditButtonClick = (data) => {
        this.setState({ showModal: true, instituteUserID: data.instituteUserId }, () => {
          window.scrollTo({
            top: 1000,
            left: 100,
            behavior: 'smooth'
          });
        });
    
        this.setState({
          userData:{
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.contactNumber
          }
        }, () => this.isFromSubmissionBlock())
      }

    onDeleteButtonClick = (data) => {
        swal({
          title: "Are you sure you want to delete?",
          buttons: [
            'No',
            'Yes'
          ],
          dangerMode: true,
        }).then(isConfirm => {
          if (isConfirm) {
            this.deleteAds(data)
          } else {
            //   swal("Cancelled", "Your imaginary file is safe :)", "error");
          }
        })
      }

      deleteAds = (data) => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        this.setState({ isLoading: true })
    
        axios.delete(`${baseURL}/instituteUser/${data.instituteUserId}`, {
          headers: { 'x-access-token': token }
        }).then(res => {
          this.setState({
            isLoading: false
          })
          this.getUserData();
        }).catch(err=>{
            this.setState({
                isLoading: false
              })
        })
      }

    render() {
        return (
            <div>
                <ManageUserJSX
                    showModal={this.state.showModal}
                    onCreateButtonClick={this.onCreateButtonClick}
                    onInputChangeHandler={this.onInputChangeHandler}
                    onTouchHandler={this.onTouchHandler}
                    userData={this.state.userData}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    data={this.state.data}
                    formError={this.state.formError}
                    onDeleteButtonClick={this.onDeleteButtonClick}
                    onEditButtonClick={this.onEditButtonClick}
                    instituteUserID={this.state.instituteUserID}
                />
                {this.state.isLoading && <Loader />}
                <ToastContainer />
            </div>
        )
    }
}
