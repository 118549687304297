import axios from "axios";
import React, { Component } from "react";
import { baseURL } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";
import ListOfAdsJsx from "./listOfAd.jsx"

export class ListOfAdsJsxclass extends Component {
  constructor(props){
    super(props);
    this.state={
      adsData:null,
      isProcessing: false,
      page: 1,
      instituteCategoryID:'',
      instituteSubcategoryID:'',
      categoryData:[],
      subCategoryData:[],
      legalName:'',
      fromPage:''
    }
  }

  componentDidMount = () =>{
    if(this.props.location?.state?.data){
      const {data,pagePath} = this.props.location?.state;
      pagePath && this.setState({fromPage:pagePath});
      data && this.setState({legalName:data.replace('/','')},()=> this.getOfferData());
    }
  }

  getOfferData = () =>{
    let flowName = localStorage.getItem('flowName');
    let subURL = '';
    let catqrURL = '';
    let subCatqrURL = '';
    subURL = this.state.fromPage ? (this.state.fromPage.includes('expertPpv') ? 'expert' : 'institute') : 'institute'

     catqrURL = this.state.fromPage ? (this.state.fromPage.includes('expertPpv') ? 'expertCategoryId' : 'institutionCategoryId') : 'institutionCategoryId'
     
     subCatqrURL = this.state.fromPage ? (this.state.fromPage.includes('expertPpv') ? 'expertSubCategoryId' : 'institutionSubCategoryId') : 'institutionSubCategoryId'
    // if(flowName === 'expert')
    //   subURL = 'expert'
    // else subURL = 'institute'

    this.setState({isProcessing:true})
    axios.get(`${baseURL}/${subURL}/${this.state.legalName}/adsPerPage?page=${this.state.page}&perPage=10&${catqrURL}=${this.state.instituteCategoryID}&${subCatqrURL}=${this.state.instituteSubcategoryID}`).then(res=>{
      this.setState({adsData:res.data,isProcessing:false});
    })

    axios.get(`${baseURL}/${subURL}/${this.state.legalName}/institutionCategories`).then(res=>{
      this.setState({categoryData:res.data.data,isProcessing:false});
    })
  }

  onPaginationButtonClick = (page) =>{
    window.scrollTo(0,0);
    this.setState({ page: page }, () => this.getOfferData())
  }

  onCategoryClick = (value) =>{
    const data = this.state.categoryData?.filter(catDT => catDT.institutionCategoryId == value);
    this.setState({subCategoryData:data[0]?.subInstitutionCategories});
  }


  onCatgeroryChange=(e)=>{
    this.setState({instituteSubcategoryID:''})
    this.setState({instituteCategoryID:e.target.value})
    this.onCategoryClick(e.target.value)
    
  }

  onSubcatgeroryChange=(e)=>{
    this.setState({instituteSubcategoryID:e.target.value})
  }

  onSearchButtonClick = () =>{
    this.setState({ isProcessing: true })

    this.setState({ page: 1 })
    setTimeout(() => {
      this.getOfferData();

    }, 1000);
  }

    render() {
      return (
        <div>
          <ListOfAdsJsx 
          adsData={this.state.adsData}
          onCatgeroryChange={this.onCatgeroryChange}
          onSubcatgeroryChange={this.onSubcatgeroryChange}
          onSearchButtonClick={this.onSearchButtonClick}
          onPaginationButtonClick={this.onPaginationButtonClick}
          categoryData={this.state.categoryData}
          subCategoryData={this.state.subCategoryData}
          instituteSubcategoryID={this.state.instituteSubcategoryID}
          />
          {this.state.isProcessing && <Loader />}
        </div>
      );
    }
  }

  export default ListOfAdsJsxclass;
