import React from 'react'
import leftarrow from "../../../../assets/images/left-arrow.svg";
import { uploadURL,NEW_URL } from '../../../../constants/applicationConstants';import { Pagination } from '../../../common/pagination/pagination';
import { useHistory,useLocation } from "react-router-dom";
import { Footer } from '../../../common/footer/footer'

const ListOfAdsJsx = (props) => {
  const history = useHistory();
  const location = useLocation()
  const {state:locationState} = location

  const {
    adsData,
    onCatgeroryChange,
    onSubcatgeroryChange,
    onSearchButtonClick,
    onPaginationButtonClick,
    categoryData,
    subCategoryData,
    instituteSubcategoryID
  } = props;

  return (
    <>
      <section className="listofoffermainsection">
      <div className="container">
      <div className="leftsightbackdetails listofofferleft padtop60">
                <div className="back_btn" onClick={() => locationState?.frompage ? history.push(`${locationState?.pagePath ?? '/'}#${locationState?.frompage}`) : history.goBack()}><img src={leftarrow} alt={' '} /></div>
                <div className="plaindetailsleft">
                  <h1 className="h2_style font-weight-bold">List of Ads </h1>
                  <h5 className="h5_style">A penny saved, is a penny earned! Trust us, we can bloat your piggy bank.</h5>
                  <h5 className="h5_style font-weight-bold">Become aware of our latest financial products, schemes, and discounts. Just explore our top advertisements below</h5>
                  <h5 className="h5_style">Are you interested in any specific category? Narrow down your search by selecting a suitable category.</h5>
                  

                </div>
              </div>
              <div className="myexpertformarea martop40">
                 <div className="input-container form-group">
                     <select className="input form-control form-select" placeholder=" " name='category' onChange={onCatgeroryChange} >
                       <option value="">All</option>
                       {
                         categoryData?.map(cat=>{
                          return <option value={cat.institutionCategoryId} >{cat.name}</option>
                         })
                       }
                     </select>
                     <div className="placeholder">Category</div>
                   </div>
                  
                   <div className="input-container form-group">
                     <select className="input form-control form-select" placeholder=" " value={instituteSubcategoryID} name='subCategory' onChange={onSubcatgeroryChange} >
                       <option value="">All</option>
                       {
                            subCategoryData?.map(subCat=>{
                             return <option value={subCat.institutionCategoryId} >{subCat.name}</option>
                            })
                       }
                     </select>
                     <div className="placeholder">Sub Category</div>
                   </div>
                   
                   <div className="input-container form-group"><button className="ctaonebutton" onClick={()=> onSearchButtonClick()}>Search</button></div>
               </div>
               <div className="listofad mt-5 mb-5">
                 <div className="row">
                   {
                     adsData?.data?.map(ad=>{
                       return <div className="col-lg-6 col-sm-6">
                       <div className="listofadinner mb-5">
                          <div className="listofimage">
                            <img src={`${NEW_URL}/${ad?.imageUrl}`} alt={' '}/>
                          </div>
                          <div className="listofadinnerpop">
                          <div className="listofadtext">
                            <h3>{ad?.subInstitutionCategory?.name}</h3>
                          </div>
                          <div className="listofadbutton">
                            {ad.externalLink && <button onClick={()=> {window.scrollTo(0,0); window.open(ad.externalLink,'_blank')}} className="ctatwobutton ctanewtwobutton width100">Know More</button>}
                          </div>
                          </div>
                       </div>
                     </div>
                     })
                   }
                   </div>
                   {adsData?.data?.length === 0 && <div>No Record Found</div>}

                   {adsData?.data?.length > 0 && <Pagination
                                    limit={10}
                                    totalCount={adsData?.meta?.totalCount}
                                    onPaginationButtonClick={onPaginationButtonClick}
                                />}
               </div>
      </div>
    </section>
    <Footer />
      
    </>

  );
};

export default ListOfAdsJsx;
