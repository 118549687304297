import React from 'react'
import { useEffect,useState } from 'react';
import { Footer } from '../../common/footer/footer';
import offerbanner from "../../../assets/images/blogs-banner.svg";
import mobileofferbanner from "../../../assets/images/blog_mobile_banner.svg";
import blogImg from "../../../assets/images/blogImg.svg";
import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useLocation} from 'react-router-dom';
import "./blogs.css";


const BlogsJsx = (props) => {
    const { blogData, onSelectBlogType } = props;
    const  {hash} = useLocation()
  useEffect(() => {
    AOS.init();
    if(hash)
    {
      document.getElementById(hash.replace('#','')).scrollIntoView()
    }else
    {
    //window.scrollTo(0, 0);
    }

  }, [])
    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});


return(
    <>
    <section className="blogcustommainpage">
        <section className="herosectionoffer d-none d-sm-block">
            <img src={offerbanner} alt="" />
        </section>
        
        <section className="herosectionoffer d-block d-lg-none d-sm-none d-md-none">
            <img src={mobileofferbanner} alt="" />
        </section>

        <div className="container"> 
            <div className="blog">
                <h1>Blogs</h1>
                <p>Carefully picked top stories & curated content that makes sense. Read from below!</p>
            </div>


        <section> 
            <div className="row">
                <h4>Featured Blogs</h4>
                <div className="col-md-6 col-sm-12">
                {
                    blogData?.body?.length > 0 ?
                        blogData?.body?.map((blog,index) => {
                            if(index == 0){
                                return (<div className="card">
                                <a onClick={() => {setBlog(blog); setOpenModal(true)}}>
                                    {/* <div className="bloginner mb-3"> */}
                                        <div className="card-img-top"><img src={blog?.image} alt={''} /></div>
                                        <div style={{padding:"15px"}}>
                                            <div className="bloginnertag mt-4">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                                            <div className="bloginnertext mt-4">{blog?.title}</div>
                                            <div className="bloginnerdate mt-4">{blog?.views} views  .  {blog?.date_publish}</div>
                                        </div>
                                    {/* </div> */}

                                </a>
                            </div>)
                            }else{
                                return <></>
                            }
                        }) :
                        <div className="dropdefault text-center">
                            <div className="dropdefaultimage">
                                {/* <img src={dropbox} alt={''} /> */}
                            </div>
                            <div className="dropdefaulttext">
                                <h5>You don't have any Blogs yet. </h5>
                            </div>
                        </div>
                    }
                </div>

               
                <div className="col-md-6 col-sm-12" style={{padding:"0 15px  12px 17px"}}>
                    {
                    blogData?.body?.map((blog,index) => {
                        if(index > 0 && index<4){
                            return (
                            <div className="row smblog">
                                <div className="col-md-5 col-sm-12">
                                    {
                                        <a onClick={() => {setBlog(blog); setOpenModal(true)}}>
                                            <div><img src={blog?.image} alt={''} /></div>  
                                        </a>
                                    }
                                </div>
                                <div className="col-md-7 col-sm-12 ">
                                    {
                                        <a onClick={() => {setBlog(blog); setOpenModal(true)}}>
                                             <button className="bloginnertag mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</button>
                                            <div className="bloginnertext mt-2">{blog?.title}</div>
                                           
                                            <div className="bloginnerdate">{blog?.views} views  .  {blog?.date_publish}</div>
                                        </a>
                                    }
                                </div>
                                <hr/>
                            </div>
                            )}
                        }) 
                    }
                </div>
            </div>
        </section>


        <section>
            <div className="row mt-5">
            <h4>Latest Blogs</h4>
                <div className="col-md-6 col-sm-12" style={{padding: "10px 10px 10px 20px !important;"}}>
                    {
                    blogData?.body?.map((blog,index) => {
                        if(index > 8 && index<12){
                            return (
                            <div className="row smblog">
                                <div className="col-md-5 col-sm-12">
                                    {
                                        <a onClick={() => {setBlog(blog); setOpenModal(true)}}>
                                            <div><img src={blog?.image} alt={''} /></div>  
                                        </a>
                                    }
                                </div>
                                <div className="col-md-7 col-sm-12">
                                    {
                                        <a onClick={() => {setBlog(blog); setOpenModal(true)}}>
                                             <button className="bloginnertag mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</button>
                                            <div className="bloginnertext mt-2">{blog?.title}</div>
                                           
                                            <div className="bloginnerdate">{blog?.views} views  .  {blog?.date_publish}</div>
                                        </a>
                                    }
                                </div>
                                <hr/>
                            </div>
                            )}
                        }) 
                    }
                </div>

                <div className="col-md-6 col-sm-12" style={{padding: "10px 20px 10px 10px !important;"}}>
                    {
                    blogData?.body?.map((blog,index) => {
                        if(index > 12 && index<16){
                            return (
                            <div className="row smblog">
                                <div className="col-md-5 col-sm-12">
                                    {
                                        <a onClick={() => {setBlog(blog); setOpenModal(true)}}>
                                            <div><img src={blog?.image} alt={''} /></div>  
                                        </a>
                                    }
                                </div>
                                <div className="col-md-7 col-sm-12">
                                    {
                                        <a onClick={() => {setBlog(blog); setOpenModal(true)}}>
                                             <button className="bloginnertag mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</button>
                                            <div className="bloginnertext mt-2">{blog?.title}</div>
                                           
                                            <div className="bloginnerdate">{blog?.views} views  .  {blog?.date_publish}</div>
                                        </a>
                                    }
                                </div>
                                <hr/>
                            </div>
                            )}
                        }) 
                    }
                </div>
            </div>
        </section>


        <section>
            <div className="row mt-5">
            <h4>Trending Blogs</h4>
                {
                blogData?.body?.map((blog,index) => {
                    if(index > 21 && index<26){
                        return (
                        <div className="col-md-3 col-sm-12" style={{padding: "13px !important;"}}>
                            <div className="row smblog">
                                <a onClick={() => {setBlog(blog); setOpenModal(true)}}>
                                    <div><img src={blog?.image} alt={''} /></div>
                                    <button className="bloginnertag mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</button>
                                    <div className="bloginnertext mt-3">{blog?.title}</div>
                                   
                                    <div className="bloginnerdate">{blog?.views} views  .  {blog?.date_publish}</div>
                                </a>
                            </div>   
                        </div>
                        )}
                    }) 
                }
            </div>
        </section>


        <section>
            <div className="row mt-5">
            <h4>Glossary Blogs</h4>
                {
                blogData?.body?.map((blog,index) => {
                    if(index > 31 && index<36){
                        return (
                        <div className="col-md-3 col-sm-12" style={{padding: "13px !important;"}}>
                            <div className="row smblog">
                                <a onClick={() => {setBlog(blog); setOpenModal(true)}}>
                                    <div><img src={blog?.image} alt={''} /></div>
                                    <button className="bloginnertag mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</button>
                                    <div className="bloginnertext mt-3">{blog?.title}</div>
                                    <div className="bloginnerdate">{blog?.views} views  .  {blog?.date_publish}</div>   
                                </a>
                            </div>   
                        </div>
                        )}
                    }) 
                }
            </div>
        </section>

        </div> 


        {
        openModal && 
        <div className="modalspecial blog-modal">
            <div>
                <div className="mybtn">
                    <button type="button" className="myadbtn" onClick={() => { setOpenModal(false)}}><div id="cross"></div></button>
                </div>
                <div className="modal-header">
                    <h3>{blog?.title}</h3>
                </div>
                <div className="modal-body">
                    <div className="blog-image-dv"><img src={blog.image} alt={' '} /></div>
                    <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content}}></div>
                </div>
            </div>
        </div>
        }

      </section>

    <Footer />
    </>
)
};

export default BlogsJsx;