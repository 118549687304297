import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { Loader } from '../../../common/loader/loader.jsx';
import ExpertBlogJsx from './expertBlog.jsx'
import {BLOG_URL,BLOG_URL_TOKEN} from "../../../../constants/applicationConstants"

export default class ExpertBlog extends Component{
    constructor(props) {
        super(props);
        this.state = {
            blogData: null,
            isProcessing: false,
            page: 1,
            blogType:'ArticleByYou'
        }
    }

    getType = {
        ArticleByYou:'',
        Suggested:"trending"
      }

    getBlogData = () => {
      const name = JSON.parse(sessionStorage.getItem("masterID"));
      const urlName = sessionStorage.getItem("slugURL")
        var data = new FormData();
        data.append('limit', '10');
        data.append('device_token', '20.204.66.244');
        this.state.blogType === 'ArticleByYou' && data.append('expertid',urlName.replace('/', ''));
        data.append('paged', this.state.page)
        data.append('type',this.getType[this.state.blogType])
        this.setState({ isProcessing: true })

                axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
            }).then(res => {
                this.setState({ blogData: res.data, isProcessing: false });
            }).catch(err=>{
                this.setState({ isProcessing: false })
              })
    }

    componentDidMount = () => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        if (!token){
          this.props.history.push("/");   
         }
        this.getBlogData();
    }

    onPaginationButtonClick = (page) => {
        window.scrollTo(0,0);
        this.setState({ page: page }, () => this.getBlogData())
    }

    onBlogTypeClick = (title) =>{
      this.setState({blogType:title},()=> this.getBlogData());
    }

    render() {
        return (
            <div>
                <ExpertBlogJsx
                    blogData={this.state.blogData}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    blogType={this.state.blogType}
                    onBlogTypeClick={this.onBlogTypeClick}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}