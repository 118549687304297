import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import ProductJsx from './products.jsx'
import { nameRegex, postalCodeRegex } from '../../../shared/helper.js';
export default class Product extends Component{

    constructor(props){
        super(props);
        this.state={
          categoryData: null,
          subCategoryData: null,
          formData: {
              name: '',
              postalCode: '',
              category: null,
              subCategory: null
          },
            realStateProductData:null,
            topProducts : null,
            investmentProductData:null,
            mortgageProductData:null,
            insuranceProductData:null,
            bankingProductData:null,
            isProcessing:false,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData:[],
            page: 1,
        }
    }

    componentDidMount = () =>{
      this.getRealStateProductData();
      this.getTopProducts();
        this.getInvestmentProductData();
        this.getMortgageProductData();
        this.getInsuranceProductData();
        this.getBankingProductData();
        this.getcategorySubcategory();

    }
    getcategorySubcategory = () =>{
      this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
    }

    getRealStateProductData = () =>{
      this.setState({ isProcessing: true })
      axios.get(`${baseURL}/featuredProducts?institutionCategoryId=5`).then(res=>{
        this.setState({realStateProductData:res.data.data,isProcessing: false})
      })
    }
   getTopProducts = () =>{
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/topProducts`).then(res=>{
      this.setState({topProducts:res.data.data,isProcessing: false})
    })
   }


    getInvestmentProductData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredProducts?institutionCategoryId=2`).then(res=>{
          this.setState({investmentProductData:res.data.data,isProcessing: false})
        })
      }

      getMortgageProductData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredProducts?institutionCategoryId=3`).then(res=>{
          this.setState({mortgageProductData:res.data.data,isProcessing: false})
        })
      }

      getInsuranceProductData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredProducts?institutionCategoryId=1`).then(res=>{
          this.setState({insuranceProductData:res.data.data,isProcessing: false})
        })
      }

      getBankingProductData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredProducts?institutionCategoryId=4`).then(res=>{
          this.setState({bankingProductData:res.data.data,isProcessing: false})
        })
      }
      onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)

        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }
    isFromSubmissionBlock = () => {
      let { postalCode } = this.state.formData;

      if (postalCode) {
          if (
              this.inputRegexValidation('postalCode', postalCode)
          ) {
              this.setState({
                  formSubmitButtonDisable: false
              });
          }
          else {
              this.setState({
                  formSubmitButtonDisable: true
              });
          }
      } else {
          this.setState({
              formSubmitButtonDisable: true
          });
      }
  };
    onInputChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;

      if (name === 'category')
      {this.onCatgoryClick(value)
          this.setState(
              {
                  formData: {
                      ...this.state.formData,
                      'subCategory': '',
                  }
              }
          )
      
      }
          

      if (value) {
          if (!this.inputRegexValidation(name, value)) {
              this.setState(
                  {
                      formData: {
                          ...this.state.formData,
                          [name]: value,
                      },
                      formError: {
                          ...this.state.formError,
                          [name]: "regx",
                      },
                  },
                  () => this.isFromSubmissionBlock()
              );
          } else {
              this.setState(
                  {
                      formData: {
                          ...this.state.formData,
                          [name]: value,
                      },
                      formError: {
                          ...this.state.formError,
                          [name]: "",
                      },
                  },
                  () => this.isFromSubmissionBlock()
              );
          }
      } else {
          this.setState(
              {
                  formData: {
                      ...this.state.formData,
                      [name]: value,
                  },
                  formError: {
                      ...this.state.formError,
                      [name]: "",
                  },
              },
              () => this.isFromSubmissionBlock()
          );
      }
  }
  inputRegexValidation = (name, value) => {
    if (name === 'name')
        return nameRegex.test(value);
    if (name === 'postalCode')
        return postalCodeRegex.test(value);

    return true;
};

onSubmitButtonClick = () =>{
  const {name,postalCode,category,subCategory} = this.state.formData;
//   if(category){
//     this.props.history.push(`/productListing/${category}/${subCategory}`);

//   }
this.props.history.push(`/productListing/${category}/${subCategory}`);
}

    render(){
        return(
            <div>
                <ProductJsx 
                investmentProductData={this.state.investmentProductData}
                mortgageProductData={this.state.mortgageProductData}
                insuranceProductData={this.state.insuranceProductData}
                bankingProductData={this.state.bankingProductData}
                realStateProductData={this.state.realStateProductData}
                topProducts = {this.state.topProducts}
                categoryData={this.state.categoryData}
                subCategoryData={this.state.subCategoryData}
                formData={this.state.formData}
                onInputChange={this.onInputChange}
                formError={this.state.formError}
                onSubmitButtonClick={this.onSubmitButtonClick}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}
