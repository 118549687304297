import React from 'react'
import { useEffect, Suspense, lazy } from 'react';
import { useTranslation } from "react-i18next";
import Consultingrafiki from "../../../assets/images/Consulting-rafiki.svg";
import realestate from "../../../assets/images/realestate.svg";
import investment from "../../../assets/images/investment.svg";
import mortgage from "../../../assets/images/mortgage.svg";
import insurance from "../../../assets/images/insurance.svg";
import banking from "../../../assets/images/banking.svg";
import realestateone from "../../../assets/images/realestateone.svg";
import investmentone from "../../../assets/images/investmentone.svg";
import mortgageone from "../../../assets/images/mortgageone.svg";
import insuranceone from "../../../assets/images/insurenceone.svg";
import bankingone from "../../../assets/images/bankingone.svg";
import ad1 from "../../../assets/images/Vertical_Ad_1.png";
import ad2 from "../../../assets/images/Vertical_Ad_2.jpg";
import ad7 from "../../../assets/images/Vertical_Ad_3.jpg";
import ad3 from "../../../assets/images/6.png";
import ad4 from "../../../assets/images/Vertical_Ad_4.jpg";
import ad6 from "../../../assets/images/Horizontal_Ad_1.jpg";
import ad11 from "../../../assets/images/5.png";
import ad12 from "../../../assets/images/vermobile.png";
import ad13 from "../../../assets/images/Mb1.png";
import ad14 from "../../../assets/images/mb2.png";
import ad15 from "../../../assets/images/mb3.png";
import ad16 from "../../../assets/images/mb4.png";
import vads1 from "../../../assets/images/ads/VerticalAD1.png";
import vads2 from "../../../assets/images/ads/VerticalAD2.png";
import vads3 from "../../../assets/images/ads/VerticalAD3.png";
import vads4 from "../../../assets/images/ads/VerticalAD4.png";
import hads1 from "../../../assets/images/ads/HorizontalAD.png";
import hads2 from "../../../assets/images/ads/HorizontalAD2.png";
import offerbanner from "../../../assets/images/offerbanner.svg";
import { uploadURL,NEW_URL } from '../../../constants/applicationConstants';
import { Footer } from '../../common/footer/footer';
import Global from '../../common/global/global';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const BlogSection = lazy(() => import('./blogSection'));
const QuoteSection = lazy(() => import('./quotesSection'));
const ExpertSection = lazy(() => import('./expertSection'));
const ProductSection = lazy(() => import('./productSection'));
const OfferSection = lazy(() => import('./offerSection'));


const HomeJsx = (props) => {
  const { t } = useTranslation();
  const {
    blogData,
    onSelectBlogType,
    quoteData,
    onTouchHandler,
    onInputChangeHandler,
    formError,
    onButtonClick,
    expertData,
    onViewProfieClick,
    topOffers,
    productData,
    offerData
  } = props;
  const location = useLocation()

  useEffect(() => {
    AOS.init();
    const hash = window.location.hash;
    if (hash) {
      // Remove the '#' character to get the section ID
      const sectionId = hash.substring(1);
      
      // Find the element with the corresponding ID
      const sectionElement = document.getElementById(sectionId);
      
      if (sectionElement) {
        // Scroll to the section
        sectionElement.scrollIntoView({ behavior: 'smooth' });
        
      }
    }else{
      window.scrollTo(0, 0);
    }
    // alert(hash)
    
  }, [location])
  
  var settingstwo = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows:false,
    autoplay:true,

    // initialSlide:2    
  };
  var settingsthree = {
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight:true,
    // initialSlide:2  
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '0px',
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: '0px',
            slidesToScroll: 1
          }
        }
      ]  
  };
  return (
    <>
    <section className="homecustom"><Global />
    <section className="herosectionoffer d-none d-sm-block">
    <img src={offerbanner} alt=""/>
     <div className="container">
     <div className="offerbannerslider">
     <Slider {...settingsthree}>
                {
                  topOffers?.map(offer => {
                    return <div className="sliderContent">
                    <a href={offer.externalUrl ?? '#'} target="_blank">
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />
                          </a>
                         <h5>{offer.offerName}</h5>
                    </div>
                  })
                }
              </Slider>
       </div>
     </div>
    </section>
    
      {/* <section className="herosection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="heroleftpanel"  data-aostest="fade-right">
                <h2>{t("HOME.HEADING1")}</h2>
                <h5>{t("HOME.HEADING2")}</h5>
                <h6 className="mt-5">{t("HOME.HEADING3")}</h6>
                <h6>{t("HOME.HEADING4")}</h6>
                <div className="heroleftbutton d-flex mt-4">
                  <Link to={'/AskTheExpert'} className="ctaonebutton border-radius-50"> I want to ask now!</Link>
                  <a href="/AskTheExpert#faq" className="ctatwobutton border-radius-50">FAQs</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="herorightpanel"  data-aostest="fade-left">
                <img src={Consultingrafiki} alt={' '}/>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="homepageproductoffer">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="pname">

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer mt-5 mb-5  d-none d-sm-block">
        <div className="container">
          <h2 className="text-center mb-5" data-aostest="fade-top">{t("HOME.INTERESTEDHEADING")}</h2>
          <div className="row rimibicons">
            <div className="colps">
              {/* <Link to={'/realEstate'}> */}
              <a href="/realEstate">
              <div className="rimibimage" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="1000">
                <img src={realestate} className="rimibimageone" alt={' '} />
                <img src={realestateone} className="rimibimagetwo"  alt={' '}/>
              </div>
              </a>
              {/* </Link> */}
            </div>
            <div className="colps">
            {/* <Link to={'/investment'}> */}
              <a href="/investment">
              <div className="rimibimage"  data-aostest="flip-right" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="3000">
                <img src={investment} className="rimibimageone"  alt={' '}/>
                <img src={investmentone} className="rimibimagetwo" alt={' '} />
              </div>
              </a>
              {/* </Link> */}
            </div>
            <div className="colps">
            {/* <Link to={'/mortgage'}> */}
            <a href="/mortgage">
              <div className="rimibimage" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="5000">
                <img src={mortgage} className="rimibimageone"  alt={' '}/>
                <img src={mortgageone} className="rimibimagetwo" alt={' '} />
              </div>
              </a>
              {/* </Link> */}
            </div>
            <div className="colps">
            {/* <Link to={'/insurance'}> */}
            <a href="/insurance">
              <div className="rimibimage" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="7000">
                <img src={insurance} className="rimibimageone"  alt={' '}/>
                <img src={insuranceone} className="rimibimagetwo" alt={' '} />
              </div>
              </a>
              {/* </Link> */}
            </div>
            <div className="colps">
            {/* <Link to={'/banking'}> */}
            <a href="/banking">
              <div className="rimibimage" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="10000">
                <img src={banking} className="rimibimageone"  alt={' '}/>
                <img src={bankingone} className="rimibimagetwo"  alt={' '}/>
              </div>
              </a>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer mt-5 mb-5 d-block d-sm-none">
        <div className="container">
          <h2 className="text-center mb-5" data-aostest="fade-top">{t("HOME.INTERESTEDHEADING")}</h2>
          <div className="row" style={{ justifyContent: 'center',}}>
            <div className="colsec">
              <a href='/realEstate'>
              <div className="rimibimage" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="1000">
                <div class="textbold">R</div>
              </div>
              </a>
            </div>
            <div className="colsec">
            <a href='/investment'>
              <div className="rimibimage"  data-aostest="flip-right" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="3000">
                <div class="textbold">I</div>
              </div>
              </a>
            </div>
            <div className="colsec">
            <a href='/mortgage'>
              <div className="rimibimage" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="5000">
                <div class="textbold">M</div>
              </div>
              </a>
            </div>
            <div className="colsec">
            <a href='/insurance'>
              <div className="rimibimage" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="7000">
                <div class="textbold">I</div>
              </div>
              </a>
            </div>
            <div className="colsec">
            <a href='/banking'>
              <div className="rimibimage" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="10000">
                <div class="textbold">B</div>
              </div>
              </a>
            </div>
          </div>
        </div>
      </section>
 
      <OfferSection offerData={offerData} />
      <section className="homepageproductoffer mt-5 mb-5 d-none d-sm-block">
        <div className="container">
          <h2 className="text-center" data-aostest="fade-top">{t("HOME.PRODUCTSHEADING")}</h2>
          <h4 className="mt-3 mb-5 text-center " data-aostest="fade-bottom">{t("HOME.PRODUCTSHEADING1")}</h4>
          <div className="row">
          <div className="col-lg-2 col-md-12  col-sm-12 advertisehome">
          <Slider {...settingstwo}>
            <div className="adscreenone">
             <a href="https://www.meridiancu.ca/personal/investing/class-a-shares" target="_blank" rel="noreferrer"> <img src={vads1}  alt={' '}/></a>
            </div>
            <div className="adscreenone">
            <a href="https://www.meridiancu.ca/rrsp-2024" target="_blank" rel="noreferrer"><img src={vads2}  alt={' '}/></a>
            </div>
          
           
            </Slider>
          </div>
            <div className="col-lg-4 col-md-6  col-sm-6">
              <div className="pofferinner"  data-aostest="fade-right">
                <h5>{t("HOME.PRODUCTTEXT1")}</h5>
                <h5>{t("HOME.PRODUCTTEXT2")}</h5>
<Link to={'/products'} className="ctaonebutton border-radius-50">{t("HOME.PRODUCTTEXT5")}</Link>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6  col-sm-6">
              <div className="pofferinner"  data-aostest="fade-left">
                <h5>{t("HOME.PRODUCTTEXT3")}</h5>
                <h5>{t("HOME.PRODUCTTEXT4")}</h5>
<Link to={'/offers'} className="ctaonebutton border-radius-50">{t("HOME.PRODUCTTEXT6")}</Link>
               
              </div>
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 advertisehome">
          <Slider {...settingstwo}>
            <div className="adscreenone">
            <a href="https://www.meridiancu.ca/personal/rates-and-fees#gics" target="_blank" rel="noreferrer"><img src={vads3} alt={' '} /></a>
            </div>
            <div className="adscreenone">
            <a href="https://www.meridiancu.ca/personal/mortgages/variable-rate-mortgage" target="_blank" rel="noreferrer"><img src={vads4} alt={' '} /></a>
            </div>
            {/* <div className="adscreenone">
            <a href="https://www.icicibank.ca/en/personal/services/safety-deposit-boxes " target="_blank" rel="noreferrer"><img src={ad7} alt={' '} /></a>
            </div> */}
         
            </Slider>
          </div>
          </div>
        </div>
      </section>
<section className="homepageproductoffer mt-5 mb-5 d-block d-sm-none">
        <div className="container">
          
          <div className="row">
          <div className="col-lg-2 col-md-12  col-sm-12 advertisehome">
          <Slider {...settingstwo}>
          <div className="adscreenone">
             <a href="https://www.icicibank.ca/en/promo/two-homes-one-bank/index" target="_blank" rel="noreferrer"> <img src={ad13}  alt={' '}/></a>
            </div>
            <div className="adscreenone">
            <a href="https://www.icicibank.ca/en/personal/services/precious-metals" target="_blank" rel="noreferrer"><img src={ad14}  alt={' '}/></a>
            </div>
            <div className="adscreenone">
            <a href="https://www.icicibank.ca/en/personal/creditservices/mortgages" target="_blank" rel="noreferrer"><img src={ad16}  alt={' '}/></a>
            </div>
            <div className="adscreenone">
            <a href="https://www.icicibank.ca/en/personal/services/safety-deposit-boxes " target="_blank" rel="noreferrer"><img src={ad15}  alt={' '}/></a>
            </div>
            <div className="adscreenone">
            <a href="https://www.icicibank.ca/en/personal/services/overview-services/secured-lines-of-credit" target="_blank" rel="noreferrer"><img src={ad12}  alt={' '} /></a>
            </div>
            </Slider>
          </div>
          <div className="col-lg-12 col-md-12  col-sm-12">
          <h2 className="text-center mt-3" data-aostest="fade-top">{t("HOME.PRODUCTSHEADING")}</h2>
          <h4 className="mt-3 mb-5 text-center " data-aostest="fade-bottom">{t("HOME.PRODUCTSHEADING1")}</h4>
          </div>
            <div className="col-lg-4 col-md-6  col-sm-6">
              <div className="pofferinner"  data-aostest="fade-right">
                <h5>{t("HOME.PRODUCTTEXT1")}</h5>
                <h5>{t("HOME.PRODUCTTEXT2")}</h5>
<Link to={'/products'} className="ctaonebutton border-radius-50">{t("HOME.PRODUCTTEXT5")}</Link>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6  col-sm-6">
              <div className="pofferinner"  data-aostest="fade-left">
                <h5>{t("HOME.PRODUCTTEXT3")}</h5>
                <h5>{t("HOME.PRODUCTTEXT4")}</h5>
<Link to={'/offers'} className="ctaonebutton border-radius-50">{t("HOME.PRODUCTTEXT6")}</Link>
               
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <Suspense fallback={<componentLoading />}>          
        <QuoteSection quoteData={quoteData} onInputChangeHandler={onInputChangeHandler} onTouchHandler={onTouchHandler} formError={formError} onButtonClick={onButtonClick}/>
      </Suspense>

      <Suspense fallback={<componentLoading />}>          
        <ExpertSection expertData={expertData} onViewProfieClick={onViewProfieClick}/>
      </Suspense>

      <Suspense fallback={<componentLoading />}>          
        <ProductSection productData={productData}/>
      </Suspense>      

      
      {/* <section className="latestrates">
        <div className="container">
          <h2 className="text-center">Latest Rates</h2>
          <h4 className="text-center mt-3">Don’t wander places! Get all the latest rates here. All top institutions covered.</h4>
          <h4 className="text-center">Browse more and secure the best deals. </h4>
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="latestratesinner mt-5">
                <div className="latestratesinnerheading text-center">Mortgages</div>
                <ul className="logheading">
                  <li><span>Lender</span> <span>Rate</span></li>
                </ul>
                <ul className="logohd">
                  <li>
                    <span className="ratesicon"><img src={Butler_logo} /> Butler Mortgage</span>
                    <span className="ratesicontext">4.49%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <li>
                    <span className="ratesicon"><img src={pine_logo} /> Pine Mortgage</span>
                    <span className="ratesicontext">4.59%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <li>
                    <span className="ratesicon"><img src={neo_logo} /> Neo Financial</span>
                    <span className="ratesicontext">4.64%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <button type="button" className="viewall">View All<img src={chevrons_right} /></button>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="latestratesinner mt-5">
                <div className="latestratesinnerheading text-center">Mortgages</div>
                <ul className="logheading">
                  <li><span>Lender</span> <span>Rate</span></li>
                </ul>
                <ul className="logohd">
                  <li>
                    <span className="ratesicon"><img src={saven_logo} /> Saven Financial</span>
                    <span className="ratesicontext">5.30%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <li>
                    <span className="ratesicon"><img src={alterna_logo} /> Altrena Bank</span>
                    <span className="ratesicontext">5.00%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <li>
                    <span className="ratesicon"><img src={Scotia_logo} /> Scotiabank</span>
                    <span className="ratesicontext">5.00%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <button type="button" className="viewall">View All<img src={chevrons_right} /></button>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="homepageproductoffer mt-5 mb-5">
        <div className="container">
          <h2 className="text-center"  data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="2000">{t("HOME.SEARCHHEADING")}</h2>
          <h4 className="text-center mt-3"   data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="2000">{t("HOME.SEARCHHEADING1")}</h4>
          <h4 className="text-center"   data-aostest="flip-left">{t("HOME.SEARCHHEADING2")} </h4>
          <div className="heroleftbutton experthomepagebutton d-flex mt-5"   data-aostest="flip-left">
          <Link to={'/search'} className="ctaonebutton border-radius-50"> I want to search now</Link>
                 
           
          </div>
        </div>
      </section>
        <Suspense fallback={<componentLoading />}>          
          <BlogSection blogData={blogData} onSelectBlogType={onSelectBlogType}/>
        </Suspense>
      
      {/* <section className="homepageproductoffer mt-5 mb-5">
        <div className="container">
          <h2 className="text-center" data-aostest="flip-right" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="2000">Free Tools</h2>
          <h4 className="text-center mt-3" data-aostest="fade-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="2000">Crunch real numbers with our modern financial calculators. </h4>
          <h4 className="text-center" data-aostest="fade-right" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="2000">Craft the personalized financial plan you always wanted!</h4>
           <ul className="calculatehomepage d-flex mt-5 mb-4">
            <li>
              <h4>Received your free amortization schedule yet?</h4>
              <button type="button" className="ctaonebutton border-radius-50 mt-3">Calculate it now!</button>
            </li>
            <li>
              <h4>Compare Top-Ranked Financial Products</h4>
              <button type="button" className="ctaonebutton border-radius-50 mt-3">Compare Now</button>
            </li>
          </ul> 
          <div className="homeiconimage text-center mt-3">
            <img src={homeimage} data-aostest="flip-left"/>
            <h4 className="mt-3" data-aostest="fade-left">Improve your financial health by tapping our mortgage calculators!</h4>
          </div>
        </div>
      </section> */}
      <section className="latestrates">
        <div className="container">
          <h2 className="text-center" data-aostest="fade-left"  data-aostest-anchor-placement="bottom-bottom">{t("HOME.KNOWSEARCH1")}</h2>
          <div className="d-flex latestratesflex">
            <div data-aostest="fade-left">
          <h4 className="mt-3">{t("HOME.KNOWSEARCH2")}</h4>
          <h4 className="">{t("HOME.KNOWSEARCH3")}</h4>
          </div>
          <div className="" data-aostest="fade-right"  data-aostest-anchor-placement="bottom-bottom">
            <Link to={'/searchInstitute'} className="ctaonebutton border-radius-50 mt-5 widthhalf">{t("HOME.KNOWSEARCH4")}</Link>
            </div>
        </div>
        <div className="advertisehome d-none d-sm-block">
          <Slider {...settingstwo}>
          <div class="adscreenone mt-5 adscreenheight">
          <a href="https://www.meridiancu.ca/personal/loans-and-lines/driveon" target="_blank" rel="noreferrer"><img src={hads1} alt={' '}/></a></div>
          <div class="adscreenone mt-5 adscreenheight">
          <a href="https://appt-booking.meridiancu.ca/AppointmentType?default=Personal%20borrowing&_gl=1*1io06y9*_ga*MTg1NDE5NTU3NS4xNzA1NzM1NTAx*_ga_6D78MN23LS*MTcwNTkxNjczNC42LjEuMTcwNTkxNjk4NS4zMy4wLjA." target="_blank" rel="noreferrer"><img src={hads2} alt={' '}/></a></div>
          </Slider>
           </div>
        </div>
      </section>
      <Footer />
</section>
    </>

  );
};

export default HomeJsx;
