import React, { useState } from 'react';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cross from "../../../assets/images/cross.svg";

export const ExpertBlogSection = (props) => {
  const { t } = useTranslation();
  const { blogData } = props;
  var settings = {
    dots: true,
    infinite: (blogData?.body).length > 2 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,

    // initialSlide:2    
  };

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState({});


  return (
    <section className="myblogppv topexpert">
      <div className="container">
        <h2>{t("PPVEXPERT.BLOGTITLE")}</h2>
        <h4 className="mt-3 text-center">{t("PPVEXPERT.BLOGSUBTITLE")} <br></br>{t("PPVEXPERT.BLOGSUBSUBTITLE")}
        </h4>
        <div className="productslider mt-5 mb-5">

          <Slider {...settings}>
            {
              blogData?.body?.length > 0 &&
              blogData?.body?.map(blog => {
                return <div className="p_sliderouter">
                  <div className="p_slider" onClick={() => { setBlog(blog); setOpenModal(true) }}>
                    <div className="blog-image"><img src={blog.image} alt={' '} /></div>
                    <div className="useblog d-flex">
                      <div className="userdetails">
                        <img src={'https://ui-avatars.com/api/?name=Staf+Expert'} alt={' '} />
                      </div>
                      <div className="userpop">
                        <h5>{blog.author}</h5>
                        <div className="date">{blog.date_publish}</div>
                      </div>
                    </div>
                    <div className="slidertext">
                      <h4>{blog.title}</h4>
                      <p>{blog.short_content}</p></div>
                  </div>
                </div>
              })
            }
          </Slider>

        </div>
        <Link
          to={{
            pathname: "/expertPpvBlog",
            state:{
              expertIDSlug:window.location.pathname.replace('/expertPpv/', '')
            }
          }}
          className="ctaonebutton widthhalf mt-5"
          onClick={() => window.scrollTo(0, 0)}>
          View All
                    </Link>
      </div>

      {
        openModal &&
        <div className="modalspecial blog-modal">
          <div>
            <div className="mybtn">
              <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
            </div>
            <div className="modal-header text-center">
              <h3>{blog?.title}</h3>
            </div>
            <div className="modal-body text-center">
              <div className="blog-image-dv"><img src={blog.image} alt={' '} />
              </div>
              <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
            </div>
          </div>
        </div>

      }

    </section>

  )
}