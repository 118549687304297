import React from 'react'
import { useTranslation } from "react-i18next";
import leftarrow from "../../../../assets/images/left-arrow.svg";
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import PlanJSON from './plans.json';
import { useHistory } from "react-router-dom";

const PlansJsx = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    bilingPeriod,
    onBilingButtonClick,
    planType,
    onPlanTypeButtonClick,
    isInstitutionExpet,
    onInstitutionExpertClick,
    termAndCondition,
    onTermsAndConditionClick,
    onNextButtonClick,
    planDetail
  } = props;

  const getPrice = (name) => {
    const planTypeData = planDetail?.filter(data => data.planName === name);
    let rate = ''
    planTypeData?.[0].subscriptionPlanTypes?.forEach(data => {
      if (data.planType === "monthly" && bilingPeriod === 'Monthly Billing')
        rate = data.price
      else if (data.planType === "annually" && bilingPeriod === 'Annual Billing')
        rate = data.price
    })
    return rate;
  }
  const removeId = ()=>{
    const adminToken = sessionStorage.getItem('adminOldToken');

    sessionStorage.removeItem("instituteId")
    sessionStorage.setItem("token", JSON.stringify(adminToken));
    sessionStorage.removeItem("adminOldToken")
    sessionStorage.removeItem("resumeOnBoard")

    history.push("/admin/institutes")
  }

const isNormalExpert = sessionStorage.getItem("resumeOnBoard")


  return (
    <>
      <section className="loginscreeninstitute registrationinstitute">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
              <div className="back_btn" onClick={() => isNormalExpert ? removeId() : history.goBack()}><img src={leftarrow} alt={' '} /></div>
                <div className="detailsleft plaindetailsleft">
                  <h1> {t("PLANS.TITLE")}  </h1>
                  <p>{t("PLANS.FEATURE")}</p>
                  <div className="instituteleft-illustration martop60">

                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext planoutmainsub martop60">
                
                <div className="planbuttonouter"><div class="smtext">Select the payment frequency</div>
                  <button type="button" className={`${bilingPeriod === 'Monthly Billing' ? 'ctaonebutton' : 'ctatwobutton'} planbutton`} onClick={() => onBilingButtonClick('Monthly Billing')}>{t("PLANS.QUERTER")}</button>
                  <button type="button" className={`${bilingPeriod === 'Annual Billing' ? 'ctaonebutton' : 'ctatwobutton'} planbutton`} onClick={() => onBilingButtonClick('Annual Billing')}>{t("PLANS.ANNUAL")} <span>{t("PLANS.SAVE")}</span></button>
                </div>
                <div className="signout">
                  <img src={Sign_out_squre}  alt={' '} />
                  <span className="title">{t("PLANS.LOGOUT")}</span>
                </div>
              </div>
            </div>
            <div className="mainplan">
              <div className="planwhole">
                <div className="planesall">
                  <div className="row">
                    <div className="col-lg-8 col-sm-8">
                      <div className="planesinner">
                        <h2 className="h3_style text-center">{t("PLANS.BOOSTBUSSINESS")}</h2>
                        <hr></hr>
                        <ul>
                          {
                            PlanJSON.Plans.map(plans => {
                              return <li>{plans.name} <span className="plantooltip custooltip" ><img src={planpricingtooltip} className="tooltipim"  alt={' '} />
                                <div class="tooltipcontent"> {plans.tooltipTitle}</div>
                              </span></li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2">
                      <div className={`planesinnerright premium ${planType === 'Premium' ? 'broadtp' : ''}`} onClick={() => onPlanTypeButtonClick('Premium')}>
                        <h2 className="text-center h3_style">{t("PLANS.PREMIUM")}</h2>
                        <hr></hr>
                        <ul>
                          {
                            PlanJSON.Plans.map(plans => {
                              return <li>{plans.Premium}</li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2 no-pad-left">
                      <div className={`planesinnerright elite ${planType === 'Elite' ? 'broadtp' : ''}`} onClick={() => onPlanTypeButtonClick('Elite')}>
                        <h2 className="text-center h3_style">{t("PLANS.ELITE")}</h2>
                        <hr></hr>
                        <ul>
                          {
                            PlanJSON.Plans.map(plans => {
                              return <li>{plans.Elite}</li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="planesallbottom">
                  <div className="row">
                    <div className="col-lg-8 col-sm-8">
                      <div className="planesinner">


                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2">
                      <div className="planesinnerright">

                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2">
                      <div className="planesinnerright">
                        {/* <p className="smalltext">{t("PLANS.SELECTPAYMENT")}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="planesallbottom">
                  <div className="row">
                    <div className="col-lg-8 col-sm-8">
                      <div className="planesinner">
                        <h5 className="h3_style fst-italic">{t("PLANS.SEENFEATURE")}
                          <span>{t("PLANS.SELECTPLAN")}</span></h5>

                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2">
                      <div className="planesinnerright">
                        <h6 className="text-center"> $ {getPrice('Premium')} </h6>
                        <button type="button" class={`${planType === 'Premium' ? 'ctaonebutton' : 'ctatwobutton'}`} onClick={() => onPlanTypeButtonClick('Premium')}>{t("PLANS.PREMIUM")}</button>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2">
                      <div className="planesinnerright">
                        <h6 className="text-center">$ {getPrice('Elite')}  </h6>
                        <button type="button" class={`${planType === 'Elite' ? 'ctaonebutton' : 'ctatwobutton'}`} onClick={() => onPlanTypeButtonClick('Elite')}>{t("PLANS.ELITE")}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="planesallbottom">
                  <div className="row">
                    <div className="col-lg-8 col-sm-8">
                      <div className="planesinner">
                        <h5 className="h3_style fst-italic">{t("PLANS.PAYEXPERT")}
                          <span>{t("PLANS.ADDITIONALFEATURE")}</span></h5>

                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                      <div className="planesinnerright">
                        <div className="form-check text-center">
                          <label className="container-checkbox" for="premium">
                            <input type="checkbox" id="premium" checked={isInstitutionExpet} onClick={() => onInstitutionExpertClick(!isInstitutionExpet)} />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="planesallbottom">
                  <div className="row">
                    <div className="col-lg-8 col-sm-8">
                      <div className="planesinner">

                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                      <div className="planesinnerright text-center">
                        <div className="mb-4 form-check">
                          <input type="checkbox" className="form-check-input" id="termscondition" checked={termAndCondition} onClick={() => onTermsAndConditionClick(!termAndCondition)} />
                          <label className="form-check-label" for="termscondition">{t("PLANS.AGREE")} <a href="/termsCondition" target="_blank"  rel="noreferrer" >{t("PLANS.TERMS")}</a></label>
                        </div>
                        <button type="button" disabled={!termAndCondition} className={`${termAndCondition ? 'ctaonebutton' : 'btn-secondary btn ctatwobutton'}`} onClick={() => onNextButtonClick()}>{t("PLANS.NEXT")}</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default PlansJsx;
