import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { BLOG_URL_TOKEN,BLOG_URL } from '../../../../constants/applicationConstants.js';
import { Loader } from '../../../common/loader/loader.jsx';
import ExpertPpvBlogJsx from './expertPpvBlog.jsx'

export default class ExpertPpvBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            isProcessing: false,
            blogData: null
        }
    }

    componentDidMount = () => {
        this.getBlogData();
    }

    getBlogData = () => {
        const expertId = JSON.parse(sessionStorage.getItem("currentExpertId"))
        var data = new FormData();
        data.append('limit', '10');
        data.append('device_token', '20.204.66.244');
        data.append('expertid', this.props.location.state.expertIDSlug);
        data.append('paged', this.state.page)
        this.setState({ isProcessing: true })

        axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
            }).then(res => {
                this.setState({ blogData: res.data, isProcessing: false });
            }).catch(err=>{
                this.setState({isProcessing: false})
              })
    }

    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getBlogData())
    }

    render() {
        return (
            <div>
                <ExpertPpvBlogJsx
                    blogData={this.state.blogData}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}