import React, { Component } from "react";
import NewPasswordPage from "./newPassword.jsx"
import axios from 'axios';
import {
  baseURL
} from "../../../constants/applicationConstants.js";
import { getRoute, encrypt } from '../../../shared/helper'
import { Loader } from "../loader/loader.jsx";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class NewPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {

        newpassword: "",
        verifypassword: "",
      },
      formError: {},
      formSubmitButtonDisable: true,
      isLoading: false,
    };
  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },

          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };
  success = (e) => {
    toast.success("Congrats ! Expert created!");
    let oldToken = localStorage.getItem("instituteExpertToken")
    sessionStorage.setItem("token", oldToken);
    setTimeout(() => {
      this.props.history.push("/dashboard");
      sessionStorage.removeItem("expertId");
      sessionStorage.removeItem("expertData");
      sessionStorage.removeItem("description");

      localStorage.setItem('flowName', 'institute')
      localStorage.setItem('flowType', 'institute')
      localStorage.removeItem("instituteExpert");
      localStorage.removeItem("instituteExpertToken");

      // window.location.reload()
    }, 2000)
    // swal({
    //   title: "Congrats ! Expert created!",
    //   icon: "Success",
    //   buttons: [
    //     'Keep Logged in!',
    //     'Go to dashboard'
    //   ],
    //   dangerMode: true,
    // }).then(function (isConfirm) {
    //   if (isConfirm) {

    //     // sessionStorage.removeItem("expertId");
    //     // sessionStorage.removeItem("expertData");
    //     // localStorage.setItem('flowName', 'institute')
    //     // localStorage.setItem('flowType', 'institute')
    //     // localStorage.removeItem("instituteExpert");

    //     setTimeout(() => {

    //       window.location.reload()
    //     }, 2000)

    //   } else {
    //     //   swal("Cancelled", "Your imaginary file is safe :)", "error");
    //   }
    // })

  }

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {
    let passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#()^@$!%*?&])[A-Za-z\d@$!#()^%*?&]{8,30}$/;
    if (name === "currentpassword") {
      if (!passwordRegex.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      if (!passwordRegex.test(value)) {
        return false;
      } else {
        return true;
      }
    }
  };

  isFromSubmissionBlock = () => {
    let { newpassword, verifypassword } = this.state.formData;
    if ((newpassword && verifypassword)) {
      if (
        !this.inputRegexValidation("newpassword", newpassword) &&
        !this.inputRegexValidation("verifypassword", verifypassword)
      ) {
        this.setState({
          formSubmitButtonDisable: true,
        });
      } else {
        if (newpassword === verifypassword) {
          this.setState({
            formSubmitButtonDisable: false,
            formError: {
              ...this.state.formError,
              verifypassword: "",
            }
          });
        } else {
          this.setState({
            formSubmitButtonDisable: true,
            formError: {
              ...this.state.formError,
              verifypassword: "notMatch",
            }
          });
        }
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };
  formSubmitHandler = (e) => {


    e.preventDefault();
    let flowType = localStorage.getItem("flowType")
    let caseName = localStorage.getItem("requestType")

    const { newpassword, verifypassword } = this.state.formData;
    const email = JSON.parse(sessionStorage.getItem('email'));
    const token = JSON.parse(sessionStorage.getItem('token'));
    let instituteUSER = sessionStorage.getItem("instituteUSER")

    let data = {
      "password": encrypt(newpassword),
      "confirmPassword": encrypt(verifypassword),
      "tokenValidate": token,
      "category": "institute"
    }
    if (instituteUSER) {
      data.userType = "institute-user"
    } 
    if (flowType === "customer") {
      delete data.category
    }
    if (caseName === "forgot-password"){
      data.requestType = "forgot-password"
    }
    this.setState({
      isLoading: true
    })
    let expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))
    if (expertId || flowType === "expert") {
      delete data.category
    }

    axios.post(`${baseURL}/${flowType === "expert" ? "expert" : flowType === "customer"? "customer" : instituteUSER ? "institute-user" : "institute"}/${email}/password`, data, {
      headers: {
        token: token
      }
    }).then(resp => {
      if (resp.data.success) {
        this.setState({
          isLoading: false
        })
        const type = localStorage.getItem('flowName');
        const instExpert = localStorage.getItem("instituteExpert") && (localStorage.getItem("instituteExpert"))
        if (instExpert) {
          this.success()
        } else {
          this.props.history.push(getRoute(type, 'newPassword'));
        }
        localStorage.removeItem("requestType")
        // window.location.reload()
      } else {
        toast.error("something went wrong");

        this.setState({
          isLoading: false
        })
      }
    }).catch(err=>{
      this.setState({
        isLoading: false
      })
    })
  };



  render() {

    return (
      <>
        <NewPasswordPage
          formData={this.state.formData}
          onInputChangeHandler={this.onInputChangeHandler}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          formError={this.state.formError}
          formSubmitHandler={this.formSubmitHandler}
        />
        {this.state.isLoading && <Loader />}

      </>
    );
  }
}

export default NewPass;


