import React, { useEffect } from 'react';

export const Timer = ({show,onCompletion}) =>{
    let minute=0;
    let second=30;
    let interval='';

    useEffect(()=>{
        interval = show && setInterval(getTime,1000)
    },[show,second])

    const getTime = ()=>{
        if(second<0){
            second=59;
            minute--;
        }
        const element = document.getElementById('timer')
        // let min = minute<10 ? `0${minute}` : minute;
        let sec = second<10 ? `0${second}` : second;
        if(element) element.innerHTML=` Time Remaining ${sec} seconds`;
        second--;
        if(minute === 0 && second < 0){
            clearInterval(interval);
            onCompletion();
        }
    }

    return(
        show && <div id='timer'></div>
    )
}