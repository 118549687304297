import React, { useLayoutEffect } from 'react'
import { useEffect } from 'react';
import { Footer } from '../../common/footer/footer';
import productbanner from "../../../assets/images/productbanner.svg";
import productbannermobile from "../../../assets/images/Top_Product_Mobile.jpg";
import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { uploadURL, NEW_URL } from '../../../constants/applicationConstants';
import dropbox from "../../../assets/images/dropbox.svg";
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom'

const ProductJsx = (props) => {

  const {
    categoryData,
    subCategoryData,
    onSubmitButtonClick,
    formData,
    onInputChange,
    formError,
    investmentProductData,
    mortgageProductData,
    insuranceProductData,
    bankingProductData,
    realStateProductData,
    topProducts
  } = props;

  const { hash } = useLocation()

  useEffect(() => {
    AOS.init();

    if (hash) {
      document.getElementById(hash.replace('#', '')) && document.getElementById(hash.replace('#', '')).scrollIntoView()
    } else {
      window.scrollTo(0, 0);
    }

  }, [realStateProductData,investmentProductData,mortgageProductData,insuranceProductData,bankingProductData])

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20px',

    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };
  var settingstwo = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };
  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="herosectionoffer d-none d-sm-block">
        <img src={productbanner} alt="" />
        <div className="container">
          <div className="offerbannerslider productbannerslider">
            <Slider {...settingstwo}>
              {
                topProducts?.map(product => {

                  return (
                    <Link
                      to={{
                        pathname: "/detailedProduct",
                        state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'realestateProduct', pagePath: '/products' }
                      }}
                      
                      className="ctaonebutton"
                      onClick={() => window.scrollTo(0, 0)}>
                      <div className="sliderContent">
                        <img
                          className="d-block w-100"
                          src={`${NEW_URL}/${product.imageUrl}`}
                          alt=" "
                        />
                        <h5>{product.productName}</h5>
                      </div>                            </Link>

                  )

                })
              }
            </Slider>
          </div>
        </div>
      </section>

      <section className="herosectionoffer d-block d-sm-none">
        <Link to={'/askTheExpert'}> <img src={productbannermobile} alt="" /></Link>
      </section>
      <section className="newserachfield">
        <div className="container">
          <div className="myexpertformarea searcharea martop40">
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="category"
                onChange={onInputChange}
              // onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {categoryData?.map((item) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Category</div>
            </div>
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="subCategory"
                value={formData.subCategory}

                onChange={onInputChange}
              // onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {subCategoryData?.map((item, i) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Sub Category</div>
            </div>
            <div className="input-container form-group">
              <button type="submit" className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Search Products</button>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="herosection">
        <div className="container">
          <h2 className="text-center">{t("TOPPRODUCTS.PRODUCTTITLE")}</h2>
          <h5 className="text-center">{t("TOPPRODUCTS.PRODUCTSUBTITLE")}</h5>
          <h5 className="text-center mb-5">{t("TOPPRODUCTS.PRODUCTSUBTITLE1")}</h5>
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="heroleftpanel" data-aostest="fade-right">

                <h6 className="mt-5">{t("TOPPRODUCTS.PRODUCTSUBSUBTITLE")}<br />
                {t("TOPPRODUCTS.PRODUCTSUBSUBTITLE1")}<br />
                {t("TOPPRODUCTS.PRODUCTSUBSUBTITLE2")}</h6>
                <div className="heroleftbutton d-flex mt-4">
                  <Link to={'/AskTheExpert'} className="ctaonebutton"> Ask the Expert</Link>

                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="herorightpanel" data-aostest="fade-left">
                <img src={Consultingrafiki} alt={' '} />
              </div>
            </div>
          </div>
        </div>
      </section> */}
     { realStateProductData?.length > 2 &&
      <section className="topexpert topexpertproduct" id="realestateProduct">
        <div className="container">

          <h2 className="mt-3 text-center" data-aostest="fade-top">Top Real Estate Products</h2>

          <div className="productslider mt-4">

            <Slider {...settings}>
              {
                realStateProductData?.length > 0 ? realStateProductData?.map(product => {
                  return (
                    <div className="p_sliderouter" data-aostest="flip-down">
                      <div className="p_slider">
                        <h5>{product.productName}</h5>
                        <div className="sldeimage">
                          <LazyLoad>
                            <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                          </LazyLoad>
                        </div>
                        <div className="slbtn">
                          <div className="d-flex">
                            {product?.externalLink && <button type="button" className="ctaonebutton" onClick={() => window.open(product?.externalLink, '_blank')}>Apply Now</button>}
                            <Link
                              to={{
                                pathname: "/detailedProduct",
                                state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'realestateProduct', pagePath: '/products' }
                              }}
                              className="ctaonebutton ctanewtwobutton"
                              onClick={() => window.scrollTo(0, 0)}>
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>No Record Found !! </h5>
                    </div>
                  </div>
              }
            </Slider>

          </div>
        </div>
      </section>
}
{ investmentProductData?.length > 2 &&
      <section className="topexpert topexpertproduct mb-5" id="investmentProduct">
        <div className="container">

          <h2 className="mt-3 text-center" data-aostest="fade-top">Top Investment Products</h2>
          <div className="productslider mt-4">

            <Slider {...settings}>
              {
                investmentProductData?.length > 0 ? investmentProductData?.map(product => {
                  return (
                    <div className="p_sliderouter" data-aostest="flip-down">
                      <div className="p_slider">
                        <h5>{product.productName}</h5>
                        <div className="sldeimage">
                          <LazyLoad>
                            <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                          </LazyLoad>
                        </div>
                        <div className="slbtn">
                          <div className="d-flex">
                            {product?.externalLink && <button type="button" className="ctaonebutton" onClick={() => window.open(product?.externalLink, '_blank')}>Apply Now</button>}
                            <Link
                              to={{
                                pathname: "/detailedProduct",
                                state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'investmentProduct', pagePath: '/products' }
                              }}
                              className="ctaonebutton ctanewtwobutton"
                              onClick={() => window.scrollTo(0, 0)}>
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>No Record Found !! </h5>
                    </div>
                  </div>
              }
            </Slider>

          </div>
        </div>
      </section>
}
{mortgageProductData?.length > 2 &&
      <section className="topexpert topexpertproduct mb-5" id="mortgageProduct">
        <div className="container">

          <h2 className="mt-3 text-center" data-aostest="fade-top">Top Mortgage Products</h2>
          <div className="productslider mt-4">

            <Slider {...settings}>
              {
                mortgageProductData?.length > 0 ? mortgageProductData?.map(product => {
                  return (
                    <div className="p_sliderouter" data-aostest="flip-down">
                      <div className="p_slider">
                        <h5>{product.productName}</h5>
                        <div className="sldeimage">
                          <LazyLoad>
                            <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                          </LazyLoad>
                        </div>
                        <div className="slbtn">
                          <div className="d-flex">
                            {product?.externalLink && <button type="button" className="ctaonebutton" onClick={() => window.open(product?.externalLink, '_blank')}>Apply Now</button>}
                            <Link
                              to={{
                                pathname: "/detailedProduct",
                                state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'mortgageProduct', pagePath: '/products' }
                              }}
                              className="ctaonebutton ctanewtwobutton"
                              onClick={() => window.scrollTo(0, 0)}>
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>No Record Found !! </h5>
                    </div>
                  </div>
              }
            </Slider>

          </div>
        </div>
      </section>}
      {insuranceProductData?.length > 2 &&
      <section className="topexpert topexpertproduct mb-5" id="insuranceProduct">
        <div className="container">

          <h2 className="mt-3 text-center" data-aostest="fade-top">Top Insurance Products</h2>
          <div className="productslider mt-4">

            <Slider {...settings}>
              {
                insuranceProductData?.length > 0 ? insuranceProductData?.map(product => {
                  return (
                    <div className="p_sliderouter" data-aostest="flip-down">
                      <div className="p_slider">
                        <h5>{product.productName}</h5>
                        <div className="sldeimage">
                          <LazyLoad>
                            <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                          </LazyLoad>
                        </div>
                        <div className="slbtn">
                          <div className="d-flex">
                            {product?.externalLink && <button type="button" className="ctaonebutton" onClick={() => window.open(product?.externalLink, '_blank')}>Apply Now</button>}
                            <Link
                              to={{
                                pathname: "/detailedProduct",
                                state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'insuranceProduct', pagePath: '/products' }
                              }}
                              className="ctaonebutton ctanewtwobutton"
                              onClick={() => window.scrollTo(0, 0)}>
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>No Record Found !! </h5>
                    </div>
                  </div>
              }
            </Slider>

          </div>
        </div>
      </section>}
      {bankingProductData?.length > 2 &&
      <section className="topexpert topexpertproduct mb-5" id="bankingProduct">
        <div className="container">

          <h2 className="mt-3 text-center" data-aostest="fade-top">Top Banking Products</h2>
          <div className="productslider mt-4">

            <Slider {...settings}>
              {
                bankingProductData?.length > 0 ? bankingProductData?.map(product => {
                  return (
                    <div className="p_sliderouter" data-aostest="flip-down">
                      <div className="p_slider">
                        <h5>{product.productName}</h5>
                        <div className="sldeimage">
                          <LazyLoad>
                            <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                          </LazyLoad>
                        </div>
                        <div className="slbtn">
                          <div className="d-flex">

                            {product?.externalLink && <button type="button" className="ctaonebutton" onClick={() => window.open(product?.externalLink, '_blank')}>Apply Now</button>}
                            <Link
                              to={{
                                pathname: "/detailedProduct",
                                state: { data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'bankingProduct', pagePath: '/products' }
                              }}
                              className="ctaonebutton ctanewtwobutton"
                              onClick={() => window.scrollTo(0, 0)}>
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>No Record Found !! </h5>
                    </div>
                  </div>
              }
            </Slider>

          </div>
        </div>
      </section>
}
      <Footer />
    </>

  );
};

export default ProductJsx;