import React from 'react'
import ResetPassJson from "./newPassword.json";
import { useTranslation } from "react-i18next";

import { ToastContainer } from 'react-toastify';
const ChangePaawordJsx = (props) => {
  const { t } = useTranslation();

  let {
    formData,
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    termAndCondition,
    handleChange
  } = props;

  return (
    <>
      <section className="loginscreeninstitute registrationinstitute passw">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
                {/* <div className="back_btn" onClick={() => history.goBack()}><img src={leftarrow} alt={'icon'} /></div> */}
                <div className="detailsleft plaindetailsleft paddingleft0">
                  <h1>{t("NEWPASSWORD.TITLE")}</h1>


                  {
                    localStorage.getItem('flowName') === 'login' ?
                      <>
                        <div className="passtext martop60">
                          <h4 className="italic h4_style color-light-blue">{t("NEWPASSWORD.FARFROMHACKER")} </h4>
                          <h4 className="h4_style p-text">{t("NEWPASSWORD.STRONGPASSWORD")}</h4>
                          <ul className="martop20">
                            <li>{t("NEWPASSWORD.CHARACTER")}</li>
                            <li>{t("NEWPASSWORD.ONENUMBER")}</li>
                            <li>{t("NEWPASSWORD.SPECIALCHAR")}</li>
                            <li>{t("NEWPASSWORD.UPPERCHAR")}</li>
                          </ul>
                        </div>
                      </>
                      : <>

                        <div className="passtext martop60">
                          <h4 className="italic h4_style color-light-blue">Valid Password Characteristics

</h4>
                          <h4 className="h4_style p-text">Create a strong password having:</h4>
                          <ul className="martop20">
                            <li>{t("NEWPASSWORD.CHARACTER")}</li>
                            <li>{t("NEWPASSWORD.ONENUMBER")}</li>
                            <li>{t("NEWPASSWORD.SPECIALCHAR")}</li>
                            <li>{t("NEWPASSWORD.UPPERCHAR")}</li>
                          </ul>
                        </div>
                      </>
                  }

                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext NEWPASSWORD martop60 paddingbot60">
                <div className="forgetpassinner">
                  {
                    localStorage.getItem('flowName') === 'login' ?
                      <>
                        <h2 className="h2_style">{t("NEWPASSWORD.CHANGEPASS")}</h2>
                        <h5 className="h2_style fst-italic color-light-blue">{t("NEWPASSWORD.HACKERSTAY")}</h5>
                      </>
                      : <>

                        <h2 className="h2_style text-center">Create a New Password</h2>
                        <h5 className="h5_style fst-italic text-center">Experience enhanced security by updating your temporary password. 
Kindly ensure it meets all the valid password characteristics.</h5>
                      </>
                  }

                  {
                    localStorage.getItem('flowName') === 'login' ?
                      <>
                        <h5 className="h5_style martop30">{t("NEWPASSWORD.CHECKEMAIL")}</h5>
                      </>
                      : <>

                        <h5 className="h5_style martop30"></h5>
                      </>
                  }
                  <div className="ft-inner otpauthentication">
                    {ResetPassJson.map((item, i) => (
                      <>
                        <div className="input-container form-group mb-4">
                          <input
                            type={item.type}
                            className={
                              item.isRequired && formError[item.name]
                                ? "input form-control  is-invalid"
                                : "input form-control"
                            }
                            id={item.name}
                            name={item.name}
                            placeholder=" "
                            defaultValue={formData[item.name]}
                            onChange={onInputChangeHandler}
                            onBlur={onTouchHandler}
                            maxLength={item.maxLength}
                          />
                          <div className="cut"></div>
                          <label className="placeholder">{t(`${item.label}`)}</label>
                          <span className="h6 text-danger">
                            {item.isRequired &&
                              formError[item.name] &&
                              (formError[item.name] === "req"
                                ? t(item.error)
                                : (formError[item.name] === "regx") ? t(item.type_error) : "Password did not match")}
                          </span>
                        </div>

                      </>
                    ))}
                    
                    <div className="mb-4 form-check">
                          <input type="checkbox" className="form-check-input" id="termscondition" onChange={e => handleChange(e)} />
                          <label className="form-check-label" for="termscondition">{t("PLANS.AGREE")} <a href="/termsCondition" target="_blank"  rel="noreferrer" >{t("PLANS.TERMS")}</a></label>
                        </div>
                    <button type="submit"
                      className={(formSubmitButtonDisable || !termAndCondition) ? "btn-secondary btn mt-4 ctatwobutton"
                        : "mt-4 ctaonebutton"}
                      disabled={formSubmitButtonDisable || !termAndCondition}
                      onClick={formSubmitHandler}>{t("NEWPASSWORD.CONTINUE")}</button>

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>

      <ToastContainer />

    </>

  );
};

export default ChangePaawordJsx;
