import React from 'react';
import envelop from "../../../assets/images/blue_env.svg";
import facebook from "../../../assets/images/facebookwhite.svg";
import twitter from "../../../assets/images/twitterwhite.svg";
import instagram from "../../../assets/images/instawhite.svg";
import linkedin from "../../../assets/images/linkedinwhite.svg";
import planic from "../../../assets/images/planic.svg";
import call from "../../../assets/images/blue_mob.svg";
import awardicon from "../../../assets/images/awardicon.svg";
import certificationicon from "../../../assets/images/certificationicon.svg";
import { uploadURL,NEW_URL } from '../../../constants/applicationConstants';
import { getProperWebsite,formatCanadianPhoneNumber } from '../../../shared/helper';

export const ExpertDetails = (props) => {
  const { profileData } = props;

  const getCategory =  () =>{
    const data =  profileData?.expertSpecializations?.map(spec => '<span>'+spec.parentInstitutionCategory+'</span>');
    //return [...new Set(data)].toString()
    return data && data.join(', ');
    
  }

  return (
    <div className="container">
      <div className="publicdeinnerts ppvexpertdetailspop institutepublicprofile">
      <div className="publicdeinner">
        <div className="publicmainpopdis basicdetails">
          <div className="publicviewprofilelogoupload">
            <div className="publicviewprofilelogouploadinner">
            <img src={!profileData?.imageUrl ? `https://ui-avatars.com/api/?name=${profileData?.firstName}+${profileData?.lastName}&rounded=true` : `${NEW_URL}/${profileData.imageUrl}`} alt={' '} />
            </div>
          </div>
          <div className="publicviewprofilede">
            <h2 className="h2_style">{`${profileData?.firstName} ${profileData?.lastName}`}</h2> 
            <div className="cstlist" dangerouslySetInnerHTML={{__html:getCategory()}}></div>
          </div>
         
           <div className="d-flex">
        <div className="publicpersonaldetails">
          <div className="logemail">
            
            <ul className="detwo ppvtwo">
              <heading>Certifications :</heading>
              
                {
                  profileData?.certificates.length > 0 ? profileData?.certificates.map(certificate => {
                    return <li><div><span><img src={certificationicon}  alt={' '}/></span><div className="cerdet">{certificate.certificate}</div></div></li>
                  })
                  :
                  <li><div><span><img src={certificationicon}  alt={' '}/></span><div className="cerdet">N/A</div></div></li>
                }
              

            </ul>
            
          </div>
        </div>
        <div className="publicpersonaldetails">
          <div className="logemail">
          <heading>Awards :</heading>
            <ul className="dethree ppvthree">
              
            {
                 profileData?.awards.length > 0 ? profileData?.awards.map(award => {
                    return <li><div><span><img src={awardicon}  alt={' '}/></span><div className="cerdet">{award.award}</div></div></li>
                  })
                  :
                  <li><div><span><img src={awardicon}  alt={' '}/></span><div className="cerdet">{`N/A`}</div></div></li>
                }
              

            </ul>
          </div>
        </div>
        </div>
        </div>
        {(profileData?.socialMedia?.facebookUrl || profileData?.socialMedia?.twitterUrl || profileData?.socialMedia?.instagramUrl || profileData?.socialMedia?.linkedinUrl) &&
          <div className="socialpublic d-flex">
              <ul>

                {profileData?.socialMedia?.facebookUrl && <li><span><a href={getProperWebsite(profileData?.socialMedia?.facebookUrl)} target="_blank"  rel="noreferrer"  ><img src={facebook} alt={' '} /></a></span></li>}
                {profileData?.socialMedia?.twitterUrl && <li><span><a href={getProperWebsite(profileData?.socialMedia?.twitterUrl)} target="_blank"  rel="noreferrer" ><img src={twitter}  alt={' '}/></a></span></li>}
                {profileData?.socialMedia?.instagramUrl && <li><span><a href={getProperWebsite(profileData?.socialMedia?.instagramUrl)} target="_blank"  rel="noreferrer" ><img src={instagram}  alt={' '}/></a></span></li>}
                {profileData?.socialMedia?.linkedinUrl && <li><span><a href={getProperWebsite(profileData?.socialMedia?.linkedinUrl)} target="_blank"  rel="noreferrer" ><img src={linkedin}  alt={' '} /></a></span></li>}
              </ul>
            </div>
        }
        <div className="logemail mainsecpos">
            <ul className="deone">
              <li><span><img src={envelop} alt={' '} /></span> <a href={`mailto:${profileData?.email}`} target="_blank"  rel="noreferrer" >{profileData?.email} </a> </li>
              <li><span><img src={call} alt={' '} /></span> <a href={`tel:${profileData?.phoneNumber}`}> {profileData?.phoneNumber && formatCanadianPhoneNumber(profileData?.phoneNumber)}</a> </li>
            </ul>
          </div>
     <div class="ppvplan"><img src={planic} alt={' '} />{profileData?.planName}</div>
      </div>
      
      </div>
    </div>

  )
}