import React, { useEffect } from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import call from "../../../../assets/images/call.svg";
import map from "../../../../assets/images/map.svg";
import envelop from "../../../../assets/images/envelop.svg";
import Partial from "../myExpertUn/myExpert.js"
import Sidebar from "../../../../components/dashboard/institute/sidebar/sidebar.js";
import InputMask from 'react-input-mask';
import {  NEW_URL } from '../../../../constants/applicationConstants'; import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatCanadianPhoneNumber } from '../../../../shared/helper'

const MyExpertJsx = (props) => {
  const history = useHistory();
  const { t } = useTranslation();


  const {
    categoryData, formData,
    onInputChange,
    formError,
    onTouchHandler,
    onSubmitButtonClick,
    onViewProfieClick,
    expertData,
    activeTab,
    onTabClick
  } = props;
  const loginData = JSON.parse(sessionStorage.getItem('loginData'));

  const getCategory =  (abhi) =>{
    const data =  abhi?.expertSpecializations?.map(spec => '<span>'+spec.parentInstitutionCategory+'</span>');
    //return [...new Set(data)].toString()
    return data && data.join("")
    
  }

  const getInstitutionName = () => {
    let tempArr = [];

    expertData?.map(cat => {

      tempArr.push(cat?.slugUrl)
    })
    localStorage.setItem("slugSet", tempArr.join())




  }
  useEffect(() => {
    getInstitutionName()
  });

  const planType = sessionStorage.getItem("planName")
  const addAnotherExpert = () => {
    let token = sessionStorage.getItem("token")
    localStorage.setItem('AdminFlow', 'expert')
    localStorage.setItem("instituteExpertToken", token)
    localStorage.setItem("instituteExpert", true)
    localStorage.setItem("adInsExpert", true)
    sessionStorage.setItem("comingFromInstitute", true)


    // sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.tokenValidate));
    history.push("/expertRegistration");
  }

  // {t("PRINT.TITLE")}
  return (
    <>
      <>
        {  activeTab === 'acntStng' ?
          <section className="entiredashboard">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-3">
                  <Sidebar />
                </div>
                <div className="col-lg-9 col-sm-9">
                  <div className="dashboardrightpanel martop40">
                    <div className="dashboardrightpannerinner">
                      <div className="dashboardrightpannerinnerleft">
                        <h2 className="h2_style font-weight-600">{t("MYEXPERT.HEADING")}</h2>
                        <h5 className="h5_style">{t("MYEXPERT.SUBHEADING1")} <strong>{t("MYEXPERT.SUBHEADING2")}</strong> {t("MYEXPERT.SUBHEADING3")}  </h5>
                      </div>
                      <div className="dashboardrightpannerinnerright">
                        <div className="signout">
                          <a href="#logout">
                            <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                          </a>
                        </div>
                        <div id="logout" className="modal-window">
                          <div>
                            <a  title="Close" className="modal-close">Close</a>
                            <div className="modal-header">

                            </div><div className="modal-body mb-4">
                              <div className="modal-body-inner">
                                <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="ctaonebutton"> Save</button>
                              <button type="button" className="ctatwobutton"> Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="addexpert martop40">
                      <div className="addexpertleft">
                        <h3 className="h3_style">{t("MYEXPERT.ADDEXPERT")}</h3>
                        <p>{t("MYEXPERT.ADDEXPERTTITLE")} {loginData?.totalExpertsLimit} </p>
                      </div>
                      <div className="addexpertright">
                        {planType && planType === "Premium" ? <button type="button" className="ctaonebutton" onClick={addAnotherExpert} >{t("MYEXPERT.ADDEXPERTBUTTON")}</button>
                          :
                          <button type="button" className="ctaonebutton" onClick={addAnotherExpert} >{t("MYEXPERT.ADDEXPERTBUTTON")}</button>

                        }

                      </div>
                    </div>
                    <p className="mt-5">{t("MYEXPERT.ADDEXPERTTITLEONE")} {loginData?.totalExpertsLimit} {t("MYEXPERT.ADDEXPERTTITLETWO")}</p>
                    <div class="myexpertnav martop60 expnewdesign">
                      <ul>
                        <li className="active">Registered Experts</li>
                        <li onClick={() => onTabClick('partial')}>Partially Registered Experts</li>
                      </ul>
                    </div>



                    <section className="registeredExperts">
                      <div className="myexpertformarea martop40">

                        <div class="martop60">
                          <div className="input-container form-group">
                            <InputMask mask={'a9a 9a9'} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                              {(inputProps) => <input {...inputProps}
                                type="tel"
                                className={
                                  formError['postalCode']
                                    ? "input form-control  is-invalid"
                                    : "input form-control"
                                }
                                placeholder=" "
                                disableUnderline />}
                            </InputMask>
                            <div className="placeholder">Postal Code</div>
                          </div>

                          <div className="input-container form-group">
                            <select
                              className="input form-control form-select"
                              placeholder=" "
                              name="category"
                              onClick={onInputChange}
                              onBlur={onTouchHandler}
                            >
                              <option value="">All</option>
                              {categoryData?.map((item) => {
                                return (
                                  <>
                                    <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                                  </>
                                )
                              })}
                            </select>
                            <div className="placeholder">Category</div>
                          </div>

                          <div className="input-container form-group"><button className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Submit</button></div>
                        </div>
                      </div>
                    </section>



                    <p className="smalltext myexpert martop20">{t("MYEXPERT.ADDEXPERTWARN")}</p>
                    <div className="expertsection martop30">
                      {props?.expertData?.length > 0 ?
                        props.expertData?.map(data => {
                          return <div className="expertinner">
                            <div className="expertuser"><img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data?.firstName}+${data?.lastName}&rounded=true` : `${NEW_URL}/${data.imageUrl}`} alt={' '} /></div>
                            <div className="experdetails">
                            <h3>{`${data?.firstName} ${data?.lastName}`}<span><div className="expcat needcoma"  dangerouslySetInnerHTML={{__html:getCategory(data)}}></div></span></h3>
                              <ul>
                                <li><span><img src={map} alt={''} /></span> {data?.expertSpecializations[0]?.city || "NA"}</li>
                                <li><span><img src={call} alt={''} /></span> {data.phoneNumber && formatCanadianPhoneNumber(data.phoneNumber)}</li>
                                <li><span><img src={envelop} alt={''} /></span> {data.email}</li>
                              </ul>
                            </div>
                            <div className="viewprofile"><button className="ctaonebutton" onClick={() => onViewProfieClick(data?.slugUrl)}>View Profile</button></div>
                            <div className="expertpremium">{data.planName || "Not available"}</div>
                          </div>
                        })
                        : <h3>NO EXPERT AVAILABLE</h3>}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          : <Partial />}
      </>
    </>

  );
};

export default MyExpertJsx;
