import React from 'react'
import { Component } from 'react';

export default class Search extends Component{
    render(){
        return(
            <div>
                This is Tools Screen.
            </div>
        )
    }
}