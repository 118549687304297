import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { toast } from 'react-toastify';
import { baseURL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import PendingQuestionJsx from './pendingQuestion.jsx'

export default class PendingQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingQuestions: null,
            answeredQuestion: null,
            isProcessing: false
        }
    }

    componentDidMount = () => {
        this.getData();
        const token = JSON.parse(sessionStorage.getItem('token'));

        if (!token) {
            this.props.history.push("/admin");
        }
    }

    getData = () => {
        this.setState({ isProcessing: true });
        axios.get(`${baseURL}/askExpertQuestionUserResponses?status=pending`).then(res => {
            this.setState({ pendingQuestions: res.data.data, isProcessing: false })
        }).catch(err => {
            this.setState({ isProcessing: false })
        })

        axios.get(`${baseURL}/askExpertQuestionUserResponses?status=accepted`).then(res => {
            this.setState({ answeredQuestion: res.data.data, isProcessing: false })
        }).catch(err => {
            this.setState({ isProcessing: false })
        })
    }

    onButtonClick = (id, status) => {
        this.setState({ isProcessing: true });
        axios.put(`${baseURL}/askExpertQuestionUserResponse/${id}`, { "status": `${status}` }).then(res => {
            this.setState({ isProcessing: false });
            this.getData();
        }).catch(err => {
            toast.error(err?.response.data.error.message)
            this.setState({
                isProcessing: false
            })
        })
    }

    onAnsweredQuestionClick = () => {
        this.props.history.push('./answerQuestion')
    }

    render() {
        return (
            <div>
                <PendingQuestionJsx
                    data={this.state.pendingQuestions}
                    answeredQuestion={this.state.answeredQuestion}
                    onButtonClick={this.onButtonClick}
                    onAnsweredQuestionClick={this.onAnsweredQuestionClick}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}