import React from 'react'
import { useEffect } from 'react';
import testicomma from "../../../../assets/images/testicomma.svg";
import testicommainvert from "../../../../assets/images/testicommainvert.svg";
import { Footer } from '../../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeInsuranceJsx = () => {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <section className="homecustom">
      <section className="homepageproductoffer mt-5 mb-5 padbottom100">
        <div className="container">
          <h2 className="text-center" data-aos="fade-top">Quotes</h2>
          <div className="row mt-5">
            <div className="col-lg-6 col-sm-6"  data-aos="fade-right">
              <div className="testileftpanel">
                <img src={testicomma} className="testiconone" alt={''} />
                <img src={testicommainvert} className="testicontwo" alt={''} />
                <h2>Affordable rates. </h2>
                <h2>Unbeatable coverage.</h2>
                <h2>Never seen before quotes.</h2>
                <h4 className="mt-4 mb-4">Get customized mortgage & insurance quotes that
work for you!</h4>
                <h5>We offer options that fit your unique needs and budget.<i>Quick and Easy. Get your special quotes now!</i></h5>
                
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="rightform"  data-aos="fade-left">
                <div className="mb-4 input-container selectboxmain form-group">
                  <select className="input form-control form-select">
                  <option>All</option>
                  <option>Car Insurance </option>
                    <option>Home Insurance</option>
                    <option>Life Insurance</option>
                    <option>Travel Insurance</option>

                  </select><label className="placeholder">Type of quotes</label>
                </div>
                <div className="mb-4 input-container form-group">
                  <input type="text" className="input form-control" placeholder=" " maxlength="30" />
                  <label className="placeholder">Your full Name</label>
                </div>
                <div className="mb-4 input-container form-group">
                  <input type="text" className="input form-control" placeholder=" " maxlength="30"/>
                  <label className="placeholder">Your Email</label>
                </div>
                <div className="disgrid grid-2">
                  <div className="mb-4 input-container form-group">
                    <input type="tel" className="input form-control" placeholder=" " maxlength="10"/>
                    <label className="placeholder">Your phone number </label>
                  </div>
                  <div className="mb-4 input-container form-group">
                    <input type="text" className="input form-control" placeholder=" " />
                    <label className="placeholder">Postal Code</label>
                  </div>
                </div>
                <button type="button" className="ctaonebutton border-radius-50">Show me Quotes!</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="homepageproductoffer mt-5 mb-5">
        <div className="container">
          <h2 className="text-center">Home Insurance</h2>
          <h5 className="text-center">Don't leave the safety of your beloved home and belongings to chance.<br/>
We offer hassle-free access to the best home insurance quotes on the market.<br/>
 Take a minute to fill in the details below.</h5>

          <div className="innerpagesform mt-5 mb-5">
          <div className="form-group">
              <label>Type of Coverage :</label>
              <select>
                <option>2000</option>
                <option>2001</option>
              </select>
            </div>
            <div className="form-group">
              <label>Year of Manufacture : </label>
              <select>
                <option>2000</option>
                <option>2001</option>
              </select>
            </div>
            <div className="form-group">
              <label>Registration Number:</label>
              <input type="text" className="forn-control" />
            </div>
            <div className="form-group">
              <label>Kms covered :</label>
              <input type="text" className="forn-control" />
            </div>
            <div className="form-group">
              <label>No. of travelers :</label>
              <input type="text" className="forn-control" />
            </div>
            <div className="form-group">
              <label>Driver's age :</label>
              <input type="text" className="forn-control" />
            </div>
            <div className="form-group">
              <label>Type of Coverage :</label>
              <select>
                <option>Comprehensive</option>
                <option>Basic</option>
              </select>
            </div>
            <div className="form-group mt-4 form-grouptravel">
            <label class="container-checkbox"> 
            <input type="checkbox" class="form-check-input"  checked="" />
              <span class="checkmark"></span> I consent to the use of my personal information for the purpose of
 providing me with personalized insurance quotes.</label>
            </div>
            <div className="form-group mt-4 form-grouptravel">
            <label class="container-checkbox"> 
            <input type="checkbox" class="form-check-input"  checked="" />
              <span class="checkmark"></span> I hereby grant my consent to be contacted by experts in order
 to obtain more personalized quotes.</label>
            </div>
            <div className="form-group mt-4">
              <button type="submit" className="ctaonebutton widthhalf">Submit</button>
            </div>
          </div>
        </div>
      </section>
        <Footer />
      </section>
    </>

  );
};

export default HomeInsuranceJsx;