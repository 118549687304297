import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { Loader } from '../../../common/loader/loader.jsx';
import ManageUnverifiedInstituteJsx from './manageUnverifiedInstitute.jsx'

export default class ManageUnverifiedInstitute extends Component{
    constructor(props){
        super(props);
        this.state={
            data:[],            
            page: 1,
            meta:{},
            isProcessing:false
        }
    }

    componentDidMount = () =>{
        this.getInstitute()
       
    }

    getInstitute = () => {
        this.setState({isProcessing:true})
        const token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/searchInstitutes?status=unverified&perPage=10&page=${this.state.page}`,{
            headers:{
                'x-access-token':token
            }
        }).then(res=>{
            this.setState({data:res.data.data,meta:res.data.meta,isProcessing:false});
        }).catch(()=>{
            this.setState({isProcessing:false});
        })
        if (!token) {
            this.props.history.push("/admin");
        }
    }

    onAddButtonClick = () =>{
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("instituteId");
        localStorage.removeItem("AdminFlow")
        const token = JSON.parse(sessionStorage.getItem('token'));

        sessionStorage.setItem("adminOldToken", token);
        localStorage.setItem('verificationStatus', 'unVerified')
        this.props.history.push('/institutionalRegistration')
    }

    onVerifyNowClick = (id) =>{
        localStorage.setItem('VerifyInstituteID', id)
        const token = JSON.parse(sessionStorage.getItem('token'));

        sessionStorage.setItem("adminOldToken", token);
        this.props.history.push('/institutionalRegistration')
    }

    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getInstitute())
    }

    render(){
        return(
            <div>
                <ManageUnverifiedInstituteJsx
                data={this.state.data}
                onAddButtonClick={this.onAddButtonClick}
                onVerifyNowClick={this.onVerifyNowClick}
                meta={this.state.meta}
                onPaginationButtonClick={this.onPaginationButtonClick}

                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}
