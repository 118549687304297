import React from 'react'
import login1json from "./login.json";
import { useTranslation } from "react-i18next";
import logIN from "../../assets/images/login.png";
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

const LoginJsx = (props) => {
  const { t } = useTranslation();
  const {
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    isLoading
  } = props;

  return (
    <>
      <section className="loginscreeninstitute">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <a  className="logincs active">
                <div className="loginscreenimage">
                  <figure><img src={logIN}  alt={' '}/></figure>
                  <figcaption>
                    <h3>Operation User Login</h3>
                    <p>Login here with Operation User's Credentials</p>
                  </figcaption>
                </div>
              </a>

              <Link to ="/expertLogin" className="logincs ">
                <div className="loginscreenimage">
                  <figure><img src={logIN}  alt={' '}/></figure>
                  <figcaption>
                    <h3>Marketting User Login</h3>
                    <p>Login here with Marketting User’s Credentials</p>
                  </figcaption>
                </div>
              </Link>

              <a href="/login" className="logincs">
                <div className="loginscreenimage">
                  <figure><img src={logIN}  alt={' '}/></figure>
                  <figcaption>
                    <h3>Admin User Login</h3>
                    <p>Login here with Admin User’s Credentials</p>
                  </figcaption>
                </div>
              </a>



            </div>
            <div className="col-lg-7 col-sm-7">
              <div className="institutelogintext martop60 paddingbot60">
                <h2 className="h2_style text-center font-weight-600">Operation User Login</h2>
                <h6 className="h6_style text-center">Your dashboard is waiting for you. Login Now.</h6>
                <p className="smalltext martop30">Enter your email below.</p>

                <form onSubmit={formSubmitHandler}>
                  {login1json.map((item, i) => (
                    <>
                      {item.fieldType === "input" &&
                        <div className="mb-4 input-container form-group">
                          <input
                            onChange={onInputChangeHandler}
                            onBlur={onTouchHandler}
                            required
                            type={item.type}
                            placeholder=" "
                            className={
                              item.isRequired && formError[item.name]
                                ? "input form-control  is-invalid"
                                : "input form-control"
                            }
                            id={item.name}
                            name={item.name}
                            maxLength={item.maxLength}

                          />
                          <div className="cut"></div>
                          <label class="placeholder">{t(`${item.placeholder}`)} </label>
                          
                          <span className="text-danger">
                            {item.isRequired &&
                              formError[item.name] &&
                              (formError[item.name] === "req"
                                ? t(item.error)
                                : t(item.type_error))}
                          </span>
                          
                        </div>
                      }
                      {item.fieldType === "select" &&
                        <div className="mb-4 input-container form-group">
                          <select className="form-select"
                          >sssssss</select>
                        </div>
                      }
                      {
                        item.fieldType === "multiple" &&
                        <div className="row">
                          {item.fields.map((items) => {
                            return <>
                              {
                                items.fieldType === "input" &&
                                <div className="col-sm-4 form-group">
                                  <input
                                    onChange={onInputChangeHandler}
                                    required
                                    type={items.type}
                                    className="input form-control"
                                    id={items.name}
                                    name={items.name}
                                    placeholder={t(`${items.placeholder}`)}

                                  />
                                </div>
                              }{
                                items.fieldType === "select" &&
                                <div className="col-sm-4 form-group">
                                  <select className="form-select">sssssss</select>
                                </div>
                              }
                            </>

                          })}

                        </div>
                      }
                    </>
                  ))}


                  <button type="submit" className={
                    formSubmitButtonDisable
                      ? "btn-secondary btn mt-4 ctatwobutton"
                      : "mt-4 ctatwobutton"
                  }
                    disabled={formSubmitButtonDisable}>{isLoading ? "Processing........" : "Next"}</button>
                  <div className="form-text">{t("LOGIN.IS_ACCOUNT")} </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />

    </>

  );
};

export default LoginJsx;
