import React, { Component } from "react";
import AskTheExpertQuestionListCustomerJsx from "./askTheExpertQuestionListCustomer.jsx"


export default class AskTheExpertQuestionListCustomer extends Component{
  render(){
      return(
          <div>
              <AskTheExpertQuestionListCustomerJsx />
          </div>
      )
  }
}