import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { Loader } from '../../../common/loader/loader.jsx';
import SuggestedOfferJsx from './suggestedOffer.jsx'

export default class SuggestedOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investmentOfferData: null,
      mortgageOfferData: null,
      insuranceOfferData: null,
      bankingOfferData: null,
      realestateOfferData: null,
      isProcessing: false
    }
  }

  componentDidMount = () => {
    this.getInvestmentOfferData();
    this.getMortgageOfferData();
    this.getInsuranceOfferData();
    this.getBankingOfferData();
    this.getRealestatefferData();
  }

  getInvestmentOfferData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/featuredOffers?institutionCategoryId=2`).then(res => {
      this.setState({ investmentOfferData: res.data.data, isProcessing: false })
    })
  }

  getMortgageOfferData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/featuredOffers?institutionCategoryId=3`).then(res => {
      this.setState({ mortgageOfferData: res.data.data, isProcessing: false })
    })
  }

  getInsuranceOfferData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/featuredOffers?institutionCategoryId=1`).then(res => {
      this.setState({ insuranceOfferData: res.data.data, isProcessing: false })
    })
  }

  getBankingOfferData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/featuredOffers?institutionCategoryId=4`).then(res => {
      this.setState({ bankingOfferData: res.data.data, isProcessing: false })
    })
  }

  getRealestatefferData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/featuredOffers?institutionCategoryId=5`).then(res => {
      this.setState({ realestateOfferData: res.data.data, isProcessing: false })
    })
  }

  render() {
    return (
      <div>
        <SuggestedOfferJsx
          investmentOfferData={this.state.investmentOfferData}
          mortgageOfferData={this.state.mortgageOfferData}
          insuranceOfferData={this.state.insuranceOfferData}
          bankingOfferData={this.state.bankingOfferData}
          realestateOfferData={this.state.realestateOfferData}
        />
        {this.state.isProcessing && <Loader />}
      </div>
    )
  }
}