import React from 'react'
import { useTranslation } from "react-i18next";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { getProperWebsite } from '../../../../shared/helper';
import { NEW_URL } from '../../../../constants/applicationConstants';

const SuggestedOfferJsx = (props) => {
  const { t } = useTranslation();
  const {
    investmentOfferData,
    mortgageOfferData,
    insuranceOfferData,
    bankingOfferData,
    realestateOfferData,
  } = props;

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  const onOfferClick = (offer) => {
    if (offer?.externalUrl) {
      window.open(getProperWebsite(offer.externalUrl), '_blank');
    }
  }

  return (
    <>
      <section class="alldashboard">

        <div className="dashboardrightpanel martop40 mb-5">

          <div className="suggestedoffercustomer">
            <section className="limitedoffer mt-5">

              <div className="limitedofferinner">
                <div className="heading">
                  <h2>{t("TOPOFFERS.OFFERTITLE0")}</h2>
                  <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE0")}</h5>
                </div>
                <div className="offerslider martop40 mb-5">
                  <AwesomeSlider>
                    {
                      realestateOfferData?.map(offer => {
                        return <div>
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />
                          <h3>{offer.description}</h3>
                          <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="ppvawesomesliderproducttype"><div>
                              <h4>Description</h4> {offer?.offerName}
                            </div>{offer?.description}</div>
                            <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                            <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                          </div>
                          <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                        </div>
                      })
                    }
                  </AwesomeSlider>
                </div>
              </div>
              <section className="limitedoffer mt-5">
                <div className="limitedofferinner">
                  <div className="heading">
                    <h2>{t("TOPOFFERS.OFFERTITLE1")}</h2>
                    <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE1")} </h5>
                  </div>
                  <div className="offerslider martop40 mb-5">

                    <AwesomeSlider>
                      {
                        investmentOfferData?.map(offer => {
                          return <div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                            <h3>{offer.description}</h3>
                            <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype"><div>
                                <h4>Description</h4> {offer?.offerName}
                              </div>{offer?.description}</div>
                              <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                              <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                            </div>
                            <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              </section>
              <section className="limitedoffer mt-5">
                <div className="limitedofferinner">
                  <div className="heading">
                    <h2>{t("TOPOFFERS.OFFERTITLE2")}</h2>
                    <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE2")} </h5>
                  </div>
                  <div className="offerslider martop40 mb-5">

                    <AwesomeSlider>
                      {
                        mortgageOfferData?.map(offer => {
                          return <div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                            <h3>{offer.description}</h3>
                            <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype"><div>
                                <h4>Description</h4>  {offer?.offerName}
                              </div>{offer?.description}</div>
                              <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                              <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                            </div>
                            <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              </section>
              <section className="limitedoffer mt-5">
                <div className="limitedofferinner">
                  <div className="heading">
                    <h2>{t("TOPOFFERS.OFFERTITLE3")}</h2>
                    <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE3")}</h5>
                  </div>
                  <div className="offerslider martop40 mb-5">

                    <AwesomeSlider>
                      {
                        insuranceOfferData?.map(offer => {
                          return <div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                            <h3>{offer.description}</h3>
                            <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype"><div>
                                <h4>Description</h4>  {offer?.offerName}
                              </div>{offer?.description}</div>
                              <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                              <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                            </div>
                            <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              </section>
              <section className="limitedoffer mt-5">
                <div className="limitedofferinner">
                  <div className="heading">
                    <h2>{t("TOPOFFERS.OFFERTITLE4")}</h2>
                    <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE4")} </h5>
                  </div>
                  <div className="offerslider martop40 mb-5">

                    <AwesomeSlider>
                      {
                        bankingOfferData?.map(offer => {
                          return <div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                            <h3>{offer.description}</h3>
                            <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype"><div>
                                <h4>Description</h4>  {offer?.offerName}
                              </div>{offer?.description}</div>
                              <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                              <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                            </div>
                            <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      </section>
    </>

  );
};

export default SuggestedOfferJsx;