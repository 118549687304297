import React from 'react'
import { useTranslation } from "react-i18next";
import Sign_out_squre from "../../../../../assets/images/Sign_out_squre.svg";
import planpricingtooltip from "../../../../../assets/images/planpricingtooltip.svg";
import question from "../../../../../assets/images/question.svg"
import Sidebar from "../../../../../components/dashboard/institute/sidebar/sidebar.js";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PlanJSON from './plans.json';
import 'aos/dist/aos.css';


const ChangePlaneJsx = (props) => {
  const { t } = useTranslation();
  const {
    bilingPeriod,
    onBilingButtonClick,
    planType,
    onPlanTypeButtonClick,
    isInstitutionExpet,
    onInstitutionExpertClick,
    termAndCondition,
    onTermsAndConditionClick,
    onNextButtonClick,
    planDetail
  } = props;

  const getPrice = (name) => {
    const planTypeData = planDetail?.filter(data => data.planName === name);
    let rate = ''
    planTypeData?.[0].subscriptionPlanTypes?.map(data => {
      if (data.planType === "monthly" && bilingPeriod === 'Monthly Billing')
        rate = data.price
      else if (data.planType === "anually" && bilingPeriod === 'Annual Billing')
        rate = data.price
    })
    return rate;
  }

  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">Plans & Pricing</h2>
                    <h5 className="h5_style">All our features drive business performance!
Review them below & choose the best plan that suits you.</h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={question}  alt={''} /><span className="title">Help</span>
                      </a>
                    </div>
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre}  alt={''} /><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" className="modal-window">
                      <div>
                        <a  title="Close" className="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
                <div class="myexpertnav myaccountnav palnnav martop40">
                    <ul>
                      <li className={`${bilingPeriod === 'Monthly Billing' ? 'active' : ''}`} onClick={() => onBilingButtonClick('Monthly Billing')}>Monthly Billing</li>
                      <li className={`${bilingPeriod === 'Annual Billing' ? 'active' : ''}`} onClick={() => onBilingButtonClick('Annual Billing')}>Annual Billing
                      <span>Save upto 40%</span></li>
                    </ul>
                  </div>
                  <div className="planwhole mt-4">
                <div className="planesall">
                  <div className="row">
                    <div className="col-lg-8 col-sm-8">
                      <div className="planesinner">
                        <h2 className="h3_style text-center">{t("PLANS.BOOSTBUSSINESS")}</h2>
                        <hr></hr>
                        <ul>
                          {
                            PlanJSON.Plans.map(plans => {
                              return <li>{plans.name} <span className="plantooltip custooltip" ><img src={planpricingtooltip}  alt={''} className="tooltipim" />
                                <div class="tooltipcontent"> {plans.tooltipTitle}</div>
                              </span></li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2">
                      <div className={`planesinnerright premium ${planType === 'Premium' ? 'broadtp' : ''}`} onClick={() => onPlanTypeButtonClick('Premium')}>
                        <h2 className="text-center h3_style">{t("PLANS.PREMIUM")}</h2>
                        <hr></hr>
                        <ul>
                          {
                            PlanJSON.Plans.map(plans => {
                              return <li>{plans.Premium}</li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2 no-pad-left">
                      <div className={`planesinnerright elite ${planType === 'Elite' ? 'broadtp' : ''}`} onClick={() => onPlanTypeButtonClick('Elite')}>
                        <h2 className="text-center h3_style">{t("PLANS.ELITE")}</h2>
                        <hr></hr>
                        <ul>
                          {
                            PlanJSON.Plans.map(plans => {
                              return <li>{plans.Elite}</li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="planesallbottom">
                  <div className="row">
                    <div className="col-lg-8 col-sm-8">
                      <div className="planesinner">
                        <h5 className="h3_style fst-italic">{t("PLANS.SEENFEATURE")}
                          <span>{t("PLANS.SELECTPLAN")}</span></h5>

                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2">
                      <div className="planesinnerright">
                        <h6 className="text-center">{getPrice('Premium')} $ </h6>
                        <button type="button" disabled={true} class={`${planType === 'Premium' ? 'ctaonebutton' : 'ctatwobutton'}`} onClick={() => onPlanTypeButtonClick('Premium')}>{t("PLANS.PREMIUM")}</button>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-2">
                      <div className="planesinnerright">
                        <h6 className="text-center">{getPrice('Elite')} $ </h6>
                        <button type="button" class={`${planType === 'Elite' ? 'ctaonebutton' : 'ctatwobutton'}`} onClick={() => onPlanTypeButtonClick('Elite')}>{t("PLANS.ELITE")}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="planesallbottom">
                  <div className="row">
                    <div className="col-lg-8 col-sm-8">
                      <div className="planesinner">
                        <h5 className="h3_style fst-italic">{t("PLANS.PAYEXPERT")}
                          <span>{t("PLANS.ADDITIONALFEATURE")}</span></h5>

                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                      <div className="planesinnerright">
                        <div className="form-check text-center">
                          <label className="container-checkbox" for="premium">
                            <input type="checkbox" id="premium" checked={isInstitutionExpet} onClick={() => onInstitutionExpertClick(!isInstitutionExpet)} />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="planesallbottom">
                  <div className="row">
                    <div className="col-lg-8 col-sm-8">
                      <div className="planesinner">

                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                      <div className="planesinnerright text-center">
                        <div className="mb-4 form-check">
                          <input type="checkbox" className="form-check-input" id="termscondition" checked={termAndCondition} onClick={() => onTermsAndConditionClick(!termAndCondition)} />
                          <label className="form-check-label" for="termscondition">terms <a href="/termsCondition" target="_blank"  rel="noreferrer" >{t("PLANS.TERMS")}</a></label>
                        </div>
                        <button type="button" disabled={!termAndCondition} className={`${termAndCondition ? 'ctaonebutton' : 'btn-secondary btn ctatwobutton'}`} onClick={() => onNextButtonClick()}>{t("PLANS.NEXT")}</button>
                      </div>
                    </div>

                  </div>
                </div>
                 </div>
                </div>
                </div>            
      </div>
      </div>
      </section>
    </>

  );
};

export default ChangePlaneJsx;