import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL, BLOG_URL_TOKEN, SERVER_IP,BLOG_URL } from '../../../constants/applicationConstants.js';
import { emailRegex, nameRegex } from '../../../shared/helper.js';
import { Loader } from '../../common/loader/loader.jsx';
import HomeJsx from './home.jsx'

export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      blogData: null,
      isProcessing: false,
      topOffers:null,
      page: 1,
      blogType: 'Featured',
      quoteData: {
        type: null,
        name: null,
        email: null,
        phone: null,
        postalCode: null
      },
      formError: {},
      expertData: null,
      productData: null,
      offerData: null
    }
  }

  componentDidMount = () => {
    this.getBlogData();
    this.getExpertData();
    this.getProductData();
    this.getTopOffers();

    this.getIPData();
    this.getOfferData();
  }

  getExpertData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/experts/homeFeatured`).then(res => {
      this.setState({ expertData: res.data.data, isProcessing: false })
    })
  }



  getProductData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/homeFeaturedProducts`).then(res => {
      this.setState({ productData: res.data.data, isProcessing: false })
    })
  }

  getOfferData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/homeFeaturedOffers`).then(res => {
      this.setState({ offerData: res.data.data, isProcessing: false })
    })
  }
  // top offers
  getTopOffers = () =>{
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/homeFeaturedOffers`).then(res=>{
      this.setState({topOffers:res.data.data,isProcessing: false})
    })
  }

  getBlogData = () => {
    var data = new FormData();
    data.append('limit', '9');
    data.append('device_token', '20.204.66.244');
    //   data.append('expertid', 'admin');
    data.append('paged', this.state.page)
    data.append('type', this.getType[this.state.blogType])
    this.setState({ isProcessing: true })

    axios.post(`${BLOG_URL}`, data,
      {
        headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
      }).then(res => {
        this.setState({ blogData: res.data, isProcessing: false });
      }).catch(err=>{
        this.setState({isProcessing: false})
      })
  }

  // get system ip

  getIPData = () => {
    axios.get(`https://api64.ipify.org?format=json`).then(response => {
      // const serverArray = SERVER_IP.split(",")
      // serverArray.map(value => {
      // })

      const arrIP = SERVER_IP.split(","); //convert string to array to check

      if (arrIP.includes(response?.data?.ip)) //Current IP of user
      {
          // write your logic
      }
      else {
        // write your logic
      }


    })
  }

  getType = {
    Featured: 'main_news',
    Trending: "trending",
    Latest: "featured",
    Glossary: "glossory"
  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            quoteData: {
              ...this.state.quoteData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            quoteData: {
              ...this.state.quoteData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          quoteData: {
            ...this.state.quoteData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };

  isFromSubmissionBlock = () => {
    let { name, email, phone, phoneNumber, message } = this.state.quoteData;

    if (name && email && phone && phoneNumber && message) {
      if (
        this.inputRegexValidation('name', name) &&
        this.inputRegexValidation('email', email) &&
        this.inputRegexValidation('phone', phone) &&
        phoneNumber && message
      ) {
        this.setState({
          formSubmitButtonDisable: false
        });
      }
      else {
        this.setState({
          formSubmitButtonDisable: true
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true
      });
    }
  };

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {
    if (name === 'name')
      return nameRegex.test(value);
    else if (name === "email")
      return emailRegex.test(value);
    else if (name === "phone")
      return !this.getProperPhoneNumber(value).includes('_');

    return true;
  };

  getProperPhoneNumber = (value) => {

    if (!isNaN(value)) {
      return value
    }

    const number = value.split(' ');
    return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
  }

  onSelectBlogType = (title) => {
    this.setState({ blogType: title }, () => this.getBlogData());
  }

  onButtonClick = () => {
  }

  onViewProfieClick = (url) => {
    this.props.history.push(`/expertPpv/${url}`)
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <HomeJsx
          blogData={this.state.blogData}
          topOffers={this.state.topOffers}
          onSelectBlogType={this.onSelectBlogType}
          quoteData={this.state.quoteData}
          onTouchHandler={this.onTouchHandler}
          onInputChangeHandler={this.onInputChangeHandler}
          formError={this.state.formError}
          onButtonClick={this.onButtonClick}
          expertData={this.state.expertData}
          onViewProfieClick={this.onViewProfieClick}
          productData={this.state.productData}
          offerData={this.state.offerData}
        />
        {this.state.isProcessing && <Loader />}
      </div>
    )
  }
}