import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MyWorkspaceJsx from "./myWorkspace.jsx";

export class MyWorkspaceJsxclass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'myAds'
    }
  }

  componentDidMount = () => {
    const searchParams = new URLSearchParams(this.props.location.search);    
    if (searchParams.has('page')) {
       this.setState({ activeTab: searchParams.get('page') || 'myAds' });
    }
  }

  onTabClick = (tab) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', tab);
    this.props.history.replace({
      pathname: '/myWorkspace',
      search: searchParams.toString()
    });
    this.setState({ activeTab: tab })
  }

  render() {
    return (
      <div>
        <MyWorkspaceJsx
          activeTab={this.state.activeTab}
          onTabClick={this.onTabClick}
        />
      </div>
    );
  }
}

export default withRouter(MyWorkspaceJsxclass);
