import React from 'react'
import leftarrow from "../../../../assets/images/left-arrow.svg";
import { Link } from "react-router-dom";
import { uploadURL,NEW_URL } from '../../../../constants/applicationConstants';
import { Pagination } from '../../../common/pagination/pagination';
import { useHistory,useLocation } from "react-router-dom";
import { Footer } from '../../../common/footer/footer'

const ListOfOffersJsx = (props) => {
  const history = useHistory();
  const location = useLocation()
  const {state:locationState} = location
  

  const {
    offerData,
    onCategoryChange,
    onOfferNameChange,
    onSearchButtonClick,
    onPaginationButtonClick,
    categoryData
  } = props;


  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="listofoffermainsection">
        <div className="container">
          <div className="leftsightbackdetails listofofferleft padtop60">
            <div className="back_btn" onClick={() => locationState?.frompage ? history.push(`${locationState?.pagePath ?? '/'}#${locationState?.frompage}`) : history.goBack()}><img src={leftarrow} alt={' '} /></div>
            <div className="plaindetailsleft">
              <h1 className="h2_style font-weight-bold">List of Offers </h1>
              <h5 className="h5_style">Like you, we also care for your money! </h5>
              <h5 className="h5_style font-weight-bold">Now enjoy the best financial products at the lowest rates with our jaw-dropping offers. </h5>
              <h5 className="h5_style">Don’t miss out the opportunity! Explore our top offers below.
</h5>


            </div>
          </div>
          <div className="myexpertformarea martop40">
            <div className="input-container form-group">
              <select className="input form-control form-select" placeholder=" " name='category' onChange={onCategoryChange} >
                <option value="">All</option>
                {
                  categoryData?.map(cat => {
                    return <option value={cat.institutionCategoryId}>{cat.name}</option>
                  })
                }
              </select>
              <div className="placeholder">Category</div>
            </div>
            <div className="input-container form-group">
              <input type="text" className="form-control input" placeholder=" " onChange={onOfferNameChange} />
              <div className="placeholder">Enter the product name you want to search for</div>
            </div>
            <div className="input-container form-group"><button className="ctaonebutton" onClick={() => onSearchButtonClick()}>Search</button></div>
          </div>
          <div className="listofad mt-5">
            <div className="row">
              {
                offerData?.data?.map(offer => {
                  return <div className="col-lg-6 col-sm-6">
                    <div className="listofadinner mb-4 mb-5">
                      <div className="listofimage">
                        <img src={`${NEW_URL}/${offer?.imageUrl}`} alt={' '} />
                      </div>
                      <div className="listofadinnerpop">
                        <div className="listofadtext">
                          <h3>{offer.offerName}</h3>
                        </div>
                        <div className="listofadbutton">
                          <Link
                            to={{
                              pathname: "/detailedOffer",
                              state: { data: offer }
                            }}
                            className="ctatwobutton ctanewtwobutton width100"
                            onClick={() => window.scrollTo(0, 0)}>
                            Know More
                            </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>

            {offerData?.data?.length === 0 && <div>No Record Found</div>}

            {offerData?.data?.length > 0 && <Pagination
              limit={10}
              totalCount={offerData?.meta?.totalCount}
              onPaginationButtonClick={onPaginationButtonClick}
            />}
          </div>
        </div>
      </section>
      <Footer />
    </>

  );
};

export default ListOfOffersJsx;