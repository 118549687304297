import React from 'react'

import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import datecalender from "../../../../assets/images/datecalender.svg";
import fileuploadpopup from "../../../../assets/images/fileuploadpopup.svg";
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { formatInputValue } from '../../../../shared/helper';
import { TagsInput } from "react-tag-input-component";
import dropbox from "../../../../assets/images/dropbox.svg";
import { NEW_URL } from '../../../../constants/applicationConstants';import { useTranslation } from "react-i18next";

const MyOfferJsx = (props) => {
  const {
    blogFormData,
    formError,
    onDateChange,
    onInputChangeHandler,
    showAddModal,
    onModalClose,
    onSubmitButtonclick,
    onTouchHandler,
    formSubmitButtonDisable,
    categoryData,
    subCategoryData,
    onMetaTagChange,
    onEditButtonClick,
    onDeleteButtonClick,
    adData,
    onScrollerChange,
    profileImagePreviewUrl,
    imgURL,
    editMode,
    onFileChange
  } = props;

  const { t } = useTranslation();
  const limitData = JSON.parse(sessionStorage.getItem('loginData'));

  return (
    <>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="">
              <div className="dashboardrightpanel martop40">
                <section classNames="addexpertformsec">
                  <div className="addexpert martop40">
                    <div className="addexpertleft">{t("MYOFFER.TITLE")}
                      <div className="userguide d-block">


                        <div class="dropdown-toggle userdropdown-toggle text-decoration-underline" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false"> <img src={planpricingtooltip}  alt={''} /> {t("MYOFFER.GUIDE")}  </div>
                        <ul className="dropdown-menu userdropdown-menu" aria-labelledby="dropdownMenu2">
                          <li className="dropdown-item">{t("MYOFFER.How to create a new offer?")}
                            <ul className="dropdown-menu dropdown-submenu">
                              <li className="dropdown-item">{t("MYOFFER.UPLOADADIMAGE")} </li>
                              <li className="dropdown-item">{t("MYOFFER.ENTERADCAPTION")}</li>
                              <li className="dropdown-item">{t("MYOFFER.EXPIREDATE")} </li>
                              <li className="dropdown-item">{t("MYOFFER.NEVEREXPIRE")} </li>
                              <li className="dropdown-item">{t("MYOFFER.CLICKSUBMIT")} </li>
                              <li className="dropdown-item">{t("MYOFFER.DELETEIT")} </li>


                            </ul>
                          </li>
                          <li><a className="dropdown-item" type="button">{t("MYOFFER.EDITAD")} </a>
                            <ul className="dropdown-menu dropdown-submenu">
                              <li className="dropdown-item">{t("MYOFFER.CLICKEDIT")}  </li>
                              <li className="dropdown-item">{t("MYOFFER.POPUPEDITFIELD")}   </li>
                              <li className="dropdown-item">{t("MYOFFER.POPUPINFO")}   </li>
                            </ul>
                          </li>
                          <li><a className="dropdown-item" type="button">{t("MYOFFER.DELETEEXISTINGAD")}  </a>
                            <ul className="dropdown-menu dropdown-submenu">
                              <li><a className="dropdown-item" >{t("MYOFFER.DELETEOPTION")}  </a></li>


                            </ul>
                          </li>
                          <li><a className="dropdown-item" type="button">{t("MYOFFER.ADDMOEAD")} </a>
                            <ul className="dropdown-menu dropdown-submenu">
                              <li><a className="dropdown-item" >{t("MYOFFER.ADLIMIT")} </a></li>

                            </ul>
                          </li>
                          <li><a className="dropdown-item" type="button">{t("MYOFFER.ADSTATUS")}</a>
                            <ul className="dropdown-menu dropdown-submenu">
                              <li className="dropdown-item">{t("MYOFFER.SWITCH")}</li>
                              <li className="dropdown-item">{t("MYOFFER.ACTIVETOGGLE")}</li>
                              <li className="dropdown-item">{t("MYOFFER.INACTIVETOGGLE")}</li>


                            </ul>
                          </li>
                        </ul>
                      </div>

                    </div>
                    <div className="addexpertright">
                      <button type="button" disabled={adData?.length === limitData?.totalOffersLimit} className="ctaonebutton" onClick={() => onModalClose(true)}>{t("MYOFFER.CREATEOFFER")}</button>
                      {adData?.length === limitData?.totalOffersLimit && <p className="text-danger">{t("MYOFFER.MAXLIMIT")}</p>}
                    </div>
                  </div>
                  <div style={{marginTop:'20px'}}>

                  <section classNames="addexpertformsec">
                    <div className="expertformmain">

                      {showAddModal && <div className="expertinnerformmain">
                        <div className="expertformmainheader">
                          <div className="expertformmainheaderleft">
                            <h3>{t("MYOFFER.CREATEOFFER")} </h3>
                          </div>


                          <div className="expertformmainheaderright">
                            <button type="button" className="myadbtn" onClick={() => onModalClose(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-sm-6">
                            <div className="uploadexpert mb-4">
                              <div className="button_outerlogo">
                                <div className="btn_uploadlogo"><input type="file" id="upload_filelogo" name="" onChange={onFileChange} />
                                  <label for="upload_filelogo">

                                    {
                                      profileImagePreviewUrl ?
                                        <img
                                          src={profileImagePreviewUrl}
                                          height="170px"
                                          width="250px"
                                          alt="edit profile"
                                        /> :
                                        editMode ?
                                          <img
                                            src={`${NEW_URL}/${imgURL}`}
                                            height="170px"
                                            width="250px"
                                            alt="edit profile"
                                          />
                                          : <img src={fileuploadpopup}  alt={''} />
                                    }
                                  </label>
                                </div>
                              </div>
                              {!(editMode || profileImagePreviewUrl) ? <><h5 className="text-center h5_style"><b>{t("MYOFFER.UPLOADLOGO")}</b></h5>
                                <p className="text-center">{t("MYOFFER.CLICKADD")}</p></>
                                :
                                <div className="button_outer">
                                  <div className="btn_upload">
                                    <input type="file" id="upload_file" name="" onChange={onFileChange} />
                                    <label for="upload_file">{"Change Icon"}</label>
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="disgrid grid-2">
                              <DatePicker3 className="form-control" name="startDate"
                                onChange={(date) => { onDateChange('validFrom', date) }}
                                inputPlaceholder="dd/mm/yyyy"
                                shouldHighlightWeekends
                                wrapperClassName="input-container form-group"
                                maximumDate={{
                                  year: blogFormData?.validTill?.year,
                                  month: blogFormData?.validTill?.month,
                                  day: blogFormData?.validTill?.day
                                }}
                                renderInput={({ ref }) => (
                                  <>
                                    <input
                                      ref={ref}
                                      placeholder=" "
                                      type="text"
                                      required
                                      className="input form-control"
                                      value={formatInputValue(blogFormData?.validFrom)}
                                    />
                                    <div className="cut"></div>
                                    <label className="placeholder">{'Valid From*'}</label>
                                    <span className="dateim"><img src={datecalender}  alt={''}/></span>
                                  </>
                                )}
                                formatInputText={() => formatInputValue(blogFormData?.validFrom)}
                              />
                              <DatePicker3 className="form-control" name="startDate"
                                onChange={(date) => { onDateChange('validTill', date) }}
                                inputPlaceholder="dd/mm/yyyy"
                                shouldHighlightWeekends
                                wrapperClassName="input-container form-group"
                                minimumDate={{
                                  year: blogFormData?.validFrom?.year,
                                  month: blogFormData?.validFrom?.month,
                                  day: blogFormData?.validFrom?.day
                                }}
                                renderInput={({ ref }) => (
                                  <>
                                    <input
                                      ref={ref}
                                      placeholder=" "
                                      type="text"
                                      required
                                      className="input form-control"
                                      value={formatInputValue(blogFormData?.validTill)}
                                    />
                                    <div className="cut"></div>
                                    <label className="placeholder">{'Valid Till*'}</label>
                                    <span className="dateim"><img src={datecalender} alt={''} /></span>
                                  </>
                                )}
                                formatInputText={() => formatInputValue(blogFormData?.validTill)}
                              />
                            </div>
                            <div className="disgrid grid-2">
                              <div className="mb-4 input-container form-group">
                                <select className="input form-control form-select" onChange={onInputChangeHandler} name="category">
                                  <option value="">{t("MYOFFER.SELECTCATEGORY")}</option>
                                  {categoryData.map((item) => {
                                    return (
                                      <>
                                        <option value={item.institutionCategoryId} selected={item.institutionCategoryId == blogFormData.category}>{item.name}</option>
                                      </>
                                    )
                                  })}
                                </select>
                                <label className="placeholder">{t("MYOFFER.CATEGORY")}</label>
                              </div>
                              <div className="mb-4 input-container form-group">
                                <select className="input form-control form-select" onChange={onInputChangeHandler} name="relatedProduct">
                                  <option value="" >{t("MYOFFER.SELECT")}</option>
                                  {subCategoryData?.map((item) => {
                                    return (
                                      <>
                                        <option value={item.institutionCategoryId} selected={item.institutionCategoryId == blogFormData.relatedProduct}>{item.name}</option>
                                      </>
                                    )
                                  })}
                                </select>
                                <label className="placeholder">{t("MYOFFER.RELATEDPROD")}</label>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-6 col-sm-6">

                            <div className="mb-4 input-container form-group">
                              <input
                                onBlur={onTouchHandler}
                                className={'input form-control '}
                                placeholder=" "
                                value={blogFormData.product}
                                name="product"
                                onChange={onInputChangeHandler} 
                                maxLength={50} />
                              <label className="placeholder">{t("MYOFFER.PRODNAME")}</label>
                              <span className="text-danger">
                                {formError['product'] &&
                                  (formError['product'] === "req"
                                    ? 'Offer Name is Required'
                                    : '')}
                              </span>
                            </div>

                            <div className="mb-4 input-container form-group">
                              <textarea
                                onBlur={onTouchHandler}
                                className={"input form-control textareaform"}
                                placeholder=" "
                                value={blogFormData.caption}
                                name="caption"
                                onChange={onInputChangeHandler}
                                maxLength={150}></textarea>
                              <label className="placeholder">{t("MYOFFER.DESCRIPTION")}</label>
                              <span className="text-danger">
                                {formError['caption'] &&
                                  (formError['caption'] === "req"
                                    ? 'Caption is required'
                                    : '')}
                              </span>
                            </div>

                            <div className="mb-4 input-container form-group specialtag">
                                                    <div className="normal" id="tagInput" titleLbl={'Offer Meta Tag'}
                                                    onFocus={()=>{
                                                        let elem = document.getElementById("tagInput"); 
                                                        elem.classList.add("my-classfocus"); 
                                                    }}          
                                                    onBlur={()=>{
                                                        let elem = document.getElementById("tagInput"); 
                                                        elem.classList.remove("my-classfocus"); 
                                                    }}  
                                                    
                                                    >

                                                    <TagsInput
                                                        value={blogFormData.metaTag}
                                                        onChange={onMetaTagChange}
                                                        label="xxxxxxxxx"
                                                        name="metaTags"
                                                        placeHolder=""
                                                        classNames={{tag: 'tag-clsxxxx', input: 'input form-control'}}                                              
                                                    />
                                                    </div>
                                                    <label className="placeholder">{t("MYOFFER.METATAG")}<span className="plantooltip custooltip" ><img src={planpricingtooltip}  alt={''} className="tooltipim" />
                                <div class="tooltipcontent"> {t("MYOFFER.METATAGTOOLTIP")}</div>
                              </span></label>
                                                    
                                                </div>

                                                <div className="mb-4 input-container form-group">
                              <input
                                onBlur={onTouchHandler}
                                className={'input form-control '}
                                placeholder=" "
                                value={blogFormData.url}
                                name="url"
                                onChange={onInputChangeHandler} 
                                maxLength={100} />
                              <label className="placeholder">{'External Link'}</label>
                              <span className="text-danger">
                                {formError['url'] &&
                                  (formError['url'] === "req"
                                    ? 'URL is Required'
                                    : '')}
                              </span>
                            </div>

                            <div className="mb-4 input-container form-group">
                              <button type="button" disabled={formSubmitButtonDisable} className="ctaonebutton" onClick={() => onSubmitButtonclick()}>{t("MYOFFER.SUBMIT")}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                  </section>
                  </div>

                  <div class="myadssection">
                    <h2 class="h2_style martop30">{t("MYOFFER.MYAD")}</h2>
                    <p>{t("MYOFFER.VIIBLEAD")}</p>
                    <p>{t("MYOFFER.BELOWAD")} {limitData?.totalOffersLimit} {t("MYOFFER.BELOWAD1")}  </p>
                  </div>


                  <div className="myadpost martop20">
                    <div className="row">
                      {
                        adData?.length > 0 ?
                          adData?.map(data => {
                            return <div className="col-lg-4 col-sm-4">
                              <div className="myadpostinner">
                                <div className="myadpostinnerimage">
                                  <img src={`${NEW_URL}/${data.imageUrl}`}  alt={''} />
                                </div>
                                <div className="myadpostbanner">
                                  <label className="switch">
                                    <input type="checkbox" checked={data.isActive} onChange={() => onScrollerChange(data)} />
                                    <span className="slider round"></span>
                                  </label>
                                  <div className="mybtn">
                                    <button type="button" onClick={() => onEditButtonClick(data)} className="myadbtn"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>
                                    <button type="button" onClick={() => onDeleteButtonClick(data)} className="myadbtn"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg>
</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }) :
                          <div className="dropdefault text-center mb-5">
                      <div className="dropdefaultimage">
                        <img src={dropbox}  alt={''} />
                      </div>
                      <div className="dropdefaulttext">
                        <h5>{t("MYOFFER.LIVEAD")}   </h5>
                        <h5>{t("MYOFFER.CREATENEWAD")} </h5>
                        </div>
                       
                        </div>
                      }
                    </div>

                  </div>
                </section>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default MyOfferJsx;
