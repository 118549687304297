import Carousel from 'react-bootstrap/Carousel';
import { NEW_URL } from '../../../constants/applicationConstants';

function UncontrolledExample(props) {
  const { offerData } = props;
  return (
    <Carousel>
      {
        offerData?.map(offer => {
          return <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${NEW_URL}/${offer.imageUrl}`}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>{offer.description}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        })
      }
    </Carousel>
  );
}

export default UncontrolledExample;