import React from 'react'
import { Component } from 'react';
import PurchaseJsx from './purchase.jsx'

export default class Purchase extends Component{
    render(){
        return(
            <div>
                <PurchaseJsx />
            </div>
        )
    }
}