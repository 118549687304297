import React from 'react'
import { NEW_URL } from '../../../../constants/applicationConstants';
import dropbox from "../../../../assets/images/dropbox.svg";

const CustomerQnaAnswerJsx = (props) => {
  const {
    data,
  } = props;

  return (
    <>
      <section class="alldashboard">

        <div className="dashboardrightpanel martop40 mb-5">
          <div className="questioninner mt-4">
            {
              data.length ? data.list?.map(list => {
                return <div className="questioninnerin mb-3">
                  <h5 className="h5_style">{list.title}</h5>

                  <div className="d-flex questioninnerinbottom">
                    <div className="expertquestiondetails">
                      <div className="expertuser"><img src={!data?.imageUr ? `https://ui-avatars.com/api/?name=${data?.firstName}+${data?.lastName}&rounded=true` : `${NEW_URL}/${data?.imageUr}`} alt={''} /></div>
                      <div className="questionid"><span>Question ID -</span>{list.questionID}<div className="questiondate">{list.date}</div></div>

                    </div>
                    <div className="expertquestiondetailscategory"><span>Banking</span></div>
                  </div>
                  <p>{list.subTitle}<a href="">Read More</a></p>
                </div>
              }) :

                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={''} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5>You don't have any answered questions yet. </h5>
                  </div>
                </div>
            }
          </div>
        </div>

      </section>

    </>

  );
};

export default CustomerQnaAnswerJsx;