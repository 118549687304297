import axios from "axios";
import React, { Component } from "react";
import { baseURL } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";
import ExpertDashboardHomeJsx from './expertDashboardHome.jsx'
import { emailRegex, nameRegex } from '../../../../shared/helper.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default class ExpertDashboardHome extends Component{
    constructor(props) {
        super(props);
        this.state = {
            userData: {
                answer: ''
            },
            questionData: null,
            answerData: null,
            isLoading: false,
            isOpenModal : false,
            formError: {},

        }
    }

    componentDidMount = () => {
        this.getQuestionData();
        this.getAnsweredData();
    }
    
    openModal = (action) => {
        this.setState({ isOpenModal: action })
    }

    openModal = (action) => {
        this.setState({ isOpenModal: action })
    }
    giveAnswer = (index, action) => {
        let tempArr = [...this.state.questionData];
        tempArr[index] = { ...tempArr[index], isOpen: action }
        this.setState({ questionData: tempArr })
    }
    sendAnswer = (value,id) => {
        const userInput = document.getElementsByName(`answer${value}`)[0].value;  // Use this as value of textare
        this.setState({
            isLoading: true
        })


        const payloadData = {
            answer: userInput
        }

        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        let expertId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))

            axios.put(`${baseURL}/allocateAskExpertQuestionResponse/${id}`, payloadData, {
                headers: { 'x-access-token': token }
            }).then(res => {
                if (res.data.success) {
                    toast.success("Data Updated Successfully!")
                    this.setState({
                        isLoading: false
                    })
                    window.location.reload()
                    // this.getQuestionData()
                    // window.location.reload()
                }
                else {
                    toast.error(res.data.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err.response.data.error.message)
                this.setState({
                    isLoading: false
                })
            })
        
    }


    getQuestionData = () => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        let expertId = JSON.parse(sessionStorage.getItem("masterID"));
        this.setState({ isLoading: true })

        axios.get(`${baseURL}/expert/${expertId}/assignedQuestions?status=not-answered&isPriorityQuestions=true`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ questionData: res.data.data, isLoading: false })
        }).catch(err=>{
            this.setState({ isLoading: false })
          })
    }
    getAnsweredData = () => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        let expertId = JSON.parse(sessionStorage.getItem("masterID"));
        this.setState({ isLoading: true })

        axios.get(`${baseURL}/expert/${expertId}/assignedQuestions?status=answered`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ answerData: res.data.data, isLoading: false })
        }).catch(err=>{
            this.setState({ isLoading: false })
          })
    }
    inputRegexValidation = (name, value) => {
        if (name === 'email')
            return emailRegex.test(value);
        else if (name === 'firstName' || name === 'lastName')
            return nameRegex.test(value);


        return false;
    };

    onInputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        userData: {
                            ...this.state.userData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },

                );
            } else {
                this.setState(
                    {
                        userData: {
                            ...this.state.userData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },

                );
            }
        } else {
            this.setState(
                {
                    userData: {
                        ...this.state.userData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },

            );
        }
    };
    render(){
        return(
            <div>
                 <ExpertDashboardHomeJsx 
                questionData={this.state.questionData}
                answerData={this.state.answerData}
                    sendAnswer={this.sendAnswer}
                    onInputChangeHandler={this.onInputChangeHandler}
                    giveAnswer={this.giveAnswer}
                    onServiceSave={this.onServiceSave}
                    userData={this.state.userData}
                    openModal={this.openModal}
                    isOpenModal={this.state.isOpenModal}
                 />
                  {this.state.isLoading && <Loader />}
                <ToastContainer />
            </div>
        )
    }
}
