import React from 'react'
import { Component } from 'react';
import ExpertDashboardHomeJsx from './expertDashboardHome.jsx'

export default class ExpertDashboardHome extends Component {

    componentDidMount = () => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        if (!token) {
            this.props.history.push("/admin");
        }
    }
    onAddInstituteClick = () => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        sessionStorage.setItem("adminOldToken", token);
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("instituteId");
        localStorage.setItem('AdminFlow', 'institute')
        this.props.history.push('/institutionalRegistration')
        localStorage.removeItem("VerifyInstituteID")


    }

    onAddExpertClick = () => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        sessionStorage.setItem("adminOldToken", token);
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("instituteId");
        localStorage.setItem('AdminFlow', 'expert')
        this.props.history.push('/expertRegistration')
        localStorage.removeItem("VerifyExpertID")

    }


    render() {
        return (
            <div>
                <ExpertDashboardHomeJsx onAddInstituteClick={this.onAddInstituteClick}
                    onAddExpertClick={this.onAddExpertClick} />
            </div>
        )
    }
}