import React from 'react'
import { useEffect } from 'react';
import { Footer } from '../../../common/footer/footer';
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';

const SearchMainJsx = (props) => {
  const { t } = useTranslation();
  const {
    onSearchButtonClick,
    onDropDownChange
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="searchpageherosection backwhite">
        <div className="container">
          <div className="searchmaininnerhero heroleftpanel">
            <h2 className="text-center">{t("SEARCHMAIN.SEARCHTITLE")}</h2>
            <h4 className="h4_style text-center"><i>{t("SEARCHMAIN.SEARCHSUBTITLE")}</i><br />{t("SEARCHMAIN.SEARCHSUBTITLE1")}<br />{t("SEARCHMAIN.SEARCHSUBTITLE2")}</h4>
            <h2 className="h2_style text-center mt-4">{t("SEARCHMAIN.SEARCHSUBSUBTITLE")} </h2>
          </div>
          <div className="myexpertformarea searcharea searchmainara martop40">
            <div className="input-container form-group">
              <select className="input form-control form-select" placeholder=" " name="category" onChange={(e) => onDropDownChange(e)}>

                <option value="searchExpert">Expert</option>
                <option value="searchInstitute">Institution</option>
              </select>
              <div className="placeholder">Select</div>
            </div>
            <div className="input-container form-group">
              <button type="submit" className="ctaonebutton" onClick={() => onSearchButtonClick()}>Search</button></div>

          </div>
        </div>
      </section>

      <Footer />
    </>

  );
};

export default SearchMainJsx;