import React from 'react'
import { useEffect } from 'react';
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const TermsConditionJsx = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])
 
  return (
    <>
      <section className="homecustom">
      <section className="termscondition">
        <div className="container">
          <div className="terminner mt-5 mb-5">
         <h2> TERMS AND CONDITIONS </h2>
  <div>
 
  <div>
    <p>Welcome to RIMIB ("we," "us," or "our")! These Terms and Conditions (the "Agreement") govern your use of our website and services. By using our website and services, you agree to be bound by this Agreement. If you do not agree to this Agreement, you may not use our website or services.&nbsp;</p>
  </div>
  <div>
    <h3>1.&nbsp;Liability&nbsp;</h3>
  </div>
  <div>
  <h4>1.1 Limitation of Liability&nbsp;</h4>
  </div>
  <div>
    <p>RIMIB will not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or services. This limitation of liability applies regardless of whether the&nbsp;damages are&nbsp;foreseeable or not and whether we have been advised of the possibility of such&nbsp;damages.&nbsp;</p>
  </div>
  <div>
  <h4>1.2 Circumstances of Liability&nbsp;</h4>
  </div>
  <div>
    <p>RIMIB will only be liable for any losses or damages incurred by you as a result of our gross negligence or willful misconduct.&nbsp;</p>
  </div>
  <div>
  <h3>2.&nbsp;Termination&nbsp;</h3>
  </div>
  <div>
  <h4>2.1 Termination Clause&nbsp;</h4>
  </div>
  <div>
    <p>RIMIB reserves the right to&nbsp;terminate&nbsp;your account or access to our website and services at any time, with or without cause, and with or without notice. We may&nbsp;terminate&nbsp;this Agreement&nbsp;immediately&nbsp;if you breach any provision of this Agreement. Upon termination, you must&nbsp;immediately&nbsp;stop using our website and services.&nbsp;</p>
  </div>
  <div>
    <h4>2.2 Notice Period&nbsp;</h4>
  </div>
  <div>
    <p>If RIMIB terminates your account or access to our website and services without cause, we will provide you with a notice period of&nbsp;30 days.&nbsp;</p>
  </div>
  <div>
    <h3>3.&nbsp;Dispute Resolution&nbsp;</h3>
  </div>
  <div>
    <h4>3.1 Dispute Resolution Clauses&nbsp;</h4>
  </div>
  <div>
    <p>Any disputes arising out of or in connection with this Agreement shall be resolved through arbitration. The arbitration shall be conducted&nbsp;in accordance with&nbsp;the Canadian Arbitration Association's rules and procedures. The arbitrator's decision shall be final and binding on both parties.&nbsp;</p>
  </div>
  <div>
    <p>Dispute Resolution Clause In the event of any disputes arising between RIMIB and you&nbsp;regarding&nbsp;the interpretation, implementation, or performance of this Agreement, the parties shall&nbsp;endeavor&nbsp;to settle the dispute amicably through mutual consultation.&nbsp;</p>
  </div>
  <div>
    <p>Mediation If the parties are unable to reach an amicable settlement within&nbsp;30 days&nbsp;of the dispute arising, the parties may agree to mediate the dispute. The mediator shall be chosen by mutual agreement of the parties. The costs of mediation shall be borne equally by the parties.&nbsp;</p>
  </div>
  <div>
    <p>Arbitration If the parties are unable to settle the dispute through mediation or any other amicable means, the dispute shall be finally resolved by arbitration&nbsp;in accordance with&nbsp;the Canadian Arbitration Association's rules and procedures. The arbitration shall take place in the Province of Canada. The&nbsp;decision of the arbitrator shall be final and binding on both parties. Each party shall bear its own costs of the arbitration.&nbsp;</p>
  </div>
  <div>
    <p>Injunctive Relief Notwithstanding the above provisions, RIMIB shall be entitled to seek injunctive relief,&nbsp;security&nbsp;or other&nbsp;equitable&nbsp;remedies from any court of competent&nbsp;jurisdiction.&nbsp;</p>
  </div>
  <div>
    <h3>4.&nbsp;Limitation of Liability&nbsp;</h3>
  </div>
  <div>
    <h4>4.1 Limitation of Liability Clause&nbsp;</h4>
  </div>
  <div>
    <p>RIMIB's liability for any damages or losses that may result from your use of our website or services shall be limited to the amount paid by you to us for the use of our website or services.&nbsp;</p>
  </div>
  <div>
    <h3>5.&nbsp;Disclaimer of Warranties&nbsp;</h3>
  </div>
  <div>
    <h4>5.1 Disclaimer of Warranties Clause&nbsp;</h4>
  </div>
  <div>
    <p>Our website and services are provided "as is" and without any warranties, express or implied. RIMIB makes no representations or warranties of any kind, whether express or implied,&nbsp;regarding&nbsp;the operation of our website or services, or the information, content, materials, or products included on our website or services.&nbsp;</p>
  </div>
  <div>
    <h3>6.&nbsp;Intellectual Property Rights&nbsp;</h3>
  </div>
  <div>
    <h4>6.1 Intellectual Property Rights Clause&nbsp;</h4>
  </div>
  <div>
    <p>All content on our website, including but not limited to text, graphics, logos, images, and software, is owned by&nbsp;RIMIB&nbsp;and is protected by Canadian and international copyright, trademark, and other intellectual property laws. You may not use our content without our prior written permission.&nbsp;</p>
  </div>
  <div>
    <h3>7.&nbsp;Governing Law and Jurisdiction&nbsp;</h3>
  </div>
  <div>
    <h4>7.1 Governing Law and Jurisdiction Clause&nbsp;</h4>
  </div>
  <div>
    <p>This Agreement shall be governed by and construed&nbsp;in accordance with&nbsp;the laws of the Province of Canada. Any disputes arising out of or in connection with this Agreement shall be resolved in the courts of the Province of Canada.&nbsp;</p>
  </div>
  <div>
    <h3>8.&nbsp;Privacy Policy&nbsp;</h3>
  </div>
  <div>
    <h4>8.1 Privacy Policy Clause&nbsp;</h4>
  </div>
  <div>
    <p>RIMIB is committed to protecting your privacy. Our privacy policy governs the collection, use, and disclosure of your personal information. By using our website and services, you agree to our privacy policy.&nbsp;</p>
  </div>
  <div>
    <h3>9.&nbsp;User Obligations&nbsp;</h3>
  </div>
  <div>
    <h4>9.1 User Obligations Clause&nbsp;</h4>
  </div>
  <div>
    <p>You&nbsp;are responsible for&nbsp;complying with&nbsp;all applicable laws and regulations when using our website and services. You agree not to use our website or services for any illegal or unauthorized purpose.&nbsp;</p>
  </div>
  <div>
    <h3>Indemnification&nbsp;</h3>
  </div>
  <div>
    <h4>11.1 Indemnification Clause&nbsp;</h4>
  </div>
  <div>
    <p>You agree to indemnify, defend and hold harmless RIMIB, its officers, directors, employees, agents, licensors, suppliers, and any third-party information providers to our website or services from and&nbsp;against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of this Agreement, or any activity related to your use of our website or services, including negligent or wrongful conduct.&nbsp;</p>
  </div>
  <div>
    <h3>Modifications&nbsp;</h3>
  </div>
  <div>
    <h4>12.1 Modification Clause&nbsp;&nbsp;</h4>
  </div>
  <div>
    <p>We reserve the right to&nbsp;modify&nbsp;this Agreement at any time, in our sole discretion. Any modifications to this Agreement will be effective&nbsp;immediately&nbsp;upon posting on our website.&nbsp;You are responsible for reviewing the Agreement from time to time to ensure that you are aware of any changes.&nbsp;</p>
  </div>
  <div>
    <h3>Entire Agreement&nbsp;</h3>
  </div>
  <div>
    <h4>13.1 Entire Agreement Clause&nbsp;&nbsp;</h4>
  </div>
  <div>
    <p>This Agreement, together with any&nbsp;additional&nbsp;terms and conditions that may apply to specific portions of our website or services, constitutes the entire agreement between you and RIMIB with respect to the use of our website and services.&nbsp;</p>
  </div>
  <div>
    <h4>13.2 Waiver of Terms&nbsp;&nbsp;</h4>
  </div>
  <div>
    <p>Any failure by RIMIB to enforce any provision of this Agreement shall not be&nbsp;deemed&nbsp;a waiver of such provision or of the right to enforce such provision.&nbsp;&nbsp;</p>
  </div>
  <div>
    <h4>13.3 Severability&nbsp;&nbsp;</h4>
  </div>
  <div>
    <p>If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent&nbsp;permitted&nbsp;by law.&nbsp;&nbsp;</p>
  </div>
  <div>
    <h4>13.4 Assignment&nbsp;&nbsp;</h4>
  </div>
  <div>
    <p>You may not assign or transfer this Agreement, or any rights or obligations hereunder, without the prior written consent of RIMIB. Any attempted assignment in violation of this provision shall be&nbsp;null and void.&nbsp;&nbsp;</p>
  </div>
  <div>
    <h4>13.5 Governing Law&nbsp;&nbsp;</h4>
  </div>
  <div>
    <p>This Agreement shall be governed by and construed&nbsp;in accordance with&nbsp;the laws of Canada, without giving effect to any principles of conflicts of law.&nbsp;&nbsp;</p>
  </div>
  <div>
    <h4>13.6&nbsp;Jurisdiction&nbsp;</h4>
  </div>
  <div>
    <p>Any action arising under or relating to this Agreement shall be brought in the courts of the Province of Canada, and each party hereby irrevocably&nbsp;submits&nbsp;to the&nbsp;jurisdiction&nbsp;of such courts.&nbsp;</p>
  </div>
  <div>
    <h4>13.7 Notices&nbsp;&nbsp;</h4>
  </div>
  <div>
    <p>All notices and other communications required or permitted hereunder shall be in writing and shall be deemed to have been duly given if delivered personally, or by overnight courier, or by certified mail, return receipt requested, postage prepaid, to the parties at the addresses set forth below or at such other address as either party may specify by written notice to the other party.&nbsp;&nbsp;</p>
  </div>
  <div>
    <p>This Agreement sets forth the entire understanding and agreement between you and RIMIB with respect to the subject matter hereof, and supersedes all prior or contemporaneous understandings or agreements, whether written or oral,&nbsp;regarding&nbsp;such subject matter.&nbsp;</p>
  </div>
  
</div>
          
          </div>
        </div>
      </section>
        <Footer />
      </section>
    </>

  );
};

export default TermsConditionJsx;
