import React from 'react'
import { Component } from 'react';
import CustomerQnaAnswerJsx from './customerQnaAnswer.jsx'
import axios from 'axios';
import { baseURL } from '../../../../constants/applicationConstants';

export default class CustomerQnaAnswer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isProcessing: false
        }
    }

    componentDidMount = () => {
        this.setState({ isProcessing: true })
        const customerId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
        const token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        axios.get(`${baseURL}/customer/${customerId}/QuestionsResponse?status=answered`,{
            headers: { 'x-access-token': token }
          }).then(res => {
            this.setState({ data: res.data.data, isProcessing: false });
        })
    }

    render() {
        return (
            <div>
                <CustomerQnaAnswerJsx
                    data={this.state.data}
                />
            </div>
        )
    }
}