import React from 'react'
import leftarrow from "../../../assets/images/left-arrow.svg";
import passleftgraphics from "../../../assets/images/passleftgraphics.svg";

const OtpAuthJsx = (props) => {
    return (
        <>
        <section className="loginscreeninstitute registrationinstitute passw">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-5">
                            <div className="leftsightbackdetails martop60">
                                <div className="back_btn" onClick={() => props.onBackClick()}><img src={leftarrow} alt={''} /></div>
                                <div className="detailsleft plaindetailsleft">
                                    <h1>OTP Authentication</h1>
                                    <div className="instituteleft-illustration martop60">
                                        <img src={passleftgraphics} alt={''} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-sm-7 right-institute">
                            <div className="institutelogintext passwordauthorization martop60 paddingbot60">
                            <div className="otpgeneration forgetpassinner">
                                <h2 className="h2_style">Enter OTP</h2>
                                <h5 className="h2_style color-light-blue">It’s time to check your registered email! We have sent you an OTP.</h5>
                                <h5 className="h4_style martop30 font-weight-normal">Please enter the received One Time Password (OTP) below</h5>
                                <div className="ft-inner otpauthentication">
                                    <div className="mb-2 mt-4 input-container form-group">
                                        <input type="password" className="input form-control" placeholder=" " value="" />
                                        <label className="placeholder">Enter OTP</label>
                                    </div>
                                    <div className="checkspam form-text">
                                        <a href="javascript:void(0)" className="text-left">(Don’t forget to check your spam/junk folder)</a>
                                    </div>
                                    <button type="submit" className="mt-4  mb-4 ctaonebutton"  onClick={() => props.handleSubmit()} disabled="">Submit</button>
                                    <div className="otptime">Time Remaining  30 seconds<a href="javascript:void(0)"> Resend OTP</a>
                                        

                                    </div>
                                    
                                    
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
        </>

    );
};

export default OtpAuthJsx;
