import React, { useEffect,useState } from 'react'
import dashboard from "../../../../assets/images/dashboard.svg";
import myblog from "../../../../assets/images/myblog.svg";
import myaccount from "../../../../assets/images/myaccount.svg";
import mypayment from "../../../../assets/images/mypayment.svg";
import logoutIcon from "../../../../assets/images/logout.svg";
import { Link } from "react-router-dom";
import { uploadURL,NEW_URL,baseURL } from '../../../../constants/applicationConstants';import swal from "sweetalert";
import QnA from "../../../../assets/images/Qna.svg";
import myad from "../../../../assets/images/myad.svg";
import User_Manual from "../../../../assets/images/User_Manual.svg"
import { useHistory } from "react-router-dom";
import axios from 'axios'

const SideBarsJsx = (props) => {
  const history = useHistory();
  const [state, setState] = useState()

  const {
    response
  } = props;

  useEffect(() => {
    callDetails() }, []);

  const callDetails = () => {
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let expertID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    axios.get(`${baseURL}/expert/${expertID}/details`, {
      headers: { 'x-access-token': token }
    }).then(res => {

      setState(res.data.data)
      if (!res.data?.data?.isPermanentPasswordSet) {
            history.push("/saveNewPassword");
            // window.location.reload()
    }

    }).catch(err=>{
    })
  }

  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("legalName"); 
        sessionStorage.removeItem("isLoggedIn");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  // {t("PRINT.TITLE")}
  return (
    <>
     <div className="dashboardleftpanel martop40">
                <div className="userdetails"> 
                  <div className="dashboardlogo"><img  src={`${NEW_URL}/${response?.imageUrl}`}  alt={''}></img></div>
                 <div className="responsename">{`${response?.firstName} ${response?.lastName}`} <Link to={`/expertPpv/${response?.slugUrl}`} target="_blank"  rel="noreferrer"  className="viewprofilebtn">View Profile</Link></div> 
                </div>
                <div className="dashboardlink martop40">
                  <ul>
                  <li className={`${window.location.href?.toLowerCase().includes('expertdashboardhome') ? 'active' : ""}`}><Link to={'/expertDashboardHome'}><img src={dashboard}  alt={''}/>Home</Link></li>
                    <li className={`${window.location.href?.toLowerCase().includes('expertqna') ? 'active' : ""}`}><Link to={'/expertQna'}><img src={QnA}  alt={''}/>QnA</Link></li>
                    <li className={`${window.location.href?.toLowerCase().includes('expertblog') ? 'active' : ""}`}><Link to={'/expertBlog'}><img src={myblog} alt={''} />My Blogs</Link></li>
                    <li className={`${window.location.href?.toLowerCase().includes('myads') ? 'active' : ""}`}><Link to={'/myAds'}><img src={myad} alt={''} />My Ads</Link></li>
                    <li className={`${window.location.href?.toLowerCase().includes('expertaccounts') ? 'active' : ""}`}><Link to={'/expertAccounts'}><img src={myaccount} alt={''} />My Account</Link></li>
                    <li className={`${window.location.href?.toLowerCase().includes('expertPayment') ? 'active' : ""}`}><Link to={'/expertPayment'}><img src={mypayment}  alt={''}/>My Payment</Link></li>
                    <li><a href={'/manual/RIMIB-User-Manual-Experts.pdf'} target="_blank"><img src={User_Manual}  alt={''}/>User Manual</a></li>
                    <li onClick={logout}><a  ><img src={logoutIcon} alt={''} />Logout</a></li>
                  </ul>
                </div>
              </div>
    </>

  );
};

export default SideBarsJsx;