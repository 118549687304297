import React from 'react'
import { Component } from 'react';
import TravelInsurenceJsx from './travelInsurence.jsx'

export default class TravelInsurence extends Component{
    render(){
        return(
            <div>
                <TravelInsurenceJsx />
            </div>
        )
    }
}