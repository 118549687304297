import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { ToastContainer,toast } from 'react-toastify';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { nameRegex, postalCodeRegex } from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';

import SearchInstituteJsx from './searchInstitute.jsx'

export default class SearchInstitute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            segment:null,
            formData: {
                name: '',
                postalCode: '',
                category: '',
                segment: ''
            },
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData:[],
            page: 1,
        }
    }

    componentDidMount = () => {
        this.setState({ isLoading: true })
        axios.get(`${baseURL}/instituteSegments`).then(res => {
                this.setState({segment:res.data.data,isLoading:false })
        })
    }

    onSegmentClick = (value) => {
        const subData = this.state.segment.filter(itm => itm.institutionSegmentId == value)
        this.setState({ categoryData: subData[0]?.institutionCategories })
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

      
        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }

        if (name === 'segment') 
        {
            this.onSegmentClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'segment': value,
                        'category': '',
                    }
                }
            )
        }
    }

    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return nameRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    isFromSubmissionBlock = () => {
        let { postalCode } = this.state.formData;

        if ( postalCodeRegex) {
            if (
                this.inputRegexValidation('postalCode', postalCode) 
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'segment')
        {this.onSegmentClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'category': '',
                    }
                }
            )
        
        }
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };
    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.onSubmitButtonClick())
    }

    onSubmitButtonClick = () =>{
        this.setState({isLoading:true});
        const {name,postalCode,category,segment} = this.state.formData;
        axios.get(`${baseURL}/filter-institutes?page=${this.state.page}&perPage=10&name=${name}&postalCode=${postalCode}&institutionCategoryId=${category}&institutionSegmentId=${segment}`).then(res=>{
            this.setState({expertData:res.data.data,isLoading:false});
        }).catch(err=>{
            this.setState({isLoading:false});
            toast.error(err?.response.data.error.message);
        })
    }

    onViewProfieClick = (url) =>{
        this.props.history.push(`/institute/${url}`)
    }

    render(){
        return(
            <div>
                <SearchInstituteJsx 
                 segment={this.state.segment}
                 categoryData={this.state.categoryData}
                 formData={this.state.formData}
                 onInputChange={this.onInputChange}
                 formError={this.state.formError}
                 onTouchHandler={this.onTouchHandler}
                 onPaginationButtonClick={this.onPaginationButtonClick}

                 formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                 onSubmitButtonClick={this.onSubmitButtonClick}
                 expertData = {this.state.expertData}
                 onViewProfieClick={this.onViewProfieClick}
                />
                 {this.state.isLoading && <Loader />}
                <ToastContainer />
            </div>
        )
    }
}