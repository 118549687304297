import axios from "axios";
import React, { Component } from "react";
import { baseURL } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";
import ListOfProductsJsx from "./listOfProduct.jsx"

export class ListOfProductsJsxclass extends Component {
  constructor(props){
    super(props);
    this.state={
      data:null,
      isProcessing: false,
      page: 1,
      instituteCategoryID:'',
      productName:'',
      legalName:'',
      category:null,
      subCategory:null
    }
  }

  componentDidMount = () =>{
    if(this.props.location?.state?.data){
      const {data, legalName,category,subCategory} = this.props.location?.state;
      data && this.setState({instituteCategoryID:data,category:category,subCategory:subCategory,legalName:legalName.replace('/','')},()=> this.getOfferData());
    }
      
  }

  getOfferData = () =>{
    this.setState({isProcessing:true})
    axios.get(`${baseURL}/institute/${this.state.legalName}/productsPerPage?page=${this.state.page}&perPage=10&institutionCategoryId=${this.state.instituteCategoryID}&productName=${this.state.productName}`).then(res=>{
      this.setState({data:res.data,isProcessing:false});
    })
  }

  onPaginationButtonClick = (page) =>{
      window.scrollTo(0,0);
      this.setState({ page: page }, () => this.getOfferData())
  }

  onOfferNameChange = (e) =>{
    this.setState({productName:e.target.value});
  }

  onSearchButtonClick = () =>{
    this.setState({ isProcessing: true })

    this.setState({ page: 1 })
    setTimeout(() => {
      this.getOfferData();

    }, 1000);
  }

    render() {
      return (
        <div>
          <ListOfProductsJsx 
          data={this.state.data}
          onPaginationButtonClick={this.onPaginationButtonClick}
          onOfferNameChange={this.onOfferNameChange}
          onSearchButtonClick={this.onSearchButtonClick}
          category={this.state.category}
          subCategory={this.state.subCategory}
          />
          {this.state.isProcessing && <Loader />}
        </div>
      );
    }
  }

  export default ListOfProductsJsxclass;