
import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import GlobalJsx from './global.jsx'
import { nameRegex, postalCodeRegex } from '../../../shared/helper.js';
import { withRouter } from 'react-router-dom';

class Global extends Component{
    constructor(props){
        super(props);
        this.state={
          categoryData: null,
            subCategoryData: null,
            formData: {
                name: '',
                postalCode: '',
                category: null,
                subCategory: null
            },
            isProcessing:false,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData:[],
            page: 1,
        }
    }

    componentDidMount = () =>{

        this.getcategorySubcategory();
    }

    getcategorySubcategory = () =>{
      this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
    }

      onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)

        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }
    isFromSubmissionBlock = () => {
      let { postalCode } = this.state.formData;

      if (postalCode) {
          if (
              this.inputRegexValidation('postalCode', postalCode)
          ) {
              this.setState({
                  formSubmitButtonDisable: false
              });
          }
          else {
              this.setState({
                  formSubmitButtonDisable: true
              });
          }
      } else {
          this.setState({
              formSubmitButtonDisable: true
          });
      }
  };
    onInputChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;

      if (name === 'category')
        {this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )
        
        }
          

      if (value) {
          if (!this.inputRegexValidation(name, value)) {
              this.setState(
                  {
                      formData: {
                          ...this.state.formData,
                          [name]: value,
                      },
                      formError: {
                          ...this.state.formError,
                          [name]: "regx",
                      },
                  },
                  () => this.isFromSubmissionBlock()
              );
          } else {
              this.setState(
                  {
                      formData: {
                          ...this.state.formData,
                          [name]: value,
                      },
                      formError: {
                          ...this.state.formError,
                          [name]: "",
                      },
                  },
                  () => this.isFromSubmissionBlock()
              );
          }
      } else {
          this.setState(
              {
                  formData: {
                      ...this.state.formData,
                      [name]: value,
                  },
                  formError: {
                      ...this.state.formError,
                      [name]: "",
                  },
              },
              () => this.isFromSubmissionBlock()
          );
      }
  }
  inputRegexValidation = (name, value) => {
    if (name === 'name')
        return nameRegex.test(value);
    if (name === 'postalCode')
        return postalCodeRegex.test(value);

    return true;
};

onSubmitButtonClick = (type,search) =>{
  const {category,subCategory} = this.state.formData;

  if(type===1){
    this.props.history.push(`/offerListing/${category}/${subCategory}?search=${search}`);
  }else{
    this.props.history.push(`/productListing/${category}/${subCategory}?search=${search}`);
  }
// this.props.history.push(`/globalListing/${category}/${subCategory}`);

}


    render(){
        return(
            <div>
                <GlobalJsx 
                categoryData={this.state.categoryData}
                subCategoryData={this.state.subCategoryData}
                formData={this.state.formData}
                onInputChange={this.onInputChange}
                formError={this.state.formError}
                onSubmitButtonClick={this.onSubmitButtonClick}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}

export default withRouter(Global);