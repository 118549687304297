import React, { useState, useEffect } from 'react'
import dropbox from "../../../../assets/images/dropbox.svg";
import minus from "../../../../assets/images/minus.svg";
import plus from "../../../../assets/images/plus.svg"
import ItemDetails from './itemDetails.jsx';
import { NEW_URL } from '../../../../constants/applicationConstants';
const ProductsJsx = (props) => {
    const {
        produtsList, getProducts, getParticularProducts, openEditBox
    } = props;
    const [showData, setShowData] = useState(produtsList);
    const [itemData, setItemData] = useState("");
    const [totalData, setTotalData] = useState("");

    const onPlusButtonClick = (value, subValue, action) => {
        let tempArray = [...showData]
        tempArray.map((tempDT) => {
            if (tempDT.parentInstitutionCategory === value)
                tempDT.subInstitutionCategories.map((subDT, index) => {
                    if (subDT.name === subValue)
                        tempDT.subInstitutionCategories[index] = { ...tempDT.subInstitutionCategories[index], isOpen: action }
                })
        })
        setShowData(tempArray)
        if (action === false) {
            // window.location.reload()
            window.location.href = `${window.location.origin}${window.location.pathname}?page=myProduct`;
            // setTimeout(() => {
            //     onTabClick('myProduct')

            // }, 1000);
        }
    }

    const onProductClick = (value, subValue, productId, action, totalData) => {
        setItemData(productId)
        setTotalData(totalData)
        let tempArray = [...showData]
        tempArray.map((tempDT) => {
            if (tempDT.parentInstitutionCategory === value)
                tempDT.subInstitutionCategories.map((subDT, index) => {
                    if (subDT.name === subValue)
                        tempDT.subInstitutionCategories[index] = { ...tempDT.subInstitutionCategories[index], activeID: !!productId }
                })
        })
        setShowData(tempArray);
    }

    useEffect(() => {
        setShowData(produtsList)
    }, [produtsList])

    const limitData = JSON.parse(sessionStorage.getItem('loginData'));

    return (
        <>

            <div className="myproductmaininner martop40">
                <h2 className="h2_style">My Product</h2>
                <p>Product visibility enables prioritization and tempts customers to buy. Create products across all 5 categories. You can upload up to {limitData?.totalProductsLimit} products with your current plan.</p>
                {
                    showData?.length > 0 ?
                        showData?.map(product => {
                            return (
                                <div className="myproduct_in">
                                    <h3 className="h3_style text-center martop40">{product.parentInstitutionCategory}</h3>

                                    <div className="customaccordian">
                                        <ul>
                                            {
                                                product.subInstitutionCategories?.map(mainItem => {
                                                    return (
                                                        <li >
                                                            <>
                                                                <h3 className="h3_style text-center">{mainItem.name}</h3>
                                                                {!mainItem.isOpen ? <img src={plus} className="plus"  alt={''} onClick={() => onPlusButtonClick(product.parentInstitutionCategory, mainItem.name, true)} /> :
                                                                    <img src={minus}  alt={''} className="minus" onClick={() => onPlusButtonClick(product.parentInstitutionCategory, mainItem.name, false)} />}

                                                                {mainItem.isOpen &&
                                                                    <>

                                                                        <div className="row">

                                                                            <>
                                                                                {mainItem?.activeID ? <ItemDetails
                                                                                    openEditBox={openEditBox}
                                                                                    onProductClick={() => onProductClick(product.parentInstitutionCategory, mainItem.name, "", false, "")}
                                                                                    itemData={itemData}
                                                                                    getProducts={getProducts}
                                                                                    getParticularProducts={getParticularProducts}
                                                                                    totalData={totalData}
                                                                                /> :

                                                                                    mainItem.products?.map(productData => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="col-lg-4 col-sm-4" onClick={() => onProductClick(product.parentInstitutionCategory, mainItem.name, productData.institutionProductId, true, productData)}>
                                                                                                    <div className="inneraccimmain">
                                                                                                        <div className="inneraccimmainimage"><img src={`${NEW_URL}/${productData.imageUrl}`}  alt={''}/></div>
                                                                                                        <div className="pname">{productData.productName}</div>
                                                                                                        <div className="pnametag">{productData.description}</div>
                                                                                                    </div>
                                                                                                </div>                                                                                        </>
                                                                                        )

                                                                                    })
                                                                                }
                                                                            </>
                                                                        </div>

                                                                    </>

                                                                }
                                                            </>


                                                        </li>
                                                    )

                                                })
                                            }
                                        </ul>
                                    </div>


                                </div>
                            )

                        }) :
                        <div className="dropdefault text-center mb-5">
                            <div className="dropdefaultimage">
                                <img src={dropbox}  alt={''} />
                            </div>
                            <div className="dropdefaulttext">
                                <h5>You dont have any product as of yet. </h5>
                                <h5>Create a new product now !</h5>
                            </div>

                        </div>
                }
            </div>

        </>

    );
};

export default ProductsJsx;
