// particular value should be here 
// all will be in upper case



export let baseURL = "";
export let uploadURL = "";
export let stripeKey = "";
export let CRYPTO_SECRET_KEY = "";
export let CAPTCHA_SECRET_KEY = "";

export let SERVER_IP = "";
export let NEW_URL = "";

export let BLOG_URL = "";
export let BLOG_URL_TOKEN = "";





if (window.location.href.includes('dev.rimib.com') || window.location.href.includes('localhost')) {
  baseURL = process.env.REACT_APP_RIMIB_BASE_URL
  uploadURL = process.env.REACT_APP_RIMIB_UPLOAD_URL
  NEW_URL = process.env.REACT_APP_RIMIB_UPLOAD_URL_NEW
  BLOG_URL = process.env.REACT_APP_RIMIB_BLOG_URL
  BLOG_URL_TOKEN = process.env.REACT_APP_RIMIB_BLOG_URL_TOKEN
  CAPTCHA_SECRET_KEY = process.env.REACT_APP_CAPTCHA_SECRET_KEY
  stripeKey = "";
  CRYPTO_SECRET_KEY = "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3325";
  SERVER_IP = "20.204.7.233,103.50.82.23"

} else if (window.location.href.includes('test.rimib.com')) {
  baseURL = process.env.REACT_APP_RIMIB_BASE_URL_TESTPROD
  uploadURL = process.env.REACT_APP_RIMIB_UPLOAD_URL_TESTPROD
  NEW_URL = process.env.REACT_APP_RIMIB_UPLOAD_URL_NEW
  BLOG_URL = process.env.REACT_APP_RIMIB_BLOG_URL
  BLOG_URL_TOKEN = process.env.REACT_APP_RIMIB_BLOG_URL_TOKEN

  CAPTCHA_SECRET_KEY = process.env.REACT_APP_CAPTCHA_SECRET_KEY_TESTPROD
  stripeKey = "";
  CRYPTO_SECRET_KEY = "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3325";
  SERVER_IP = "20.204.7.233"

}
else {
  baseURL = process.env.REACT_APP_RIMIB_BASE_URL_PROD
  uploadURL = process.env.REACT_APP_RIMIB_UPLOAD_URL_PROD
  NEW_URL = process.env.REACT_APP_RIMIB_UPLOAD_URL_NEW
  BLOG_URL = process.env.REACT_APP_RIMIB_BLOG_URL
  BLOG_URL_TOKEN = process.env.REACT_APP_RIMIB_BLOG_URL_TOKEN

  CAPTCHA_SECRET_KEY = process.env.REACT_APP_CAPTCHA_SECRET_KEY_PROD
  stripeKey = "";
  CRYPTO_SECRET_KEY = "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3325";
  SERVER_IP = "20.204.7.233"

}
