import React from 'react'
import { useEffect } from 'react';
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Pagination } from '../../../common/pagination/pagination';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';


const ManageUnverifiedExpertJsx = (props) => {
  const {
    expertWholeData,
    meta,
    data,
    onAddButtonClick,
    onPaginationButtonClick,
    onVerifyNowClick
  } = props;

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <section className="homecustom">
        <section class="entiredashboard admindashboard">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-3">


                <div className="dashboardleftpanel martop40">

                  <div className="dashboardlink martop40">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-sm-9">
                <div className="dashboardrightpanel martop40">
                  <section classNames="addexpertformsec">
                    <div className="myadssection">
                      <h2 className="h2_style martop30">Manage Unverified User</h2>
                      <p>Use this section to add all the unverified experts.</p>
                      <p>Also, you can verify & register them. Perform the desired action using the relevant buttons</p>
                    </div>
                    <div class="myexpertnav martop60">
                      <ul>
                        <li class="active"><Link to={'/admin/manageUnverifiedInstitute'}>Expert</Link></li>
                      </ul>
                    </div>
                    <div className="martop40">
                      <div className="addexpert martop40">
                        <div className="addexpertleft">
                          <h4>Add Unverified Expert</h4>
                          <p>Click on “Add” to start the process.</p>
                          
                        </div>
                        <div className="addexpertright">
                          <button type="button" className="ctaonebutton" onClick={() => onAddButtonClick()}>Add</button>
                        </div>
                      </div>
                    </div>
                    <div className="searchdetails">
                      <table className="searchdetailsdb">
                        <tr>
                          <th>Expert Name</th>
                          <th>Email</th>
                          <th>contactNumber</th>
                          <th>category</th>
                          <th>Action</th>
                        </tr>
                        {
                          data.length > 0 ? data?.map(data => {
                            return <tr>
                              <td>{`${data?.firstName} ${data?.lastName}`}</td>
                              <td>{data?.email}</td>
                              <td>{data.expertSpecializations[0]?.businessPhoneNumber}</td>
                              <td>{data.expertSpecializations[0]?.categoryName}</td>
                              <td>
                                <button type="button" className="ctaonebutton" onClick={() => onVerifyNowClick(data?.expertId)}>Verify Now</button><br />
                                {/* <button type="button" class="ctaonebutton mt-3">Verify & register</button> */}
                              </td>
                            </tr>

                          }) :
                            <tr><td colspan={4} style={{ textAlign: 'center' }}>{'No Record Found'}</td></tr>
                        }

                      </table>
            {meta?.totalCount > 0 && <Pagination
                        limit={10}
                        totalCount={meta?.totalCount}
                        onPaginationButtonClick={onPaginationButtonClick}
                    />}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          
          </div>
        </section>
        <Footer />
      </section>
    </>

  );
};

export default ManageUnverifiedExpertJsx;
