import React from 'react'
import axios from 'axios';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { ToastContainer, toast } from 'react-toastify';
import { Component } from 'react';
import OfferListingJsx from './offerListing.jsx'
import { Loader } from '../../../common/loader/loader.jsx';
import { withRouter } from "react-router";
import { nameRegex, postalCodeRegex } from '../../../../shared/helper.js';


export default class OfferListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            subCategoryData: null,
            formData: {
                name: '',
                postalCode: '',
                category: '',
                subCategory: null
            },
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            offerData: [],
            page: 1,
        }
    }

    componentDidMount = () => {
        this.searchOffer();
        this.getcategorySubcategory();

    }
    getcategorySubcategory = () => {
        const selectedCategory = this.props.match.params.categoryId;

        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
            { this.onCatgoryClick(selectedCategory) }
        })
    }

    searchOffer = () => {

        this.setState({ isLoading: true });
        const selectedCategory = this.props.match.params.categoryId;
        const selectedSubCategory = this.props.match.params.subcategoryId || 0;
        let search = '';
        const searchParams = new URLSearchParams(this.props.location.search);    
        if (searchParams.has('search')) {        
            search = searchParams.get('search') || ''
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    category: selectedCategory,
                    subCategory: selectedSubCategory
                },

            },
        );
        let url = ''
        if (selectedSubCategory === "null" && selectedCategory === "null") {
            url = `search-offers?search=${search}`

        } else if (selectedSubCategory === "null") {
            url = `search-offers?institutionCategoryId=${selectedCategory}&search=${search}`
        } else {
            url = `search-offers?institutionCategoryId=${selectedCategory}&institutionSubCategoryId=${selectedSubCategory}&search=${search}`
        }
        axios.get(`${baseURL}/${url}`).then(res => {
            this.setState({ offerData: res.data.data, isLoading: false });
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }
    onSubmitButtonClick = () => {
        this.setState({ isLoading: true });

        const { category, subCategory } = this.state.formData;
        

        let url = ''
        if (subCategory === "null" && category === "null") {
            url = `search-offers`

        } else if (subCategory === "null") {
            url = `search-offers?institutionCategoryId=${category}`
        } else {
            url = `search-offers?institutionCategoryId=${category}&institutionSubCategoryId=${subCategory}`
        }
        axios.get(`${baseURL}/${url}`).then(res => {
            this.setState({ offerData: res.data.data, isLoading: false });
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }
    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)

        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }
    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        
        if (value) {
            
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }

        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'category':value,
                        'subCategory': 'null',
                    },

                },
            );

        }

    }

    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return nameRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };
    isFromSubmissionBlock = () => {
        let { postalCode } = this.state.formData;

        if (postalCode) {
            if (
                this.inputRegexValidation('postalCode', postalCode)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    render() {
        return (
            <div>
                <OfferListingJsx
                    offerData={this.state.offerData}
                    categoryData={this.state.categoryData}
                    subCategoryData={this.state.subCategoryData}
                    formData={this.state.formData}
                    onInputChange={this.onInputChange}
                    onSubmitButtonClick={this.onSubmitButtonClick}

                    formError={this.state.formError}
                />
                {this.state.isLoading && <Loader />}

            </div>
        )
    }
}
