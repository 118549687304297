import React, { Component } from "react";
import MyAccountsJsx from "./myAccount.jsx"

export class MyAccountsJsxclass extends Component {

  constructor(props) {
    super(props);
    this.state = {
        activeTab:'acntStng'
    }
  }

  componentDidMount = () =>{
    if(this.props.location?.state?.data){
      const {data} = this.props.location?.state;
      data && this.setState({activeTab:data});
    }
  }
  
  onTabClick=(tab)=>{
    this.setState({activeTab:tab})
  }

    render() {
      return (
        <div>
          <MyAccountsJsx
           activeTab={this.state.activeTab}
           onTabClick={this.onTabClick}
          />
          
        </div>
      );
    }
  }

  export default MyAccountsJsxclass;
