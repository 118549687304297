import React from 'react'
import { useEffect,useState } from 'react';
import call from "../../../assets/images/call.svg";
import { Pagination } from '../../common/pagination/pagination';

import map from "../../../assets/images/map.svg";
import envelop from "../../../assets/images/envelop.svg";
import InputMask from 'react-input-mask';
import { Footer } from '../../common/footer/footer';
import { uploadURL,NEW_URL } from '../../../constants/applicationConstants.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCanadianPhoneNumber } from '../../../shared/helper'
import { baseURL } from '../../../constants/applicationConstants.js';
import axios from 'axios';

const SearchJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    formData,
    onInputChange,
    formError,
    onTouchHandler,
    formSubmitButtonDisable,
    onSubmitButtonClick,
    onPaginationButtonClick,
    expertData,
    onViewProfieClick,
    // meta
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const [posts, setPosts] = useState([]);
  const [meta, setMeta] = useState({});
  const [totalPosts, setTotalPosts] = useState(0);
  const [fetch, setFetch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(0);
  const [page, setPage] = useState(1);

  const getCategory =  (abhi) =>{
    const data =  abhi?.expertSpecializations?.map(spec => '<span>'+spec.categoryName+'</span>');
    //return [...new Set(data)].toString()
    return data && data.join(', ');
    
  }

  const fetchTotalPosts = async () => {


    setSearchLoading(true)

    axios.get(`${baseURL}/searchExperts`).then(res => {
      const data = res.data.data;
      setTotalPosts(data.length);
      setSearchLoading(false)

    })

  };

  const fetchPosts = async () => {
    setFetch(true)
    
            const {name,postalCode,category,subCategory} = formData;

    if (postalCode) {
      setSearchLoading(true)
      axios.get(`${baseURL}/searchExperts?page=${page}&perPage=10&name=${name}&postalCode=${postalCode}&expertCategoryId=${category}&expertSubCategoryId=${subCategory}`).then(res => {
        const data = res.data.data;
        setFetch(false)
        setVisible((prev) => prev + data.length);
        setPosts((prev) => [...prev, ...data]);
        setMeta(res?.data?.meta)

        setSearchLoading(false)

      })
    }




  };

  const fetchPosts1 = async () => {
    setPage(1)
            const {name,postalCode,category,subCategory} = formData;

    if (postalCode) {
      setSearchLoading(true)
      axios.get(`${baseURL}/searchExperts?page=${page}&perPage=10&name=${name}&postalCode=${postalCode}&expertCategoryId=${category}&expertSubCategoryId=${subCategory}`).then(res => {
        const data = res.data.data;
        setFetch(false)
        setVisible((prev) => prev + data.length);
        setPosts((prev) => [...prev, ...data]);
        setMeta(res?.data?.meta)
        setSearchLoading(false)


      })
    }




  };
  const multi = () =>{
    fetchPosts1()
    setPosts([])
  }

  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };


  useEffect(() => {
    fetchTotalPosts();
  }, []);

  useEffect(() => {
    fetchPosts();
  }, [page]);

  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="searchpageherosection">
        <div className="container">
          <div className="searchinnerhero">
            <h2 className="h2_style">{t("SEARCHEXPERT.SEARCHEXPERTTITLE")} </h2>
            <h4 className="h4_style"><i>{t("SEARCHEXPERT.SEARCHEXPERTSUBTITLE")}</i></h4>
            <h4 className="h4_style"><strong>{t("SEARCHEXPERT.SEARCHEXPERTSUBSUBTITLE")} </strong></h4>
            {/* <h4 className="h4_style">{t("SEARCHEXPERT.SEARCHEXPERTSUBSUBSUBTITLE")}</h4> */}
          </div>
          <div className="myexpertformarea searcharea martop40">
            <div className="input-container form-group">
              <InputMask mask={'a9a 9a9'} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                {(inputProps) => <input {...inputProps}
                  type="text"
                  className={
                    formError['postalCode']
                      ? "input form-control  is-invalid"
                      : "input form-control"
                  }
                  placeholder=" "
                  disableUnderline />}
              </InputMask>
              <div className="placeholder">Postal Code*</div>
            </div>
            <div className="input-container form-group">
              <input
                type="text"
                className="form-control input"
                placeholder=" "
                name="name"
                onChange={onInputChange}
                onBlur={onTouchHandler}
                value={formData.name}
              />
              <div className="placeholder">Name</div>
            </div>
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="category"
                onChange={onInputChange}
                onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {categoryData?.map((item) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Category</div>
            </div>
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="subCategory"
                value={formData.subCategory}
                onChange={onInputChange}
                onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {subCategoryData?.map((item, i) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Sub Category</div>
            </div>

            <div className="input-container form-group"><button type="submit" className="ctaonebutton" disabled={formSubmitButtonDisable} onClick={() => multi()}>Search Experts</button></div>
          </div>
        </div>
      </section>
      <section className="searchresultsection">
        <div className="expertsection martop30 mb-5">
          <div className="container">
            
            <h2 className="h2_style mt-5 mb-4 opacity0">{t("SEARCHEXPERT.SEARCHRESULT")}</h2>

            {searchLoading===true && <div className="loadingResult"><h4>Loading</h4></div>}
            {
              posts.length > 0 ?
                posts?.map(data => {
                  return <div className="expertinner">
                    <div className="expertuser"><img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data?.firstName}+${data?.lastName}&rounded=true` : `${NEW_URL}/${data.imageUrl}`} alt={' '} /></div>
                    <div className="experdetails">
                      <h3>{`${data?.firstName} ${data?.lastName}`}<span><div className="expcat"  dangerouslySetInnerHTML={{__html:getCategory(data)}}></div></span></h3>
                      <ul>
                       {  data?.expertSpecializations[0]?.city && <li><span><img src={map} alt={' '} /></span> {data?.expertSpecializations[0]?.city}</li>}
                       { data?.expertSpecializations[0]?.businessPhoneNumber &&<li><span><img src={call} alt={' '} /></span>  {formatCanadianPhoneNumber(data?.expertSpecializations[0]?.businessPhoneNumber)}</li>}
                       { data?.expertSpecializations[0]?.businessEmail && <li><span><img src={envelop} alt={' '} /></span> {data?.expertSpecializations[0]?.businessEmail} </li>}
                      </ul>
                    </div>
                    {data?.planName &&
                      <div className="viewprofile">
                        {/* <button type="submit" className="ctaonebutton" onClick={() => onViewProfieClick(data?.slugUrl)}>View Profile</button> */}
                       <Link to={`/expertPpv/${data?.slugUrl}`} className="ctaonebutton" target="_blank" rel="noreferer">View Profile</Link> 
                        </div>

                    }
                    {data?.planName && <div className="expertpremium">{data?.planName}</div>}
                  </div>
                }) :
                <div className="opacity1">{meta?.totalPages ? t("SEARCHEXPERT.SEARCHRESULTNOTFOUND") : 'Search for expert'}</div>
            }
          </div>
          <div class="text-center">
          {page < (meta?.totalPages || 1) &&               <button className="widthhalf ctaonebutton center" onClick={handleOnLoadMore}>{fetch ? "Loading....." : "Load More"}</button>
}</div>
          {/* {expertData?.length > 0 && <Pagination
            limit={10}
            totalCount={expertData?.meta?.totalCount}
            onPaginationButtonClick={onPaginationButtonClick}
          />} */}
        </div>
      </section>
      <Footer />
    </>

  );
};

export default SearchJsx;
