import React from 'react'
import faqimage from '../../../../../assets/images/faqimage.PNG'
import subcaticon from "../../../../../assets/images/subcaticon.svg"
import { getIconOne } from '../customerAskTheExpert.jsx';
import Sidebar from "../../sidebar/sidebar";

const ConfirmAskTheExpertJsx = (props) => {
  const {
    category,
    subCategory,
    question,
    onEditButtonClick,
    onConfirmAndSubmitButtonClick
  } = props;

  return (
    <>
      <section class="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlinkpop martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <section>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-sm-3">
                      <div className="categoryaskecpertconfirm">
                        <div className="categoryaskecpertconfirminner"><img src={getIconOne(category)} alt={' '} />
                          <h6>{category}</h6></div>
                        <h5>Category</h5>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="faqname">
                        <img src={faqimage} alt={''} />
                      </div>
                      <h5 className="h5_style text-center fst-italic">{question}</h5>
                      <div className="herobutton mt-4 d-flex"><button type="button" className="ctatwobutton" onClick={onEditButtonClick}>Edit</button><button type="button" className="ctaonebutton" onClick={onConfirmAndSubmitButtonClick}>Confirm and Submit</button></div>
                    </div>
                    <div className="col-lg-3 col-sm-3">
                      <div className="categoryaskecpertconfirmsub">
                        <div className="categoryaskecpertconfirminner subcatchal"><img src={`/${(subCategory)?.replace(/\s/g,'')}.png`} alt={''} />
                          <h6>{subCategory}</h6></div>
                        <h5>Sub Category</h5>
                      </div>

                    </div>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default ConfirmAskTheExpertJsx;