import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";
import { getProperWebsite, formatCanadianPhoneNumber } from '../../../shared/helper';

export const ExpertSpecialization = (props) => {
    const { t } = useTranslation();
    const { profileData } = props;

    return (
        <section className="specialisationppv">
            <div className="container">
                <div className="heading">
                    <h2>{t("PPVEXPERT.SPECIALIZATIONTITLE")}</h2>
                    <h5 className="mt-2 text-center">{t("PPVEXPERT.SPECIALIZATIONSUBTITLE")}</h5>
                    <h5 className="mt-2 text-center"><strong>{t("PPVEXPERT.SPECIALIZATIONSUBSUBTITLE")}</strong></h5>
                    <h5 className="text-center">{t("PPVEXPERT.SPECIALIZATIONSUBSUBSUBTITLE")}</h5>
                </div>
            </div>

            {/* {
                profileData?.expertSpecializations?.map(expertSpec => {
                    return (

                        <section className="latestrates specialexppv">
                            <div className="container">
                                <div>
                                    <div className="heading mb-5">
                                        <h2>{expertSpec?.parentInstitutionCategory}</h2>
                                    </div>
                                </div>
                                <div className="bg-white-new">
                                    <h3>Specialization</h3>
                                    <div className="disgrid grid-2">
                                        <div className="detailsbank">

                                            <h4>Name of Institution</h4>
                                            <h5>{expertSpec.institute?.name}</h5>
                                        </div>
                                        <div className="detailsbank">
                                            <h4>Category of Specialization</h4>
                                            <h5>{expertSpec?.parentInstitutionCategory}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="detailsbank bg-white-new">
                                        <h3>Sub-Categories</h3>
                                        <h5>
                                            <ul>
                                                <li>{expertSpec?.subInstitutionCategory?.name}</li>
                                            </ul>
                                        </h5>
                                    </div>
                                    <hr></hr>
                                    <div className="bg-white-new">
                                        <h3>Business Details</h3>
                                        <div className="disgrid grid-2">
                                            <div className="detailsbank">

                                                <h4>Business Name</h4>
                                                <h5>{expertSpec?.businessName}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>Business License No.</h4>
                                                <h5>{expertSpec?.businessLicenseNumber}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>Business Phone No.</h4>
                                                <h5>{expertSpec?.businessPhoneNumber}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>Business E-mail Address</h4>
                                                <h5>{expertSpec?.businessEmail}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>Business Website</h4>
                                                <h5>{expertSpec?.businessWebsite}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>Years of Experience</h4>
                                                <h5>{expertSpec?.experienceInYears}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="detailsbank bg-white-new">
                                        <h3>Address</h3>
                                        <div className="disgrid grid-4">
                                            <div className="detailsbank">
                                                <h4>City</h4>
                                                <h5>{expertSpec?.city}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>Province </h4>
                                                <h5>{expertSpec?.state?.name}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>Country</h4>
                                                <h5>{expertSpec?.country?.name}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>Postal</h4>
                                                <h5>{expertSpec?.postalCode}</h5>
                                            </div>
                                        </div>

                                        <h4>Street Address</h4>
                                        <h5>{expertSpec?.address}</h5>


                                    </div>

                                </div>

                            </div>
                        </section>

                    )
                })
            } */}
            <section className="faqsection" id="faq">
                <div className="container">


                    <div className="accordion" >
                        {
                            profileData?.expertSpecializations?.map((expertSpec, index) => {
                                return (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${index+1}`} aria-expanded="true" aria-controls="collapseOne">
                                                <h2>{expertSpec?.parentInstitutionCategory}</h2>
                                            </button>
                                        </h2>
                                        <div id={`collapseOne${index+1}`} className={`accordion-collapse collapse ${index === 0 && 'show'}`} aria-labelledby="headingOne" >
                                            <div className="accordion-body">
                                                <section className="latestrates specialexppv">
                                                    <div className="container">
                                                        {/* <div>
                                                            <div className="heading mb-5">
                                                                <h2>{expertSpec?.parentInstitutionCategory}</h2>
                                                            </div>
                                                        </div> */}
                                                        <div className="bg-white-new">
                                                            <h3>Specialization</h3>
                                                            <div className="disgrid grid-3">
                                                                <div className="detailsbank">

                                                                    <h4>Name of Institution</h4>
                                                                    <h5>{expertSpec.institute?.name}</h5>
                                                                    
                                                                </div>
                                                                <div className="detailsbank">
                                                                    <h4>Category of Specialization</h4>
                                                                    <h5>{expertSpec?.parentInstitutionCategory}</h5>
                                                                </div>
                                                                <div className="detailsbank">
                                                                {expertSpec?.institute?.institutionSegmentId===3 &&
                                                                        <div className="lincenceWrapExprtIns">
                                                                        <h4>Institution License No.</h4>
                                                                        {expertSpec?.institute?.instituteLicenses.map((e,key)=>(
                                                                            <h5 key={key}>{e?.licenseNumber || 'N/A'}</h5>
                                                                        ))}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="detailsbank bg-white-new">
                                                                <h3>Sub-Categories</h3>
                                                                <h5>
                                                                    <ul>
                                                                        <li>
                                                                        {expertSpec?.expertSpecializationSubCategories?.map((item1, i) => (

                                                                            <span>{item1?.subInstitutionCategory
                                                                                ?.name}, &nbsp;</span>
                                                                        ))}
                                                                        </li>
                                                                        {/* <li>{expertSpec?.subInstitutionCategory?.name}</li> */}
                                                                    </ul>
                                                                </h5>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="bg-white-new">
                                                                <h3>Business Details</h3>
                                                                <div className="disgrid grid-3">
                                                                    <div className="detailsbank">

                                                                        <h4>Business Name</h4>
                                                                        <h5>{expertSpec?.businessName}</h5>
                                                                    </div>
                                                                    <div className="detailsbank">
                                                                        <h4>Business License No.</h4>
                                                                        <h5>{expertSpec?.businessLicenseNumber}</h5>
                                                                    </div>
                                                                    <div className="detailsbank">
                                                                        <h4>Business Phone No.</h4>
                                                                        <h5>{expertSpec?.businessPhoneNumber && formatCanadianPhoneNumber(expertSpec?.businessPhoneNumber)}</h5>
                                                                    </div>
                                                                    <div className="detailsbank">
                                                                        <h4>Business E-mail Address</h4>
                                                                        <h5>{expertSpec?.businessEmail}</h5>
                                                                    </div>
                                                                    <div className="detailsbank">
                                                                        <h4>Business Website</h4>
                                                                        <h5>{expertSpec?.businessWebsite}</h5>
                                                                    </div>
                                                                    <div className="detailsbank">
                                                                        <h4>Years of Experience</h4>
                                                                        <h5>{expertSpec?.experienceInYears}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="detailsbank bg-white-new">
                                                                <h3>Address</h3>
                                                                <div className="disgrid grid-4">
                                                                    <div className="detailsbank">
                                                                        <h4>City</h4>
                                                                        <h5>{expertSpec?.city}</h5>
                                                                    </div>
                                                                    <div className="detailsbank">
                                                                        <h4>Province </h4>
                                                                        <h5>{expertSpec?.state?.name}</h5>
                                                                    </div>
                                                                    <div className="detailsbank">
                                                                        <h4>Country</h4>
                                                                        <h5>{expertSpec?.country?.name}</h5>
                                                                    </div>
                                                                    <div className="detailsbank">
                                                                        <h4>Postal Code</h4>
                                                                        <h5>{expertSpec?.postalCode}</h5>
                                                                    </div>
                                                                </div>

                                                                <h4>Street Address</h4>
                                                                <h5>{expertSpec?.address}</h5>


                                                            </div>

                                                        </div>

                                                    </div>
                                                </section>


                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </section>
        </section>

    )
}