import React, { Component } from "react";
import axios from "axios";
import MyExpertJsx from "./myExpert.jsx";
import { baseURL } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";
import { nameRegex, postalCodeRegex } from '../../../../shared/helper.js';
import { toast } from 'react-toastify';

export class MyExpertJsxclass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        postalCode: '',
        category: '',

      },
      formError: {},
      categoryData: null,
      expertData: null,
      isLoading: false,      
      activeTab: 'acntStng'


    }
   
  }


  componentDidMount = () => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    if (!token) {
      this.props.history.push("/");
    }
    this.getExpertData();
    this.getCategories();
    if (this.props.location?.state?.data) {
      const { data } = this.props.location?.state;
      data && this.setState({ activeTab: data });
    }
  }

  onTabClick = (tab) => {
    // this.setState({ activeTab: tab })
    this.props.history.push("/unregistered")
  }
  onViewProfieClick = (url) => {
    this.props.history.push(`/expertPpv/${url}`)
  }

  getCategories = () => {
    this.setState({ isLoading: true })
    let token = JSON.parse(sessionStorage.getItem('token'));
    axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ categoryData: res.data.data, isLoading: false })
    }).catch(err => {
      this.setState({ isLoading: false })
    })
  }

  getExpertData = () => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    this.setState({ isLoading: true })

    axios.get(`${baseURL}/institute/${instituteId}/experts?regisrationCompleted=true`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ expertData: res.data.data, isLoading: false })
    }).catch(err => {
      this.setState({ isLoading: false })
    })
  }
  onInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;



    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }

  }
  inputRegexValidation = (name, value) => {
    if (name === 'name')
      return nameRegex.test(value);
    if (name === 'postalCode')
      return postalCodeRegex.test(value);

    return true;
  };
  isFromSubmissionBlock = () => {
    let { name, postalCode, category, subCategory } = this.state.formData;

    if (name && postalCode && category && subCategory) {
      if (
        this.inputRegexValidation('name', name) &&
        this.inputRegexValidation('postalCode', postalCode) &&
        category && subCategory
      ) {
        this.setState({
          formSubmitButtonDisable: false
        });
      }
      else {
        this.setState({
          formSubmitButtonDisable: true
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true
      });
    }
  };

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };
  onSubmitButtonClick = () => {
    this.setState({ isLoading: true });
    const { postalCode, category } = this.state.formData;
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    let token = JSON.parse(sessionStorage.getItem('token'));

    axios.get(`${baseURL}/institute/${instituteId}/experts?postalCode=${postalCode}&expertCategoryId=${category}`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ expertData: res.data.data, isLoading: false });
    }).catch(err => {
      this.setState({ isLoading: false });
      toast.error(err?.response.data.error.message);
    })
  }
  render() {
    return (
      <div>
        <MyExpertJsx
          expertData={this.state.expertData}
          categoryData={this.state.categoryData}
          formData={this.state.formData}
          onInputChange={this.onInputChange}
          formError={this.state.formError}
          onTouchHandler={this.onTouchHandler}
          onSubmitButtonClick={this.onSubmitButtonClick}
          onViewProfieClick={this.onViewProfieClick}
          activeTab={this.state.activeTab}
          onTabClick={this.onTabClick}

        />
        {this.state.isLoading && <Loader />}
      </div>
    );
  }
}

export default MyExpertJsxclass;
