import React, { Component } from "react";
import ExpertOnboardingsJsx from "./expertOnboarding.jsx";

export class ExpertOnboardingsJsxclass extends Component {

    render() {
      return (
        <div>
          <ExpertOnboardingsJsx/>
        </div>
      );
    }
  }

  export default ExpertOnboardingsJsxclass;
