import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import OffersJsx from './offers.jsx'
import { nameRegex, postalCodeRegex } from '../../../shared/helper.js';

export default class Offers extends Component{
    constructor(props){
        super(props);
        this.state={
          categoryData: null,
            subCategoryData: null,
            formData: {
                name: '',
                postalCode: '',
                category: null,
                subCategory: null
            },
            investmentOfferData:null,
            topOffers:null,
            mortgageOfferData:null,
            insuranceOfferData:null,
            bankingOfferData:null,
            realestateOfferData:null,
            isProcessing:false,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData:[],
            page: 1,
        }
    }

    componentDidMount = () =>{
        this.getInvestmentOfferData();
        this.getTopOffers();
        this.getMortgageOfferData();
        this.getInsuranceOfferData();
        this.getBankingOfferData();
        this.getRealestatefferData();
        this.getcategorySubcategory();
    }

    getcategorySubcategory = () =>{
      this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
    }

    getInvestmentOfferData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredOffers?institutionCategoryId=2`).then(res=>{
          this.setState({investmentOfferData:res.data.data,isProcessing: false})
        })
      }
      getTopOffers = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/topOffers`).then(res=>{
          this.setState({topOffers:res.data.data,isProcessing: false})
        })
      }

      getMortgageOfferData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredOffers?institutionCategoryId=3`).then(res=>{
          this.setState({mortgageOfferData:res.data.data,isProcessing: false})
        })
      }

      getInsuranceOfferData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredOffers?institutionCategoryId=1`).then(res=>{
          this.setState({insuranceOfferData:res.data.data,isProcessing: false})
        })
      }

      getBankingOfferData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredOffers?institutionCategoryId=4`).then(res=>{
          this.setState({bankingOfferData:res.data.data,isProcessing: false})
        })
      }

      getRealestatefferData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredOffers?institutionCategoryId=5`).then(res=>{
          this.setState({realestateOfferData:res.data.data,isProcessing: false})
        })
      }
      onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)

        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }
    isFromSubmissionBlock = () => {
      let { postalCode } = this.state.formData;

      if (postalCode) {
          if (
              this.inputRegexValidation('postalCode', postalCode)
          ) {
              this.setState({
                  formSubmitButtonDisable: false
              });
          }
          else {
              this.setState({
                  formSubmitButtonDisable: true
              });
          }
      } else {
          this.setState({
              formSubmitButtonDisable: true
          });
      }
  };
    onInputChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;

      if (name === 'category')
        {this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )
        
        }
          

      if (value) {
          if (!this.inputRegexValidation(name, value)) {
              this.setState(
                  {
                      formData: {
                          ...this.state.formData,
                          [name]: value,
                      },
                      formError: {
                          ...this.state.formError,
                          [name]: "regx",
                      },
                  },
                  () => this.isFromSubmissionBlock()
              );
          } else {
              this.setState(
                  {
                      formData: {
                          ...this.state.formData,
                          [name]: value,
                      },
                      formError: {
                          ...this.state.formError,
                          [name]: "",
                      },
                  },
                  () => this.isFromSubmissionBlock()
              );
          }
      } else {
          this.setState(
              {
                  formData: {
                      ...this.state.formData,
                      [name]: value,
                  },
                  formError: {
                      ...this.state.formError,
                      [name]: "",
                  },
              },
              () => this.isFromSubmissionBlock()
          );
      }
  }
  inputRegexValidation = (name, value) => {
    if (name === 'name')
        return nameRegex.test(value);
    if (name === 'postalCode')
        return postalCodeRegex.test(value);

    return true;
};

onSubmitButtonClick = () =>{
  const {name,postalCode,category,subCategory} = this.state.formData;
//   if(category){
//     this.props.history.push(`/offerListing/${category}/${subCategory}`);

//   }
this.props.history.push(`/offerListing/${category}/${subCategory}`);

}


    render(){
        return(
            <div>
                <OffersJsx 
                investmentOfferData={this.state.investmentOfferData}
                topOffers={this.state.topOffers}
                mortgageOfferData={this.state.mortgageOfferData}
                insuranceOfferData={this.state.insuranceOfferData}
                bankingOfferData={this.state.bankingOfferData}
                realestateOfferData={this.state.realestateOfferData}

                // 
                categoryData={this.state.categoryData}
                subCategoryData={this.state.subCategoryData}
                formData={this.state.formData}
                onInputChange={this.onInputChange}
                formError={this.state.formError}
                onSubmitButtonClick={this.onSubmitButtonClick}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}