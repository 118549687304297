import React, { useState } from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { Footer } from '../../../common/footer/footer';
import { Pagination } from '../../../common/pagination/pagination';
import dropbox from "../../../../assets/images/dropbox.svg";
import Sidebar from "../sidebar/sidebar.js";

const ExpertBlogJsx = (props) => {
  const {
    blogData, onPaginationButtonClick,
    onBlogTypeClick, blogType
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState({});

  return (
    <>
      <section class="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlinkpop martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40 mb-5">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">Blogs</h2>
                    <h5 className="h5_style">Blogs showcase your expertise and knowledge, which establishes you as a thought leader.</h5>
                    <h5 className="h5_style">This can build trust with potential customers and enhance your brand reputation. </h5>
                    <h5 className="h5_style fst-italic fw-light">Below are the articles authored by you. Also read our suggested articles & become aware of the latest trends.</h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" class="modal-window">
                      <div>
                        <a  title="Close" class="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myexpertnav myaccountnav qnanav martop40">
                  <ul>
                    <li className={`${blogType === 'ArticleByYou' && "active"}`} onClick={() => onBlogTypeClick('ArticleByYou')}>Articles Authored By You</li>
                    <li className={`${blogType === 'Suggested' && "active"}`} onClick={() => onBlogTypeClick('Suggested')}>Suggested Articles</li>
                  </ul>
                </div>


                {
                  blogData?.body?.length > 0 ?
                    blogData?.body?.map(blog => {
                      return <div className="expertblog" onClick={() => { setBlog(blog); setOpenModal(true) }}>
                        <div className="expertblogimage"> <img src={blog.image} alt={''} /></div>
                        <div className="expertblogtext">
                          <div className="expertblogtextin">
                            <div className="expername">{blog.author}</div>
                            <div className="blogdates">{blog.date_publish}</div>
                          </div>
                          <div className="blog-text">
                            <p>{blog.title}</p>
                          </div>
                          <ul className="tag">
                            {
                              blog?.cats?.map(catgeory => {
                                return <li>{catgeory.cat_name}</li>
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    }) :

                    <div className="dropdefault text-center">
                      <div className="dropdefaultimage">
                        <img src={dropbox} alt={''} />
                      </div>
                      <div className="dropdefaulttext">
                        <h5>You don't have any Blogs yet. </h5>
                      </div>
                    </div>
                }

                {blogData?.body?.length > 0 && <Pagination
                  limit={10}
                  totalCount={blogData?.total_post}
                  onPaginationButtonClick={onPaginationButtonClick}
                />}

                {/* <div className="expertblog">
                    <div className="expertblogimage"></div>
                    <div className="expertblogtext">
                      <div className="expertblogtextin">
                        <div className="expername">Expert name</div>
                        <div className="blogdates">17- 12 -2022</div>
                      </div>
                      <div className="blog-text">
                        <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum ......</p>
                      </div>
                      <ul className="tag">
                        <li>Banking</li>
                        <li>Moetgage</li>
                      </ul>
                    </div>
                  </div>
                  <div className="expertblog">
                    <div className="expertblogimage"></div>
                    <div className="expertblogtext">
                      <div className="expertblogtextin">
                        <div className="expername">Expert name</div>
                        <div className="blogdates">17- 12 -2022</div>
                      </div>
                      <div className="blog-text">
                        <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum ......</p>
                      </div>
                      <ul className="tag">
                        <li>Banking</li>
                        <li>Moetgage</li>
                      </ul>
                    </div>
                  </div>
                  <div className="expertblog">
                    <div className="expertblogimage"></div>
                    <div className="expertblogtext">
                      <div className="expertblogtextin">
                        <div className="expername">Expert name</div>
                        <div className="blogdates">17- 12 -2022</div>
                      </div>
                      <div className="blog-text">
                        <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum ......</p>
                      </div>
                      <ul className="tag">
                        <li>Banking</li>
                        <li>Moetgage</li>
                      </ul>
                    </div>
                  </div>
                */}
              </div>
            </div>
          </div>
        </div>

        {
          openModal &&
          <div className="modalspecial blog-modal">
            <div>
              <div className="mybtn">
                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
              </div>
              <div className="modal-header text-center">
                <h3>{blog?.title}</h3>
              </div>
              <div className="modal-body text-center">
                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                </div>
                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
              </div>
            </div>
          </div>

        }


      </section>
      <Footer />

    </>

  );
};

export default ExpertBlogJsx;