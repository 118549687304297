import React, { Component } from "react";
import AddCardView from "./addCard.jsx"
import { nameRegex, cardRegex, cvcRegex, encrypt, decrypt } from '../../../../shared/helper.js'

export class addCard extends Component {
    constructor(props) {
        super(props);
        let cardDetails = JSON.parse(sessionStorage.getItem("addCard"))
        this.state = {
            formData: {
                name:cardDetails?.name,
                card: cardDetails ?  decrypt(cardDetails?.card) : cardDetails?.card,
                month: cardDetails?.month,
                year: cardDetails?.year,
                cvc:cardDetails ?  decrypt(cardDetails?.cvc) : cardDetails?.cvc,
            },
            isProcessing: false,
            formError: {},

            formSubmitButtonDisable: cardDetails ? false :true ,
            isLoading: false
        };
    }
    componentDidMount = () => {
     
        let token = JSON.parse(sessionStorage.getItem('token'));
        if (!token){
          this.props.history.push("/");   
         }
      }

    onInputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: name==='month' ? (value > 12 ? 12 : value) : value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    };
    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    inputRegexValidation = (name, value) => {
        // if (someday < today) {
        // alert("The expiry date is before today's date. Please select a valid expiry date");
        // return false;
        // }


        if (name === "name") {
            return nameRegex.test(value);
        } else if (name === "card") {
            return cardRegex.test(value)
        } else if (name === "month") {
            if(value > 12)
            {
                return false;
            }
            
            let today,someday
            let exMonth=value;
            let exYear=this.state.formData.year ? this.state.formData.year : (new Date().getFullYear()+1);
            today = new Date();
            someday = new Date();
            someday.setFullYear(exYear, exMonth,0);

            if ( !isNaN(someday.getTime()) ) {
                // date is valid
                if (!(someday < today)) {
                    this.setState(
                        {
                            formError: {
                                ...this.state.formError,
                                [name]: "",
                                'year':""
                            },
                        }
                    );

                    return true
                }
            } 

            //return monthRegex.test(value)

        } else if (name === "year") {

            let today,someday
            let exMonth=this.state.formData.month;

            if(exMonth > 12)
            {
                return false;
            }

            let exYear=value;
            today = new Date();
            someday = new Date();
            someday.setFullYear(exYear, exMonth,0);

            if ( !isNaN(someday.getTime()) ) {
                // date is valid
                if (!(someday < today)) {

                    this.setState(
                        {
                            formError: {
                                ...this.state.formError,
                                [name]: "",
                                'month':""
                            },
                        },
                    );


                    return true
                }
            } 
            //return yearRegex.test(value) && value >= current_year


        } else if (name === "cvc") {
            return cvcRegex.test(value)
        }
    };

    isFromSubmissionBlock = () => {
        let { name, card, month, year, cvc } = this.state.formData;
        if (name) {
            if (
                !this.inputRegexValidation("name", name) ||
                !this.inputRegexValidation("card", card) ||
                !this.inputRegexValidation("month", month) ||
                !this.inputRegexValidation("year", year) ||
                !this.inputRegexValidation("cvc", cvc)
            ) {
                this.setState({
                    formSubmitButtonDisable: true,
                });
            } else {
                this.setState({
                    formSubmitButtonDisable: false,
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true,
            });
        }
    };

    formSubmitHandler = (e) => {
        this.setState({
            isLoading: true
        })
        e.preventDefault();
        const { name, card, month, year, cvc } = this.state.formData;
        const payloadData = {
            "name": name,
            "card": encrypt(card),
            "month": month,
            "year": year,
            "cvc": encrypt(cvc),
        }
        sessionStorage.setItem("addCard", JSON.stringify(payloadData))
        this.props.history.push("/previewCard");
    };


    // handleSubmit = () => {
    //   this.props.history.push("/otpGeneration");
    // }
    onBackClick = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <>

                <AddCardView data=""
                    handleSubmit={this.handleSubmit}
                    onBackClick={this.onBackClick}

                    formData={this.state.formData}
                    onInputChangeHandler={this.onInputChangeHandler}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    formError={this.state.formError}
                    formSubmitHandler={this.formSubmitHandler}
                    isLoading={this.state.isLoading}
                />
            </>
        )
    }
}

export default addCard;