import React from 'react'
import { Component } from 'react';
import PreApprovalJsx from './preApproval.jsx'

export default class PreApproval extends Component{
    render(){
        return(
            <div>
                <PreApprovalJsx />
            </div>
        )
    }
}