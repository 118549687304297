import React from 'react';
import { Component } from 'react';
import { FinalAsktheExpertScreenJSX } from './finalAskTheExpertScreen.jsx';

export default class FinalCustomerAskTheExpertScreen extends Component {
    onHomebuttonClick = () => {
        sessionStorage.clear('askTheExpert')
        this.props.history.push('/customerAskTheExpert');
    }

    render() {
        return (
            <FinalAsktheExpertScreenJSX
                onHomebuttonClick={this.onHomebuttonClick}
            />
        )
    }
}