import React, {useState} from 'react'
import Sidebar from "../../../../components/dashboard/institute/sidebar/sidebar.js"
import { Pagination } from '../../../common/pagination/pagination';
import dropbox from "../../../../assets/images/dropbox.svg";
import { useTranslation } from "react-i18next";

export const BlogJSX = (props) => {
    const { blogData, onPaginationButtonClick,expertData } = props;

    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});

    const { t } = useTranslation();
    return (
        
        <section className="entiredashboard">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-3">
                        <Sidebar />
                    </div>
                    <div className="col-lg-9 col-sm-9">
                        <div className="dashboardrightpanel martop40">
                            <div className="sectionheader martop60 marbot60 blogsectionpos">
                                <h2 className="h2_style fbold">{t("BLOG.TITLE")}</h2>
                                <h6 className="h6_style marbot20">{t("BLOG.WRITTENARTICAL")} </h6>
                            </div>
                            <div className="dashboardslider martop30 marbot60">
                                <h2 className="h2_style">{t("BLOG.EXPERTAUTHOR")} </h2>
                                <h6 className="h6_style marbot60">{t("BLOG.REGISTERED")}</h6>

                                {
                                    expertData?.length > 0 ?
                                        expertData?.map(blog => {
                                            return <div className="expertblog" onClick={() => { setBlog(blog); setOpenModal(true) }}>
                                                <div className="expertblogimage"> <img src={blog.image} alt={''} /></div>
                                                <div className="expertblogtext">
                                                    <div className="expertblogtextin">
                                                        <div className="expername">{blog.author}</div>
                                                        <div className="blogdates">{blog.date_publish}</div>
                                                    </div>
                                                    <div className="blog-text">
                                                        <p>{blog.title}</p>
                                                    </div>
                                                    <ul className="tag">
                                                        {
                                                            blog?.cats?.map(catgeory => {
                                                                return <li>{catgeory.cat_name}</li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        }) :

                                        <div className="dropdefault text-center">
                                            <div className="dropdefaultimage">
                                                <img src={dropbox} alt={''} />
                                            </div>
                                            <div className="dropdefaulttext">
                                                <h5>{t("BLOG.NOEXPERT")}</h5>
                                            </div>
                                        </div>
                                }

                                {blogData?.body?.length > 0 && <Pagination
                                    limit={10}
                                    totalCount={blogData?.total_post}
                                    onPaginationButtonClick={onPaginationButtonClick}
                                />}
                            </div>



                            <div className="dashboardslider martop30 marbot60">
                                <h2 className="h2_style">{t("BLOG.PRODUCT")}</h2>
                                <h6 className="h6_style marbot60">{t("BLOG.PRODUCTDETAIL")} </h6>


                                {blogData?.body?.length > 0 ?
                                    blogData?.body.map(blog => {
                                        return <div className="expertblog" onClick={() => { setBlog(blog); setOpenModal(true) }}>
                                            <div className="expertblogimage"> <img src={blog.image} alt={''} /></div>
                                            <div className="expertblogtext">
                                                <div className="expertblogtextin">
                                                    <div className="expername">{blog.author}</div>
                                                    <div className="blogdates">{blog.date_publish}</div>
                                                </div>
                                                <div className="blog-text">
                                                    <p>{blog.title}</p>
                                                </div>
                                                <ul className="tag">
                                                    {
                                                        blog?.cats?.map(catgeory => {
                                                            return <li>{catgeory.cat_name}</li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }) :
                                    <div className="dropdefault text-center">
                                        <div className="dropdefaultimage">
                                            <img src={dropbox} alt={''} />
                                        </div>
                                        <div className="dropdefaulttext">
                                            <h5>{t("BLOG.NOPRODUCT")} </h5>
                                        </div>
                                    </div>
                                }

                                {blogData?.body?.length > 0 && <Pagination
                                    limit={10}
                                    totalCount={blogData?.total_post}
                                    onPaginationButtonClick={onPaginationButtonClick}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
          openModal &&
          <div className="modalspecial blog-modal">
            <div>
              <div className="mybtn">
                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
              </div>
              <div className="modal-header text-center">
                <h3>{blog?.title}</h3>
              </div>
              <div className="modal-body text-center">
                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                </div>
                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
              </div>
            </div>
          </div>

        }

        </section>
    )
}