import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";

const ExpertDashboardHomeJsx = (props) => {
  const {
    onAddInstituteClick,
    onAddExpertClick
  } = props;

  return (
    <>
      <section class="entiredashboard admindashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40 mb-5">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">Review on new Sign Up</h2>
                    <h5 className="h5_style">Welcome to your personalized dashboard, where you can easily access a variety of features. </h5>
                    <h5 className="h5_style">These include answering questions, creating ads, reading blogs, and much more. </h5>
                    <h5 className="h5_style fst-italic fw-light">Enjoy the convenience of having everything you need at your fingertips.</h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" class="modal-window">
                      <div>
                        <a  title="Close" class="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               <button  type="button" class="btn btn-primary" onClick={() => onAddInstituteClick()}>Add an Institute</button>
              <button  type="button" class="btn btn-primary ml-2" onClick={() => onAddExpertClick()}>Add an Expert</button>
                </div>
              
            </div>
          </div>
        </div>

      </section>

      <Footer />

    </>

  );
};

export default ExpertDashboardHomeJsx;