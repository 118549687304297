import React from "react";
import OtpAuthPage from "./otpAuthentication.jsx"
import { useHistory } from "react-router";

const OtpAuth = () => {
  const history = useHistory();
  const handleSubmit = () => {
    history.push("/newPassword");
  }
  const onBackClick = () => {
    history.goBack()
  }
      return (
        <div>
          <OtpAuthPage
           handleSubmit={handleSubmit}
           onBackClick={onBackClick}
          />
        </div>
      );
  }
  
  export default OtpAuth;
  