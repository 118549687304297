import React from 'react'
import leftarrow from "../../../assets/images/left-arrow.svg";
import passleftgraphics from "../../../assets/images/passleftgraphics.svg";

const PassSuccessJsx = (props) => {
  return (
    <>
      <section className="loginscreeninstitute registrationinstitute passw">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
                <div className="back_btn" onClick={() => props.onBackClick()}><img src={leftarrow} alt={''} /></div>
                <div className="detailsleft plaindetailsleft">
                  <h1>Password Reset Successfully</h1>
                  <div className="instituteleft-illustration martop60">
                    <img src={passleftgraphics} alt={''} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext passwordauthorization martop60 paddingbot60">
                <div className="forgetpassinner">
                  <h2 className="h2_style"> Congratulations!</h2>
                  <h5 className="h2_style martop20 color-light-blue">Your password has been changed successfully.</h5>
                  <h5 className="h5_style martop40 ">Kindly login again using your new password.</h5>
                  <div className="ft-inner otpauthentication">
                    <button type="submit" className="mt-4 mb-4 ctaonebutton" onClick={() => props.handleSubmit()}>Login Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>

  );
};

export default PassSuccessJsx;
