import React from 'react'
import { Component } from 'react';
import PrivacyPolicyJsx from './privacyPolicy.jsx'

export default class PrivacyPolicy extends Component{
    render(){
        return(
            <div>
                <PrivacyPolicyJsx />
            </div>
        )
    }
}