import React, {useState} from 'react'
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import downarrow from "../../../../assets/images/downarrow.svg";
import dropbox from "../../../../assets/images/dropbox.svg";
import Slider from "../../../common/slider/slider.jsx";
import Sidebar from "../sidebar/sidebar.js";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const DashBoardJsx = (props) => {
  const {
    blogData,
    offerData,
    expertData
  } = props;
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState({});

  return (
    <>
      <section className="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">{t("DASHBOARD.HEADING")} </h2>
                    <h5 className="h5_style">{t("DASHBOARD.SUBHEADING")}</h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" class="modal-window">
                      <div>
                        <a  title="Close" class="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboardslider martop30 marbot60">
                  <h2 className="h2_style text-center">{t("DASHBOARD.LIVEOFFERS")}
                  <span className="dashboardtooltip">
                      <img src={planpricingtooltip}  alt={''}/>
                      <span className="dashboardtooltipinner">{t("DASHBOARD.LIVEOFFERSDESC")} </span>
                    </span>
                  </h2>
                  {offerData?.length > 0 ? <Slider offerData={offerData} /> :
                    <div className="dropdefault text-center">
                      <div className="dropdefaultimage">
                        <img src={dropbox} alt={''} />
                      </div>
                      <div className="dropdefaulttext">
                        <h5>You don’t have any live offer as of yet. Create a new live offer now to display here.</h5>
                        <Link
                        to={{
                          pathname: "/myWorkSpace",
                          state: { data:'myOffer' }
                        }}
                        className="ctaonebutton widthhalf">
                        Create Now
                        </Link>
                      </div>
                    </div>
                  }
                </div>

                <div className="sectionheader martop60 marbot60">
                  <h2 className="h2_style text-center">{t("DASHBOARD.BLOGS")}</h2>
                  <h6 className="h6_style marbot20 text-center">{t("DASHBOARD.BLOGSDESC")}</h6>
                </div>
                <div className="dashboardslider martop30 marbot60">
                  <h2 className="h2_style">{t("DASHBOARD.AUTHORS")} </h2>
                  <h6 className="h6_style marbot60">{t("DASHBOARD.AUTHORSDESC")}</h6>


                  {
                    expertData?.length > 0 ?
                      expertData?.map(blog => {
                        return <div className="expertblog" onClick={() => { setBlog(blog); setOpenModal(true) }}>
                          <div className="expertblogimage"> <img src={blog.image} alt={''} /></div>
                          <div className="expertblogtext">
                            <div className="expertblogtextin">
                              <div className="expername">{blog.author}</div>
                              <div className="blogdates">{blog.date_publish}</div>
                            </div>
                            <div className="blog-text">
                              <p>{blog.title}</p>
                            </div>
                            <ul className="tag">
                              {
                                blog?.cats?.map(catgeory => {
                                  return <li>{catgeory.cat_name}</li>
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      }) :
                      <div className="dropdefault text-center">
                        <div className="dropdefaultimage">
                          <img src={dropbox} alt={''} />
                        </div>
                        <div className="dropdefaulttext">
                          <h5>{t("DASHBOARD.NOBLOGS")}</h5>
                          
                        </div>
                      </div>
                  }

                  {blogData?.length > 0 && <a href="/blogs" className="viewall">View All <img src={downarrow}  alt={''}/></a>}
                </div>



                <div className="dashboardslider martop30 marbot60">
                  <h2 className="h2_style">{t("DASHBOARD.PRODUCTS")}</h2>
                  <h6 className="h6_style marbot60">{t("DASHBOARD.PRODUCTSDESC")} </h6>

                  {
                    blogData?.length > 0 ?
                      blogData?.map(blog => {
                        return <div className="expertblog" onClick={() => { setBlog(blog); setOpenModal(true) }}>
                          <div className="expertblogimage"> <img src={blog.image} alt={''} /></div>
                          <div className="expertblogtext">
                            <div className="expertblogtextin">
                              <div className="expername">{blog.author}</div>
                              <div className="blogdates">{blog.date_publish}</div>
                            </div>
                            <div className="blog-text">
                              <p>{blog.title}</p>
                            </div>
                            <ul className="tag">
                              {
                                blog?.cats?.map(catgeory => {
                                  return <li>{catgeory.cat_name}</li>
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      }) :
                      <div className="dropdefault text-center">
                        <div className="dropdefaultimage">
                          <img src={dropbox}  alt={''}/>
                        </div>
                        <div className="dropdefaulttext">
                          <h5>{t("DASHBOARD.NOPRODUCTS")}</h5>
                        
                        </div>
                      </div>
                  }

                  {blogData?.length > 0 && <a href="/blogs" className="viewall">View All <img src={downarrow} alt={''} /></a>}
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          openModal &&
          <div className="modalspecial blog-modal">
            <div>
              <div className="mybtn">
                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
              </div>
              <div className="modal-header text-center">
                <h3>{blog?.title}</h3>
              </div>
              <div className="modal-body text-center">
                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                </div>
                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
              </div>
            </div>
          </div>

        }

      </section>
    </>

  );
};

export default DashBoardJsx;
