import React from 'react'
import { Component } from 'react';
import {FooterJSX} from './footer.jsx'

export class Footer extends Component{
    render(){
        return(
            <FooterJSX />
        )

    }
    
}