import React from 'react'
import leftarrow from "../../../../assets/images/left-arrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import { NEW_URL } from '../../../../constants/applicationConstants';import { Footer } from '../../../common/footer/footer'

const DetailedProductsJsx = (props) => {
  const history = useHistory();
  const location = useLocation()
  const {state:locationState} = location
  
  const {
    data,
    category,
    subCategory
  } = props;

  return (
    <>
      <section className="listofoffermainsection">
        <div className="container">
          <div className="leftsightbackdetails listofofferleft padtop60">
            <div className="back_btn" onClick={() => locationState?.frompage ? history.push(`${locationState?.pagePath ?? '/'}#${locationState?.frompage}`) : history.goBack()} ><img src={leftarrow} alt={' '} /></div>
            <div className="plaindetailsleft">
              <h1 className="h2_style font-weight-bold">Detailed Product View </h1>
              <h5 className="h5_style">We understand your financial needs! Let us help you in achieving financial freedom.</h5>
              <h5 className="h5_style font-weight-bold">Do you wish to get this product at the best price? </h5>
              <h5 className="h5_style">Hurry up! Click “Apply Now” and quickly avail of the best live offers & deals.</h5>
            </div>
          </div>
          <div className="detailedview mt-5 mb-5">
            <div className="detailedimage">
              <img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '}/>
            </div>
            <div className="detailedtext mt-4">
              <div className="pname">{data?.productName}</div>
              <p>{category}</p>
              <p>{subCategory}</p>
              <div className="detailedofferheading">Description</div>
              <p>{data?.description} </p>
              <div className="disgrid grid-2">
                <div>
                  <div className="detailedofferheading">Benefits</div>
                  <p>{data?.benefits} </p>
                </div>
                <div>
                  <div className="detailedofferheading">Features</div>
                  <p>{data?.features} </p>
                </div>
              </div>
              {data?.externalLink && <button className="ctaonebutton widthhalf mt-4" onClick={()=> window.open(data?.externalLink,'_blank')}>Apply Now</button>}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>

  );
};

export default DetailedProductsJsx;