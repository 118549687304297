import React from 'react'
import { useEffect } from 'react';
import { Footer } from '../../../common/footer/footer';
import leftarrow from "../../../../assets/images/left-arrow.svg";
import { uploadURL,NEW_URL } from '../../../../constants/applicationConstants';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";


const OfferListingJsx = (props) => {
  const history = useHistory();
  const {
    data,
    categoryData,
    subCategoryData,
    onSubmitButtonClick,
    formData,
    onInputChange,
    onPaginationButtonClick,
    onOfferNameChange,
    onSearchButtonClick,
    category,
    subCategory, offerData
  } = props;
  

  const { categoryId,subcategoryId } = useParams()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <section className="listofoffermainsection">
        <div className="container">
          <div className="leftsightbackdetails listofofferleft padtop60">
            <div className="back_btn" onClick={() => history.goBack()}><img src={leftarrow} alt={' '} /></div>
            <div className="plaindetailsleft">
              <h1 className="h2_style font-weight-bold">List of Offers</h1>
              <h5 className="h5_style">Like you, we also care for your money! </h5>
              <h5 className="h5_style font-weight-bold">Now enjoy the best financial products at the lowest rates with our jaw-dropping offers. </h5>
              <h5 className="h5_style font-weight-bold">Don’t miss out the opportunity! Explore our top offers below. Secure the best deals by clicking on “Apply Now”.</h5>
              {/* <h5 className="h5_style">Have you tried it yet? Just tap on any icon below!</h5> */}
            </div>
          </div>
          <div className="newserachfield">
            <div className="myexpertformarea searcharea martop40">
              <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="category"
                onChange={onInputChange}
                //  onBlur={onInputChange}
              >
                <option value="">All</option>
                {categoryData?.map((item) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId ==
                         categoryId}>{item.name}</option>
                    </>
                  )
                })}
              </select>
                <div className="placeholder">Category</div>
              </div>
              <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="subCategory"
                onChange={onInputChange}
                value={formData.subCategory}

                // onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {subCategoryData?.map((item, i) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId == subcategoryId}>{item.name}</option>
                    </>
                  )
                })}
              </select>
                <div className="placeholder">Sub Category</div>
              </div>
              <div className="input-container form-group">
                <button type="submit" className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Search Offers</button>
              </div>
            </div>
          </div>
          <div className="listofproduct mt-5 mb-5">
            <div className="row">
              {
                offerData.length > 0 ? 
                offerData?.map(data => {
                  return <div className="col-lg-6 col-sm-6">
                  <div className="listofadinner mb-4">
                    <div className="listofimage">
                      {/* <img src="https://testprod.rimib.com/images/institute-product/e3518bd9-de5a-44b5-90d8-1c200ca2d0df.jpg" alt={' '} /> */}
                      <img src={`${NEW_URL}/${data?.imageUrl}`} alt={''} />
                    </div>
                    <div className="listofadinnerpop">
                      <div className="listofadtext">
                        <h3>{data?.offerName}</h3>
                      </div>
                      <div className="listofadbutton">
                      {data?.externalUrl &&
                        <Link
                          to={{
                            pathname: data?.externalUrl,
                          }}
                          target="_blank"
                          className="ctaonebutton">
                          Apply Now
                              </Link> }
                        <Link
                          to={{
                            pathname: "/detailedOffer",
                            state: { data: data, category: category, subCategory: subCategory }
                          }}
                          className="ctatwobutton ctanewtwobutton"
                          onClick={() => window.scrollTo(0, 0)}>
                          View Details
                              </Link>
  
                      </div>
                    </div>
                  </div>
                </div>
                }) :
                "No offer found"
              }
             
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>

  );
};

export default OfferListingJsx;
