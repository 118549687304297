import React, { useState } from "react";
import axios from "axios";
import {
    baseURL
} from "../../../../constants/applicationConstants.js";
import PreviewCardView from './previewCard.jsx'
import swal from "sweetalert";
import { encrypt } from '../../../../shared/helper'
import { useHistory } from "react-router-dom";
import { Loader } from "../../loader/loader.jsx";

const PreviewCard = () => {
    const history = useHistory();

    const [option, setOption] = useState("de-select");
    const [isLoading, setIsLoading] = useState(false);

    const cardDetails = JSON.parse(sessionStorage.getItem("addCard"))
    const onselectTypeButtonClick = (select) => {
        setOption(select)
    }

    const formSubmitHandler = (e) => {
        setIsLoading(true)

        let instituteId = JSON.parse(sessionStorage.getItem("instituteId"));
        let isResume = sessionStorage.getItem("resumeOnBoard")
        let tokenValidate = JSON.parse(sessionStorage.getItem("token"));
        let subscriptionPlanTypeId = JSON.parse(sessionStorage.getItem("planTypeID"));
        let subscriptionPlanId = JSON.parse(sessionStorage.getItem("subscriptionPlanId"));
        let expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))
        const adminToken = sessionStorage.getItem('adminOldToken');

        e.preventDefault();
        let data = {
            instituteId: instituteId,
            subscriptionPlanTypeId: subscriptionPlanTypeId,
            tokenValidate: tokenValidate,
            cardNumber: cardDetails.card,
            expiryMonth: encrypt(cardDetails.month.toString()),
            expiryYear: encrypt(cardDetails.year),
            cvc: cardDetails.cvc,
        };
        if (expertId) {
            delete data.instituteId;
            data.expertId = expertId
        }

        let flowName = localStorage.getItem('AdminFlow');
        let subURL = '';
        if (flowName === 'institute' || flowName === 'expert') {
            subURL = '/paidByAdmin'
        }
        // /institute/:instituteId/resume-onboarding
        axios.post(`  ${baseURL}/subscriptionPlan/${subscriptionPlanId}${subURL}`, data).then((res) => {
            setIsLoading(false)
            if (res) {
                sessionStorage.removeItem("expertId");
                sessionStorage.removeItem("description");
                sessionStorage.removeItem("expertData");
                sessionStorage.removeItem("subscriptionPlanId");
                sessionStorage.removeItem("planTypeID");
                sessionStorage.removeItem("planData");
                sessionStorage.removeItem("addCard");
            }

            if (isResume) {
                let comingFromInstitute = sessionStorage.getItem("comingFromInstitute")
                if (comingFromInstitute && comingFromInstitute) {
                    sessionStorage.removeItem("resumeOnBoard");
                    sessionStorage.removeItem("isRemoveState")

                    let oldToken = localStorage.getItem("instituteExpertToken")
                    sessionStorage.setItem("token", oldToken);
                } else {
                    sessionStorage.removeItem("resumeOnBoard");
                    sessionStorage.removeItem("isRemoveState")

                    sessionStorage.setItem("token", JSON.stringify(adminToken));
                }


            }

            localStorage.setItem('paymentData', JSON.stringify(res.data.data))

            history.push("/paymentSummery");
        }).catch((res) => {
            setIsLoading(false)

            swal({
                title: "error",
                text: res?.response.data.error.message,
                icon: "error",
            });


        })

    };

    return (
        <>
            <PreviewCardView
                cardDetails={cardDetails}
                onselectTypeButtonClick={onselectTypeButtonClick}
                option={option}
                formSubmitHandler={formSubmitHandler}
            />
            {isLoading && <Loader />}

        </>
    )
}

export default PreviewCard;