import React from 'react'
import { Component } from 'react';
import { nameRegex, postalCodeRegex } from '../../../../shared/helper.js';
import OpsPaymentJsx from './opsPayment.jsx'
import data from './opsPayment.json';

export default class OpsPayment extends Component{
    constructor(props){
        super(props);
        this.state={
            activeTab: 'expert',
            searchData:{
                firstName:'',
                lastName:'',
                postalCode:'',
                status:'all'
            },
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
        }
    }

    onTabClick = (tab) =>{
        this.setState({activeTab:tab})
        this.setState({ searchData:{
            firstName:'',
            lastName:'',
            postalCode:'',
            status:'all'
        }},()=> this.isFromSubmissionBlock())
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        searchData: {
                            ...this.state.searchData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        searchData: {
                            ...this.state.searchData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    searchData: {
                        ...this.state.searchData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    inputRegexValidation = (name, value) => {
        if (name === 'firstName' || name === 'lastName')
            return nameRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    isFromSubmissionBlock = () => {
        let { postalCode } = this.state.searchData;

        if ( postalCodeRegex) {
            if (
                this.inputRegexValidation('postalCode', postalCode) 
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    onSearchButtonClick = () => {
        
    }

    render(){
        return(
            <div>
                <OpsPaymentJsx 
                    data={data.data}
                    activeTab={this.state.activeTab}
                    onTabClick={this.onTabClick}
                    searchData={this.state.searchData}
                    onInputChange={this.onInputChange}
                    formError={this.state.formError}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onSearchButtonClick={this.onSearchButtonClick}
                />
            </div>
        )
    }
}