import axios from "axios";
import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { baseURL } from "../../../constants/applicationConstants.js";
import { emailRegex, nameRegex ,postalCodeRegex} from "../../../shared/helper.js";
import { Footer } from "../../common/footer/footer.js";
import { Loader } from "../../common/loader/loader.jsx";
import PublicProfileViewsJsx from "./publicProfileView.jsx"

export class PublicProfileViewsJsxclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: null,
      isProcessing: false,
      userData: {
        name: '',
        email: "",
        phone: "",
        phoneNumber: "",
        message: ""
      },
      formData: {
        postalCode: '',
        category: '',
        
      },
      formError: {},
      formSubmitButtonDisable:true,
      formSubmitButtonDisableForBlog:true,
      legalName:'',
      categoryData: null,
      expertData: null,
      isLoading: false
    }
  }

  componentDidMount = () => {
    this.getExpertData();
    this.getCategories();
    this.setState({ isProcessing: true })
    this.setState({legalName: window.location.pathname.replace('/institute','')},()=>{
      axios.get(`${baseURL}/institute${this.state.legalName}/detailsAndCategories`).then(res => {
        this.setState({ profileData: res.data.data, isProcessing: false });

        if(window.location.hash){
          const element = document.getElementById(window.location.hash.replace("#", ""));
          element && element.scrollIntoView();
        }

      }).catch(err=>{
        toast.error('Legal Name is invalid');
        this.setState({ isProcessing: false });
      })
    })
  }
  onViewProfieClick = (url) =>{
    this.props.history.push(`/expertPpv/${url}`)
}

  getCategories = () => {
    let legalName = window.location.pathname.replace('/institute','')
    this.setState({ isLoading: true })
    axios.get(`${baseURL}/institute${legalName}/institutionCategories`).then(res => {
      this.setState({ categoryData: res.data.data, isLoading: false })
    })
  }

  getExpertData = () => {
    let legalName = window.location.pathname.replace('/institute','')
    this.setState({ isProcessing: true })

    axios.get(`${baseURL}/institute${legalName}/expertsList`).then(res => {
      this.setState({ expertData: res.data.data, isProcessing: false })
    })
  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            userData: {
              ...this.state.userData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock1()
        );
      } else {
        this.setState(
          {
            userData: {
              ...this.state.userData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock1()
        );
      }
    } else {
      this.setState(
        {
          userData: {
            ...this.state.userData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock1()
      );
    }
  };

  isFromSubmissionBlock1 = () => {
    let { name, email, phone, phoneNumber, message} = this.state.userData;

    if (name && email && phone && phoneNumber && message) {
      if (
        this.inputRegexValidation('name', name) &&
        this.inputRegexValidation('email', email)  &&
        this.inputRegexValidation('phone', phone)  &&
          phoneNumber && message
      ) {
        this.setState({
          formSubmitButtonDisableForBlog: false
        });
      }
      else {
        this.setState({
          formSubmitButtonDisableForBlog: true
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisableForBlog: true
      });
    }
  };

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {
    if (name === 'name')
      return nameRegex.test(value);
    else if (name === "email")
      return emailRegex.test(value);
    else if (name === "phone")
      return !this.getProperPhoneNumber(value).includes('_');

    return true;
  };

  getProperPhoneNumber = (value) => {

    if (!isNaN(value)) {
      return value
    }

    const number = value.split(' ');
    return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
  }

  getProperPhoneNumber = (value) => {

    if (!isNaN(value)) {
        return value
    }

    const number = value.split(' ');
    return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
}

  onSendMessageClick = () =>{
    this.setState({isProcessing:true});
    const {   name, email, phone, phoneNumer, message } = this.state.userData;
    const payload={
        "name": name,
        "phoneNumber": this.getProperPhoneNumber(phone),
        "email": email,
        "message": message,
        "formattedLegalName": this.state.legalName.replace('/',''),
        "institutionCategoryId": phoneNumer
    }
    axios.post(`${baseURL}/contactUs`,payload).then(res=>{
      this.setState({isProcessing:false})
      toast.success('Message sent successfully');
      this.setState({
        userData: {
          name: '',
          email: "",
          phone: "",
          phoneNumber: "",
          message: ""
        }
      })
    }).catch(err=>{
      this.setState({isProcessing:false})
      toast.error(err.response.data.error.message);
    })
  }

  onInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

  

    if (value) {
        if (!this.inputRegexValidation(name, value)) {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    } else {
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                formError: {
                    ...this.state.formError,
                    [name]: "",
                },
            },
            () => this.isFromSubmissionBlock()
        );
    }

}
inputRegexValidation = (name, value) => {
  if (name === 'name')
      return nameRegex.test(value);
  if (name === 'postalCode')
      return postalCodeRegex.test(value);

  return true;
};

isFromSubmissionBlock = () => {
  let { name, postalCode, category, subCategory } = this.state.formData;

  if (name && postalCode && category && subCategory) {
      if (
          this.inputRegexValidation('name', name) &&
          this.inputRegexValidation('postalCode', postalCode) &&
          category && subCategory
      ) {
          this.setState({
              formSubmitButtonDisable: false
          });
      }
      else {
          this.setState({
              formSubmitButtonDisable: true
          });
      }
  } else {
      this.setState({
          formSubmitButtonDisable: true
      });
  }
};

onTouchHandler = (e) => {
  let name = e.target.name;
  let value = e.target.value;
  if (value === "") {
      this.setState({
          formError: {
              ...this.state.formError,
              [name]: "req",
          },
      });
  } else {
      if (!this.inputRegexValidation(name, value)) {
          this.setState({
              formError: {
                  ...this.state.formError,
                  [name]: "regx",
              },
          });
      }
  }
};
onSubmitButtonClick = () =>{
    const {postalCode,category} = this.state.formData;
  let legalName = window.location.pathname.replace('/institute','')
  this.setState({ isProcessing: true })

  axios.get(`${baseURL}/institute${legalName}/expertsList?postalCode=${postalCode}&expertCategoryId=${category}`).then(res=>{
      this.setState({expertData:res.data.data,isProcessing:false});
  }).catch(err=>{
      this.setState({isProcessing:false});
      toast.error(err?.response.data.error.message);
  })
}

  render() {
    return (
      <div>
        <PublicProfileViewsJsx
         expertData={this.state.expertData}
         categoryData={this.state.categoryData}
          profileData={this.state.profileData}
          userData={this.state.userData}
          formError={this.state.formError}
          formData={this.state.formData}
          onInputChangeHandler={this.onInputChangeHandler}
          onInputChange={this.onInputChange}
          onTouchHandler={this.onTouchHandler}
          onSendMessageClick={this.onSendMessageClick}
          legalName={this.state.legalName}
          formSubmitButtonDisable ={this.state.formSubmitButtonDisable}
          formSubmitButtonDisableForBlog={this.state.formSubmitButtonDisableForBlog}
          onSubmitButtonClick={this.onSubmitButtonClick}
          onViewProfieClick={this.onViewProfieClick}
        />
        {this.state.isProcessing && <Loader />}
        <ToastContainer />
        <Footer />
      </div>
    );
  }
}

export default PublicProfileViewsJsxclass;