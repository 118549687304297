import React from 'react'
import leftarrow from "../../../assets/images/left-arrow.svg";
import passleftgraphics from "../../../assets/images/passleftgraphics.svg";
import { ToastContainer } from 'react-toastify';

const OtpGenerationJsx = (props) => {
    
    return (
        <>
            <section className="loginscreeninstitute registrationinstitute passw">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-5">
                            <div className="leftsightbackdetails martop60">
                                <div className="back_btn" onClick={() => props.onBackClick()}><img src={leftarrow} alt={''} /></div>
                                <div className="detailsleft plaindetailsleft">
                                    <h1>OTP Generation</h1>
                                    <div className="instituteleft-illustration martop60">
                                        <img src={passleftgraphics} alt={''} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-sm-7 right-institute">
                            <div className="institutelogintext passwordauthorization martop60 paddingbot60">
                                <div className="otpgeneration forgetpassinner">
                                    <h2 className="h2_style">Generating an OTP</h2>
                                    <h5 className="h2_style color-light-blue">Don’t worry we all forget our passwords!</h5>
                                    <h6 className="h5_style martop30">To reset, we will send you an OTP at your registered email address. </h6>
                                    <h5 className="h4_style martop20 font-weight-normal">Do you want to send an OTP to your registered email {props?.email}?</h5>
                                    <div className="ft-inner">

                                        <div className="savestate mt-4">
                                            <button onClick={() => props.handleSubmit()} type="button" className="mt-3 ctaonebutton">Yes</button>
                                            <button type="button" className="mt-3 ctatwobutton">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />


        </>

    );
};

export default OtpGenerationJsx;
