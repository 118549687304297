import React from "react";
import "./loader.css";
import loader from "../../../assets/images/loader.svg"

export  const Loader = () => {
  return (
    <>
      <div className={'loaderwrapper'}>
      <span className="loader1"><img src={loader} alt={''} /></span>
      </div>
    </>
    
  );
};
