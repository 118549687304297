import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { Loader } from '../../../common/loader/loader.jsx';
import ExpertsJsx from './experts.jsx'
import { toast } from 'react-toastify';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { nameRegex, postalCodeRegex } from '../../../../shared/helper.js';

export default class Experts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expertWholeData: null,
            paginationData: null,
            subCategoryData: null,
            resetPagination: false,
            formData: {
                instituteName: '',
                nameExpert: '',
                productName: '',
                type: '',
                homeFeatured: '',
                institutionCategory: '',
                status: ''
            },
            page: 1,
            categoryData: null,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData: [],
            checked: false

        }
    }

    componentDidMount = () => {
        this.getExpert()
        this.getCategories()
        const token = JSON.parse(sessionStorage.getItem('token'));

        if (!token) {
            this.props.history.push("/admin");
        }
    }
    getCategories = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }
    getExpert = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        const { instituteName, institutionCategory, nameExpert,status } = this.state.formData;

        axios.get(`${baseURL}/searchExpertsByAdmin?page=${this.state.page}&perPage=10&instituteName=${instituteName}&name=${nameExpert}&expertCategoryId=${institutionCategory}&status=${status}`, {
            headers: {
                'x-access-token': token
            }
        }
        ).then(res => {
            this.setState({ expertWholeData: res.data.data, isLoading: false })
            this.setState({ paginationData: res.data, isLoading: false })

        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }
    forceLoginExpert = (data) => {
        const token = JSON.parse(sessionStorage.getItem('token'));
        const loginData = sessionStorage.getItem('loginData');

        this.setState({
            isLoading: true
        })

        axios.post(`${baseURL}/expert/${data}/login`, {
            headers: {
                'x-access-token': token
            }
        }).then((res) => {
            sessionStorage.setItem("adminOldToken", token);
            sessionStorage.setItem("adminLoginData", loginData);

            sessionStorage.setItem("auth", JSON.stringify(res?.data?.data));
            sessionStorage.setItem("token", JSON.stringify(res.data?.data?.accessToken));
            sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.expertId));
            sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.expertId));
            sessionStorage.setItem("loginData", JSON.stringify(res.data?.data));
            localStorage.setItem('flowName', 'expert')
            localStorage.setItem('flowType', 'expert')
            sessionStorage.setItem("loginAs",'expert')

            this.setState({
                isLoading: false
            })

            toast.success("You have logged-in successfully");
            setTimeout(() => {
                this.props.history.push("/expertDashboardHome");
                window.location.reload()
            }, 2000)

        }).catch((res) => {
            toast.error(res?.response.data.error.message);
            this.setState({
                isLoading: false
            })
        })

    };
    onFirstLoginClick = (data) =>{
        let token = JSON.parse(sessionStorage.getItem("token"))

        this.setState({
          isLoading: true
        })
        axios.get(`${baseURL}/expert/${data.expertId}/details`, {
            headers: { 'x-access-token': token }
        }).then(res=>{
          this.setState({
            isLoading: false
          })
          sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.expertId));
          sessionStorage.setItem("resumeOnBoard",true)
          sessionStorage.removeItem("masterID");

          sessionStorage.setItem("adminOldToken", token);
          sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
          localStorage.setItem('flowName', 'expert')
          localStorage.setItem('flowType', 'expert')
          localStorage.setItem('AdminFlow', 'expert')
        //   window.location.href = '/sssss';
        this.props.history.replace('/')     
              if(res.data.data.onboardingStep === 'basic-info')
              this.props.history.replace('./specialInformations')
            
            else
              this.props.history.replace('/expertPlans')
        }).catch(err=>{
          toast.error(err.response.data.error.message);
          this.setState({
            isLoading: false
          })
        })
      }

      completeRegistration = (data) =>{
        let token = JSON.parse(sessionStorage.getItem("token"))

        this.setState({
          isLoading: true
        })
        axios.get(`${baseURL}/expert/${data.expertId}/details`, {
            headers: { 'x-access-token': token }
        }).then(res=>{
          this.setState({
            isLoading: false
          })
          sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.expertId));
        //   sessionStorage.setItem("resumeOnBoard",true)
          sessionStorage.removeItem("masterID");

          sessionStorage.setItem("adminOldToken", token);
          sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
          localStorage.setItem('flowName', 'expert')
          localStorage.setItem('flowType', 'expert')
          localStorage.setItem('AdminFlow', 'expert')
        //   this.props.history.replace('/')
          this.props.history.push('/expertRegistration')

        }).catch(err=>{
          toast.error(err.response.data.error.message);
          this.setState({
            isLoading: false
          })
        })
      }

    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getExpert())
    }
    handleChange = (e, id) => {
        const { checked } = e.target
        this.setState({
            checked: checked
        })
        this.makeFeature(id, checked)
    }
    makeFeature = (id, action) => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        this.setState({ isLoading: true });
        let data = {
            "isFeatured": action
        }
        axios.put(`${baseURL}/expert/${id}/featured`, data, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.getExpert()
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }

    handleChange1 = (e, id) => {
        const { checked } = e.target
        this.setState({
            checked: checked
        })
        this.makeFeature1(id, checked)
    }
    makeFeature1 = (id, action) => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        this.setState({ isLoading: true });
        let data = {
            "isHomeFeatured": action
        }
        axios.put(`${baseURL}/expert/${id}/homeFeatured`, data, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.getExpert()
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }
    excludeExpert = (e, id) => {
        const { checked } = e.target
        this.setState({
            checked: checked
        })
        this.makeExclude(id, checked)
    }
    makeExclude = (id, action) => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        this.setState({ isLoading: true });
        let data = {
            "excludeToAssignQuestions": action
        }
        axios.put(`${baseURL}/expert/${id}/exludeToAssignQuestions`, data, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.getExpert()
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }

    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)
        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category')
        {
            this.onCatgoryClick(value)
        }
        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    inputRegexValidation = (name, value) => {
        if (name === 'instituteName' || name === "productName")
            return nameRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    isFromSubmissionBlock = () => {
        let { productName } = this.state.formData;

        if (productName) {
            if (
                this.inputRegexValidation('productName', productName)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    onSubmitButtonClick = () => {
        let token = JSON.parse(sessionStorage.getItem('token'));

        this.setState({ isLoading: true });
        const { instituteName, institutionCategory, nameExpert,status } = this.state.formData;
        axios.get(`${baseURL}/searchExpertsByAdmin?instituteName=${instituteName}&perPage=10&name=${nameExpert}&expertCategoryId=${institutionCategory}&status=${status}`, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.setState({ expertWholeData: res.data.data, isLoading: false })
            this.setState({ paginationData: res.data, isLoading: false })
            this.setState({resetPagination:true},()=> this.setState({resetPagination:false}))
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }
    approveExpert = (id, action) => {
        this.setState({ isLoading: true });
        const token = JSON.parse(sessionStorage.getItem('token'));

        let payload = {
            "status": action
        }
        axios.put(`${baseURL}/expert/${id}/status`, payload, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.getExpert()
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }

    onViewProfieClick = (url) => {
        this.props.history.push(`/expertPpv/${url}`)
    }


    render() {
        return (
            <div>
                <ExpertsJsx
                    expertWholeData={this.state.expertWholeData}
                    paginationData={this.state.paginationData}

                    subCategoryData={this.state.subCategoryData}
                    formData={this.state.formData}
                    onInputChange={this.onInputChange}
                    categoryData={this.state.categoryData}
                    formError={this.state.formError}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    resetPagination={this.state.resetPagination}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    approveExpert={this.approveExpert}
                    expertData={this.state.expertData}
                    onViewProfieClick={this.onViewProfieClick}
                    forceLoginExpert={this.forceLoginExpert}
                    onFirstLoginClick={this.onFirstLoginClick}
                    handleChange={this.handleChange}
                    handleChange1={this.handleChange1}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    completeRegistration={this.completeRegistration}
                    excludeExpert={this.excludeExpert}


                />
                {this.state.isLoading && <Loader />}
            </div>
        )
    }
}