import React from 'react'
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { uploadURL,NEW_URL } from '../../../constants/applicationConstants';
import dropbox from "../../../assets/images/dropbox.svg";
import cross from "../../../assets/images/cross.svg";
import Investmentbanner from "../../../assets/images/investmentbanner.png";
import {useLocation} from 'react-router-dom'
const InvestmentJsx = (props) => {
  const { t } = useTranslation();
  const {
    offerData,
    productData,
    adData,
    expertData,
    instituteData,
    blogData,
    userData,
    onTouchHandler,
    onInputChangeHandler,
    onOfferSearchButtonClick,
    onAdSearchButtonClick,
    onTypeOfProductSearchButtonClick,
    onExpertClick,
    onInstituteClick,
    subCategoryData
  } = props;

  const  {hash} = useLocation()
  useEffect(() => {
    AOS.init();
      if(hash)
    {
      document.getElementById(hash.replace('#','')).scrollIntoView()
    }else
    {
    //window.scrollTo(0, 0);
    }

  }, [])

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
     centerMode: false,
    centerPadding: '20px',
//  initialSlide:2    
responsive: [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 2
    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
]  
};

const [openModal, setOpenModal] = useState(false);
const [blog, setBlog] = useState(null);

  // {t("PRINT.TITLE")}
  return (
    <>
    <section className="herosectionoffer categorysectiondis d-none d-sm-block">
     <img src={Investmentbanner} alt=""/>
     <Link to={'/AskTheExpert'} className="ctaonebutton">Ask the Experts</Link>
    </section>
      <section className="homecustom">
        {/* <section className="herosection backwhite">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6">
                <div className="heroleftpanel" data-aostesttesttest="fade-right">
                  <h2>Investment Solutions</h2>
                  <h6 className="h6_style">Looking for reliable options grow your portfolio at no cost?<br />
RIMIB connects you with top investment professionals in the industry.<br />
Ask from licensed investment experts today & get reliable answers. </h6>
                  <div className="heroleftbutton d-flex mt-4">
                    <Link to={'/AskTheExpert'} className="ctaonebutton"> Ask the expert</Link>

                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="herorightpanel" data-aostesttesttest="fade-left">
                  <img src={Consultingrafiki}  alt={''}/>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="homepageproductoffer mt-5 mb-5" id="latestofferinvestment">
          <div className="container">
            <h2 className="text-center mb-3" data-aostesttesttest="fade-top">{t("INVESTMENTCHILD.TEXT1")}</h2>
            <h6 className="h6_style text-center" data-aostesttesttest="fade-top">{t("INVESTMENTCHILD.TEXT2")}</h6>
            <h6 className="h6_style text-center">{t("INVESTMENTCHILD.TEXT3")}</h6>
            <h6 className="h6_style text-center">{t("INVESTMENTCHILD.TEXT4")}</h6>
            <div className="myexpertformarea searcharea searchmainara searchmainarainner martop40 mb-5 width100">
              <div className="input-container form-group">
                <select className="input form-control form-select" placeholder=" " name="productType" onChange={onInputChangeHandler}>
                <option value='0'>All</option>
               {subCategoryData?.map((item) => {
                                return (
                                  <>
                                    <option value={item.institutionCategoryId}>{item.name}</option>
                                  </>
                                )
                              })}
                </select>
                <div class="placeholder">Type</div>
              </div>
              <div className="input-container form-group">
                <input type="text" className="input form-control" placeholder=" " name='productName' onChange={onInputChangeHandler} onBlur={onTouchHandler} value={userData.productName}/>
                <div class="placeholder">Enter the product name you want to search for</div>
              </div>
              <div className="input-container form-group">
                <button type="submit" className="ctaonebutton" onClick={onOfferSearchButtonClick}>Search</button>
              </div>
            </div>
            <div className="row">
              {
                offerData?.length>0  ? offerData?.map(offer => {
                  return <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-5">
                    <div className="offer-insurence-inner" data-aostesttesttest="flip-left">
                      <div className="offer-image"><img src={`${NEW_URL}/${offer?.imageUrl}`} alt={' '} /></div>
                      <div className="offer-name mt-3 mb-3 disfontsmall">{offer?.offerName}</div>
                      <div className="posbuttontf d-flex">
                      <a 
                        href={offer?.externalUrl} target="_blank"
                        className="ctaonebutton text-center"
                        >
                        Apply Now                            </a>
                      <Link
                        to={{
                          pathname: "/detailedOffer",
                          state: { data: offer , frompage: 'latestofferinvestment' ,pagePath:'/investment' }
                        }}
                        className="ctatwobutton text-center ctanewtwobutton"
                        onClick={() => window.scrollTo(0, 0)}>
                        View Details
                            </Link>
                           
                        </div>
                    </div>
                  </div>
                }) :
                <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox}  alt={''}/>
                    </div>
                    <div className="dropdefaulttext">
                      <h5 className="opacity0">No Record Found !! </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </section>

        <section className="homepageproductoffer mt-5 mb-5">
          <div className="container">
            <h2 className="h2_style text-center">{t("INVESTMENTCHILD.TEXT5")}</h2>
            <h6 className="h6_style text-center fw-lighter" style={{margin:'0'}}>{t("INVESTMENTCHILD.TEXT6")} </h6>
            <h6 className="h6_style text-center"><b>{t("INVESTMENTCHILD.TEXT7")}</b></h6>
            <div className="myexpertformarea searcharea searchmainara searchmainarainner martop40 mb-5">
              <div className="input-container form-group width100border">
                <input type="text" className="input form-control" placeholder=" " name='typeOfProduct' onChange={onInputChangeHandler} onBlur={onTouchHandler} value={userData.typeOfProduct} />
                <div class="placeholder">Type of Product</div>
              </div>
              <div className="input-container form-group">
                <button type="submit" className="ctaonebutton" onClick={onTypeOfProductSearchButtonClick}>Search</button>
              </div>
            </div>
            <div className="row">
              {
                productData?.length>0 ? productData?.map(data => {
                  return <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-5">
                    <div className="offer-insurence-inner" data-aostesttesttest="flip-left">
                      <div className="offer-image"><img src={`${NEW_URL}/${data?.imageUrl}`}  alt={' '}/></div>
                      <div className="offer-name mt-3 mb-3">{data?.productName}</div>
                      {data?.externalLink && <button className="ctaonebutton mb-3 text-center" onClick={() => window.open(data?.externalLink,'_blank')}>Know More</button>}

                    </div>
                  </div>
                }):
                <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox}  alt={''} />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !! </h5>
                </div>
              </div>
              }
            </div>



            <h2 className="h2_style text-center">{t("INVESTMENTCHILD.TEXT8")}</h2>
            <h6 className="h6_style text-center fw-lighter">{t("INVESTMENTCHILD.TEXT9")}</h6>

            <div className="row">
            {
                instituteData?.length>0 ? instituteData?.map(institue=> {
                  return <div className="col-lg-4 col-sm-4 mt-5">
                  <div className="colinstititeinner" onClick={()=> onInstituteClick(institue)}>
                    <div className="colinstititeinnerimage text-center"><img src={`${NEW_URL}/${institue?.imageUrl}`} alt={' '} /></div>
                    <div className="colinstititeinnername text-center">{institue?.name}</div>
                  </div>
                </div>
                }):
                <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox}  alt={''}/>
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !! </h5>
                </div>
              </div>
              }
                </div>

          </div>

        </section>

        <section className="homepageproductoffer mt-5 mb-5">
          <div className="container">
            <h2 className="text-center mb-3" data-aostesttesttest="fade-top">{t("INVESTMENTCHILD.TEXT10")}</h2>
            <h6 className="h6_style text-center" style={{margin:'0'}}>{t("INVESTMENTCHILD.TEXT11")}</h6>
            <h6 className="h6_style text-center"><i>{t("INVESTMENTCHILD.TEXT12")}</i></h6>
            <div className="myexpertformarea searcharea searchmainara searchmainarainner martop40 mb-5">
              <div className="input-container form-group width100border">
                <select className="input form-control form-select" placeholder=" " name="adType" onChange={onInputChangeHandler}>
                <option value='all'>All</option>
                {subCategoryData?.map((item) => {
                                return (
                                  <>
                                    <option value={item.institutionCategoryId}>{item.name}</option>
                                  </>
                                )
                              })}
                </select>
                <div class="placeholder">Types</div>
              </div>
              <div className="input-container form-group">
                <button type="submit" className="ctaonebutton" onClick={onAdSearchButtonClick}>Search</button>
              </div>
            </div>
            <div className="row">
              {
                adData?.length>0 ? adData?.map(data => {
                  return <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-5">
                    <div className="offer-insurence-inner" data-aostesttesttest="flip-left">
                      <div className="offer-image"><img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '} /></div>
                      <div className="offer-name mt-3 mb-3">{data?.subInstitutionCategory?.name}</div>
                      {data?.externalLink &&  <button onClick={()=> {window.scrollTo(0,0); window.open(data.externalLink,'_blank')}} className="ctatwobutton mb-3 text-center">Know More</button>}
                    </div>
                  </div>


                }):
                <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5 className="opacity0">No Record Found !! </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </section>
        <section className="homepageproductoffer mt-5 mb-5">
          <div className="container">
            <h2 className="text-center" data-aostesttesttest="flip-left" data-aostesttesttest-easing="ease-out-cubic"
              data-aostesttesttest-duration="2000">{t("INVESTMENTCHILD.TEXT13")}</h2>
            <h5 className="text-center mt-3 h5_style" data-aostesttesttest="flip-left" data-aostesttesttest-easing="ease-out-cubic"
              data-aostesttesttest-duration="2000">{t("INVESTMENTCHILD.TEXT14")}<b>{t("INVESTMENTCHILD.TEXT15")}</b></h5>

<div className="p">
              
              { expertData?.length > 0 ? 
              
              <Slider {...settings}> 
                  {expertData?.map(data => {
                    return (                  
                      <div className="expertinnsearch" data-aostesttesttest="flip-right" data-aostesttesttest-duration="1000" onClick={()=> onExpertClick(data)}>
                        <div className="expertinnsearchimage"><img src={`${NEW_URL}/${data?.imageUrl}`}  alt={' '} /></div>
                        <div className="expertinnsearchname">{`${data.firstName} ${data.lastName}`}</div>
                      </div>                  
                    )
                  }) }
              </Slider>
              :
                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5 className="opacity0">No Record Found !! </h5>
                  </div>
                </div>
              }
          </div>
          </div>
        </section>


        <section className="homepageproductoffer">
          <div className="container">

            <div className="d-flex latestratesflex">
              <div data-aostesttesttest="fade-left">
                <h4 className="mt-3" data-aostesttesttest="flip-left" data-aostesttesttest-easing="ease-out-cubic"
                  data-aostesttesttest-duration="2000">{t("INVESTMENTCHILD.TEXT16")}</h4>
                <h4 className="" data-aostesttesttest="flip-left" data-aostesttesttest-easing="ease-out-cubic"
                  data-aostesttesttest-duration="2000"><i>{t("INVESTMENTCHILD.TEXT17")}</i></h4>
                <h4 className="" data-aostesttesttest="flip-left" data-aostesttesttest-easing="ease-out-cubic"
                  data-aostesttesttest-duration="2000">{t("INVESTMENTCHILD.TEXT18")}</h4>
              </div>
              <div className="" data-aostesttesttest="fade-right" data-aostesttesttest-anchor-placement="bottom-bottom"><Link to={'/AskTheExpert'} className="ctaonebutton"> Ask the experts</Link></div>
            </div>

          </div>
        </section>
        <section className="homepageproductoffer blogdetailsexpertppv padtop60 mb-5">
          <div className="container">
            <h2 className="text-center" data-aostesttesttest="flip-left" data-aostesttesttest-easing="ease-out-cubic"
              data-aostesttesttest-duration="2000">Top Investment Blogs</h2>
            <h5 className="text-center mt-3" data-aostesttesttest="fade-left" data-aostesttesttest-anchor-placement="bottom-bottom">{t("INVESTMENTCHILD.TEXT19")}</h5>
            <h5 className="text-center" data-aostesttesttest="fade-right" data-aostesttesttest-anchor-placement="bottom-bottom">{t("INVESTMENTCHILD.TEXT20")}</h5>
            <h5 className="text-center" data-aostesttesttest="fade-left" data-aostesttesttest-anchor-placement="bottom-bottom">{t("INVESTMENTCHILD.TEXT21")}</h5>
            <div className="row mt-5">

              {
                blogData?.body?.length > 0 ?
                  blogData?.body?.map(blog => {
                    return <div className="col-lg-4 col-sm-4">
                      <a onClick={() => {setBlog(blog); setOpenModal(true)}} >
                        <div className="bloginner mb-3">
                          <div className="bloginnerimage"><img src={blog?.image} alt={' '}  /></div>
                          <div className="bloginnertag mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                          <div className="bloginnertext mt-3">{blog?.title}</div>
                          <div className="bloginnerdate mt-3">{blog?.views} views  .  {blog?.date_publish}</div>
                        </div>
                      </a>
                    </div>
                  }) :

                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>You don't have any Blogs yet. </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </section>
        <Footer />

        {
                openModal && 
                <div className="modalspecial blog-modal">
                    <div>
                        <div className="mybtn">
                            <button type="button" className="myadbtn" onClick={() => { setOpenModal(false)}}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                        </div>
                        <div className="modal-header text-center">
                            <h3>{blog?.title}</h3>
                        </div>
                        <div className="modal-body text-center">
                            <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                            </div>
                            <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content}}></div>
                        </div>
                    </div>
                </div>

            }



      </section>
    </>

  );
};

export default InvestmentJsx;