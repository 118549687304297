import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import { uploadURL,NEW_URL } from '../../../constants/applicationConstants';
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProperWebsite } from '../../../shared/helper';
import Slider from "react-slick";
export const OfferSection = (props) => {
  const { t } = useTranslation();
  const { profileData, legalName } = props;


  var settings = {
    dots: true,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight:true,
    // initialSlide:2  
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerPadding: '0px',
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: '0px',
            slidesToScroll: 1
          }
        }
      ]  
  };
  var settingstwo = {
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight:true,
    // initialSlide:2  
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '0px',
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: '0px',
            slidesToScroll: 1
          }
        }
      ]  
  };

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  const onOfferClick = (offer) =>{
    if(offer?.externalUrl){
      window.open(getProperWebsite(offer.externalUrl),'_blank');
    }
  }

  return <section className="limitedoffer" id="ppvOfferSection">
    <div className="container">
      <div className="limitedofferinner ppvofferinnersection">
        <div className="heading">
          <h2>{t("PPVINSTITUTE.OFFERTITLE")}</h2>
          <h5 className="mt-2">{t("PPVINSTITUTE.OFFERSUBTITLE")} </h5>
          <div className="offerslider martop40 mb-5">
          <Slider {...settingstwo}>
                {
                  profileData?.instituteOffers?.map(offer => {
                    return <div className="sliderContent">
                     
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />
                         
                         
                        <div className="descriptionppvpop">
                         <h3>Description</h3> <h5> {offer.description}</h5>
                         <div className="disgrid grid-3 offerdspop">
                         <div className="ppvcat"><h3>Category</h3>{offer?.subInstitutionCategory?.name}</div>
                        <div className="ppvdate"><h3>Expires On</h3>{getProperData(offer?.validTo)}</div>
                        </div>
                        </div>
                    </div>
                  })
                }
              </Slider>

            {/* <AwesomeSlider>
              {
                profileData?.instituteOffers?.map(offer => {
                  return <div onClick={()=> onOfferClick(offer)}>
                    <img
                      className="d-block w-100"
                      src={`${NEW_URL}/${offer.imageUrl}`}
                      alt=" "
                    />
                    <h3>{offer.description}</h3>
                    <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className="ppvawesomesliderproducttype"><div>
                        {offer?.offerName}
                        </div>{offer?.description}</div>
                      <div className="ppvawesomesliderreleatedproduct">{offer?.subInstitutionCategory?.name}</div>
                      <div className="ppvawesomesliderproductoffer">{getProperData(offer?.validTo)}</div>
                    </div>
                  </div>
                })
              }
            </AwesomeSlider> */}
          </div>
                    <Link
                        to={{
                            pathname: "/listOfOffer",
                            state: { data: legalName, frompage: 'ppvOfferSection' ,pagePath:window.location.pathname }
                        }}
                        className="ctaonebutton widthhalf mt-100"
                        onClick={()=> window.scrollTo(0,0)}>
                        View All
                    </Link>
        </div>
      </div>
    </div>
  </section>
}
