import React, { useState, useEffect } from 'react'
import greentick from "../../../../assets/images/greentick.svg"
import { useTranslation } from "react-i18next";
import downarrow from "../../../../assets/images/expand_down_light.svg";
import { ToastContainer } from 'react-toastify';

const MyServicesJsx = (props) => {
  const {
    serviceData,
    onServiceSave
  } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    setData(serviceData);
  }, [serviceData])

  const onPlusButtonClick = (value, action, flag) => {
    let tempArray = [...data]
    tempArray.map((tempDT, index) => {
      if (tempDT.institutionCategoryId === value)
        tempArray[index] = { ...tempArray[index], [flag]: action }
    })
    setData(tempArray)
  }

  const onCheckboxClick = (parentID, childID) => {
    let tempArray = [...data]
    tempArray.map((tempDT) => {
      if (tempDT.institutionCategoryId === parentID) {
        tempDT.institutionServices.map((subDT, index) => {
          if (subDT.institutionServiceId === childID)
            tempDT.institutionServices[index] = { ...tempDT.institutionServices[index], isSelected: !tempDT.institutionServices[index].isSelected }
        })
      }

    })
    setData(tempArray)
  }

  const onSaveServiceButtonClick = (data) =>{
    const selectedData = data.institutionServices?.filter(service => service.isSelected).map(data=> data.institutionServiceId)
    onServiceSave(selectedData,data.institutionCategoryId);
    onPlusButtonClick(data.institutionCategoryId, false, 'isOpen');
    onPlusButtonClick(data.institutionCategoryId, false, 'isEditable');
  }

  const { t } = useTranslation();

  return (
    <>
      <section className="">

        <div className="">
          <div className="dashboardrightpanel martop40">
            <section classNames="addexpertformsec">
              <div className="myadssection">
                <h2 className="h2_style martop30">{t("MYSERVICE.TITLE")}</h2>
                <p>{t("MYSERVICE.DESCRIPTION")} </p>
              </div>
              <div className="customaccordian">
                <ul>
                  {
                    data?.map(data1 => {
                      return <li>
                        <h3 className="h3_style text-center">
                          {data1.name}
                        </h3>
                        {
                          data1.isOpen ? <img src={downarrow} className="downminus" alt={' '} onClick={() => onPlusButtonClick(data1.institutionCategoryId, false, 'isOpen')} />
                            : <img src={downarrow} className="downminus"  alt={''} onClick={() => onPlusButtonClick(data1.institutionCategoryId, true, 'isOpen')} />
                        }
                        {data1.isOpen && data1.institutionServices.length > 0 && <><table className="myserviceinner">
                          <tr>
                            <th>{t("MYSERVICE.PARTICULARS")} </th>
                            <th></th>
                          </tr>
                          {
                            data1.institutionServices?.map(service => {
                              return <>
                                {
                                  data1.isEditable ?
                                    <tr>
                                      <td>{service.name}</td>
                                      <td>
                                        {
                                          data1.isEditable ?
                                           <label className="container-checkbox"> <input type="checkbox" className="form-check-input" id="servicetextbox" checked={service.isSelected} onClick={() => onCheckboxClick(data1.institutionCategoryId, service.institutionServiceId)} /><span className="checkmark"></span></label>
                                            :
                                            <img src={service.isSelected ? greentick : ''}  alt={''}/>
                                        }
                                      </td>
                                    </tr> :
                                    service.isSelected ?
                                      <tr>
                                        <td>{service.name}</td>
                                        <td>
                                          {
                                            <img src={greentick} alt={''} />
                                          }
                                        </td>
                                      </tr> : null
                                }
                              </>
                            })
                          }
                        </table>
                          {
                            data1.isEditable ?
                              <button type="button" className="ctaonebutton mt-5" onClick={() => onSaveServiceButtonClick(data1)}>{t("MYSERVICE.SAVE")} </button>
                              : <button type="button" className="ctaonebutton mt-5" onClick={() => onPlusButtonClick(data1.institutionCategoryId, true, 'isEditable')}>{t("MYSERVICE.ADD")} </button>
                          }
                        </>
                        }
                      </li>
                    })
                  }
                </ul>
              </div>
            </section>
          </div>
        </div>

      </section>
      <ToastContainer />

    </>

  );
};

export default MyServicesJsx;
