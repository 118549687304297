import React from 'react'
import { Component } from 'react';
import ExpertQnaAnswerJsx from './expertQnaAnswer.jsx'

export default class ExpertQnaAnswer extends Component{
    render(){
        return(
            <div>
                 <ExpertQnaAnswerJsx />
            </div>
        )
    }
}