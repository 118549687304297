import React from 'react'
import { useEffect } from 'react';
import faqimage from '../../../../assets/images/faqimage.PNG'
import subcaticon from "../../../../assets/images/subcaticon.svg"
import { Footer } from '../../../common/footer/footer';
import { getIconOne } from '../askTheExpert.jsx';

const ConfirmAskTheExpertJsx = (props) => {
  const {
    category,
    subCategory,
    subCategoryData,
    question,
    onEditButtonClick,
    onConfirmAndSubmitButtonClick
  } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <section className="herosection heroasktheexpert heroconfirmexpert">

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">
              <div className="categoryaskecpertconfirm">
                <div className="categoryaskecpertconfirminner"><img src={getIconOne(category)}  alt={' '} />
                  <h6>{category}</h6></div>
                <h5 className="mt-3">Category</h5>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="faqname">
                <img src={faqimage}  alt={''} />
              </div>
              <h5 className="h5_style text-center fst-italic">{question}</h5>
              <div className="herobutton mt-4 d-flex"><button type="button" className="ctatwobutton" onClick={onEditButtonClick}>Edit</button><button type="button" className="ctaonebutton" onClick={onConfirmAndSubmitButtonClick}>Confirm and Submit</button></div>
            </div>
            <div className="col-lg-3 col-sm-3">
            {subCategory &&
              <div className="categoryaskecpertconfirmsub">
                <div className="categoryaskecpertconfirminner subcatchal"><img src={`/${(subCategory).replace(/\s/g,'')}.png`}  alt={''}/>
                  <h6>{subCategory}</h6></div>
                <h5 className="mt-3">Sub-category</h5>
              </div>
            }
            </div>
          </div>
        </div>

      </section>
      <Footer />

    </>

  );
};

export default ConfirmAskTheExpertJsx;