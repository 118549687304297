import axios from "axios";
import React, { Component } from "react";
import { baseURL, uploadURL } from "../../../../constants/applicationConstants.js";
import { nameRegex, websiteRegex } from "../../../../shared/helper.js";
import { Loader } from "../../../common/loader/loader.jsx";
import ProductsJsx from "./products.jsx";
import swal from "sweetalert";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideAdd: false,
      productsDetail: null,
      subCategoryData: null,
      productTypes: null,
      categoryData: null,
      productFormData: {
        category: "",
        subCategory: "",
        product: "",
        description: "",
        benefits: "",
        features: "",
        interestRate: "",
        metaTags: [],
        externalLink: "",
        productTypeId: null,


      },
      productCount: null,
      imageUrl: "",
      formSubmitButtonDisable: true,
      formError: {},
      isProcessing: false,
      fileInfo: null,
      profileImagePreviewUrl: null,
      editMode: false,
      addProdScreen: false

    }
  }

  // onFileChange = (e) => {
  //   this.setState({ fileInfo: e.target.files[0] })
  //   this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })


  // }


// file validation
 validFileName = (name) => name.includes('.pdf') || name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png')

 onFileChange = (e) => {
  const name = e.target.name;
  if (e.target.files[0]?.size) {
      if (e.target.files[0]?.size < 512000) {
          if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
            this.setState({ fileInfo: e.target.files[0] })
            this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
          }
          else {
              toast.error('.JPG/.JPEG/.PDF format required')
          }
      }
      else {
          toast.error('File should be less than 500 kb')
      }
  }
}



// end //////////////




  _handleUploadImage = () => {
    this.setState({
      isLoading: true
    })
    let formData1 = new FormData();

    if (this.state.fileInfo) {
      formData1.append('image', this.state.fileInfo)
      formData1.append('imageCategory', "institute-product")
      axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {

        if (resp.status === 200) {
          localStorage.setItem("productImg", resp.data.filename)
          toast.success("Product Image uploaded successfully");
          // getIMAGEuRL(resp.data.filename)
          // setIsLoading(false)
          // window.location.reload()
          setTimeout(() => {
            this.onSubmitButtonclick()
          }, 2000)
        } else {
          //alert("error")
          // setIsLoading(false)
        }
      }).catch(() => {
        //   setIsLoading(false)
      })
    }

    else this.onSubmitButtonclick();
  };

  componentDidMount = () => {
    this.getAllProducts();
    this.getAllCatAndSubCat();
    let token = JSON.parse(sessionStorage.getItem('token'));
    if (!token) {
      this.props.history.push("/");
    }
    //  this.getParticularProducts();
  }

  getAllProducts = () => {
    this.setState({ isLoading: true })

    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    let token = JSON.parse(sessionStorage.getItem('token'));

    axios.get(`${baseURL}/institute/${instituteId}/products`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ productsDetail: res.data.data.categories, productCount: res.data.data.productsCount })
      this.setState({ isLoading: false })

    }).catch(err=>{
      this.setState({ isLoading: false })
    })
  }

  getParticularProducts = (id) => {
    sessionStorage.setItem("editProductId", id)
    this.setState({ isLoading: true })
    this.setState({ hideAdd: true })
    this.setState({ editMode: true })
    let token = JSON.parse(sessionStorage.getItem('token'));

    axios.get(`${baseURL}/institutionProduct/${id}`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      let result = res.data.data

      this.setState({
        productFormData: {
          category: result.institutionCategoryId,
          subCategory: result.institutionSubCategoryId,
          product: result.productName,
          description: result.description,
          benefits: result.benefits,
          features: result.features,
          interestRate: result.interestRate,
          metaTags: result.metaTags,
          externalLink: result.externalLink,
          productTypeId: result?.productType?.productTypeId ?? null,
        }
      })
      // this.setState({ profileImagePreviewUrl: result.imageUrl })
      this.setState({ imageUrl: result.imageUrl })
      const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == result.institutionCategoryId)
      this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
      this.setState({ productTypes: [result.productType] })

      // this.setState({ productsDetail: res.data.data })
      this.setState({ isLoading: false })


    })
  }

  getAllCatAndSubCat = () => {
    this.setState({ isLoading: true })
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    let token = JSON.parse(sessionStorage.getItem('token'));
    axios.get(`${baseURL}/institutionCategoriesAndSubCategories?instituteId=${instituteId}`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ categoryData: res.data.data })
      this.setState({ isLoading: false })

    }).catch(err=>{
      this.setState({ isLoading: false })
    })
  }

  getSubCategories = (value) => {

    const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)
    this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
  }

  onMetaTagChange = (e) => {
    this.setState(
      {
        productFormData: {
          ...this.state.productFormData,
          'metaTags': e,
        },

      }
    )
  }
  // changeHideState = (action) => {
  //   this.setState({ hideAdd: action })
  //   sessionStorage.removeItem("editProductId")

  // }
  changeHideState = (action) => {
    this.setState({
      hideAdd: action,
      editMode: action,
    }, () => {
      action && window.scrollTo({
        top: 250,
        left: 100,
        behavior: 'smooth'
      })
    })
    sessionStorage.removeItem("editProductId")
    if (action == false) {
      this.setState({
        productFormData: {
          category: "",
          subCategory: "",
          product: "",
          description: "",
          benefits: "",
          features: "",
          interestRate: "",
          metaTags: [],
          externalLink: "",
          productTypeId: null,

        },
      })
      this.setState({ profileImagePreviewUrl: null })
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  }
  getIMAGEuRL = (value) => {
    // this.setState(
    //   {
    //     productFormData: {
    //       imageUrl: value
    //     }
    //   })
    this.setState({ imageUrl: value })

  }

  onInputChangeHandler = (e) => {

    let token = JSON.parse(sessionStorage.getItem('token'));

    let name = e.target.name;
    let value = e.target.value;
    if (name === "category") {
      this.getSubCategories(value)
      this.setState({
        productFormData: {

          productTypeId: null,

        },
      })
    }
    if (name === "subCategory") {
      this.setState({ isLoading: true })
      axios.get(`${baseURL}/productTypes?institutionSubCategoryId=${value}`, {
        headers: { 'x-access-token': token }
      }).then(res => {
        this.setState({ productTypes: res.data.data })
        this.setState({ isLoading: false })

      }).catch(err=>{
        this.setState({ isLoading: false })
      })
    }
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            productFormData: {
              ...this.state.productFormData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            productFormData: {
              ...this.state.productFormData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          productFormData: {
            ...this.state.productFormData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };

  isFromSubmissionBlock = () => {
    let { product, description, benefits, features, category, subCategory, externalLink } = this.state.productFormData;
    const { profileImagePreviewUrl } = this.state;

    if (product && description && benefits && features && category && subCategory && profileImagePreviewUrl) {
      if (
        !this.inputRegexValidation("product", product) ||
        !(externalLink ? this.inputRegexValidation("externalLink", externalLink) : true)
        // ||
        // !this.inputRegexValidation("description", description)
        // ||
        // !this.inputRegexValidation("benefits", benefits)
        // ||
        // !this.inputRegexValidation("features", features)
      ) {
        this.setState({
          formSubmitButtonDisable: true,
        });
      } else {
        this.setState({
          formSubmitButtonDisable: false,
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };
  onSubmitButtonclick = () => {
    this.setState({
      isLoading: true
    })
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    let image = localStorage.getItem("productImg")
    let data = {
      institutionCategoryId: this.state.productFormData.category,
      institutionSubCategoryId: this.state.productFormData.subCategory,
      productName: this.state.productFormData.product,
      description: this.state.productFormData.description,
      benefits: this.state.productFormData.benefits,
      features: this.state.productFormData.features,
      interestRate: this.state.productFormData.interestRate,
      metaTags: this.state.productFormData.metaTags,
      externalLink: this.state.productFormData.externalLink,
      productTypeId: this.state.productFormData.productTypeId,
      imageUrl: image
    }
    if(!image){
     delete data.imageUrl
    }
    // axios.post(`${baseURL}/institute/${instituteId}/product`, this.state.productFormData).then(res => {
    //   this.setState({
    //     isLoading: false
    //   })
    // })
    let token = JSON.parse(sessionStorage.getItem('token'));
    let editModId = JSON.parse(sessionStorage.getItem('editProductId'));
    {
      !editModId ?
        axios.post(`${baseURL}/institute/${instituteId}/product`, data, {
          headers: { 'x-access-token': token }
        }).then((res) => {

          // swal({
          //   title: "success",
          //   text: "product added successfully",
          //   icon: "success",
          // });
          toast.success("Product added successfully");
          this.getAllProducts();

          this.setState({
            productFormData: {
              category: "",
              subCategory: "",
              product: "",
              description: "",
              benefits: "",
              features: "",
              interestRate: "",
              metaTags: [],
              externalLink: "",
              productTypeId: null,

            },
            
          })
          this.setState({ profileImagePreviewUrl: null,fileInfo:null,AdsImg:"",image: "" ,imageUrl: ""})
          localStorage.removeItem("productImg");

          this.setState({
            isLoading: false
          })
          this.setState({ hideAdd: false })

        }).catch((res) => {
          swal({
            title: "error",
            text: res?.response.data.error.message,
            icon: "error",
          });
          this.setState({
            isLoading: false
          })

        })
        :
        axios.put(`${baseURL}/institute/${instituteId}/institutionProduct/${editModId}`, data, {
          headers: { 'x-access-token': token }
        }).then((res) => {

          // swal({
          //   title: "success",
          //   text: "product added successfully",
          //   icon: "success",
          // });
          toast.success("Product updated successfully");
          this.getAllProducts();
          this.setState({
            productFormData: {
              category: "",
              subCategory: "",
              product: "",
              description: "",
              benefits: "",
              features: "",
              interestRate: "",
              metaTags: [],
              externalLink: "",
              productTypeId: null,

            },
          })
          this.setState({
            isLoading: false
          })
          // this.setState({ hideAdd: false })
          this.setState({ hideAdd: false })
          this.setState({ profileImagePreviewUrl: null,fileInfo:null,AdsImg:"",image: "" ,imageUrl: ""})
          localStorage.removeItem("productImg");
          sessionStorage.removeItem("editProductId")

        }).catch((res) => {
          // swal({
          //   title: "error",
          //   text: res?.response.data.error.message,
          //   icon: "error",
          // });
          toast.error(res?.response.data.error.details[0].msg + " " + res?.response.data.error.details[0].param);
          this.setState({
            isLoading: false
          })

        })

    }


  }

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };
  inputRegexValidation = (name, value) => {

    if (name === "product") {
      return nameRegex.test(value);
    }
    else if (name === "externalLink") {
      return websiteRegex.test(value);
    }
    // else if (name === "feature") {
    //   return nameRegex.test(value);
    // }
    // else if (name === "description") {
    //   return nameRegex.test(value);
    // }
    else {
      return nameRegex.test(value)
    }
  };



  onDateChange = (name, date) => {
    this.setState(
      {
        productFormData: {
          ...this.state.productFormData,
          [name]: date,
        }
      }
    );
  }

  render() {
    return (
      <div>
        <ProductsJsx
          productFormData={this.state.productFormData}
          formError={this.state.formError}
          onDateChange={this.onDateChange}
          onInputChangeHandler={this.onInputChangeHandler}
          onRadioButtonClick={this.onRadioButtonClick}
          showAddModal={this.state.showAddModal}
          productCount={this.state.productCount}
          onModalClose={this.onModalClose}
          onSubmitButtonclick={this.onSubmitButtonclick}
          getProducts={this.getAllProducts}
          editMode={this.state.editMode}
          getParticularProducts={this.getParticularProducts}
          onTouchHandler={this.onTouchHandler}
          onMetaTagChange={this.onMetaTagChange}
          addProdScreen={this.state.addProdScreen}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          hideAdd={this.state.hideAdd}
          changeHideState={this.changeHideState}
          produtsList={this.state.productsDetail}
          productTypes={this.state.productTypes}
          categoryData={this.state.categoryData}
          subCategoryData={this.state.subCategoryData}
          getIMAGEuRL={this.getIMAGEuRL}
          onFileChange={this.onFileChange}
          _handleUploadImage={this._handleUploadImage}
          imgURL={this.state.imageUrl}
          profileImagePreviewUrl={this.state.profileImagePreviewUrl}
          onTabClick={this.props.onTabClick}
        />
        {this.state.isLoading && <Loader />}

      </div>
    );
  }
}

export default Products;
