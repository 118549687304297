import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Footer } from '../../../common/footer/footer';

export const FinalAsktheExpertScreenJSX = (props) => {
    const { t } = useTranslation();
    const {onHomebuttonClick} = props;
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    return (
        <>
            <div style={{ marginBottom: '50px' }}>
            <section className="herosection heroasktheexpert heroconfirmexpert">
                <div className="container">
                    <div className="confirmtion">
                        <h2 className="text-center h2_style">{t("FINALASKTHEEXPERT.HEADING")}</h2>
                        <h5 className="text-center h5_style fst-italic">{t("FINALASKTHEEXPERT.SUBHEADING")}</h5>
                        <h5 className="text-center h5_style fst-italic">{t("FINALASKTHEEXPERT.TEXT1")}</h5>
                        <h5 className="text-center h5_style fst-italic">{t("FINALASKTHEEXPERT.TEXT2")}</h5>
                        <div className="herobutton mt-4 d-flex"><button type="button" className="ctatwobutton" onClick={onHomebuttonClick}>Home</button><button type="button" className="ctaonebutton" onClick={onHomebuttonClick}>Ask Another Query</button></div>
                    </div>
                </div>
                <div className="waveWrapper waveAnimation">
                    <div className="waveWrapperInner bgTop">
                        <div className="wave waveBottom" ></div>
                    </div>
                    <div className="waveWrapperInner bgMiddle">
                        <div className="wave waveMiddle" ></div>
                    </div>

                </div>
                </section>
            </div>

            <Footer />

        </>
    )
}