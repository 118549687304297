import React from 'react'
import leftarrow from "../../../../assets/images/left-arrow.svg";
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import approvecard from "../../../../assets/images/approvecard.svg";
import {decrypt} from '../../../../shared/helper';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const PreviewCardView = (props) => {
  const {
    cardDetails,
    onselectTypeButtonClick,
    option,formSubmitHandler
  } = props;
  const { t } = useTranslation();
  const history = useHistory();

    return (
        <>
           <section className="loginscreeninstitute registrationinstitute passw">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
                <div className="back_btn"  onClick={() => history.goBack()}><img src={leftarrow} alt={'icon'} /></div>
                <div className="detailsleft plaindetailsleft">
                  <h1>Make Payment</h1>
                  
                </div>
              </div>
              <div className="passtext martop60">
                    <h5 className="h5_style martop60 text-center">{t("PREVIEWCARD.TITLE")} </h5>
                    <h5 className="h5_style text-center">{t("PREVIEWCARD.TAGLINE")} <span className="pcardtext">{t("PREVIEWCARD.TAGLINEAFTER")}</span></h5>
                  </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext martop60 paddingbot60">
                <div className="planbuttonouter">
                  <h2 className="h2_style">{t("PREVIEWCARD.TITLETWO")}</h2>
                  
                </div>
                <div className="signout">
                  <img src={Sign_out_squre} alt={'icon'} />
                  <span className="title">Logout</span>
                </div>
                
                <div className={`previewcard martop60 ${option === 'selected' ? 'card-selected' : ''}`}  onClick={() => onselectTypeButtonClick('selected')}>
                    <div className="previewcardinner mb-4">
                        <div className="approved"><img src={approvecard} alt={'icon'} /></div>
                        <div className="namecardholder">{cardDetails.name}</div>
                        <div className="cardnumber"><div>{decrypt(cardDetails.card).replace(/[^a-z0-9]+/gi, '').replace(/(.{4})/g, '$1 ')}</div><div className="cardde"></div></div>
                        <div className="carddate">{cardDetails.month}/{cardDetails.year}</div>
                    </div>
                  <button type="submit" disabled={option === 'de-select'} onClick={formSubmitHandler}
                  //  className="ctaonebutton mt-4"
                   className={
                    option === 'de-select'
                      ? "btn-secondary btn ctatwobutton"
                      : "mt-3 ctaonebutton"
                  }
                   
                   >Complete Payment</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    
        </>
    )
}


export default PreviewCardView;