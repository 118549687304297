import React from 'react'
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import { Footer } from '../../common/footer/footer';
import offerbanner from "../../../assets/images/offerbanner.svg";
import offerbannermobile from "../../../assets/images/Top_Offer_Mobile_banner.JPG";
import Slider from "react-slick";
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { uploadURL,NEW_URL } from '../../../constants/applicationConstants';
import { getProperWebsite } from '../../../shared/helper';

const OffersJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    onSubmitButtonClick,
    formData,
    onInputChange,
    formError,
    investmentOfferData,
    mortgageOfferData,
    insuranceOfferData,
    bankingOfferData,
    realestateOfferData,
    topOffers
  } = props;

  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };
  var settingstwo = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  const onOfferClick = (offer) => {
    if (offer?.externalUrl) {
      window.open(getProperWebsite(offer.externalUrl), '_blank');
    }
  }

  return (
    <>
      <section className="herosectionoffer d-none d-sm-block">
        <img src={offerbanner} alt="" />
        <div className="container">
          <div className="offerbannerslider">
            <Slider {...settingstwo}>
              {
                topOffers?.map(offer => {
                  return <div className="sliderContent">

                    <a href={offer.externalUrl ?? '#'} target="_blank">
                      <img
                        className="d-block w-100"
                        src={`${NEW_URL}/${offer.imageUrl}`}
                        alt=" "
                      />
                    </a>
                    <h5>{offer.offerName}</h5>
                  </div>
                })
              }
            </Slider>
          </div>
        </div>
      </section>
      <section className="herosectionoffer d-block d-sm-none">
        <Link to={'/askTheExpert'}> <img src={offerbannermobile} alt="" /></Link>
      </section>
      <section className="newserachfield">
        <div className="container">
          <div className="myexpertformarea searcharea martop40">
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="category"
                onChange={onInputChange}
                // onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {categoryData?.map((item) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Category</div>
            </div>
            <div className="input-container form-group">
            <select
                className="input form-control form-select"
                placeholder=" "
                name="subCategory"
                onChange={onInputChange}
                value={formData.subCategory}

                // onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {subCategoryData?.map((item, i) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Sub Category</div>
            </div>
            <div className="input-container form-group">
              <button type="submit" className="ctaonebutton"  onClick={() => onSubmitButtonClick()}>Search Offers</button>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="herosection">
        <div className="container">
          <h2 className="text-center">{t("TOPOFFERS.OFFERSTITLE")} </h2>
          <h5 className="text-center">{t("TOPOFFERS.OFFERSSUBTITLE")}   </h5>
          <h5 className="text-center mb-5">{t("TOPOFFERS.OFFERSSUBTITLE1")} </h5>
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="heroleftpanel" data-aos="fade-right">

                <h6 className="mt-5"><i>{t("TOPOFFERS.OFFERSSUBSUBTITLE")}</i> {t("TOPOFFERS.OFFERSSUBSUBTITLE1")}<br></br></h6>
                <h6>{t("TOPOFFERS.OFFERSSUBSUBTITLE2")}</h6>
                <div className="heroleftbutton d-flex mt-4">
                  <Link to={'/AskTheExpert'} className="ctaonebutton"> Ask the Expert</Link>

                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="herorightpanel" data-aos="fade-left">
                <img src={Consultingrafiki} alt={' '}/>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* top offers */}
      {/* <section className="limitedoffer mt-5">
        <div className="container">
          <div className="limitedofferinner">
            <div className="heading">
              <h2>Top Offers</h2>
              <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE1")} </h5>
            </div>
            <div className="offerslider martop40 mb-5">
            {/* {JSON.stringify(realestateOfferData)} 
              <Slider {...settings}>
                {
                  realestateOfferData?.map(offer => {
                    return <div className="sliderContent">
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />
                          <div class="mobile_description">
                            <div className="d-flex justify-content-between">
                              <h3>Description</h3>
                              <h3>Expires On:<span> {getProperData(offer?.validTo)}</span></h3>
                            </div>
                            <p>{offer.description}</p>
                            <button onClick={()=> onOfferClick(offer)} className="ctaonebutton mobilebutton">Know More</button>
                            </div>
                          
                          <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="ppvawesomesliderproducttype"><div>
                            <h4>Description</h4> <div className="typepop">{offer?.offerName}</div>
                            </div><div className="of-div">{offer?.description}</div></div>
                            <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                            <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                          </div>
                          <button onClick={()=> onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                    </div>
                  })
                }
              </Slider>
              {/* <Link
                        to={{
                            pathname: "/listOfOffer",
                            state: { data: legalName }
                        }}
                        className="ctaonebutton widthhalf mt-100"
                        onClick={()=> window.scrollTo(0,0)}>
                        View All
                    </Link> 
            </div>
          </div>
        </div>
      </section> */}

      {/* Real estate offers */}

      <section className="limitedoffer mt-5">
        <div className="container">
          <div className="limitedofferinner">
            <div className="heading">
              <h2>{t("TOPOFFERS.OFFERTITLE1")}</h2>
              {/* <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE1")} </h5> */}
            </div>
            <div className="offerslider martop40 mb-5">

              <Slider {...settings}>
                {
                  investmentOfferData?.map(offer => {
                    return <div className="sliderContent">

                      <img
                        className="d-block w-100"
                        src={`${NEW_URL}/${offer.imageUrl}`}
                        alt=" "
                      />

                      <div className="descriptionppvpop">
                        <h3>Description</h3>
                        <h5> {offer?.description}</h5>
                        <div className="offerdspop disgrid grid-3">
                          <div className="ppvcat">
                            <h3>Offer Name</h3>
                            {offer?.offerName}
                          </div>
                          <div className="ppvcat">
                            <h3>Category </h3>
                            {offer?.subInstitutionCategory?.name}
                          </div>

                          <div className="ppvdate">
                            <h3>Expires On</h3>
                            {getProperData(offer?.validTo)}
                          </div>
                        </div>
                      </div>
                      {/* <div className="mobile_description">
                            <div className="d-flex justify-content-between">
                              <h3>Description</h3>
                              <h3>Expires On:<span> {getProperData(offer?.validTo)}</span></h3>
                            </div>
                            <p>{offer.description}</p>
                            <button onClick={()=> onOfferClick(offer)} className="ctaonebutton mobilebutton">Know More</button>
                            </div>
                      <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between' , alignItems: 'center' }}>
                        <div className="ppvawesomesliderproducttype"><div>
                         <h4>Description</h4> <div className="typepop"> {offer?.offerName}</div>
                        </div><div className="of-div">{offer?.description}</div></div>
                        <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                        <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                      </div> */}
                      <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                    </div>
                  })
                }
              </Slider>
              {/* <Link
                        to={{
                            pathname: "/listOfOffer",
                            state: { data: legalName }
                        }}
                        className="ctaonebutton widthhalf mt-100"
                        onClick={()=> window.scrollTo(0,0)}>
                        View All
                    </Link> */}
            </div>
          </div>
        </div>
      </section>
      <section className="limitedoffer mt-5">
        <div className="container">
          <div className="limitedofferinner">
            <div className="heading">
              <h2>{t("TOPOFFERS.OFFERTITLE2")}</h2>
              {/* <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE2")} </h5> */}
            </div>
            <div className="offerslider martop40 mb-5">

              <Slider {...settings}>
                {
                  mortgageOfferData?.map(offer => {
                    return <div className="sliderContent">

                      <img
                        className="d-block w-100"
                        src={`${NEW_URL}/${offer.imageUrl}`}
                        alt=" "
                      />
                      <div className="descriptionppvpop">
                        <h3>Description</h3>
                        <h5> {offer?.description}</h5>
                        <div className="offerdspop disgrid grid-3">
                          <div className="ppvcat">
                            <h3>Offer Name</h3>
                            {offer?.offerName}
                          </div>
                          <div className="ppvcat">
                            <h3>Category </h3>
                            {offer?.subInstitutionCategory?.name}
                          </div>

                          <div className="ppvdate">
                            <h3>Expires On</h3>
                            {getProperData(offer?.validTo)}
                          </div>
                        </div>
                      </div>
                      {/* <div class="mobile_description">
                            <div className="d-flex justify-content-between">
                              <h3>Description</h3>
                              <h3>Expires On:<span> {getProperData(offer?.validTo)}</span></h3>
                            </div>
                            <p>{offer.description}</p>
                            <button onClick={()=> onOfferClick(offer)} className="ctaonebutton mobilebutton">Know More</button>
                            </div>
                      <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between' , alignItems: 'center' }}>
                        <div className="ppvawesomesliderproducttype"><div>
                        <h4>Description</h4> <div className="typepop"> {offer?.offerName}</div>
                        </div><div className="of-div">{offer?.description}</div></div>
                        <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                        <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                      </div> */}
                      <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                    </div>
                  })
                }
              </Slider>
              {/* <Link
                        to={{
                            pathname: "/listOfOffer",
                            state: { data: legalName }
                        }}
                        className="ctaonebutton widthhalf mt-100"
                        onClick={()=> window.scrollTo(0,0)}>
                        View All
                    </Link> */}
            </div>
          </div>
        </div>
      </section>
      <section className="limitedoffer mt-5">
        <div className="container">
          <div className="limitedofferinner">
            <div className="heading">
              <h2>{t("TOPOFFERS.OFFERTITLE3")}</h2>
              {/* <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE3")}</h5> */}
            </div>
            <div className="offerslider martop40 mb-5">

              <Slider {...settings}>
                {
                  insuranceOfferData?.map(offer => {
                    return <div className="sliderContent">

                      <img
                        className="d-block w-100"
                        src={`${NEW_URL}/${offer.imageUrl}`}
                        alt=" "
                      />

                      <div className="descriptionppvpop">
                        <h3>Description</h3>
                        <h5> {offer?.description}</h5>
                        <div className="offerdspop disgrid grid-3">
                          <div className="ppvcat">
                            <h3>Offer Name</h3>
                            {offer?.offerName}
                          </div>
                          <div className="ppvcat">
                            <h3>Category </h3>
                            {offer?.subInstitutionCategory?.name}
                          </div>

                          <div className="ppvdate">
                            <h3>Expires On</h3>
                            {getProperData(offer?.validTo)}
                          </div>
                        </div>
                      </div>
                      {/* <div class="mobile_description">
                            <div className="d-flex justify-content-between">
                              <h3>Description</h3>
                              <h3>Expires On:<span> {getProperData(offer?.validTo)}</span></h3>
                            </div>
                            <p>{offer.description}</p>
                            <button onClick={()=> onOfferClick(offer)} className="ctaonebutton mobilebutton">Know More</button>
                            </div>
                      <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between' ,  alignItems: 'center'}}>
                        <div className="ppvawesomesliderproducttype"><div>
                        <h4>Description</h4>  <div className="typepop"> {offer?.offerName}</div>
                        </div><div className="of-div">{offer?.description}</div></div>
                        <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                        <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                      </div> */}
                      <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                    </div>
                  })
                }
              </Slider>
              {/* <Link
                        to={{
                            pathname: "/listOfOffer",
                            state: { data: legalName }
                        }}
                        className="ctaonebutton widthhalf mt-100"
                        onClick={()=> window.scrollTo(0,0)}>
                        View All
                    </Link> */}
            </div>
          </div>
        </div>
      </section>
      <section className="limitedoffer mt-5">
        <div className="container">
          <div className="limitedofferinner">
            <div className="heading">
              <h2>{t("TOPOFFERS.OFFERTITLE4")}</h2>
              {/* <h5 className="mt-2">{t("TOPOFFERS.OFFERSUBTITLE4")} </h5> */}
            </div>
            <div className="offerslider martop40 mb-5">

              <Slider {...settings}>
                {
                  bankingOfferData?.map(offer => {
                    return <div className="sliderContent">

                      <img
                        className="d-block w-100"
                        src={`${NEW_URL}/${offer.imageUrl}`}
                        alt=" "
                      />

                      <div className="descriptionppvpop">
                        <h3>Description</h3>
                        <h5> {offer?.description}</h5>
                        <div className="offerdspop disgrid grid-3">
                          <div className="ppvcat">
                            <h3>Offer Name</h3>
                            {offer?.offerName}
                          </div>
                          <div className="ppvcat">
                            <h3>Category </h3>
                            {offer?.subInstitutionCategory?.name}
                          </div>

                          <div className="ppvdate">
                            <h3>Expires On</h3>
                            {getProperData(offer?.validTo)}
                          </div>
                        </div>

                      </div>
                      {/* <div class="mobile_description">
                            <div className="d-flex justify-content-between">
                              <h3>Description</h3>
                              <h3>Expires On:<span> {getProperData(offer?.validTo)}</span></h3>
                            </div>
                            <p>{offer.description}</p>
                            <button onClick={()=> onOfferClick(offer)} className="ctaonebutton mobilebutton">Know More</button>
                            </div>
                      <div className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between' ,  alignItems: 'center'}}>
                        <div className="ppvawesomesliderproducttype"><div>
                        <h4>Description</h4>  <div className="typepop"> {offer?.offerName}</div>
                        </div><div className="of-div">{offer?.description}</div></div>
                        <div className="ppvawesomesliderreleatedproduct"><h4>Category</h4>{offer?.subInstitutionCategory?.name}</div>
                        <div className="ppvawesomesliderproductoffer"><h4>Date</h4>{getProperData(offer?.validTo)}</div>
                      </div> */}
                      <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf">Know More</button>
                    </div>
                  })
                }
              </Slider>
              {/* <Link
                        to={{
                            pathname: "/listOfOffer",
                            state: { data: legalName }
                        }}
                        className="ctaonebutton widthhalf mt-100"
                        onClick={()=> window.scrollTo(0,0)}>
                        View All
                    </Link> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>

  );
};

export default OffersJsx;
