import React,{Component} from 'react';
import NavBar from './common/header/header.jsx'

export class LayoutContainer extends Component{
    render(){
        return(
            <>
                <NavBar />
                <div>
                    {this.props.children}
                </div>
            </>
        )
    }
}