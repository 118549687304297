import React from 'react'
import { useHistory } from "react-router-dom";
import leftarrow from "../../../../assets/images/left-arrow.svg";
import fileuploadpopup from "../../../../assets/images/fileuploadpopup.svg";
import ViewSpecialInfo from "./viewSpecialInfo.jsx";

const SpecialInformationsJsx = (props) => {
  const history = useHistory();

  const {
    formData,
    changeHideState,
    onInputChangeHandler,
    onTouchHandler,
    formError,
    onSubmitButtonclick,
    specialInfoDetails,
    onDeleteButtonClick,
    editParticularSpecialization
  } = props;

  let verifyExpertID = localStorage.getItem('VerifyExpertID');

  return (
    <>
      <section className="listofoffermainsection">
        <div className="container">
          <div className="leftsightbackdetails listofofferleft padtop60">
            <div className="back_btn" onClick={() => history.goBack()}><img src={leftarrow} alt={' '} />
            </div>
            <div className="plaindetailsleft">
              <h1 className="h2_style font-weight-bold">Specialization Information</h1>
              <h5 className="h5_style">"Specialization" is where you can showcase your expertise and register as a domain expert on our site. </h5>
              <h5 className="h5_style">This section provides an opportunity for you to highlight your areas of expertise and the areas in which you can provide the
most valuable assistance.</h5>
              <h5 className="h5_style">By registering as a specialist in a particular field, you'll be able to help others seeking information and advice in that area.</h5>


            </div>
          </div>

          <div className="specialinfoinner mt-5 mb-5">
            <div className="row">
              <div className="col-lg-5">
                <div className="mb-4 input-container form-group">
                  <textarea className="input form-control descriptiontextarea" placeholder=" "
                    name="description"
                    value={formData.description}
                    onChange={onInputChangeHandler}
                    onBlur={onTouchHandler}
                    maxLength={250}
                  ></textarea>
                  <span className="text-danger">
                    {formError['description'] &&
                      (formError['description'] === "req"
                        ? 'description is required'
                        : '')}
                  </span>
                  <label className="placeholder">Description *</label>
                </div>
                <button disabled={!formData.description} onClick={() => onSubmitButtonclick()} type="submit" className="ctaonebutton mt-5">{verifyExpertID ? 'Verify Now' : 'Next'}</button>

              </div>
              <div className="col-lg-7">
                {/* <a >
                  <div className="expertfileupload">
                    <div className="button_outerlogo">
                      <div className="btn_uploadlogo" onClick={() => changeHideState(true)}><img src={fileuploadpopup} /></div>
                    </div>
                    <h5 className="text-center h5_style">Add your Specializations</h5>
                    <p className="text-center">You can enter multiple specializations.</p>
                    <p className="text-center">Just click on  “+”</p>

                  </div></a>
                  <button disabled={!formData.description} onClick={() => onSubmitButtonclick()} type="submit" className="ctaonebutton mt-5">Next</button> */}
                {specialInfoDetails?.length ?
                  <ViewSpecialInfo
                    specialInfoDetails={specialInfoDetails}
                    changeHideState={changeHideState}
                    onDeleteButtonClick={onDeleteButtonClick}
                    editParticularSpecialization={editParticularSpecialization}
                  /> :
                  <>
                    <a >
                      <div className="expertfileupload">
                        <div className="button_outerlogo">
                          <div className="btn_uploadlogo" onClick={() => changeHideState(true)}><img src={fileuploadpopup}  alt={' '}  /></div>
                        </div>
                        <h5 className="text-center h5_style">Add your Specializations</h5>
                        <p className="text-center">You can enter multiple specializations.</p>
                        <p className="text-center">Just click on  “+”</p>

                      </div></a>
                  </>
                }
                

              </div>
            </div>
          </div>
        </div>

      </section>


    </>

  );
};

export default SpecialInformationsJsx;