import React from 'react'
import { Component } from 'react';
import CustomerDashboardJsx from './interest.jsx'

export default class CustomerDashboard extends Component{
    constructor(props){
        super(props)
        this.state={
            activeTab:'custOffer'
        }
    }

    onTabClick = (tab) =>{
        this.setState({activeTab:tab})
    }

    render(){
        return(
            <div>
                 <CustomerDashboardJsx 
                    activeTab={this.state.activeTab}
                    onTabClick={this.onTabClick}
                />
            </div>
        )
    }
}