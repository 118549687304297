import axios from "axios";
import React, { Component } from "react";
import { baseURL, uploadURL } from "../../../../constants/applicationConstants.js";
import { getProperDateMonth, websiteRegex } from "../../../../shared/helper.js";
import { Loader } from "../../../common/loader/loader.jsx";
import MyAdsJsx from "./myAds.jsx";
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class MyAds extends Component {

  constructor(props) {
    super(props);
    this.state = {
      blogFormData: {
        validFrom: null,
        validTill: null,
        category: "",
        subCategory: "",
        caption: "",
        metaTag: "",
        link: "",
        isExpires: true
      },
      isProcessing: false,
      formSubmitButtonDisable: true,
      formError: {},
      showAddModal: false,
      categoryData: null,
      subCategoryData: null,
      adData: null,
      instituteAdID: null,
      imageUrl: "",
      fileInfo: null,
      profileImagePreviewUrl: null,
      editMode: false,
      AdsImg: ""
    }
  }


  componentDidMount = () => {
    this.setState({ isLoading: true })

    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));

    let flowName = localStorage.getItem('flowName');
    let subURL = '';
    if (flowName === 'expert')
      subURL = 'institutionCategoriesAndSubCategories?expertId'
    else subURL = 'institutionCategoriesAndSubCategories?instituteId'

    axios.get(`${baseURL}/${subURL}=${instituteId}`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ categoryData: res.data.data, isLoading: false })
    }).catch(err=>{
     this.setState({ isLoading: false })
    })

    this.getADS();
    if (!token) {
      this.props.history.push("/");
    }
  }

  getADS = () => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));

    let flowName = localStorage.getItem('flowName');
    let subURL = '';
    if (flowName === 'expert')
      subURL = 'expert'
    else subURL = 'institute'

    axios.get(`${baseURL}/${subURL}/${instituteId}/ads`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ adData: res.data.data, isLoading: false })
    }).catch(err=>{
      this.setState({ isLoading: false })
    })
  }

  getSubCategories = (value) => {
    const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)
    this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
  }

  validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png');


  onFileChange = (e) => {
    if (e.target.files[0]?.size) {
      if (e.target.files[0]?.size < 512000) {
        if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
          this.setState({ fileInfo: e.target.files[0] })
          this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) }, () => this.isFromSubmissionBlock())
        }
        else
          toast.error('.jpeg/.jpg/.png format required')
      }
      else
        toast.error('File should be less than 500 kb.')
    }
  }

  onSubmitButtonclick = () => {
    this.setState({
      isLoading: true
    })
    let formData1 = new FormData();

    if (this.state.fileInfo) {
      formData1.append('image', this.state.fileInfo)
      formData1.append('imageCategory', "institute-ad")

      axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {
        if (resp.status === 200) {
          localStorage.setItem("AdsImg", resp.data.filename)
          this.setState({ AdsImg: resp.data.filename }, () => this.onSubmitButtonclick1())
          toast.success("Ad created successfully");
        } else {
          // setIsLoading(false)
        }
      }).catch(() => {
        this.setState({
          isLoading: false
        })
      })
    }
    else this.onSubmitButtonclick1();
  };

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "category") {
      this.getSubCategories(value)
    }

    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            blogFormData: {
              ...this.state.blogFormData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            blogFormData: {
              ...this.state.blogFormData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          blogFormData: {
            ...this.state.blogFormData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };

  onSubmitButtonclick1 = () => {
    this.setState({
      isLoading: true
    })
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    let image = this.state.AdsImg;
    const { validFrom, validTill, category, subCategory, caption, metaTag, link, isExpires } = this.state.blogFormData;

    const payload1 = {
      "institutionCategoryId": category,
      "institutionSubCategoryId": subCategory,
      "description": caption,
      "metaTags": metaTag,
      "externalLink": link,
      "neverExpires": !isExpires
    }

    const payload2 = image ? { ...payload1, imageUrl: image } : { ...payload1 }

    const payload = !isExpires ? {...payload2} :
     {...payload2,
      "validFrom": validFrom && `${validFrom?.year}-${getProperDateMonth(validFrom?.month)}-${getProperDateMonth(validFrom.day)}`,
      "validTo": validTill && `${validTill?.year}-${getProperDateMonth(validTill?.month)}-${getProperDateMonth(validTill.day)}`,
    }

    let flowName = localStorage.getItem('flowName');
    let subURL = '';
    if (flowName === 'expert')
      subURL = 'expert'
    else subURL = 'institute'

    if (this.state.instituteAdID) {
      const userType = flowName === 'expert' ? 'advertisement' : 'instituteAd'
      axios.put(`${baseURL}/${subURL}/${instituteId}/${userType}/${this.state.instituteAdID}`, payload, {
        headers: { 'x-access-token': token }
      }).then(res => {
        this.setState({
          isLoading: false,
          showAddModal: false
        })
        this.setblacnk();

        this.resetState();
        this.getADS();
      }).catch(err => {
        toast.error(err.response.data.error.message)
        this.setState({
          isLoading: false
        })
      })
    }
    else {
      axios.post(`${baseURL}/${subURL}/${instituteId}/ad`, payload, {
        headers: { 'x-access-token': token }
      }).then(res => {
        this.setState({
          isLoading: false,
          showAddModal: false
        })
        this.setblacnk();
        this.resetState();
        this.getADS();
      }).catch(err => {
        toast.error(err.response.data.error.message)
        this.setState({
          isLoading: false
        })
      })
    }
  }

  isFromSubmissionBlock = () => {
    let { validFrom, validTill, category, subCategory, caption, isExpires } = this.state.blogFormData;
    const { profileImagePreviewUrl, editMode } = this.state;

    if (isExpires) {
      if (validFrom && validTill && category && subCategory && caption && (profileImagePreviewUrl || editMode)) {
        this.setState({
          formSubmitButtonDisable: false
        });
      } else {
        this.setState({
          formSubmitButtonDisable: true
        });
      }
    }
    else {
      if (category && subCategory && caption && (profileImagePreviewUrl || editMode)) {
        this.setState({
          formSubmitButtonDisable: false
        });
      } else {
        this.setState({
          formSubmitButtonDisable: true
        });
      }
    }


  };

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {
    if (name === 'link')
      return websiteRegex.test(value);

    return false;
  };

  onRadioButtonClick = (value) => {
    this.setState({
      blogFormData: { ...this.state.blogFormData, isExpires: value }
    }, () => this.isFromSubmissionBlock());
  }

  onDateChange = (name, date) => {
    this.setState(
      {
        blogFormData: {
          ...this.state.blogFormData,
          [name]: date,
        }
      }, () => this.isFromSubmissionBlock()
    );
  }

  onMetaTagChange = (e) => {
    this.setState(
      {
        blogFormData: {
          ...this.state.blogFormData,
          'metaTag': e,
        },

      }
    )
  }

  resetState = () => {
    this.setState({ imageUrl: "", fileInfo:null, profileImagePreviewUrl: null, image: "",editMode: false, formSubmitButtonDisable: true })

    this.setState({
      blogFormData: {
        validFrom: null,
        validTill: null,
        category: "",
        subCategory: "",
        caption: "",
        metaTag: "",
        link: "",
        isExpires: true
      }
    })

  }


  setblacnk = () =>{
    this.setState({fileInfo:null,AdsImg:"", imageUrl: "", profileImagePreviewUrl: null, image: "" })
  }

  onModalClose = (value) => {
    let flowName = localStorage.getItem('flowName');
    let topScroll = '';
    if (flowName === 'expert')
      topScroll=250
    else topScroll = 350;

    this.setState({
      showAddModal: value,
      instituteAdID: null
    }, () => {
      value && window.scrollTo({
        top: topScroll,
        left: 100,
        behavior: 'smooth'
      })
    })
    this.resetState();
    this.setState({ imageUrl: "", profileImagePreviewUrl: null, image: "" })
    !value && this.setState({ editMode: false, formSubmitButtonDisable: true })
  }

  onEditButtonClick = (data) => {
    this.setState({ showAddModal: true, editMode: true, imageUrl: data.imageUrl, instituteAdID: data?.instituteAdId || data?.advertisementId }, () => {
      window.scrollTo({
        top: 500,
        left: 100,
        behavior: 'smooth'
      });
    });
    const validFromDate = !data.neverExpires && data.validFrom.split("T")[0].split("-");
    const validToDate = !data.neverExpires && data.validTo.split("T")[0].split("-");
    const categoryID = this.state.categoryData.filter(catDT => catDT.name === data.parentInstitutionCategory);

    this.setState({
      blogFormData: {
        validFrom: !data.neverExpires && {
          day: parseInt(validFromDate[2]),
          month: parseInt(validFromDate[1]),
          year: parseInt(validFromDate[0])
        },
        validTill: !data.neverExpires && {
          day: parseInt(validToDate[2]),
          month: parseInt(validToDate[1]),
          year: parseInt(validToDate[0])
        },
        isExpires: !data.neverExpires,
        link: data.externalLink,
        caption: data.description,
        category: categoryID[0]?.institutionCategoryId,
        subCategory: data.subInstitutionCategory?.institutionCategoryId,
        metaTag: data.metaTags
      },
      subCategoryData: categoryID[0]?.subInstitutionCategories
    }, () => this.isFromSubmissionBlock())
  }

  onDeleteButtonClick = (data) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this Ad",
      icon: "warning",
      buttons: [
        'Do not cancel it!',
        'Yes, Delete it!'
      ],
      dangerMode: true,
    }).then(isConfirm => {
      if (isConfirm) {
        this.deleteAds(data)
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })
  }

  deleteAds = (data) => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    this.setState({ isLoading: true })

    // let flowName = localStorage.getItem('flowName');
    // let subURL = '';
    // let id;
    // if(flowName === 'expert'){
    //   subURL = 'advertisement'
    //   id=data.advertisementId
    // }
    // else {
    //   subURL = 'advertisement'
    //   id=data.advertisementId
    // }

    axios.delete(`${baseURL}/advertisement/${data.advertisementId}`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({
        isLoading: false
      })
      this.getADS();
    }).catch(err=>{
      this.setState({ isLoading: false })
    })
  }

  onScrollerChange = (data) => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    this.setState({ isLoading: true })

    const payload = {
      "isActive": !data.isActive
    }

    axios.put(`${baseURL}/institute/${instituteId}/instituteAd/${data.advertisementId}`, payload, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({
        isLoading: false
      })
      this.getADS();
    }).catch(err => {
      this.setState({
        isLoading: false
      })
    })
  }

  render() {
    return (
      <div>
        <MyAdsJsx
          blogFormData={this.state.blogFormData}
          formError={this.state.formError}
          onDateChange={this.onDateChange}
          onInputChangeHandler={this.onInputChangeHandler}
          onRadioButtonClick={this.onRadioButtonClick}
          isExpires={this.state.blogFormData.isExpires}
          showAddModal={this.state.showAddModal}
          onModalClose={this.onModalClose}
          onSubmitButtonclick={this.onSubmitButtonclick}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          categoryData={this.state.categoryData}
          subCategoryData={this.state.subCategoryData}
          onMetaTagChange={this.onMetaTagChange}
          onEditButtonClick={this.onEditButtonClick}
          onDeleteButtonClick={this.onDeleteButtonClick}
          adData={this.state.adData}
          onScrollerChange={this.onScrollerChange}
          imgURL={this.state.imageUrl}
          profileImagePreviewUrl={this.state.profileImagePreviewUrl}
          editMode={this.state.editMode}
          onFileChange={this.onFileChange}
        />
        {this.state.isLoading && <Loader />}
        <ToastContainer />
      </div>
    );
  }
}

export default MyAds;
