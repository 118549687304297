import React, { Component } from "react";
import CreateAsktheExpertQuestionJsx from "./createAsktheExpertQuestion.jsx"


export default class CreateAsktheExpertQuestion extends Component{
  render(){
      return(
          <div>
              <CreateAsktheExpertQuestionJsx />
          </div>
      )
  }
}