import React from 'react'
import { Component } from 'react';
import ManageInstituteJsx from './manageInstitute.jsx'

export default class ManageInstitute extends Component{
    render(){
        return(
            <div>
                <ManageInstituteJsx />
            </div>
        )
    }
}