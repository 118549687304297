import React from 'react'

import datecalender from "../../../../assets/images/datecalender.svg";
import fileuploadpopup from "../../../../assets/images/fileuploadpopup.svg";
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import dropbox from "../../../../assets/images/dropbox.svg";
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { formatInputValue } from '../../../../shared/helper';
import { TagsInput } from "react-tag-input-component";
import { NEW_URL } from '../../../../constants/applicationConstants';
import { useTranslation } from "react-i18next";

const MyAdsJsx = (props) => {
    const {
        blogFormData,
        formError,
        onDateChange,
        onInputChangeHandler,
        onRadioButtonClick,
        isExpires,
        showAddModal,
        onModalClose,
        onSubmitButtonclick,
        onTouchHandler,
        formSubmitButtonDisable,
        categoryData,
        subCategoryData,
        onMetaTagChange,
        onEditButtonClick,
        onDeleteButtonClick,
        adData,
        onScrollerChange,
        profileImagePreviewUrl,
        imgURL,
        editMode,
        onFileChange
    } = props;

    const limitData = JSON.parse(sessionStorage.getItem('loginData'));

    let flowName = localStorage.getItem('flowName');

    const { t } = useTranslation();
    return (
        <>
            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="">
                            <div className="dashboardrightpanel martop40">
                            {flowName === 'expert' && <div>
                                <h2>{t("MYADS.MYAD")}</h2>
                                {t("MYADS.ADDISCRIPTION")}
                    </div>}
                                <div className="addexpert martop40">
                                    <div className="addexpertleft">
                                        <p>{t("MYADS.TITLE")}</p>
                                        <div className="userguide">


                                            <div class="dropdown-toggle userdropdown-toggle text-decoration-underline" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false"> <img src={planpricingtooltip} alt={' '}/> Help Guide  </div>
                                            <ul className="dropdown-menu userdropdown-menu" aria-labelledby="dropdownMenu2">
                                                <li className="dropdown-item">{t("MYADS.HOWTOCREATE")}
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li className="dropdown-item">{t("MYADS.UPLOADADIMAGE")}</li>
                                                        <li className="dropdown-item">{t("MYADS.ENTERADCAPTION")}</li>
                                                        <li className="dropdown-item">{t("MYADS.EXPIREDATE")}
                                                            <ol>
                                                                <li>{t("MYADS.SELECTEXPIRE")}</li>
                                                                <li>{t("MYADS.ENTERVALIDDATE")}</li>
                                                            </ol></li>
                                                        <li className="dropdown-item" >{t("MYADS.NEVEREXPIRE")}</li>
                                                        <li className="dropdown-item" >{t("MYADS.CLICKSUBMIT")}</li>
                                                        <li className="dropdown-item" >{t("MYADS.DELETEIT")}</li>

                                                    </ul>
                                                </li>
                                                <li><a className="dropdown-item" type="button">{t("MYADS.EDITAD")}</a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li><a className="dropdown-item"  >{t("MYADS.CLICKEDIT")}</a></li>

                                                        <li><a className="dropdown-item"  >{t("MYADS.POPUPINFO")}
                                                            <ol>
                                                                <li>{t("MYADS.UPLOADNEWAD")} </li>
                                                                <li>{t("MYADS.EDITADCAPTION")} </li>
                                                            </ol> </a></li>
                                                        <li><a className="dropdown-item"  >{t("MYADS.CLICKSUBMITBUTTON")} </a></li>
                                                        <li><a className="dropdown-item"  >{t("MYADS.DISCARDCHANGES")} </a></li>

                                                    </ul>
                                                </li>
                                                <li><a className="dropdown-item" type="button">{t("MYADS.DELETEEXISTINGAD")}</a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li><a className="dropdown-item"  >{t("MYADS.DELETEOPTION")} </a></li>


                                                    </ul>
                                                </li>
                                                <li><a className="dropdown-item" type="button">{t("MYADS.ADDMOEAD")} </a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li><a className="dropdown-item"  >{t("MYADS.ADLIMIT")}</a></li>

                                                    </ul>
                                                </li>
                                                <li><a className="dropdown-item" type="button">{t("MYADS.ADSTATUS")}</a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li><a className="dropdown-item"  >{t("MYADS.SWITCH")} </a></li>
                                                        <li><a className="dropdown-item"  >{t("MYADS.ACTIVETOGGLE")}</a></li>
                                                        <li><a className="dropdown-item"  >{t("MYADS.INACTIVETOGGLE")} </a></li>


                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div className="addexpertright">
                                        <button type="button" disabled={adData?.length === limitData?.totalAdsLimit} className="ctaonebutton" onClick={() => onModalClose(true)}>{t("MYADS.CREATEAD")}</button>
                                        {adData?.length === limitData?.totalAdsLimit && <p className="text-danger">{t("MYADS.MAXLIMIT")}</p>}
                                    </div>


                                </div>
                                {showAddModal && <div className="expertformmain" style={{ marginTop: '30px' }}>

                                    <div className="expertinnerformmain">
                                        <div className="expertformmainheader">
                                            <div className="expertformmainheaderleft">
                                                <h3>{t("MYADS.CREATEAD")}</h3>
                                                <div className="userguide">
                                                    <span class="plantooltip custooltip">
                                                        <img src={planpricingtooltip}  alt={''} className="tooltipim" />{t("MYADS.HOWTOCREATE")}
                                                        <div className="tooltipcontent">
                                                            <ul className="tcon">
                                                                <li className="tconitem">{t("MYADS.UPLOADADIMAGE")}</li>
                                                                <li className="tconitem">{t("MYADS.ENTERADCAPTION")}</li>
                                                                <li className="tconitem">{t("MYADS.EXPIREDATE")}
                                                                    <ol>
                                                                        <li>{t("MYADS.SELECTEXPIRE")}</li>
                                                                        <li>{t("MYADS.ENTERVALIDDATE")}</li>
                                                                    </ol>
                                                                </li>
                                                                <li className="tconitem">{t("MYADS.NEVEREXPIRE")}</li>
                                                                <li className="tconitem">{t("MYADS.CLICKSUBMIT")}</li>
                                                                <li className="tconitem">{t("MYADS.DELETEIT")}</li>

                                                            </ul>
                                                        </div>
                                                    </span>

                                                </div>
                                            </div>
                                            <div className="expertformmainheaderright">
                                                <button type="button" className="myadbtn" onClick={() => onModalClose(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-6">
                                                <div className="uploadexpert mb-4">
                                                    <div className="button_outerlogo">
                                                        <div className="btn_uploadlogo"><input type="file" id="upload_filelogo" name="" onChange={onFileChange} />
                                                            <label for="upload_filelogo">

                                                                {
                                                                    profileImagePreviewUrl ?
                                                                        <img
                                                                            src={profileImagePreviewUrl}
                                                                            height="170px"
                                                                            width="250px"
                                                                            alt="edit profile"
                                                                        /> :
                                                                        editMode ?
                                                                            <img
                                                                                src={`${NEW_URL}/${imgURL}`}
                                                                                height="170px"
                                                                                width="250px"
                                                                                alt="edit profile"
                                                                            />
                                                                            : <img src={fileuploadpopup}  alt={''} />
                                                                }

                                                            </label>
                                                        </div>
                                                    </div>
                                                    {!(editMode || profileImagePreviewUrl) ? <><h5 className="text-center h5_style"><b>{t("MYADS.UPLOADLOGO")}</b></h5>
                                                        <p className="text-center">{t("MYADS.CLICKADD")}</p></>
                                                        :
                                                        <div className="button_outer">
                                                            <div className="btn_upload">
                                                                <input type="file" id="upload_file" name="" onChange={onFileChange} />
                                                                <label for="upload_file">{"Change Icon"}</label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {isExpires && <div className="disgrid grid-2">
                                                    <DatePicker3 className="form-control" name="startDate"
                                                        onChange={(date) => { onDateChange('validFrom', date) }}
                                                        inputPlaceholder="dd/mm/yyyy"
                                                        shouldHighlightWeekends
                                                        wrapperClassName="mb-4 input-container form-group"
                                                        maximumDate={{
                                                            year: new Date().getFullYear(),
                                                            month: new Date().getMonth() + 1,
                                                            day: new Date().getDate()
                                                        }}
                                                        renderInput={({ ref }) => (
                                                            <>
                                                                <input
                                                                    ref={ref}
                                                                    placeholder=" "
                                                                    type="text"
                                                                    required
                                                                    className="input form-control"
                                                                    value={formatInputValue(blogFormData?.validFrom)}
                                                                />
                                                                <div className="cut"></div>
                                                                <label className="placeholder">{'Valid From*'}</label>
                                                                <span className="dateim"><img src={datecalender}  alt={''}/></span>
                                                            </>
                                                        )}
                                                        formatInputText={() => formatInputValue(blogFormData?.validFrom)}
                                                    />
                                                    <DatePicker3 className="form-control" name="startDate"
                                                        onChange={(date) => { onDateChange('validTill', date) }}
                                                        inputPlaceholder="dd/mm/yyyy"
                                                        shouldHighlightWeekends
                                                        wrapperClassName="input-container form-group"
                                                        minimumDate={{
                                                            year: new Date().getFullYear(),
                                                            month: new Date().getMonth() + 1,
                                                            day: new Date().getDate() + 1
                                                        }}
                                                        renderInput={({ ref }) => (
                                                            <>
                                                                <input
                                                                    ref={ref}
                                                                    placeholder=" "
                                                                    type="text"
                                                                    required
                                                                    className="input form-control"
                                                                    value={formatInputValue(blogFormData?.validTill)}
                                                                />
                                                                <div className="cut"></div>
                                                                <label className="placeholder">{'Valid Till*'}</label>
                                                                <span className="dateim"><img src={datecalender}  alt={''} /></span>
                                                            </>
                                                        )}
                                                        formatInputText={() => formatInputValue(blogFormData?.validTill)}
                                                    />
                                                </div>
                                                }
                                                <div className="disgrid grid-2">
                                                    <div className="mb-4 input-container form-group">
                                                        <select className="input form-control form-select" onChange={onInputChangeHandler} name="category">
                                                            <option value="">{t("MYADS.SELECTCATEGORY")}</option>
                                                            {categoryData?.map((item) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.institutionCategoryId} selected={item.institutionCategoryId === blogFormData.category}>{item.name}</option>
                                                                    </>
                                                                )
                                                            })}
                                                        </select>
                                                        <label className="placeholder">{t("MYADS.CATEGORY")}</label>
                                                    </div>
                                                    <div className="mb-4 input-container form-group">
                                                        <select className="input form-control form-select" onChange={onInputChangeHandler} name="subCategory">
                                                            <option value="">{t("MYADS.SELECTSUBCAT")}</option>
                                                            {subCategoryData?.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.institutionCategoryId} selected={item.institutionCategoryId === blogFormData.subCategory}>{item.name}</option>
                                                                    </>
                                                                )
                                                            })}
                                                        </select>
                                                        <label className="placeholder">{t("MYADS.SUBCAT")}</label>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-sm-6">
                                                <div className="mb-4 input-container form-group">
                                                    <textarea
                                                        onBlur={onTouchHandler}
                                                        className={"input form-control textareaform"}
                                                        placeholder=" "
                                                        value={blogFormData.caption}
                                                        name="caption"
                                                        onChange={onInputChangeHandler}
                                                        maxLength={500}></textarea>
                                                    <label className="placeholder">{t("MYADS.ADDCAPTION")} </label>
                                                    <span className="text-danger">
                                                        {formError['caption'] &&
                                                            (formError['caption'] === "req"
                                                                ? 'Caption is required'
                                                                : '')}
                                                    </span>
                                                </div>

                                                <div className="mb-4 input-container form-group specialtag">
                                                    <div className="normal" id="tagInput" titleLbl={'Product Meta Tag'}
                                                    onFocus={()=>{
                                                        let elem = document.getElementById("tagInput"); 
                                                        elem.classList.add("my-classfocus"); 
                                                    }}          
                                                    onBlur={()=>{
                                                        let elem = document.getElementById("tagInput"); 
                                                        elem.classList.remove("my-classfocus"); 
                                                    }}  
                                                    
                                                    >

                                                    <TagsInput
                                                        value={blogFormData.metaTag}
                                                        onChange={onMetaTagChange}
                                                        label="xxxxxxxxx"
                                                        name="metaTags"
                                                        placeHolder=" "
                                                        classNames={{tag: 'tag-clsxxxx', input: 'input form-control'}}                                              
                                                    />
                                                    </div>
                                                    <label className="placeholder">{t("MYADS.METATAG")}<span className="plantooltip custooltip" ><img src={planpricingtooltip}  alt={''} className="tooltipim" />
                                                    <div class="tooltipcontent">{t("MYADS.METATAGTOOLTIP")}</div>
                                                    </span></label>
                                                    
                                                </div>
                                                <div className="mb-4 input-container form-group">
                                                    <input
                                                        onBlur={onTouchHandler}
                                                        className={'input form-control '}
                                                        placeholder=" "
                                                        value={blogFormData.link}
                                                        name="link"
                                                        onChange={onInputChangeHandler}
                                                        maxLength={100}
                                                    />
                                                    <label className="placeholder">{t("MYADS.EXTERNALLINK")}</label>
                                                    <span className="text-danger">
                                                        {formError['link'] &&
                                                            (formError['link'] === "req"
                                                                ? ''
                                                                : 'Enter a valid link')}
                                                    </span>
                                                </div>

                                                <div className="mb-4 input-container form-group radioform">
                                                    <label className="radiocontrol">
                                                        <input type="radio" name="radio" onClick={() => onRadioButtonClick(true)} checked={blogFormData.isExpires} />{t("MYADS.EXPIRES")}</label>

                                                    <label className="radiocontrol">
                                                        <input type="radio" name="radio" onClick={() => onRadioButtonClick(false)} checked={!blogFormData.isExpires} />{t("MYADS.NEVEREXPIRES")}</label>
                                                </div>
                                                <div className="mb-4 input-container form-group">
                                                    <button type="button" disabled={formSubmitButtonDisable} className="ctaonebutton" onClick={() => onSubmitButtonclick()}>{t("MYADS.SUBMIT")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }


                                <div className="myadssection">
                                    <h2 className="h2_style martop30">{t("MYADS.MYAD")} </h2>
                                    <p>{t("MYADS.VIIBLEAD")} </p>
                                    <p>{t("MYADS.BELOWAD")} {limitData?.totalAdsLimit} {t("MYADS.BELOWAD1")}  </p>
                                </div>

                                <div className="myadpost martop20">
                                    <div className="row">
                                        {
                                            adData?.length > 0 ?
                                                adData?.map(data => {
                                                    return <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="myadpostinner">
                                                            <div className="myadpostinnerimage">
                                                                <img src={`${NEW_URL}/${data.imageUrl}`}  alt={''}/>
                                                            </div>
                                                            <div className="myadpostbanner">
                                                                <label className="switch">
                                                                    <input type="checkbox" checked={data.isActive} onChange={() => onScrollerChange(data)} />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <div className="mybtn">
                                                                    <button type="button" onClick={() => onEditButtonClick(data)} className="myadbtn"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>
                                                                    <button type="button" onClick={() => onDeleteButtonClick(data)} className="myadbtn"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg>
</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }) :
                                                <div className="dropdefault text-center mb-5">
                      <div className="dropdefaultimage">
                        <img src={dropbox}  alt={''} />
                      </div>
                      <div className="dropdefaulttext">
                        <h5>{t("MYADS.LIVEAD")} </h5>
                        <h5>{t("MYADS.CREATENEWAD")}</h5>
                        </div>
                       
                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                       
                        </div>
                    </div>
                </div>
            </section>


        </>

    );
};

export default MyAdsJsx;
