import React from 'react'
import { useTranslation } from "react-i18next";
import '../../../../assets/stylesheet/desktop.css';
import '../../../../assets/stylesheet/responsive.css';
import 'bootstrap/dist/css/bootstrap.css';
import leftarrow from "../../../../assets/images/left-arrow.svg";
import signupleftgraphics from "../../../../assets/images/signupleftgraphics.svg";
import fileuploadpopup from "../../../../assets/images/fileuploadpopup.svg";
import datecalender from "../../../../assets/images/datecalender.svg"
import alertCircle from "../../../../assets/images/planpricingtooltip.svg";
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Select, { components } from 'react-select';
import { useHistory } from "react-router-dom";
import { formatInputValue } from '../../../../shared/helper';
import InputMask from 'react-input-mask';
import { uploadURL,NEW_URL } from '../../../../constants/applicationConstants';
import { TagsInput } from "react-tag-input-component";

const InstitutionalRegistrationJSX = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    field,
    formData,
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    onDateChange,
    handleChange,
    onNextButtonClick,
    getSocialImage,
    onFileChange,
    imageName,
    profileImagePreviewUrl,
    imgURL,
    sameError,
    editMode,
    onMetaTagChange
  } = props;

  const minimumDate = {
    year: 2005,
    month: 1,
    day: 30
  };
  const defaultValue = {
    year: 2005,
    month: 1,
    day: 30
  };

  const getMuskPattern = (name) =>
    name === 'phone' ? '(999) 999 9999' : 'a9a 9a9'


    const removeId = ()=>{
      const adminToken = sessionStorage.getItem('adminOldToken');
  
      localStorage.removeItem("VerifyExpertID")
      sessionStorage.setItem("token", JSON.stringify(adminToken));
      sessionStorage.removeItem("adminOldToken")
      history.goBack()
    }
  

  return (
    <section className="loginscreeninstitute registrationinstitute">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-5">
            <div className="leftsightbackdetails martop60 mobileregistration">
              <div className="back_btn" onClick={() =>removeId()}><img src={leftarrow} alt={' '} /></div>
              <div className="detailsleft">
                <h1>{t("EXPERT_SIGNUP.TITLE")}</h1>
                <a href="#logochangeModal"><div className="uploadinslogo">
                  {
                    profileImagePreviewUrl ?
                      <img
                        src={profileImagePreviewUrl}
                        height="170px"
                        width="250px"
                        alt="edit profile"
                      /> :
                      editMode ?
                        <img
                          src={`${NEW_URL}/${imgURL}`}
                          height="170px"
                          width="250px"
                          alt="edit profile"
                        />
                        : t("EXPERT_SIGNUP.LOGO")
                  }
                </div></a>
                <div id="logochangeModal" className="modal-window">

                  <div>
                    <a  title="Close" className="modal-close">{t("EXPERT_SIGNUP.CLOSE")}</a>
                    <div className="modal-header"><h2 className="h2_style">{t("EXPERT_SIGNUP.LOGOCHANGE")}</h2></div>
                    <div className="modal-body mb-4">
                      <div className="modal-body-inner">
                        <div className="button_outerlogo">
                          <div className="btn_uploadlogo"><input type="file" id="upload_filelogo" name="" onChange={onFileChange} />
                            <label for="upload_filelogo">

                              {
                                profileImagePreviewUrl ?
                                  <img
                                    src={profileImagePreviewUrl}
                                    height="170px"
                                    width="250px"
                                    alt="edit profile"
                                  /> :
                                  editMode ?
                                    <img
                                      src={`${NEW_URL}/${imgURL}`}
                                      height="170px"
                                      width="250px"
                                      alt="edit profile"
                                    />
                                    : <img src={fileuploadpopup} alt={' '}  />
                              }
                            </label>
                          </div>
                        </div>
                        {imageName && <h6 className='text-center'>{imageName}</h6>}
                        {!(editMode || profileImagePreviewUrl) && <><h5 className="text-center h5_style">{t("EXPERT_SIGNUP.UPLOADLOGOIMAGE")}</h5>
                          <p className="text-center">{t("EXPERT_SIGNUP.ADDICON")}</p></>}
                      </div>
                    </div>
                    {(profileImagePreviewUrl || editMode) && <div className="modal-footer">
                      <div className="button_outer">
                        <div className="btn_upload">
                          <input type="file" id="upload_file" name="" onChange={onFileChange} />
                          <label for="upload_file">{"Change Icon"}</label>
                        </div>
                      </div>
                    </div>}

                  </div>
                </div>

                <main className="main_full">

                  <div className="uploadproduct mb-4">
                    <div className="button_outer">
                      <div className="btn_upload">
                        <input type="file" id="upload_file" name=""
                          accept="image/png, image/gif, image/jpeg"
                          onChange={onFileChange}
                        />
                        <label for="upload_file">{t("EXPERT_SIGNUP.FILEUPLOADTEXT")}</label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="btn_upload">
                        <input type="file" id="upload_file" name="" onChange={onFileChange} />
                        <label for="upload_file">{t("EXPERT_SIGNUP.FILEUPLOADTEXT")}</label>
                      </div> */}
                  <div className="error_msg"> {t("EXPERT_SIGNUP.IMAGEMSG")}</div>
                  <div className="error_msg"> {t("EXPERT_SIGNUP.IMAGEMSGONE")}</div>
                  <div className="error_msg"> {t("EXPERT_SIGNUP.IMAGEMSGTWO")}</div>
                </main>
                <div className="instituteleft-illustration martop60">
                  <img src={signupleftgraphics}  alt={' '} />
                </div>

              </div>
            </div>
          </div>
          <div className="col-lg-7 col-sm-7 right-institute ">
            <div className="institutelogintext martop60 paddingbot60">
              <h2 className="h2_style text-center">{t("EXPERT_SIGNUP.LETUSKNOW")}</h2>
              <h6 className="h6_style text-center">{t("EXPERT_SIGNUP.ENTERINFO")}</h6>
              <p className="text-center smalltext">{t("EXPERT_SIGNUP.MANDATORY")} </p>
              <h4 className="h4_style text-center">{t("EXPERT_SIGNUP.INSTITUTIONALDETAILS")}</h4>
              <h5 className="h5_style text-center">{t("EXPERT_SIGNUP.DESCRIPTION")}</h5>
              <br />
              {field?.map((item, i) => (
                <>
                  {item.fieldType === "metaTags" &&
                    <div>
                      <div className="mb-4 input-container form-group specialtag">
                      <div className="normal" id={`tagI${i}`} 
                                                    onFocus={()=>{
                                                        let elem = document.getElementById("tagI"+i); 
                                                        elem.classList.add("my-classfocus"); 
                                                    }}          
                                                    onBlur={()=>{
                                                        let elem = document.getElementById("tagI"+i); 
                                                        elem.classList.remove("my-classfocus"); 
                                                    }}  
                                                    
                                                    >

                        <TagsInput
                          value={formData[item.name]}
                          onChange={(e) => onMetaTagChange(e, item)}
                          label="xxxxxxxxx"
                          name={item.name}
                          maxLength={item.maxLength}
                          placeHolder=" "
                          classNames={{tag: 'tag-clsxxxx', input: 'input form-control'}} 
                        />
                         </div>
                                                    <label className="placeholder">{item.placeholder}{item.showTooltip && <span className="plantooltip custooltip" title="" > {<img src={alertCircle} className="tooltipim"  alt={' '} />}<div className="tooltipcontent">{item.tooltipTitle}</div></span>}</label>
                      </div>
                    </div>
                  }

                  {item.fieldType === "input" &&
                    <div>

                      <div className="mb-4 input-container form-group">
                        {item.name === 'postalCode'  || item.name === 'billing_postalCode' ?
                          <InputMask mask={getMuskPattern(item.name)} value={formData[item.name]} name={item.name} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                            {(inputProps) => <input {...inputProps} type="tel" className="input form-control" placeholder=" " disableUnderline />}
                          </InputMask> :
                          <input
                            onChange={onInputChangeHandler}
                            onBlur={onTouchHandler}
                            required
                            type={item.type}
                            className={
                              item.isRequired && formError[item.name]
                                ? "input form-control  is-invalid"
                                : "input form-control"
                            }
                            id={item.name}
                            name={item.name}
                            placeholder={" "}
                            maxLength={item.maxLength}
                            value={formData[item.name]}
                          />}
                        <div className="cut"></div>
                        <label className="placeholder">{t(`${item.placeholder}`)} {item.showTooltip && <span className="plantooltip custooltip" title="" > {<img src={alertCircle} className="tooltipim"  alt={' '} />}<div className="tooltipcontent">{item.tooltipTitle}</div></span>}</label>
                        <span className="text-danger">
                          {item.isRequired &&
                            formError[item.name] &&
                            (formError[item.name] === "req"
                              ? t(item.error)
                              : t(item.type_error))}
                        </span>
                      </div>
                    </div>}

                  {item.fieldType === "select" &&
                    <div className={`mb-4 input-container selectboxmain form-group ${formData[item.name] && `selecttransition`}`}>
                      <>
                        <Select className={`selectbox`}
                          closeMenuOnSelect={true}
                          options={item?.options}
                          placeholder={" "}
                          onChange={(e) => handleChange(e, item)}

                          components={{
                            Input: (props) => {
                              const { value: inputval } = props

                              return <>


                                <label className={`placeholder ${inputval !== '' && 'active'}`}>{t(`${item.placeholder}`)}
                                  {item.showTooltip && <span className="plantooltip custooltip" title="" >
                                    <img src={alertCircle} className="tooltipim"  alt={' '} />
                                    <div className="tooltipcontent">{item.tooltipTitle}</div></span>}</label>

                                <components.Input {...props} className={"mb-4 input-container form-group"} >
                                  {props.children}
                                </components.Input>
                              </>
                            },
                          }}

                          value={item?.options.filter(ite => ite.value === formData[item.name])}
                        />

                      </>
                    </div>
                  }
                  {
                    item.fieldType === "multiple" &&
                    <div className={`disgrid grid-${item.fields.length}`}>
                      {item.fields.map((items) => {
                        return <>
                          {
                            items.fieldType === "input" &&
                            <div>

                              <div className="mb-4 input-container form-group">
                                {
                                  items.name === 'phone' || items.name === 'secondaryContact' || items.name === 'primaryContact' ?
                                    <InputMask mask={getMuskPattern('phone')} value={formData[items.name]} name={items.name} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                      {(inputProps) => <input {...inputProps} type="tel" className="input form-control" placeholder=" " disableUnderline />}
                                    </InputMask> :
                                    <input
                                      onChange={onInputChangeHandler}
                                      onBlur={onTouchHandler}
                                      required
                                      type={items.type}
                                      className={
                                        items.isRequired && formError[items.name]
                                          ? "input form-control  is-invalid"
                                          : "input form-control"
                                      }
                                      id={items.name}
                                      name={items.name}
                                      placeholder={" "}
                                      maxLength={items.maxLength}
                                      value={formData[items.name]}
                                    />}
                                <div className="cut"></div>
                                <label className="placeholder">{t(`${items.placeholder}`)} {items.showTooltip && <span className="plantooltip custooltip righttool" title="" > <img src={alertCircle} className="tooltipim"  alt={' '} /><div className="tooltipcontent">{items.tooltipTitle}</div></span>} </label>
                                <span className="text-danger">
                                  {items.isRequired &&
                                    formError[items.name] &&
                                    (formError[items.name] === "req"
                                      ? t(items.error)
                                      : t(items.type_error))}
                                </span>
                                { items.name === 'expertEmail' || items.name === 'altEmail'? 
                              sameError ? <span class="text-danger samemailerr">Login email &amp; alternative email can’t be same</span> : null: null  
                              }
                              </div>
                            </div>
                          }{
                            items.fieldType === "select" &&
                            <div className={`mb-4 input-container selectboxmain form-group ${formData[items.name] && `selecttransition`}`}>
                              <>
                                <Select className={`selectbox`}
                                  closeMenuOnSelect={true}
                                  placeholder={" "}
                                  options={items?.options}
                                  onChange={(e) => handleChange(e, items)}
                                  value={items?.options.filter(ite => ite.value === formData[items.name])}

                                  components={{
                                    Input: (props) => {
                                      const { value: inputval } = props

                                      return <>
                                        <div className={`placeholder ${inputval !== '' && 'active'}`}>{t(`${items.placeholder}`)} {item.showTooltip && <span className="plantooltip custooltip" title="" > <img src={alertCircle} className="tooltipim"  alt={' '} /><div className="tooltipcontent">{item.tooltipTitle}</div></span>}</div>

                                        <components.Input {...props} className={"mb-4 input-container form-group"} >
                                          {props.children}
                                        </components.Input>
                                      </>
                                    },
                                  }}
                                />
                                {/* <div className="placeholder">{t(`${items.placeholder}`)} {item.showTooltip && <span className="plantooltip custooltip" title="" > <img src={alertCircle} className="tooltipim"  alt={' '} /><div className="tooltipcontent">{item.tooltipTitle}</div></span>}</div> */}
                              </>
                            </div>
                          }
                          {items.fieldType === "date" &&
                            <>
                              <DatePicker3 className="form-control" name="startDate"
                                onChange={(date) => { onDateChange(date) }}
                                inputPlaceholder="dd/mm/yyyy"
                                shouldHighlightWeekends
                                value={defaultValue}

                                // maximumDate={{
                                //   year: new Date().getFullYear(),
                                //   month: new Date().getMonth() + 1,
                                //   day: new Date().getDate()
                                // }}
                                maximumDate={minimumDate}
                                wrapperClassName="input-container form-group"
                                renderInput={({ ref }) => (
                                  <>
                                    <input
                                      ref={ref}
                                      placeholder=" "
                                      type="text"
                                      required
                                      className="input form-control"
                                      value={formatInputValue(formData.dateOfBirth)}
                                    />
                                    <div className="cut"></div>
                                    <label className="placeholder">{t(`${items.placeholder}`)}</label>
                                    <span className="dateim"><img src={datecalender} alt={' '}  /></span>
                                  </>
                                )}
                                formatInputText={() => formatInputValue(formData.dateOfBirth)}
                              />
                            </>
                          }
                        </>
                      })}
                    </div>
                  }
                  {
                    item.fieldType === "text" &&
                    <div className="primarycontact martop40">
                      <h4 className="h4_style text-center">{t(`${item.contentHeading}`)}</h4>
                      <h5 className="h5_style text-center">{t(`${item.SUBHEADING1}`)}</h5>
                      <hr></hr>
                    </div>
                  }
                  {item.fieldType === "social" &&
                    <div className="disgrid grid-2">
                      {
                        item.types.map(items =>
                          <div className="input-container form-group social-control">
                            <input
                              type="text"
                              required=""
                              name={items.name}
                              className={
                                items.isRequired && formError[items.name]
                                  ? "input form-control  is-invalid"
                                  : "input form-control"
                              }
                              placeholder=" "
                              onChange={onInputChangeHandler}
                              value={formData[items.name]}
                              maxLength={items.maxLength}
                            />
                            <label className="placeholder">{t("EXPERT_SIGNUP.LINK")}</label>
                            <div className="cut"></div>
                            <img src={getSocialImage(items)} className="formscicon"  alt={' '}  />
                            <span className="text-danger">
                              {items.isRequired &&
                                formError[items.name] &&
                                (formError[items.name] === "req"
                                  ? t(items.error)
                                  : t(items.type_error))}
                            </span>
                          </div>
                        )
                      }
                    </div>
                  }
                  {item.fieldType === "multiSelect" &&
                    <>

                      <Select className="mb-4 form-container form-group multiselect"
                      classNamePrefix="multicustomselect"
                        closeMenuOnSelect={false}
                        isMulti
                        options={item?.options}
                        onChange={(e) => handleChange(e, item)}
                        value={formData['category']}
                        components={{
                          Input: (props) => {
                            const { value } = props

                            return <>
                              <label className={`multiplaceholer ${(formData['category']?.length > 0 || value !== '') ? 'active' : ''}`}>{t(`${item.placeholder}`)}
                                {item.showTooltip && <span className="plantooltip custooltip" title="" > {<img src={alertCircle} className="tooltipim"  alt={' '} />}<div className="tooltipcontent">{item.tooltipTitle}</div></span>}
                              </label>

                              <components.Input {...props} className={"mb-4 input-container form-group"} >
                                {props.children}
                              </components.Input>
                            </>
                          },
                          Option: (props) => {
                            const { isSelected } = props

                            return (
                              <div>
                                <components.Option {...props}>
                                  <input
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={() => null}
                                  />
                                  {props.children}
                                </components.Option>
                              </div>
                            );
                          }
                        }}

                      />
                    </>
                  }
                </>
              ))}

              <button className={formSubmitButtonDisable || sameError ? 'btn-secondary btn mt-4 ctatwobutton' : 'mt-4 ctaonebutton'} disabled={formSubmitButtonDisable || sameError} onClick={() => onNextButtonClick()}>{t("EXPERT_SIGNUP.NEXT")}</button>
              {/* <button className='mt-4 ctaonebutton'onClick={() => testClick()}>{t("EXPERT_SIGNUP.NEXT")}</button> */}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstitutionalRegistrationJSX;
