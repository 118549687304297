import React from 'react'
import { NEW_URL } from '../../../../constants/applicationConstants';import dropbox from "../../../../assets/images/dropbox.svg";
import Sidebar from "../sidebar/sidebar.js";

const CustomerQnaJsx = (props) => {
  const {
    data,
  } = props;

  return (
    <>
      <section class="">

        <div className="container">
          <div className="row">
            
            <div className="col-lg-12 col-sm-12 martop40">

              {
                data.length ? data?.map(list => {
                  return <div className="questioninnerin mb-3 backdeepblue">
                    <p>{list.askExpertQuestionUserResponse.question}</p>

                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={!data?.imageUr ? `https://ui-avatars.com/api/?name=${data?.firstName}+${data?.lastName}&rounded=true` : `${NEW_URL}/${data?.imageUr}`} alt={''} /></div>
                        <div className="questionid"><span>Question ID -</span> {list.allocateAskExpertQuestionResponseId}
                        {/* <div className="questiondate">{list.askExpertQuestionUserResponse.createdAt}</div> */}
                        </div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>{list.askExpertQuestionUserResponse.parentInstitutionCategory}</span></div>
                    </div>

                  </div>
                }) :

                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={''} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>You don't have any pending questions yet. </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>

      </section>
    </>

  );
};

export default CustomerQnaJsx;