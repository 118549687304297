import React, { Component } from "react";
import PasswordCreationPage from "./passwordCreation.jsx"
import axios from 'axios';
import {
  baseURL
} from "../../../constants/applicationConstants.js";
import swal from 'sweetalert'

export class passwordCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {

        newpassword: "",
        verifypassword: "",
      },
      formError: {},
      formSubmitButtonDisable: true,
      isLoading: false,
    };
  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },

          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {
    let passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#()^@$!%*?&])[A-Za-z\d@$!#()^%*?&]{8,30}$/;
    if (name === "currentpassword") {
      if (!passwordRegex.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      if (!passwordRegex.test(value)) {
        return false;
      } else {
        return true;
      }
    }
  };

  isFromSubmissionBlock = () => {
    let { newpassword, verifypassword } = this.state.formData;
    if ((newpassword && verifypassword)) {
      if (
        !this.inputRegexValidation("newpassword", newpassword) &&
        !this.inputRegexValidation("verifypassword", verifypassword)
      ) {
        this.setState({
          formSubmitButtonDisable: true,
        });
      } else {
        if (newpassword === verifypassword) {
          this.setState({
            formSubmitButtonDisable: false,
            formError: {
              ...this.state.formError,
              verifypassword: "",
            }
          });
        } else {
          this.setState({
            formSubmitButtonDisable: true,
            formError: {
              ...this.state.formError,
              verifypassword: "notMatch",
            }
          });
        }
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };
  formSubmitHandler = (e) => {
    e.preventDefault();
    let token = this.props.match.params.id;
    let data = {
      newpassword: this.state.formData.newpassword
    }
    this.setState({
      isLoading: true
    })

    axios.post(`${baseURL}/forgotPassword/${token}`, data, {
      headers: {
        token: token
      }
    }).then(resp => {
      if (resp.data.status === "success") {
        this.setState({
          isLoading: false
        })
        swal({
          title: "Congrats!",
          text: resp.data.message,
          icon: "success",
          successMode: true,
        }).then(res => {
            if (res) {
              this.props.history.push("/");
              window.location.reload()
            }
        });

      } else {
        // alert( resp.data.message)
        this.props.history.push("/linkExpired");
        this.setState({
          isLoading: false
        })
      }
    }).catch(err=>{
      this.setState({
        isLoading: false
      })
    })

    // let obj = {

    //   newpassword: this.state.formData.newpassword,
    //   verifypassword: this.state.formData.verifypassword,
    //   userId: this.props.match.params.id,

    // };
    // this.props.history.push("/");
  };



  render() {

    return (
      <>
        <PasswordCreationPage
          formData={this.state.formData}
          onInputChangeHandler={this.onInputChangeHandler}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          formError={this.state.formError}
          formSubmitHandler={this.formSubmitHandler}
        />
      </>
    );
  }
}

export default passwordCreation;


