import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import AnswerQuestionJsx from './answerQuestion.jsx'

export default class AnswerQuestion extends Component{
    constructor(props){
        super(props);
        this.state={
            answeredQuestion:null,
            pendingQuestions:null,
            isProcessing:false
        }
    }

    componentDidMount = () =>{
        this.setState({isProcessing:true})
        axios.get(`${baseURL}/askExpertQuestionUserResponses?status=accepted`).then(res=>{
            this.setState({answeredQuestion:res.data.data,isProcessing:false})
        }).catch(err=>{
            this.setState({isProcessing:false})
        })

        axios.get(`${baseURL}/askExpertQuestionUserResponses?status=pending`).then(res=>{
            this.setState({pendingQuestions:res.data.data,isProcessing:false})
        }).catch(err=>{
            this.setState({isProcessing:false})
        })
    }

    onPendingQuestionClick = () =>{
        this.props.history.push('./pendingQuestion')
    }

    onViewProfileClick = (url) =>{
        this.props.history.push(`/expertPpv/${url}`)
    }

    render(){
        return(
            <div>
                 <AnswerQuestionJsx 
                    answeredQuestion={this.state.answeredQuestion}
                    pendingQuestions={this.state.pendingQuestions}
                    onPendingQuestionClick={this.onPendingQuestionClick}
                    onViewProfileClick={this.onViewProfileClick}
                 />
                 {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}