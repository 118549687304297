import React, { useState } from "react";
import OtpGenerationPage from "./otpGeneration.jsx"
import { useHistory } from "react-router";
import { getRoute } from '../../../shared/helper'
import axios from "axios";
import { Loader } from "../loader/loader.jsx";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  baseURL,
} from "../../../constants/applicationConstants.js";

const OtpGenerate = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const email = JSON.parse(sessionStorage.getItem("email"))
  const history = useHistory();
  const handleSubmit = () => {
    setIsLoading(true)
    const type = localStorage.getItem('flowName');
    let flowType = localStorage.getItem("flowType")
    let caseName = localStorage.getItem("requestType")
    let instituteUSER = sessionStorage.getItem("instituteUSER")

    let data = {
      category: "institute",
      requestType: "forgot-password",
      userType : "institute"
    };


    if (instituteUSER) {
      data.userType = "institute-user"
    } 
    if (caseName === "forgot-password") {
      data.requestType = "forgot-password"
    }
    let dataExpert = {
      category: "expert",
      requestType: "forgot-password"
    };
    let dataCustomer = {
      category: "customer",
      requestType: "forgot-password"
    };
    if (caseName === "forgot-password") {
      dataExpert.requestType = "forgot-password"
    }
    axios.post(`${baseURL}/forgotPassword/${email}/sendOTP`, flowType === "expert" ? dataExpert : flowType === "customer" ? dataCustomer : data).then((res) => {
      sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));

      setIsLoading(false)
      toast.success("OTP is sent to your registered email successfully");
      setTimeout(() => {
        history.push(getRoute(type, 'otpGeneration'));
      }, 2000)
      // swal({
      //   title: "success",
      //   text: "OTP is sent to your registered email successfully",
      //   icon: "success",
      //   successMode: true,
      // }).then(res => {
      //   if (res) {

      //     history.push(getRoute(type, 'otpGeneration'));

      //   }
      // });

    }).catch((res) => {
      setIsLoading(false)
      toast.error(res?.response.data.error.message)
      // swal({
      //   title: "error",
      //   text: res?.response.data.error.message,
      //   icon: "error",
      // });
    })
  }

  const onBackClick = () => {
    history.goBack()
  }


  return (
    <div>
      <OtpGenerationPage
        handleSubmit={handleSubmit}
        onBackClick={onBackClick}
        email={email}

      />
      {isLoading && <Loader />}
    </div>
  );
}

export default OtpGenerate;
