import React from 'react'
import { NEW_URL } from '../../../constants/applicationConstants'
import call from "../../../assets/images/call.svg";
import map from "../../../assets/images/map.svg";
import envelop from "../../../assets/images/envelop.svg";
import globe from "../../../assets/images/globe.svg";
import facebook from "../../../assets/images/facebookwhite.svg";
import twitter from "../../../assets/images/twitterwhite.svg";
import instagram from "../../../assets/images/instawhite.svg";
import linkedin from "../../../assets/images/linkedinwhite.svg";
import planic from "../../../assets/images/planicwhite.svg";
import { Link } from 'react-router-dom';
import { getProperWebsite } from '../../../shared/helper';
import { formatCanadianPhoneNumber } from '../../../shared/helper';


export const InstituteDetail = (props) => {
  const { profileData } = props;

  const getInstitutionName = () => {
    let tempArr = [];
    profileData?.instituteCategories.map(cat => {
      tempArr.push(cat?.institutionCategory?.name)
    })

    return tempArr.join(', ');
  }

  

  const getProperLicense = (data) => {
    let tempArr = [];
    profileData?.instituteLicenses.map(cat => {
      tempArr.push(cat?.licenseNumber)
    })

    return tempArr.toString();
  }

  return <section className="publicviewprofiledetails institutepublicprofile">
    <div className="container">
      <div className="publicdeinner">
       <div className="publicmainpopdis">
        <div className="publicviewprofilelogoupload">
        <div className="publicviewprofilelogouploadinner">
          <img src={`${NEW_URL}/${profileData?.imageUrl}`}  alt={' '}/>
          </div>
          </div>
          <div className="publicviewprofilede">
          <h2 className="h2_style">{profileData?.name} </h2>
          <div className="cstlist">{getInstitutionName()}</div>
          <div className="licencenumber">{(getProperLicense() && profileData?.institutionSegmentId === 3) && <span>License No.: {getProperLicense()} </span>} </div>
        </div> 
        <div className="socialpublic d-flex">
                {profileData?.socialMedia?.facebookUrl && <li><span><a href={profileData?.socialMedia?.facebookUrl} target="_blank"  rel="noreferrer"  ><img src={facebook}  alt={' '}/></a></span></li>}
                {profileData?.socialMedia?.twitterUrl && <li><span><a href={profileData?.socialMedia?.twitterUrl} target="_blank"  rel="noreferrer" ><img src={twitter} alt={' '} /></a></span></li>}
                {profileData?.socialMedia?.instagramUrl && <li><span><a href={profileData?.socialMedia?.instagramUrl} target="_blank"  rel="noreferrer" ><img src={instagram} alt={' '} /></a></span></li>}
                {profileData?.socialMedia?.linkedinUrl && <li><span><a href={profileData?.socialMedia?.linkedinUrl} target="_blank"  rel="noreferrer" ><img src={linkedin}  alt={' '}/></a></span></li>}
        </div> 
        </div>
        
        <div className="publicpersonaldetails martop20">
          <div className="logemail">
            <ul className="deone">
              <li><span><img src={envelop} alt={' '} /></span> <a href={`mailto:${profileData?.email}`} target="_blank"  rel="noreferrer" >{profileData?.email}</a> </li>
              <li><span><img src={globe}  alt={' '}/></span> <a href={getProperWebsite(profileData?.website)} target="_blank"  rel="noreferrer" >{getProperWebsite(profileData?.website)}</a> </li>
            </ul>
            <ul className="detwo">
              <li><span><img src={call} alt={' '} /></span> <a href={`tel:${profileData?.contactNumber}`}> {formatCanadianPhoneNumber(profileData?.contactNumber)}</a> </li>
              <li><span><img src={map}  alt={' '}/></span> <Link target="_blank"  rel="noreferrer"  to={{ pathname: `https://maps.google.com/maps?q=${profileData?.address}` }}>{profileData?.address}</Link>  </li>
            </ul>
            <ul className="dethree">
             
              
            </ul>
          </div>
        </div>
        <div class="ppvplan"><img src={planic}  alt={' '}/>{profileData?.payments[0]?.subscriptionPlan?.planName ?? ''}</div>
      </div>
    </div>
  </section>
}