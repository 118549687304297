import React from 'react'
import { useEffect, useState } from 'react';
import call from "../../../../assets/images/call.svg";
import map from "../../../../assets/images/map.svg";
import { Pagination } from '../../../common/pagination/pagination';

import envelop from "../../../../assets/images/envelop.svg";
import InputMask from 'react-input-mask';
import { uploadURL, NEW_URL } from '../../../../constants/applicationConstants'; import { Footer } from '../../../common/footer/footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import { baseURL } from '../../../../constants/applicationConstants.js';
import axios from 'axios';


const SearchInstituteJsx = (props) => {
  const { t } = useTranslation();
  const {
    segment,
    categoryData,
    formData,
    onInputChange,
    formError,
    onTouchHandler,
    onPaginationButtonClick,
    formSubmitButtonDisable,
    onSubmitButtonClick,
    expertData,
    onViewProfieClick
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])
  const getCategory = (data) => {
    const dt = data.map(dt => dt.name);
    return [...new Set(dt)].join(', ');
  }



  const RESULTS_PER_PAGE = 10;

  const [posts, setPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [fetch, setFetch] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(0);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  const fetchTotalPosts = async () => {



    setSearchLoading(true)
    axios.get(`${baseURL}/filter-institutes`).then(res => {
      
      const data = res.data.data;
      setTotalPosts(data.length);
      setSearchLoading(false)


    })

  };

  const fetchPosts = async () => {
    setFetch(true)
    const { name, postalCode, category, segment } = formData;
    if (postalCode) {
      setSearchLoading(true)
      axios.get(`${baseURL}/filter-institutes?page=${page}&perPage=10&name=${name}&postalCode=${postalCode}&institutionCategoryId=${category}&institutionSegmentId=${segment}`).then(res => {
        const data = res.data.data;
        setFetch(false)
        setVisible((prev) => prev + data.length);
        setPosts((prev) => [...prev, ...data]);
        setMeta(res?.data?.meta)
        setSearchLoading(false)


      })
    }




  };

  const fetchPosts1 = async () => {
    setPage(1)
    const { name, postalCode, category, segment } = formData;
    if (postalCode) {
      setSearchLoading(true)
      axios.get(`${baseURL}/filter-institutes?page=${page}&perPage=10&name=${name}&postalCode=${postalCode}&institutionCategoryId=${category}&institutionSegmentId=${segment}`).then(res => {
        const data = res.data.data;
        setFetch(false)
        setVisible((prev) => prev + data.length);
        setPosts((prev) => [...prev, ...data]);
        setMeta(res?.data?.meta)
        setSearchLoading(false)


      })
    }




  };
  const multi = () =>{
    fetchPosts1()
    setPosts([])
  }

  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };


  useEffect(() => {
    fetchTotalPosts();
  }, []);

  useEffect(() => {
    fetchPosts();
  }, [page]);

  return (
    <>
      <section className="searchpageherosection">
        <div className="container">
          <div className="searchinnerhero">
            <h2 className="h2_style">{t("SEARCHINSTITUTE.SEARCHTITLE")} </h2>
            <h4 className="h4_style"><i>{t("SEARCHINSTITUTE.SEARCHSUBTITLE")}</i> <br />
              <b>{t("SEARCHINSTITUTE.SEARCHSUBTITLE1")} </b><br />
              {/* {t("SEARCHINSTITUTE.SEARCHSUBTITLE2")} */}
            </h4>

          </div>
          <div className="myexpertformarea searcharea martop40">
            <div className="input-container form-group">
              <InputMask mask={'a9a 9a9'} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                {(inputProps) => <input {...inputProps}
                  type="text"
                  className={
                    formError['postalCode']
                      ? "input form-control  is-invalid"
                      : "input form-control"
                  }
                  placeholder=" "
                  disableUnderline />}
              </InputMask>
              <div className="placeholder">Postal Code *</div>
            </div>
            <div className="input-container form-group">
              <input
                type="text"
                className="form-control input"
                placeholder=" "
                name="name"
                onChange={onInputChange}
                onBlur={onTouchHandler}
                value={formData.name}
              />
              <div className="placeholder">Name of Institution</div>
            </div>
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="segment"
                onChange={onInputChange}
                onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {segment?.map((item, i) => {
                  return (
                    <>
                      <option value={item.institutionSegmentId} selected={item.institutionSegmentId === formData.subCategory}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Segment</div>
            </div>
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="category"
                value={formData.category}
                onChange={onInputChange}
                onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {categoryData?.map((item, i) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Category</div>
            </div>
            <div className="input-container form-group">
              <button type="submit" className="ctaonebutton" disabled={formSubmitButtonDisable} onClick={() => multi()}>Search Institutions</button></div>

          </div>
        </div>
      </section>
      <section className="searchresultsection">
        <div className="expertsection martop30 mb-5">
          <div className="container">
            <h2 className="h2_style mt-5 mb-4 opacity0">Search Results:</h2>
            {searchLoading===true && <div className="loadingResult"><h4>Loading</h4></div>}
            {
              posts.length > 0 ?
                posts?.map(data => {
                  return <div className="expertinner">
                    <div className="expertuser"><img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data?.name}&rounded=true` : `${NEW_URL}/${data.imageUrl}`} alt={' '} /></div>
                    <div className="experdetails">
                      <h3>{`${data?.name}`} <span><div className="expcat">{`${getCategory(data?.instituteCategories)}`}</div></span></h3>

                      <ul>
                        {data.city && <li><span><img src={map} alt={' '} /></span>{data?.city} </li>}
                        {data.contactNumber && <li><span><img src={call} alt={' '} /></span>  {formatCanadianPhoneNumber(data?.contactNumber)}</li>}
                        {data.email && <li><span><img src={envelop} alt={' '} /></span>{data?.email}  </li>}
                      </ul>
                    </div>
                    {(data.status == "unverified" || data.status == "verified") ? null : <>{data?.formattedLegalName && <div className="viewprofile"><Link to={`/institute/${data?.formattedLegalName}`} className="ctaonebutton" target="_blank" rel="noreferer">View Profile</Link></div>}
                    </>}
                    {data?.planName && <div className="expertpremium">{data?.planName}</div>}
                  </div>
                }) :
                <div className="opacity1">No Institute Found</div>
            }
          </div>
          <div class="text-center">
          {page < (meta?.totalPages || 1) &&               <button className="widthhalf ctaonebutton center" onClick={handleOnLoadMore}>{fetch ? "Loading....." : "Load More"}</button>
}</div>
          
          {/* <Pagination
            limit={10}
            totalCount={expertData?.meta?.totalCount}
            onPaginationButtonClick={onPaginationButtonClick}
          /> */}
        </div>
      </section>

      <Footer />



      {/* <div className="container">
        <h1>Load More Example in React</h1>
        {error && <div>{error}</div>}
        {posts.map((post) => (
          <div className="post" >
            <h2>{post.name}</h2>

          </div>
        ))}

        {totalPosts > 0 ? (
          visible < totalPosts ? (
            <div>
              <button onClick={handleOnLoadMore}>Load More</button>
            </div>
          ) : (
            <div>
              <div>Sorry, that's all folks! No more to load.</div>
            </div>
          )
        ) : null}
      </div> */}
    </>

  );
};

export default SearchInstituteJsx;
