import React from 'react'
import { Component } from 'react';
import ManageExpertJsx from './manageExpert.jsx'

export default class ManageExpert extends Component{
    render(){
        return(
            <div>
                <ManageExpertJsx />
            </div>
        )
    }
}