import React from 'react'
import moment from 'moment';
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { Footer } from '../../../common/footer/footer';
import { uploadURL,NEW_URL } from '../../../../constants/applicationConstants';import Sidebar from "../sidebar/sidebar.js";
import { Link } from "react-router-dom";
import dropbox from "../../../../assets/images/dropbox.svg";

const ExpertAnswerJsx = (props) => {
  const {
 questionData, answerData
  } = props;


  // {t("PRINT.TITLE")}
  return (
    <>
      <section class="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlinkpop martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40 mb-5">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">QnA - Pending Questions</h2>
                    <h5 className="h5_style">Providing quality answers enhances your reputation and increases the likelihood of attracting new clients
</h5>
                    <h5 className="h5_style">Also, it helps you gain insight into customer’s needs, pain points, and interests.</h5>
                    <h5 className="h5_style fst-italic fw-light">Don’t miss the chances of securing new business. Answer all your pending questions now!</h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" class="modal-window">
                      <div>
                        <a  title="Close" class="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myexpertnav myaccountnav qnanav martop40">
                  <ul>
                    <li > <Link to={'/expertQna'}>Pending Questions<span>{questionData?.length}</span></Link></li>
                    <li className="active"><Link to={'/expertAnswer'}>Answered Questions<span>{answerData?.length}</span></Link></li>
                  </ul>
                </div>

                <div className="questioninner mt-4">

                  {/* <div className="questioninnerin mb-3">
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........</p>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage} alt={''} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                      <button type="button" className="ctaonebutton">Answer Now</button>
                    </div>
                  </div> */}
                  {answerData && answerData.length ?
                    <> {
                      answerData?.map((data, index) => {
                        return <div className="questioninnerin mt-3 mb-3">
                          <h5 className="h5_style">{data.askExpertQuestionUserResponse.question} </h5>

                          <div className="d-flex questioninnerinbottom">
                            <div className="expertquestiondetails">
                              <div className="expertuser"><img src={!data?.imageUr ? `https://ui-avatars.com/api/?name=${data.expert?.firstName}+${data.expert?.lastName}&rounded=true` : `${NEW_URL}/${data?.imageUr}`}  alt={''}/></div>
                              <div className="questionid"><span>Question ID -</span> {data?.askExpertQuestionUserResponseId}<div className="questiondate">{moment(answerData.createdAt).format('DD-MM-YYYY')}</div></div>
                              {/* <div className="questionid"><strong>QUESTION: </strong>{data?.askExpertQuestionUserResponse?.question}</div> <br/>

                            <div className="questionid"><strong>ANSWER: </strong>{data?.answer}</div> */}
                            </div>
                            <div className="expertquestiondetailscategory"><span>{data.askExpertQuestionUserResponse.parentInstitutionCategory}</span></div>

                          </div>
                          <p><strong>ANSWER: </strong>{data?.answer}</p>

                        </div>
                      })
                    }
                    </> : 
                    <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox}  alt={''}/>
                    </div>
                    <div className="dropdefaulttext">
                      <h5>You don't have any Answer. </h5>
                    </div>
                  </div>
                    }


                  {/* <div className="questioninnerin mb-3">
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........</p>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                      <button type="button" className="ctaonebutton">Answer Now</button>
                    </div>
                  </div>
                  <div className="questioninnerin mb-3">
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........</p>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                      <button type="button" className="ctaonebutton">Answer Now</button>
                    </div>
                  </div>
                  <div className="questioninnerin mb-3">
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........</p>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                      <button type="button" className="ctaonebutton">Answer Now</button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>

  );
};

export default ExpertAnswerJsx;
