import React from 'react'
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import ProductList from "./productsList.jsx"
import { TagsInput } from "react-tag-input-component";
import { ToastContainer } from 'react-toastify';
import { NEW_URL } from '../../../../constants/applicationConstants';
const ProductsJsx = (props) => {
    const {
        productFormData,
        categoryData,
        subCategoryData,
        productTypes,
        changeHideState,
        formError,
        imgURL,
        hideAdd,
        profileImagePreviewUrl,
        onFileChange,
        _handleUploadImage,
        onInputChangeHandler,
        getProducts,
        getParticularProducts,
        onTouchHandler,
        formSubmitButtonDisable,
        produtsList,
        onMetaTagChange,
        onTabClick,
        productCount
    } = props;
    const editMode = sessionStorage.getItem("editProductId")
    const limitData = JSON.parse(sessionStorage.getItem('loginData'));
    const {

    } = props;


    const openEditBox = () => {
        window.scrollTo({
            top: 500,
            left: 100,
            behavior: 'smooth'
        });
    }
    // const onFileChange = (e) => {
    //         setFileInfo(e.target.files[0])
    //         setProfileImagePreviewUrl(URL?.createObjectURL(e.target.files[0]))
    // }

    // const _handleUploadImage = () => {
    //     if (fileInfo) {
    //         formData1.append('image', fileInfo)
    //         formData1.append('imageCategory', "institute-product")
    //     }

    //     axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {

    //         if (resp.status === 200) {
    //             localStorage.setItem("productImg", resp.data.filename)
    //             toast.success("Product Image uploaded successfully");
    //             getIMAGEuRL(resp.data.filename)
    //             // setIsLoading(false)
    //             // window.location.reload()
    //         } else {
    //             //alert("error")
    //             // setIsLoading(false)
    //         }
    //     }).catch(() => {
    //         //   setIsLoading(false)
    //     })
    // };


    // {t("PRINT.TITLE")}
    return (
        <>
            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="">
                            <section classNames="addexpertformsec">
                                <div className="dashboardrightpanel martop40">
                                    <div className="addexpert martop40">
                                        <div className="addexpertleft">Quickly Add your Product. All Added products appear on public profile page
                                        <div className="userguide d-block">


                                                <div class="dropdown-toggle userdropdown-toggle text-decoration-underline" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false"> <img src={planpricingtooltip} alt={''} /> Help Guide  </div>
                                                <ul className="dropdown-menu userdropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <li className="dropdown-item">How to create a new Ad?
    <ul className="dropdown-menu dropdown-submenu">
                                                            <li className="dropdown-item">1. Click on “Create New Product” </li>
                                                            <li className="dropdown-item">2. Upload the product image  </li>
                                                            <li className="dropdown-item">3. Enter the Product Name </li>
                                                            <li className="dropdown-item">4. Enter Description, Benefits & Features </li>
                                                            <li className="dropdown-item">5. Put the applicable Interest Rate & Meta Tags related to your product </li>
                                                            <li className="dropdown-item">6. Add the link to the product on your website  </li>

                                                        </ul>
                                                    </li>
                                                    <li><a className="dropdown-item" type="button"> How to edit an existing product? </a>
                                                        <ul className="dropdown-menu dropdown-submenu">
                                                            <li><a className="dropdown-item"  >1. Click on the “Edit” option  </a></li>

                                                            <li><a className="dropdown-item"  >2. A pop-up window will appear, where you can edit all the input fields
       </a></li>
                                                            <li><a className="dropdown-item"  >3. Click on “Submit” to save the changes   </a></li>


                                                        </ul>
                                                    </li>
                                                    <li><a className="dropdown-item" type="button">How to delete an existing product?  </a>
                                                        <ul className="dropdown-menu dropdown-submenu">
                                                            <li><a className="dropdown-item"  >1. Click on the “Delete” option   </a></li>


                                                        </ul>
                                                    </li>
                                                    <li><a className="dropdown-item" type="button">How to add more products?  </a>
                                                        <ul className="dropdown-menu dropdown-submenu">
                                                            <li><a className="dropdown-item"  >You can upload 20 products with the Premium plan and 60 products with the Elite plan. You must delete any of your existing products to add more.   </a></li>

                                                        </ul>
                                                    </li>
                                                    <li><a className="dropdown-item" type="button">How to set the status of an Ad – Active or Inactive? </a>
                                                        <ul className="dropdown-menu dropdown-submenu">
                                                            <li><a className="dropdown-item"  >There is a switch below every Ad   </a></li>
                                                            <li><a className="dropdown-item"  >When you toggle to the right side, the Ad will become Active  </a></li>
                                                            <li><a className="dropdown-item"  >When you toggle to the left side, the Ad will become Inactive </a></li>


                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="addexpertright">

                                            <button type="button" disabled={productCount === limitData?.totalProductsLimit} className="ctaonebutton" onClick={() => changeHideState(true)}>Create New Product</button>
                                            {productCount === limitData?.totalProductsLimit && <p className="text-danger">Maximum Products Limit Reached !</p>}
                                        </div>
                                    </div>
                                    <section classNames="addexpertformsec">
                                        <br />


                                        {hideAdd && <div className="expertformmain">

                                            <div className="expertinnerformmain" id="abhinaba">
                                                <div className="expertformmainheader">
                                                    <div className="expertformmainheaderleft">
                                                        <h3>Create New Product</h3>

                                                    </div>
                                                    <div className="expertformmainheaderright">
                                                        <button type="button" className="myadbtn" onClick={() => changeHideState(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-4">
                                                        <div className="uploadproduct mb-4">
                                                            {/* {editMode ?
                                                                <div className="uploadpd">
                                                                    {profileImagePreviewUrl ? <img
                                                                        src={`${NEW_URL}/${imgURL}`}
                                                                        height="170px"
                                                                        width="250px"
                                                                        alt="edit profile"
                                                                    /> : null}
                                                                </div> :
                                                                <div className="uploadpd">
                                                                    {profileImagePreviewUrl ? <img
                                                                        src={profileImagePreviewUrl}
                                                                        height="170px"
                                                                        width="250px"
                                                                        alt="edit profile"
                                                                    /> : null}
                                                                </div>
                                                            } */}
                                                            {
                                                                
                                                                profileImagePreviewUrl ?
                                                                <div className="uploadpd">
                                                                    <img
                                                                        src={profileImagePreviewUrl}
                                                                        height="170px"
                                                                        width="250px"
                                                                        alt="edit profile"
                                                                    /> </div> : 
                                                                    editMode ?
                                                                    <div className="uploadpd">
                                                                        <img
                                                                            src={`${NEW_URL}/${imgURL}`}
                                                                            height="170px"
                                                                            width="250px"
                                                                            alt="edit profile"
                                                                        />
                                                                        </div>
                                                                        :  <div className="uploadpd"></div>
                                                            }

                                                            <div className="button_outer">
                                                                <div className="btn_upload">
                                                                    <input type="file" id="upload_file" name=""
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        onChange={onFileChange}
                                                                    />
                                                                    <label for="upload_file" >Upload Image *</label>
                                                                </div>
                                                            </div>
                                                            {/* <button disabled={!profileImagePreviewUrl} className="ctaonebutton" onClick={() => _handleUploadImage()}>Submit</button> */}
                                                        </div>



                                                    </div>
                                                    <div className="col-lg-8 col-sm-8">
                                                        <div className="disgrid grid-3">
                                                            <div className="mb-4 input-container form-group">
                                                                <select className="input form-control form-select" onChange={onInputChangeHandler} name="category">
                                                                    <option value="">Select Category</option>
                                                                    {categoryData?.map((item, i) => {
                                                                        return (
                                                                            <>
                                                                                <option value={item.institutionCategoryId} selected={item.institutionCategoryId === productFormData.category}>{item.name}</option>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </select>
                                                                <label className="placeholder">Category *</label>
                                                            </div>
                                                            <div className="mb-4 input-container form-group">
                                                                <select className="input form-control form-select" onChange={onInputChangeHandler} name="subCategory">
                                                                    <option value="">Select Sub-Category</option>
                                                                    {subCategoryData?.map((item, i) => {
                                                                        return (
                                                                            <>
                                                                                <option value={item.institutionCategoryId} selected={item.institutionCategoryId === productFormData.subCategory}>{item.name}</option>
                                                                            </>
                                                                        )
                                                                    })}
                                                                    {/* <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                                <option>4</option> */}
                                                                </select>
                                                                <label className="placeholder">Sub-Category *</label>
                                                            </div>
                                                            <div className="mb-4 input-container form-group">
                                                                <select className="input form-control form-select" onChange={onInputChangeHandler} name="productTypeId">

                                                                    {productTypes?.length <= 0 ? <option value={null}>No Product Available</option> : <option value="">Select Product</option>}
                                                                    {productTypes?.map((item, i) => {
                                                                        return (
                                                                            <>
                                                                               {item?.name && <option value={item.productTypeId} selected={item.productTypeId == productFormData.productTypeId}>{item.name}</option>}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </select>
                                                                <label className="placeholder">Product Type </label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-4 input-container form-group">
                                                            <input className="input form-control" placeholder=" "
                                                                name="product"
                                                                value={productFormData.product}
                                                                onChange={onInputChangeHandler}
                                                                onBlur={onTouchHandler}
                                                                maxLength={50}
                                                            />
                                                            <span className="text-danger">
                                                                {formError['product'] &&
                                                                    (formError['product'] === "req"
                                                                        ? 'product is required'
                                                                        : 'please enter valid product name')}
                                                            </span>
                                                            <label className="placeholder">Product Name * </label>

                                                        </div>
                                                        <div className="mb-4 input-container form-group">
                                                            <textarea className="input form-control descriptiontextarea" placeholder=" "
                                                                name="description"
                                                                value={productFormData.description}
                                                                onChange={onInputChangeHandler}
                                                                onBlur={onTouchHandler}
                                                                maxLength={250}
                                                            ></textarea>
                                                            <span className="text-danger">
                                                                {formError['description'] &&
                                                                    (formError['description'] === "req"
                                                                        ? 'description is required'
                                                                        : '')}
                                                            </span>
                                                            <label className="placeholder">Description *</label>
                                                        </div>
                                                        <div className="disgrid grid-2">
                                                            <div className="mb-4 input-container form-group">
                                                                <textarea className="input form-control descriptiontextarea" placeholder=" "
                                                                    name="benefits"
                                                                    value={productFormData.benefits}
                                                                    onChange={onInputChangeHandler}
                                                                    onBlur={onTouchHandler}
                                                                    maxLength={250}
                                                                ></textarea>
                                                                <span className="text-danger">
                                                                    {formError['benefits'] &&
                                                                        (formError['benefits'] === "req"
                                                                            ? 'benefits is required'
                                                                            : '')}
                                                                </span>
                                                                <label className="placeholder">Benefits *</label>
                                                            </div>
                                                            <div className="mb-4 input-container form-group">
                                                                <textarea className="input form-control descriptiontextarea" placeholder=" "
                                                                    name="features"
                                                                    value={productFormData.features}
                                                                    onChange={onInputChangeHandler}
                                                                    onBlur={onTouchHandler}
                                                                    maxLength={250}
                                                                ></textarea>
                                                                <span className="text-danger">
                                                                    {formError['features'] &&
                                                                        (formError['features'] === "req"
                                                                            ? 'features is required'
                                                                            : '')}
                                                                </span>
                                                                <label className="placeholder">Features *</label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-4 input-container form-group">
                                                            <input className="input form-control" placeholder=" "
                                                                name="interestRate"
                                                                type="number"
                                                                value={productFormData.interestRate}
                                                                onChange={onInputChangeHandler}
                                                            />
                                                            <label className="placeholder">Interest Rate </label>
                                                        </div>
                                                        {/* <div className="mb-4 input-container form-group">
                                                        <input className="input form-control" placeholder=" "
                                                            name="metaTags"
                                                            value={productFormData.metaTags}
                                                            onChange={onInputChangeHandler}
                                                        />
                                                        <label className="placeholder">Product Meta Tags</label>
                                                    </div> */}

                                                        {/*  */}


                                                        <div className="mb-4 input-container form-group specialtag">
                                                            <div className="normal" id="tagInput"
                                                                onFocus={() => {
                                                                    let elem = document.getElementById("tagInput");
                                                                    elem.classList.add("my-classfocus");
                                                                }}
                                                                onBlur={() => {
                                                                    let elem = document.getElementById("tagInput");
                                                                    elem.classList.remove("my-classfocus");
                                                                }}

                                                            >
                                                                <TagsInput
                                                                    value={productFormData.metaTags}
                                                                    onChange={onMetaTagChange}
                                                                    label="xxxxxxxxx"
                                                                    name="metaTags"
                                                                    placeHolder=" "
                                                                    classNames={{ tag: 'tag-clsxxxx', input: 'input form-control' }}
                                                                />
                                                            </div>
                                                            <label className="placeholder">Meta Tags<span className="plantooltip custooltip" ><img src={planpricingtooltip} className="tooltipim" />
                                                                <div class="tooltipcontent"> Please ensure that you add specific and relevant meta tags. To enter multiple meta tags, hit Enter after adding each one. We advise against using too many tags or repeating them excessively. </div>
                                                            </span></label>
                                                        </div>
                                                        {/*  */}
                                                        <div className="mb-4 input-container form-group">
                                                            <input className="input form-control" placeholder=" "
                                                                name="externalLink"
                                                                value={productFormData.externalLink}
                                                                onChange={onInputChangeHandler}
                                                                onBlur={onTouchHandler}
                                                            />
                                                            <label className="placeholder">External Link</label>
                                                            <span className="text-danger">
                                                                {formError['externalLink'] &&
                                                                    (formError['externalLink'] === "req"
                                                                        ? ''
                                                                        : 'Enter a valid externalLink')}
                                                            </span>
                                                        </div>

                                                        <div className="mb-4 input-container form-group">
                                                            <button type="button" disabled={!editMode && formSubmitButtonDisable} className="ctaonebutton" onClick={() => _handleUploadImage()} >{!editMode ? "Submit" : "Update"}</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </section>
                                    <ProductList
                                        openEditBox={openEditBox}
                                        getProducts={getProducts}
                                        getParticularProducts={getParticularProducts}
                                        produtsList={produtsList}
                                        onTabClick={onTabClick}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />

        </>

    );
};

export default ProductsJsx;
