import React from 'react'
import { useEffect,useState } from 'react';
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import dropbox from "../../../assets/images/dropbox.svg";
import { uploadURL,NEW_URL } from '../../../constants/applicationConstants';
import cross from "../../../assets/images/cross.svg";
import Mortgagebanner from "../../../assets/images/Mortgagebanner.png";
import {useLocation} from 'react-router-dom'
const MortgageJsx = (props) => {
  const {
    offerData,
    adData,
    expertData,
    blogData,
    userData,
    onTouchHandler,
    instituteData,
    onInputChangeHandler,
    onOfferSearchButtonClick,
    onAdSearchButtonClick,
    onExpertClick,
    onInstituteClick,subCategoryData
  } = props;
  const  {hash} = useLocation()
  useEffect(() => {
    AOS.init();
    if(hash)
    {
      document.getElementById(hash.replace('#','')).scrollIntoView()
    }else
    {
    //window.scrollTo(0, 0);
    }

  }, [])
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
     centerMode: false,
    centerPadding: '20px',
//  initialSlide:2    
responsive: [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 2
    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
]  
};

const [openModal, setOpenModal] = useState(false);
const [blog, setBlog] = useState(null);

  // {t("PRINT.TITLE")}
  return (
    <>
     <section className="herosectionoffer categorysectiondis d-none d-sm-block">
      <img src={Mortgagebanner} alt=""/>
      <Link to={'/AskTheExpert'} className="ctaonebutton">Ask the Experts</Link>
    </section>
      <section className="homecustom">
        {/* <section className="herosection backwhite">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6">
                <div className="heroleftpanel" data-aostesttesttesttest="fade-right">
                  <h2>Mortgage Solutions</h2>
                  <h6 className="h6_style">Don't let your mortgage be a burden. Get reliable solutions at no cost! <br />
                  RIMIB connects you with the licensed mortgage experts. <br />
                  Ask them today & take the first step towards a brighter future!</h6>
                  <div className="heroleftbutton d-flex mt-4">
                    <Link to={'/AskTheExpert'} className="ctaonebutton"> Ask the Expert</Link>

                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="herorightpanel" data-aostesttesttesttest="fade-left">
                  <img src={Consultingrafiki}  alt={' '} />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="homepageproductoffer mt-5 mb-5" id="homemortgage">
          <div className="container">
            <h2 className="text-center mb-3" data-aostesttesttesttest="fade-top">Latest Offers by Top Institutions</h2>
            <h6 className="h6_style text-center" data-aostesttesttesttest="fade-top">Explore unbeatable deals and enjoy big savings with RIMIB!</h6>
            <h6 className="h6_style text-center">Canada's top mortgage institutions are partnering with us to bring you exclusive offers.</h6>
            <h6 className="h6_style text-center">Don't let these amazing deals slip away – explore them today and start saving big on your mortgage needs!</h6>
            <div className="myexpertformarea searcharea searchmainara searchmainarainner martop40 mb-5 width100">
              <div className="input-container form-group">
                <select className="input form-control form-select" placeholder=" " name="productType" onChange={onInputChangeHandler}>
                <option value='0'>All</option>
                {subCategoryData?.map((item) => {
                                return (
                                  <>
                                    <option value={item.institutionCategoryId}>{item.name}</option>
                                  </>
                                )
                              })}
                </select>
                <div class="placeholder">Types</div>
              </div>
              <div className="input-container form-group">
                <input type="text" className="input form-control" placeholder=" " name='productName' onChange={onInputChangeHandler} onBlur={onTouchHandler} value={userData.productName}/>
                <div class="placeholder">Enter the product name you want to search for</div>
              </div>
              <div className="input-container form-group">
                <button type="submit" className="ctaonebutton" onClick={onOfferSearchButtonClick}>Search</button>
              </div>
            </div>
            <div className="row">
              {
                offerData?.length > 0 ? offerData?.map(offer => {
                  return <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-5">
                    <div className="offer-insurence-inner" data-aostesttesttesttest="flip-left">
                      <div className="offer-image"><img src={`${NEW_URL}/${offer?.imageUrl}`}  alt={' '} /></div>
                      <div className="offer-name mt-3 mb-3 disfontsmall">{offer?.offerName}</div>
                      <div className="posbuttontf d-flex">
                      <a 
                        href={offer?.externalUrl} target="_blank"
                        className="ctaonebutton text-center"
                        >
                        Apply Now                            </a>
                        <Link
                        to={{
                          pathname: "/detailedOffer",
                          state: { data: offer,  frompage: 'homemortgage' ,pagePath:'/mortgage' }
                        }}
                        className="ctatwobutton text-center ctanewtwobutton"
                        onClick={() => window.scrollTo(0, 0)}>
                        View Details
                            </Link>
                           
                            </div>
                    </div>
                  </div>
                }) :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5 className="opacity0">No Record Found !! </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </section>

        <section className="homepageproductoffer mt-5 mb-5">
          <div className="container">
            {/* <h2 className="text-center" data-aostesttesttesttest="fade-down">Best quotes for you!</h2>
            <h4 className="mt-3 text-center" data-aostesttesttesttest="fade-left">With so many mortgage options out there, it can be hard to know where to start. </h4>
            <h4 className="mt-3 text-center" data-aostesttesttesttest="fade-bottom"><b>Let us help you get clear, straightforward & personalized mortgage quotes.</b></h4>
            <h4 className="mb-5 text-center" data-aostesttesttesttest="fade-right">So, what type of mortgage are you interested in? Just click on “Get Quotes” to begin.</h4>
            <div className="row mb-4">
              <div className="col">
                <div className="homepageexpertinner backan" data-aostesttesttesttest="flip-left">
                  <div className="homepageexpertinnername mt-3">Rate Approval</div>
                  <div className="homepageexpertinneruser"><img src={rateapproval}  alt={' '} /></div>
                  <Link to={'/#'} className="prifileview mt-3">Get Quotes</Link>
                  
                </div>
              </div>
              <div className="col">
                <div className="homepageexpertinner backan" data-aostesttesttesttest="flip-left">
                  <div className="homepageexpertinnername mt-3">  Pre-Approval</div>
                  <div className="homepageexpertinneruser"><img src={preapproval}  alt={' '} /></div>
                  <Link to={'/#'} className="prifileview mt-3">Get Quotes</Link>
                </div>
              </div>
              <div className="col">
                <div className="homepageexpertinner backan" data-aostesttesttesttest="flip-left">
                  <div className="homepageexpertinnername mt-3">Purchase</div>
                  <div className="homepageexpertinneruser"><img src={purchase} alt={' '}  /></div>
                  <Link to={'/#'} className="prifileview mt-3">Get Quotes</Link>
                </div>
              </div>
              <div className="col">
                <div className="homepageexpertinner backan" data-aostesttesttesttest="flip-left">
                  <div className="homepageexpertinnername mt-3">HELOC</div>
                  <div className="homepageexpertinneruser"><img src={heloc}  alt={' '} /></div>
                  <Link to={'/#'} className="prifileview mt-3">Get Quotes</Link>
                </div>
              </div>
            </div> */}

            <h2 className="h2_style text-center">Top Mortgage Institutions in Canada</h2>
            <h6 className="h6_style text-center fw-lighter">RIMIB has partnered with Canada's top mortgage institutions to provide you with a convenient and comprehensive way to access the best deals on your home financing needs. Whether you're a first-time homebuyer or a seasoned homeowner, we help you achieve your goals. Experience the peace of mind that comes with working with Canada's top mortgage institutions.</h6>

            <div className="row">
            {
                instituteData?.length>0 ? instituteData?.map(institue=> {
                  return <div className="col-lg-4 col-sm-4 mt-5">
                  <div className="colinstititeinner" onClick={()=> onInstituteClick(institue)}>
                    <div className="colinstititeinnerimage text-center"><img src={`${NEW_URL}/${institue?.imageUrl}`}  alt={' '}  /></div>
                    <div className="colinstititeinnername text-center">{institue?.name}</div>
                  </div>
                </div>
                }):
                <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt={' '} />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !! </h5>
                </div>
              </div>
              }
                </div>
          </div>

        </section>

        <section className="homepageproductoffer mt-5 mb-5">
          <div className="container">
            <h2 className="text-center mb-3" data-aostesttesttesttest="fade-top">Latest Ads by Top Institutions</h2>
            <h6 className="h6_style text-center" style={{margin:'0'}}>Stay up-to-date! These are the latest Ads from Canada's top mortgage institutions.</h6>
            <h6 className="h6_style text-center"><i>Don't miss out on any opportunities to save money or to find the mortgage option. Explore now!</i></h6>
            <div className="myexpertformarea searcharea searchmainara searchmainarainner martop40 mb-5">
              <div className="input-container form-group width100border">
                <select className="input form-control form-select" placeholder=" " name="adType" onChange={onInputChangeHandler}>
                <option value='all'>All</option>
                {subCategoryData?.map((item) => {
                                return (
                                  <>
                                    <option value={item.institutionCategoryId}>{item.name}</option>
                                  </>
                                )
                              })}
                </select>
                <div class="placeholder">Types</div>
              </div>
              <div className="input-container form-group">
                <button type="submit" className="ctaonebutton"  onClick={onAdSearchButtonClick}>Search</button>
              </div>
            </div>
            <div className="row">
              {
                adData?.length > 0 ? adData?.map(data => {
                  return <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-5">
                    <div className="offer-insurence-inner" data-aostesttesttesttest="flip-left">
                      <div className="offer-image"><img src={`${NEW_URL}/${data?.imageUrl}`}  alt={' '} /></div>
                      <div className="offer-name mt-3 mb-3">{data?.subInstitutionCategory?.name}</div>
                      {data?.externalLink &&  <button onClick={()=> {window.scrollTo(0,0); window.open(data.externalLink,'_blank')}} className="ctatwobutton mb-3 text-center">Know More</button>}
                    </div>
                  </div>


                }) :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5 className="opacity0">No Record Found !! </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </section>
        <section className="homepageproductoffer mt-5 mb-5">
          <div className="container">
            <h2 className="text-center" data-aostesttesttesttest="flip-left" data-aostesttesttesttest-easing="ease-out-cubic"
              data-aostesttesttesttest-duration="2000">Top mortgage Experts in Canada</h2>
            <h5 className="text-center mt-3 h5_style" data-aostesttesttesttest="flip-left" data-aostesttesttesttest-easing="ease-out-cubic"
              data-aostesttesttesttest-duration="2000">Get the best mortgage deals & tailored responses. We've partnered with Canada's top mortgage experts, providing you with a range of options that suit your unique needs. Whether you are looking for fixed-rate, variable-rate, high-ratio or conventional mortgages, our partnered experts will help you in every step of the way.  <b><i>Take the first step towards financial security today! </i></b></h5>

<div className="p">
              
              { expertData?.length > 0 ? 
              
              <Slider {...settings}> 
                  {expertData?.map(data => {
                    return (                  
                      <div className="expertinnsearch" data-aostesttesttesttest="flip-right" data-aostesttesttesttest-duration="1000" onClick={()=> onExpertClick(data)}>
                        <div className="expertinnsearchimage"><img src={`${NEW_URL}/${data?.imageUrl}`}  alt={' '}  /></div>
                        <div className="expertinnsearchname">{`${data.firstName} ${data.lastName}`}</div>
                      </div>                  
                    )
                  }) }
              </Slider>
              :
                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5 className="opacity0">No Record Found !! </h5>
                  </div>
                </div>
              }
          </div>
          </div>
        </section>


        <section className="homepageproductoffer">
          <div className="container">

            <div className="d-flex latestratesflex">
              <div data-aostesttesttesttest="fade-left">
                <h4 className="mt-3" data-aostesttesttesttest="flip-left" data-aostesttesttesttest-easing="ease-out-cubic"
                  data-aostesttesttesttest-duration="2000">Don't let mortgage worries keep you up at night. </h4>
                <h4 className="" data-aostesttesttesttest="flip-left" data-aostesttesttesttest-easing="ease-out-cubic"
                  data-aostesttesttesttest-duration="2000"><i>RIMIB offers a modern way of solving all your questions.</i></h4>
                <h4 className="" data-aostesttesttesttest="flip-left" data-aostesttesttesttest-easing="ease-out-cubic"
                  data-aostesttesttesttest-duration="2000">Connect with the best mortgage experts & get responses based on your specific needs. </h4>
              </div>
              <div className="" data-aostesttesttesttest="fade-right" data-aostesttesttesttest-anchor-placement="bottom-bottom"><Link to={'/AskTheExpert'} className="ctaonebutton"> Ask the Experts</Link></div>
            </div>

          </div>
        </section>
        <section className="homepageproductoffer blogdetailsexpertppv padtop60">
          <div className="container">
            <h2 className="text-center" data-aostesttesttesttest="flip-left" data-aostesttesttesttest-easing="ease-out-cubic"
              data-aostesttesttesttest-duration="2000">Top Mortgage Blogs</h2>
            <h5 className="text-center mt-3" data-aostesttesttesttest="fade-left" data-aostesttesttesttest-anchor-placement="bottom-bottom">Stay up-to-date on the latest industry trends, and discover new ways to save money. </h5>
            <h5 className="text-center" data-aostesttesttesttest="fade-right" data-aostesttesttesttest-anchor-placement="bottom-bottom">Our platform offers a carefully curated selection of the best blogs from industry experts.</h5>
            <h5 className="text-center" data-aostesttesttesttest="fade-left" data-aostesttesttesttest-anchor-placement="bottom-bottom">Get valuable insights and advice on everything related to mortgage.</h5>
            <div className="row mt-5">
              {
                blogData?.body?.length > 0 ?
                  blogData?.body?.map(blog => {
                    return <div className="col-lg-4 col-sm-4">
                      <a onClick={() => {setBlog(blog); setOpenModal(true)}} >
                        <div className="bloginner mb-3">
                          <div className="bloginnerimage"><img src={blog?.image} alt={' '}  /></div>
                          <div className="bloginnertag mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                          <div className="bloginnertext mt-3">{blog?.title}</div>
                          <div className="bloginnerdate mt-3">{blog?.views} views  .  {blog?.date_publish}</div>
                        </div>
                      </a>
                    </div>
                  }) :

                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>You don't have any Blogs yet. </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </section>
        {/* <div className="homepageproductoffer ratesmortgage padtop60 mb-5">
          <div className="container">
            <h2 className="text-center mb-4" data-aostesttesttesttest="flip-left" data-aostesttesttesttest-easing="ease-out-cubic"
              data-aostesttesttesttest-duration="2000">Top Mortgage Rates</h2>
            <h5 className="text-center mt-3" data-aostesttesttesttest="fade-left" data-aostesttesttesttest-anchor-placement="bottom-bottom">Unlock the power of localized mortgage rates with RIMIB! </h5>
            <h5 className="text-center" data-aostesttesttesttest="fade-right" data-aostesttesttesttest-anchor-placement="bottom-bottom">Simply enter your postal code and instantly access the latest and most competitive rates available near you.</h5>
            <div className="padtop60">
              <div className="myexpertformarea searcharea searchmainara">
                <div className="input-container form-group width100border">
                  <input type="text" className="input form-control" placeholder=" " />
                  <div class="placeholder">Postal Code</div>
                </div>
                <div className="input-container form-group">
                  <button type="submit" className="ctaonebutton">Go</button>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <div className="innerratesmortgage homepageexpertinner backan mb-4">
                  <div className="row">
                    <div className="col">
                      <div className="innerratesmortgagepp" data-aostesttesttesttest="flip-left">

                        <div className="homepageexpertinneruser mt-3"><img src={icicilogo} /></div>
                        <div className="p-name mt-3">  Product Name</div>
                        <button type="button" className="ctaonebutton mt-3">View Details</button>
                      </div>
                    </div>
                    <div className="col">
                      <div className="innerratesmortgagepp" data-aostesttesttesttest="flip-left">
                        <div className="rateinterest">
                          <div className="rateinterest-sec">Rate of Interest</div>
                          <div className="rateinterest-percentage">5.30%</div>
                        </div>

                        <button type="button" className="ctaonebutton mt-3">Apply Now</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-6">
                <div className="innerratesmortgage homepageexpertinner backan mb-4">
                  <div className="row">
                    <div className="col">
                      <div className="innerratesmortgagepp" data-aostesttesttesttest="flip-left">

                        <div className="homepageexpertinneruser mt-3"><img src={icicilogo} /></div>
                        <div className="p-name mt-3">  Product Name</div>
                        <button type="button" className="ctaonebutton mt-3">View Details</button>
                      </div>
                    </div>
                    <div className="col">
                      <div className="innerratesmortgagepp" data-aostesttesttesttest="flip-left">
                        <div className="rateinterest">
                          <div className="rateinterest-sec">Rate of Interest</div>
                          <div className="rateinterest-percentage">5.30%</div>
                        </div>

                        <button type="button" className="ctaonebutton mt-3">Apply Now</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-6">
                <div className="innerratesmortgage homepageexpertinner backan mb-4">
                  <div className="row">
                    <div className="col">
                      <div className="innerratesmortgagepp" data-aostesttesttesttest="flip-left">

                        <div className="homepageexpertinneruser mt-3"><img src={icicilogo} /></div>
                        <div className="p-name mt-3">  Product Name</div>
                        <button type="button" className="ctaonebutton mt-3">Get Quotes</button>
                      </div>
                    </div>
                    <div className="col">
                      <div className="innerratesmortgagepp" data-aostesttesttesttest="flip-left">
                        <div className="rateinterest">
                          <div className="rateinterest-sec">Rate of Interest</div>
                          <div className="rateinterest-percentage">5.30%</div>
                        </div>

                        <button type="button" className="ctaonebutton mt-3">Apply Now</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-6">
                <div className="innerratesmortgage homepageexpertinner backan mb-4">
                  <div className="row">
                    <div className="col">
                      <div className="innerratesmortgagepp" data-aostesttesttesttest="flip-left">

                        <div className="homepageexpertinneruser mt-3"><img src={icicilogo} /></div>
                        <div className="p-name mt-3">  Product Name</div>
                        <button type="button" className="ctaonebutton mt-3">Get Quotes</button>
                      </div>
                    </div>
                    <div className="col">
                      <div className="innerratesmortgagepp" data-aostesttesttesttest="flip-left">
                        <div className="rateinterest">
                          <div className="rateinterest-sec">Rate of Interest</div>
                          <div className="rateinterest-percentage">5.30%</div>
                        </div>

                        <button type="button" className="ctaonebutton mt-3">Apply Now</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div> */}
        <Footer />

        {
                openModal && 
                <div className="modalspecial blog-modal">
                    <div>
                        <div className="mybtn">
                            <button type="button" className="myadbtn" onClick={() => { setOpenModal(false)}}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                        </div>
                        <div className="modal-header text-center">
                            <h3>{blog?.title}</h3>
                        </div>
                        <div className="modal-body text-center">
                            <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                            </div>
                            <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content}}></div>
                        </div>
                    </div>
                </div>

            }

            
      </section>
    </>

  );
};

export default MortgageJsx;