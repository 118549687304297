import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { Loader } from '../../../common/loader/loader.jsx';
import SuggestedOfferJsx from './custOffers.jsx'

export default class SuggestedOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerData: null,
      mortgageOfferData: null,
      insuranceOfferData: null,
      bankingOfferData: null,
      realestateOfferData: null,
      isProcessing: false
    }
  }

  componentDidMount = () => {
    this.getOfferData();
  }
  
  // function to get each data
  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  getOfferData = () => {
    const customerId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    const token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))

    this.setState({ isProcessing: true })

    axios.get(`${baseURL}/customer/${customerId}/offers`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      
      // getting individual data with each category 
      const rest = res.data.data.map(({ parentInstitutionCategory }) => parentInstitutionCategory);
      const a = rest;
      // var unique = a.filter(this.onlyUnique);
      // 

      // get filtered category with data
      const groupedObjects = res.data.data.reduce((result, obj) => {
        (result[obj.parentInstitutionCategory.replace( / /g, "" )] = result[obj.parentInstitutionCategory] || []).push(obj);
        return result;
      }, []);
      this.setState({ offerData:groupedObjects, isProcessing: false })
      

       
      // Output
      // 
      
      let investmentArray = res.data.data.filter(function (el) {
        return el.parentInstitutionCategory == "Investment"
      }
      );
      let bankingArray = res.data.data.filter(function (el) {
        return el.parentInstitutionCategory == "Banking"
      }
      );
      let mortgageArray = res.data.data.filter(function (el) {
        return el.parentInstitutionCategory == "Mortgage"
      }
      );
      let realAstateArray = res.data.data.filter(function (el) {
        return el.institutionCategoryId == 5
      }
      );
      let insuranceArray = res.data.data.filter(function (el) {
        return el.parentInstitutionCategory == "insurance"
      }
      );

      
      this.setState({ insuranceOfferData: insuranceArray, realestateOfferData: realAstateArray, investmentOfferData: investmentArray, bankingOfferData: bankingArray, mortgageOfferData: mortgageArray })

    })
  }



  render() {
    return (
      <div>
        <SuggestedOfferJsx
          investmentOfferData={this.state.investmentOfferData}
          mortgageOfferData={this.state.mortgageOfferData}
          insuranceOfferData={this.state.insuranceOfferData}
          bankingOfferData={this.state.bankingOfferData}
          realestateOfferData={this.state.realestateOfferData}
          offerData={this.state.offerData}
        />
        {this.state.isProcessing && <Loader />}
      </div>
    )
  }
}