import React from 'react'
import { useEffect } from 'react';
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const PrivacyPolicyJsx = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])
 
  return (
    <>
      <section className="homecustom">
      <section className="termscondition">
        <div className="container">
          <div className="terminner mt-5 mb-5">
         <h2> Privacy Policy </h2>
         <div>
   
  </div>
  <div>
    <div>
      <p>Privacy Policy At RIMIB, we value your privacy and are committed to protecting your personal information. This Privacy Policy describes how we collect, use, and&nbsp;disclose&nbsp;information through our website and services. By using our website and services, you consent to the collection and use of your information as described in this Privacy Policy.&nbsp;</p>
    </div>
    <div>
      <ol start={1}>
        <li>
          <h3>Collection of Information&nbsp;&nbsp;</h3>
        </li>
      </ol>
    </div>
    <div>
      <p>We collect information about you when you use our website and services. This may include personal information such as your name, email address, and billing information, as well as non-personal information such as your IP address and browsing activity.&nbsp;</p>
    </div>
    <div>
      <ol start={2}>
        <li>
        <h3>Use of Information&nbsp;&nbsp;</h3>
        </li>
      </ol>
    </div>
    <div>
      <p>We use the information we collect to provide,&nbsp;maintain, and improve our website and services, to communicate with you, to process your transactions, and to&nbsp;comply with&nbsp;legal obligations. We may also use your information for marketing purposes, such as to send you promotional emails or to show you targeted advertisements.&nbsp;</p>
    </div>
  </div>
  <div>
    <div>
      <ol start={3}>
        <li>
        <h3>Disclosure of Information&nbsp;&nbsp;</h3>
        </li>
      </ol>
    </div>
    <div>
      <p>We may&nbsp;disclose&nbsp;your information to third parties as necessary to provide our website and services, to process your transactions, or to&nbsp;comply with&nbsp;legal obligations. We may also share your information with third-party service providers who perform services on our behalf, such as payment processing or website analytics. We will not sell, rent, or lease your information to third parties for their own marketing purposes.&nbsp;</p>
    </div>
    <div>
      <ol start={4}>
        <li>
        <h3>Security of Information&nbsp;&nbsp;</h3>
        </li>
      </ol>
    </div>
    <div>
      <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.&nbsp;</p>
    </div>
    <div>
      <ol start={5}>
        <li>
        <h3>Cookies&nbsp;&nbsp;</h3>
        </li>
      </ol>
    </div>
  </div>
  <div>
    <div>
      <p>We use cookies to collect information about your browsing activity on our website. Cookies are small files that are stored on your device and allow us to remember your preferences and personalize your experience. You may disable cookies in your browser settings, but this may limit your ability to use certain features of our website&nbsp;</p>
    </div>
    <div>
      <ol start={6}>
        <li>
        <h3>Children's Privacy&nbsp;&nbsp;</h3>
        </li>
      </ol>
    </div>
    <div>
      <p>Our website and services are not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13.&nbsp;If we become aware that we have collected personal information from a child under the age of 13, we will take steps to delete that information as soon as possible.&nbsp;</p>
    </div>
    <div>
      <ol start={7}>
        <li>
        <h3>Links to Other Websites&nbsp;</h3>
        </li>
      </ol>
    </div>
    <div>
      <p>Our website may&nbsp;contain&nbsp;links to other websites that are not owned or&nbsp;operated&nbsp;by us. We are not responsible for the privacy practices of these websites. We encourage you to review the privacy policies of these websites before providing them with any personal information.&nbsp;</p>
    </div>
  </div>
  <div>
    <div>
      <ol start={8}>
        <li>
        <h3>International Users&nbsp;&nbsp;</h3>
        </li>
      </ol>
    </div>
    <div>
      <p>If you are accessing our website or services from outside Canada, please be aware that your information may be transferred to, stored, and processed in Canada, where our servers are&nbsp;located. By using our website and services, you consent to the transfer of your information to Canada.&nbsp;</p>
    </div>
    <div>
      <ol start={9}>
        <li>
        <h3>Changes to this Privacy Policy&nbsp;&nbsp;</h3>
        </li>
      </ol>
    </div>
    <div>
      <p>We reserve the right to&nbsp;modify&nbsp;this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will&nbsp;notify you&nbsp;by email or by posting a notice on our website prior to the effective date of the changes. Your continued use of our website and services after the effective date of the changes&nbsp;constitutes&nbsp;your acceptance of the modified Privacy Policy.&nbsp;</p>
    </div>
    <div>
      <ol start={10}>
        <li>
        <h3>Contact Us&nbsp;&nbsp;</h3>
        </li>
      </ol>
    </div>
  </div>
  <div>
    <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at&nbsp;<a href="mailto:support@rimib.com" target="_blank"  rel="noreferrer"  rel="noreferrer noopener">support@rimib.com</a> &nbsp;</p>
  </div>
  <p><br /></p>
          
          </div>
        </div>
      </section>
        <Footer />
      </section>
    </>

  );
};

export default PrivacyPolicyJsx;
