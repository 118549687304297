import React, { useEffect, useState } from 'react'
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { uploadURL,NEW_URL } from '../../../constants/applicationConstants';
import dropbox from "../../../assets/images/dropbox.svg";
import cross from "../../../assets/images/cross.svg";
import Realestatebanner from "../../../assets/images/Realestatebanner.svg";
import {useLocation} from 'react-router-dom'
const RealEstateJsx = (props) => {
  const {
    offerData,
    userData,
    adData,
    expertData,
    instituteData,
    blogData,
    onTouchHandler,
    onInputChangeHandler,
    onOfferSearchButtonClick,
    onAdSearchButtonClick,
    onExpertClick,
    onInstituteClick,subCategoryData
  } = props;
  const  {hash} = useLocation()
  useEffect(() => {
    AOS.init();
    if(hash)
    {
      document.getElementById(hash.replace('#','')).scrollIntoView()
    }else
    {
    //window.scrollTo(0, 0);
    }

  }, [])

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
     centerMode: false,
    centerPadding: '20px',
//  initialSlide:2    
responsive: [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 2
    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
]  
};

const [openModal, setOpenModal] = useState(false);
const [blog, setBlog] = useState(null);

  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="homecustom">
      <section className="herosectionoffer categorysectiondis d-none d-sm-block">
      <img src={Realestatebanner} alt=""/>
      <Link to={'/AskTheExpert'} className="ctaonebutton">Ask the Experts</Link>
    </section>
        {/* <section className="herosection backwhite">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6">
                <div className="heroleftpanel" data-aostest="fade-right">
                  <h2>Real Estate Solutions</h2>
                  <h6 className="h6_style">Real estate goals? RIMIB can help you seal the deal!<br />
We connect you at NO COST with licensed real estate experts. <br />
Ask them today and get a personalized response.</h6>
                  <div className="heroleftbutton d-flex mt-4">
                    <Link to={'/AskTheExpert'} className="ctaonebutton"> Ask the experts</Link>

                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="herorightpanel" data-aostest="fade-left">
                  <img src={Consultingrafiki} alt={' '}/>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="homepageproductoffer mt-5 mb-5" id="homerealestate">
          <div className="container">
            <h2 className="text-center mb-3" data-aostest="fade-top">Latest Offers by Top Institutions</h2>
            <h6 className="h6_style text-center" data-aostest="fade-top">Explore unbeatable deals and enjoy big savings with RIMIB!</h6>
            <h6 className="h6_style text-center">Canada's top real estate institutions are partnering with us to bring you exclusive offers.</h6>
            <h6 className="h6_style text-center">Don't let these amazing deals slip away – explore them today and start saving big on your real estate needs!</h6>
            <div className="myexpertformarea searcharea searchmainara searchmainarainner martop40 mb-5 width100">
              <div className="input-container form-group">
                <select className="input form-control form-select" placeholder=" " name="productType" onChange={onInputChangeHandler}>
                <option value='0'>All</option>
                   {subCategoryData?.map((item) => {
                                return (
                                  <>
                                    <option value={item.institutionCategoryId}>{item.name}</option>
                                  </>
                                )
                              })}
                </select>
                <div class="placeholder">Types</div>
              </div>
              <div className="input-container form-group ">
                <input type="text" className="input form-control" placeholder=" " name='productName' onChange={onInputChangeHandler} onBlur={onTouchHandler} value={userData.productName} />
                <div class="placeholder">Enter the product name you want to search for</div>
              </div>
              <div className="input-container form-group">
                <button type="submit" className="ctaonebutton" onClick={onOfferSearchButtonClick}>Search</button>
              </div>
            </div>
            <div className="row">
              {
                offerData?.length > 0 ? offerData?.map(offer => {
                  return <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-5">
                    <div className="offer-insurence-inner" data-aostest="flip-left">
                      <div className="offer-image"><img src={`${NEW_URL}/${offer?.imageUrl}`} alt={' '}/></div>
                      <div className="offer-name mt-3 mb-3 disfontsmall">{offer?.offerName}</div>
                      <div className="posbuttontf d-flex">
                      <a 
                        href={offer?.externalUrl} target="_blank"
                        className="ctaonebutton text-center"
                        >
                        Apply Now                            </a>
                      <Link
                        to={{
                          pathname: "/detailedOffer",
                          state: { data: offer,  frompage: 'homerealestate' ,pagePath:'/realEstate' }
                        }}
                        className="ctatwobutton text-center ctanewtwobutton"
                        onClick={() => window.scrollTo(0, 0)}>
                        View Details
                            </Link>
                           
                        </div>
                    </div>
                  </div>
                }) :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5 className="opacity0">No Record Found !! </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </section>

        <section className="homepageproductoffer mt-5 mb-5">
          <div className="container">
            <h2 className="h2_style text-center">Top Real Estate Institutions in Canada</h2>
            <h6 className="h6_style text-center fw-lighter">Are you looking to buy, sell, or invest in real estate? RIMIB has partnered with Canada's top real estate institutions to provide you with the best solutions for your real estate needs. They will guide you through the entire process, giving you a comprehensive view of all your options so you can make informed decisions and achieve your real estate goals. From buying your dream home to investing in rental properties, leverage their knowledge and expertise to help you succeed.</h6>

            <div className="row">
            {
                instituteData?.length>0 ? instituteData?.map(institue=> {
                  return <div className="col-lg-4 col-sm-4 mt-5">
                  <div className="colinstititeinner" onClick={()=> onInstituteClick(institue)}>
                    <div className="colinstititeinnerimage text-center"><img src={`${NEW_URL}/${institue?.imageUrl}`} alt={' '} /></div>
                    <div className="colinstititeinnername text-center">{institue?.name}</div>
                  </div>
                </div>
                }):
                <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt={' '} />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !! </h5>
                </div>
              </div>
              }
               </div>
          </div>

        </section>

        <section className="homepageproductoffer mt-5 mb-5">
          <div className="container">
            <h2 className="text-center mb-3" data-aostest="fade-top">Latest Ads by Top Institutions</h2>
            <h6 className="h6_style text-center">Stay up-to-date! These are the latest Ads from Canada's top real estate institutions.<br />Don't miss out on any opportunities to save money or to find the real estate options. Explore now!</h6>
            <div className="myexpertformarea searcharea searchmainara searchmainarainner martop40 mb-5">
              <div className="input-container form-group width100border">
                <select className="input form-control form-select" placeholder=" " name="adType" onChange={onInputChangeHandler}>
                <option value='all'>All</option>
                {subCategoryData?.map((item) => {
                                return (
                                  <>
                                    <option value={item.institutionCategoryId}>{item.name}</option>
                                  </>
                                )
                              })}
                </select>
                <div class="placeholder">Type</div>
              </div>
              <div className="input-container form-group">
                <button type="submit" className="ctaonebutton" onClick={onAdSearchButtonClick}>Search</button>
              </div>
            </div>
            <div className="row">
              {
                adData?.length > 0 ? adData?.map(data => {
                  return <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-5">
                    <div className="offer-insurence-inner" data-aostest="flip-left">
                      <div className="offer-image"><img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '} /></div>
                      <div className="offer-name mt-3 mb-3">{data?.subInstitutionCategory?.name}</div>
                      {data?.externalLink &&  <button onClick={()=> {window.scrollTo(0,0); window.open(data.externalLink,'_blank')}} className="ctatwobutton mb-3 text-center">Know More</button>}
                    </div>
                  </div>


                }) :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5 className="opacity0">No Record Found !! </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </section>
        <section className="homepageproductoffer mt-5 mb-5">
          <div className="container">
            <h2 className="text-center" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
              data-aostest-duration="2000">Top Real Estate Experts in Canada</h2>
            <h5 className="text-center mt-3 h5_style" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
              data-aostest-duration="2000">Get the best real estate deals and tailored responses with ease and confidence. We've partnered with Canada's top real estate experts, providing you with a range of options that suit your unique needs. Whether you're buying or selling a property, looking to invest in commercial real estate, or searching for a reliable property management service, our partnered experts will guide you in every step of the way. <b>Realize your real estate dreams and take the first step towards financial security today!</b></h5>

            <div className="p">
              
                { expertData?.length > 0 ? 
                
                <Slider {...settings}> 
                    {expertData?.map(data => {
                      return (  
                        
                        <div class="mt-5">                
                        <div className="expertinnsearch" data-aostest="flip-right" data-aostest-duration="1000" onClick={()=> onExpertClick(data)}>
                          <div className="expertinnsearchimage"><img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '} /></div>
                          <div className="expertinnsearchname">{`${data.firstName} ${data.lastName}`}</div>
                        </div> 
                        </div>                 
                      )
                    }) }
                </Slider>
                :
                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5 className="opacity0">No Record Found !! </h5>
                    </div>
                  </div>
                }
            </div>
          </div>
        </section>


        <section className="homepageproductoffer">
          <div className="container">

            <div className="d-flex latestratesflex">
              <div data-aostest="fade-left">
                <h4 className="mt-3" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
                  data-aostest-duration="2000">Don't let real estate worries keep you up at night.  </h4>
                <h4 className="" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
                  data-aostest-duration="2000"><i>RIMIB offers a modern way of solving all your questions.</i></h4>
                <h4 className="" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
                  data-aostest-duration="2000">Connect with the best Real Estate experts & get responses based on your specific needs.</h4>
              </div>
              <div className="" data-aostest="fade-right" data-aostest-anchor-placement="bottom-bottom"><Link to={'/AskTheExpert'} className="ctaonebutton"> Ask the Experts</Link></div>
            </div>

          </div>
        </section>
        <section className="homepageproductoffer blogdetailsexpertppv padtop60 mb-5">
          <div className="container">
            <h2 className="text-center" data-aostest="flip-left" data-aostest-easing="ease-out-cubic"
              data-aostest-duration="2000">Top Real Estate Blogs</h2>
            <h5 className="text-center mt-3" data-aostest="fade-left" data-aostest-anchor-placement="bottom-bottom">Stay up-to-date on the latest industry trends and discover new ways to save money. </h5>
            <h5 className="text-center" data-aostest="fade-right" data-aostest-anchor-placement="bottom-bottom">Our platform offers a carefully curated selection of the best blogs from industry experts.</h5>
            <h5 className="text-center" data-aostest="fade-left" data-aostest-anchor-placement="bottom-bottom">Get valuable insights and advice on everything related to Real Estate.</h5>
            <div className="row mt-5">
              {
                blogData?.body?.length > 0 ?
                  blogData?.body?.map(blog => {
                    return <div className="col-lg-4 col-md-6 col-sm-6">
                      <a onClick={() => {setBlog(blog); setOpenModal(true)}} >
                        <div className="bloginner mb-3">
                          <div className="bloginnerimage"><img src={blog?.image} alt={' '} /></div>
                          <div className="bloginnertag mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                          <div className="bloginnertext mt-3">{blog?.title}</div>
                          <div className="bloginnerdate mt-3">{blog?.views} views  .  {blog?.date_publish}</div>
                        </div>
                      </a>
                    </div>
                  }) :

                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>You don't have any Blogs yet. </h5>
                    </div>
                  </div>
              }
            </div>
          </div>
        </section>
        <section>
     
        </section>
        <Footer />

        {
                openModal && 
                <div className="modalspecial blog-modal">
                    <div>
                        <div className="mybtn">
                            <button type="button" className="myadbtn" onClick={() => { setOpenModal(false)}}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                        </div>
                        <div className="modal-header text-center">
                            <h3>{blog?.title}</h3>
                        </div>
                        <div className="modal-body text-center">
                            <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                            </div>
                            <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content}}></div>
                        </div>
                    </div>
                </div>

            }

      </section>
    </>

  );
};

export default RealEstateJsx;
