import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Footer } from '../../../common/footer/footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Pagination } from '../../../common/pagination/pagination';
import cross from "../../../../assets/images/cross.svg";

const ExpertPpvBlogJsx = (props) => {
  const { t } = useTranslation();
  const {
    blogData,
    onPaginationButtonClick
  } = props;

  useEffect(() => {
    AOS.init();
  }, [])

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState({});


  return (
    <>
      <section className="publicviewprofiledetails padtop60 paddingbot60 publicviewprofiledetailsblog">
        <div className="container">
          <div className="publicdeinnerq">
            <h2 className="text-center h2_style text-white">{t("PPVEXPERT.BLOGTITLE")}</h2>
            <h5 className="text-center h5_style text-white fw-lighter">{t("PPVEXPERT.BLOGTITLELIGHT")}</h5>
            <h5 className="text-center h5_style text-white">{t("PPVEXPERT.BLOGSUBTITLELIGHT")}</h5>
          </div>
        </div>
      </section>
      <section className="authorised">
        <div className="container">
        {t("PPVEXPERT.BLOGAUTHORISED")}
        </div>
      </section>
      <section className="blogdetailsexpertppv padtop60">
        <div className="container">
          <div className="row">
            {
              blogData?.body?.length > 0 &&
              blogData?.body?.map(blog => {
                return <div className="col-lg-4 col-sm-4">
                  <div className="p_slider" onClick={() => {setBlog(blog); setOpenModal(true)}}>
                    <div className="blofimage"><img src={blog.image} alt={' '} /></div>
                    <div className="useblog d-flex">
                      <div className="userdetails">
                        <img src="https://ui-avatars.com/api/?name=Staf+Editor"  alt={' '}/>
                      </div>
                      <div className="userpop">
                        <h5>{blog.author}</h5>
                        <div className="date">{blog.date_publish}</div>
                      </div>
                    </div>
                    <div className="slidertext">
                      <h4>{blog.title}</h4>
                      <p>{blog.short_content}</p></div>
                  </div>
                </div>
              })
            }
          </div>
        </div>

        {blogData?.body?.length > 0 && <Pagination
          limit={10}
          totalCount={blogData?.total_post}
          onPaginationButtonClick={onPaginationButtonClick}
        />}
      </section>
      <Footer />

      {
                openModal && 
                <div className="modalspecial blog-modal">
                    <div>
                        <div className="mybtn">
                            <button type="button" className="myadbtn" onClick={() => { setOpenModal(false)}}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                        </div>
                        <div className="modal-header text-center">
                            <h3>{blog?.title}</h3>
                        </div>
                        <div className="modal-body text-center">
                            <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                            </div>
                            <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content}}></div>
                        </div>
                    </div>
                </div>

            }

    </>

  );
};

export default ExpertPpvBlogJsx;