import React from 'react'
import leftarrow from "../../../../assets/images/left-arrow.svg";
import { NEW_URL } from '../../../../constants/applicationConstants';import { useHistory, useLocation } from "react-router-dom";
import { Footer } from '../../../common/footer/footer'

const DetailedOffersJsx = (props) => {
  const history = useHistory();
  const location = useLocation()
  const {state:locationState} = location
  const {
    data
  } = props;

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  return (
    <>
      <section className="listofoffermainsection">
        <div className="container">
          <div className="leftsightbackdetails listofofferleft padtop60">
            <div className="back_btn" onClick={() => locationState?.frompage ? history.push(`${locationState?.pagePath ?? '/'}#${locationState?.frompage}`) : history.goBack()}><img src={leftarrow} alt={' '} /></div>
            <div className="plaindetailsleft">
              <h1 className="h2_style font-weight-bold">Detailed Offer View</h1>
              <h5 className="h5_style">We not only save you money! We offer you financial confidence. </h5>
              <h5 className="h5_style">Check out the detailed view of this exciting offer now. Act swiftly, before it expires. </h5>
              <h5 className="h5_style font-weight-bold">Do you want to lock this deal fast? Hurry up! Click “Apply Now”. </h5>
            </div>
          </div>
          <div className="detailedview mt-5 mb-5">
            <div className="detailedimage">
            <img src={`${NEW_URL}/${data?.imageUrl}`}  alt={' '}/>
            </div>
            <div className="detailedtext mt-4">
              <div className="pname">Offer Name</div>
              <p>{data?.offerName}</p>
              <div className="detailedofferheading">Description</div>
              <p>{data?.description} </p>
              <div className="disgrid grid-4">
                <div>
                  <div className="detailedofferheading">Valid From</div>
                  <p>{getProperData(data?.validFrom)} </p>
                </div>
                <div>
                  <div className="detailedofferheading">Valid Till</div>
                  <p>{getProperData(data?.validTo)}</p>
                </div>
                <div>
                  <div className="detailedofferheading">Category</div>
                  <p>{data?.parentInstitutionCategory}</p>
                </div>
                <div>
                  <div className="detailedofferheading">Related Product</div>
                  <p>{data?.subInstitutionCategory?.name}</p>
                </div>
              </div>
               {data?.externalUrl && <a href={data?.externalUrl} target="_blank" className="ctaonebutton widthhalf mt-4">Apply Now</a> }
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>

  );
};

export default DetailedOffersJsx;