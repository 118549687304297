import React, { Component } from "react";
import SideBarsJsx from "./sidebar.jsx"
import axios from 'axios'
import {
  baseURL
} from "../../../../constants/applicationConstants.js";
import { Loader } from '../../../common/loader/loader.jsx';

export class SideBarsJsxclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseData: null
    };
  }
  componentDidMount = () => {
    this.setState({ isLoading: true })
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let instituteId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))

    if (token && instituteId) {
      this.callDetails()
    }
  }
  callDetails = () => {
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let expertID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    axios.get(`${baseURL}/expert/${expertID}/details`, {
      headers: { 'x-access-token': token }
    }).then(res => {

      this.setState({ responseData: res.data.data })
      sessionStorage.setItem("legalName",res.data.data?.firstName);
      sessionStorage.setItem("planName",res.data.data?.planName);
      sessionStorage.setItem("slugURL",res.data.data?.slugUrl);


      this.setState({ isLoading: false })
    }).catch(err=>{
      this.setState({ isLoading: false })
    })
  }
  render() {
    return (
      <div>
        <SideBarsJsx
        response = {this.state.responseData}
        />
        {this.state.isLoading && <Loader />}
      </div>
    );
  }
}

export default SideBarsJsxclass;