import React from 'react'
import { Component } from 'react';
import CarInsuranceJsx from './carInsurance.jsx'

export default class CarInsurance extends Component{
    render(){
        return(
            <div>
                <CarInsuranceJsx />
            </div>
        )
    }
}