import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { Loader } from '../../../common/loader/loader.jsx';
import ManageUnverifiedExpertJsx from './manageUnverifiedExpert.jsx'

export default class ManageUnverifiedExpert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            meta:{},
            isProcessing: false
        }
    }

    componentDidMount = () => {
        this.getExpert()
    }

    getExpert = () => {
        this.setState({ isProcessing: true })
        const token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/searchExpertsByAdmin?status=unverified&perPage=10&page=${this.state.page}`, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.setState({ data: res.data.data,meta:res.data.meta, isProcessing: false });
        }).catch(() => {
            this.setState({ isProcessing: false });
        })
        if (!token) {
            this.props.history.push("/admin");
        }
    }

    onAddButtonClick = () => {
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("instituteId");
        localStorage.removeItem("AdminFlow")
        const token = JSON.parse(sessionStorage.getItem('token'));

        sessionStorage.setItem("adminOldToken", token);
        localStorage.setItem('verificationStatus', 'unVerified')
        localStorage.setItem('manageUnverifyExpert', true)
        this.props.history.push('/expertRegistration')
    }

    onVerifyNowClick = (id) => {
        localStorage.setItem('VerifyExpertID', id)
        const token = JSON.parse(sessionStorage.getItem('token'));

        sessionStorage.setItem("adminOldToken", token);
        this.props.history.push('/expertRegistration')
    }

    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getExpert())
    }

    render() {
        return (
            <div>
                <ManageUnverifiedExpertJsx
                    data={this.state.data}
                    meta={this.state.meta}
                    onAddButtonClick={this.onAddButtonClick}
                    onVerifyNowClick={this.onVerifyNowClick}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}
