import React from 'react';
import MyAds from '../../dashboardCommon/myAds/myAds';
import Sidebar from "../sidebar/sidebar.js";

export const ExpertAdsJSX = () =>{
    return(
        <div>
            <section class="entiredashboard">
      <div className="container">
        <div className="row">
        <div className="col-lg-3 col-sm-3">

          
<div className="dashboardleftpanel martop40">
              
               <div className="dashboardlinkpop martop40">
                <Sidebar/>
               </div>
             </div>
             </div>
              <div className="col-lg-9 col-sm-9">
              <MyAds />
              </div>
        </div>
      </div>
    </section>
   
        </div>
    )
}