import axios from "axios";
import React, { Component } from "react";
import { baseURL, uploadURL } from "../../../../constants/applicationConstants.js";
import { getProperDateMonth } from "../../../../shared/helper.js";
import { Loader } from "../../../common/loader/loader.jsx";
import MyOfferJsx from "./myOffer.jsx";
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class MyOfferJsxclass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      blogFormData: {
        validFrom: null,
        validTill: null,
        category: "",
        relatedProduct: "",
        caption: "",
        metaTag: [],
        product: "",
        url: ""
      },
      isProcessing: false,
      formSubmitButtonDisable: true,
      formError: {},
      showAddModal: false,
      categoryData: null,
      subCategoryData: null,
      adData: null,
      instituteAdID: null,
      imageUrl: "",
      fileInfo: null,
      profileImagePreviewUrl: null,
      isEditMode: false,
      AdsImg:""
    }
  }


  componentDidMount = () => {
    this.setState({ isLoading: true })

    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    axios.get(`${baseURL}/institutionCategoriesAndSubCategories?instituteId=${instituteId}`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ categoryData: res.data.data, isLoading: false })
    }).catch(err=>{
      this.setState({ isLoading: false })
    })

    this.getADS();
    if (!token) {
      this.props.history.push("/");
    }
  }

  getADS = () => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));

    axios.get(`${baseURL}/institute/${instituteId}/offers`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ adData: res.data.data, isLoading: false })
    })
  }

  getSubCategories = (value) => {
    const subCat = this.state.categoryData.filter(adDT => adDT.institutionCategoryId == value);
    subCat.length > 0 ? this.setState({subCategoryData:subCat[0].subInstitutionCategories}) :  this.setState({subCategoryData:[]})
  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "category") {
      this.getSubCategories(value)
    }

    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            blogFormData: {
              ...this.state.blogFormData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            blogFormData: {
              ...this.state.blogFormData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          blogFormData: {
            ...this.state.blogFormData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };

  validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png');


  onFileChange = (e) => {
    if (e.target.files[0]?.size) {
      if (e.target.files[0]?.size < 512000) {
        if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
          this.setState({ fileInfo: e.target.files[0] })
          this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) }, () => this.isFromSubmissionBlock())
        }
        else
          toast.error('.jpeg/.jpg/.png format required')
      }
      else
        toast.error('File should be less than 500 kb.')
    }
  }

  onSubmitButtonclick = () => {
    this.setState({
      isLoading: true
    })
    let formData1 = new FormData();

    if (this.state.fileInfo) {
      formData1.append('image', this.state.fileInfo)
      formData1.append('imageCategory', "institute-offer")

      axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {
        if (resp.status === 200) {
          this.setState({AdsImg:resp.data.filename},()=> this.onSubmitButtonclick1())
          toast.success("Offer Image uploaded successfully");
        } else {
          // setIsLoading(false)
        }
      }).catch(() => {
        this.setState({
          isLoading: false
        })
      })
    }
    else this.onSubmitButtonclick1();
  };


  onSubmitButtonclick1 = () => {
    this.setState({
      isLoading: true
    })
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    let image = this.state.AdsImg;
    const { validFrom, validTill, category, relatedProduct, caption, metaTag, product,url } = this.state.blogFormData;
    const payload1 = {
      "institutionCategoryId": category,
      "institutionSubCategoryId": relatedProduct ? relatedProduct : null,
      "offerName": product,
      "description": caption,
      "metaTags": metaTag ? metaTag :[],
      "externalUrl": url,
      "validFrom": validFrom && `${validFrom?.year}-${getProperDateMonth(validFrom?.month)}-${getProperDateMonth(validFrom.day)}`,
      "validTo": validTill && `${validTill?.year}-${getProperDateMonth(validTill?.month)}-${getProperDateMonth(validTill.day)}`
    }

    const payload = image ? {...payload1, imageUrl: image} : {...payload1}
    

    if (this.state.instituteAdID) {
      axios.put(`${baseURL}/institute/${instituteId}/instituteOffer/${this.state.instituteAdID}`, payload, {
        headers: { 'x-access-token': token }
      }).then(res => {
        this.setState({
          isLoading: false,
          showAddModal: false
        })
        this.setblacnk();
        this.resetState();
        this.getADS();
        toast.success("Offer added successfully");
        
      }).catch(err => {
        toast.error(err.response.data.error.message)
        this.setState({
          isLoading: false
        })
      })
    }
    else {
      axios.post(`${baseURL}/institute/${instituteId}/offer`, payload, {
        headers: { 'x-access-token': token }
      }).then(res => {
        this.setState({
          isLoading: false,
          showAddModal: false
        })
        this.setblacnk();
        this.resetState();
        this.getADS();
      }).catch(err => {
        toast.error(err.response.data.error.message)
        this.setState({
          isLoading: false
        })
      })
    }
  }

  isFromSubmissionBlock = () => {
    let { validFrom, validTill, category, caption, product } = this.state.blogFormData;
    const { profileImagePreviewUrl,editMode } = this.state;

    if (validFrom && validTill && category && caption && product && (profileImagePreviewUrl || editMode)) {
      this.setState({
        formSubmitButtonDisable: false
      });
    } else {
      this.setState({
        formSubmitButtonDisable: true
      });
    }
  };

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {
    return false;
  };

  onRadioButtonClick = (value) => {
    this.setState({
      blogFormData: { ...this.state.blogFormData, isExpires: value }
    }, () => this.isFromSubmissionBlock());
  }

  onDateChange = (name, date) => {
    this.setState(
      {
        blogFormData: {
          ...this.state.blogFormData,
          [name]: date,
        }
      }, () => this.isFromSubmissionBlock()
    );
  }

  onMetaTagChange = (e) => {
    this.setState(
      {
        blogFormData: {
          ...this.state.blogFormData,
          'metaTag': e,
        },

      }, () => this.isFromSubmissionBlock()
    )
  }

  resetState = () => {
    this.setState({
      blogFormData: {
        validFrom: null,
        validTill: null,
        category: "",
        relatedProduct: "",
        caption: "",
        metaTag: "",
        product: "",
        isExpires: true
      }
    })
  }


  setblacnk = () =>{
    this.setState({fileInfo:null,AdsImg:"", imageUrl: "", profileImagePreviewUrl: null, image: "" })
  }

  onModalClose = (value) => {
    this.setState({
      showAddModal: value,
      instituteAdID: null
    }, () => {
      value && window.scrollTo({
        top: 350,
        left: 100,
        behavior: 'smooth'
      })
    })
    this.setState({imageUrl:"", profileImagePreviewUrl: null,image:""})
    
    this.resetState();
    !value && this.setState({editMode:false,formSubmitButtonDisable:true})
  }

  onEditButtonClick = (data) => {
    this.setState({ showAddModal: true, editMode: true, imageUrl:data.imageUrl, instituteAdID: data.instituteOfferId }, () => {
      window.scrollTo({
        top: 350,
        left: 100,
        behavior: 'smooth'
      });
    });

    const validFromDate = data.validFrom.split("T")[0].split("-");
    const validToDate = data.validTo.split("T")[0].split("-");

    this.getSubCategories(data?.institutionCategoryId);

    this.setState({
      blogFormData: {
        validFrom: {
          day: parseInt(validFromDate[2]),
          month: parseInt(validFromDate[1]),
          year: parseInt(validFromDate[0])
        },
        validTill: {
          day: parseInt(validToDate[2]),
          month: parseInt(validToDate[1]),
          year: parseInt(validToDate[0])
        },
        caption: data.description,
        category: data.institutionCategoryId,
        metaTag: data.metaTags,
        relatedProduct: data.institutionSubCategoryId,
        product: data.offerName,
        url: data.externalUrl
      },
      // subCategoryData: categoryID[0]?.subInstitutionCategories
    }, () => this.isFromSubmissionBlock())
  }

  onDeleteButtonClick = (data) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this Offer",
      icon: "warning",
      buttons: [
        'Do not cancel it!',
        'Yes, Delete it!'
      ],
      dangerMode: true,
    }).then(isConfirm => {
      if (isConfirm) {
        this.deleteOffer(data)
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })
  }

  deleteOffer = (data) => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    this.setState({ isLoading: true })

    axios.delete(`${baseURL}/instituteOffer/${data.instituteOfferId}`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({
        isLoading: false
      })
      this.getADS();
    }).catch(err=>{
      this.setState({ isLoading: false })
    })
  }

  onScrollerChange = (data) => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    this.setState({ isLoading: true })

    const payload = {
      "isActive": !data.isActive
    }

    axios.put(`${baseURL}/institute/${instituteId}/instituteOffer/${data.instituteOfferId}`, payload, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({
        isLoading: false
      })
      this.getADS();
    }).catch(err => {
      this.setState({
        isLoading: false
      })
    })
  }

  render() {
    return (
      <div>
        <MyOfferJsx
          blogFormData={this.state.blogFormData}
          formError={this.state.formError}
          onDateChange={this.onDateChange}
          onInputChangeHandler={this.onInputChangeHandler}
          onRadioButtonClick={this.onRadioButtonClick}
          isExpires={this.state.blogFormData.isExpires}
          showAddModal={this.state.showAddModal}
          onModalClose={this.onModalClose}
          onSubmitButtonclick={this.onSubmitButtonclick}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          categoryData={this.state.categoryData}
          subCategoryData={this.state.subCategoryData}
          onMetaTagChange={this.onMetaTagChange}
          onEditButtonClick={this.onEditButtonClick}
          onDeleteButtonClick={this.onDeleteButtonClick}
          adData={this.state.adData}
          onScrollerChange={this.onScrollerChange}
          imgURL={this.state.imageUrl}
          profileImagePreviewUrl={this.state.profileImagePreviewUrl}
          editMode={this.state.editMode}
          onFileChange={this.onFileChange}
        />
        {this.state.isLoading && <Loader />}
        <ToastContainer />
      </div>
    );
  }
}
