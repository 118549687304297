import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
    baseURL
} from "../../../../constants/applicationConstants.js";

const DropDownListJSX = (props) => {
    const {
        keyword, onInstituteClick

    } = props;
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);




    useEffect(() => {
        if (keyword && keyword.length > 3){
            setLoad(true)
            let expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))
            axios.get(`${baseURL}/institutes?keyword=${keyword}&expertId=${expertId}`).then(res => {
                setData(res.data.data)
                setLoad(false)
            }).catch(err=>{
               setLoad(false)
              })
        }
        
    }, [keyword])
    return (
        <>

            {load ? "processing" : 
           < >
           {keyword && keyword.length > 3 &&
           <> 
                <ul>
                {
                    data?.map(data => {
                        return <li onClick={() => { onInstituteClick(data?.name,data?.instituteCategories,data.instituteId); setData([]) }}>
                            {data.legalName} ( {data.name} )
                        </li>
                    })
                }
            </ul>
            </>
           }
           </>
           }


        </>


    );
};

export default DropDownListJSX;
