import React, { useState, useEffect } from 'react'

import swal from "sweetalert";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseURL,NEW_URL } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";

const ItemDetails = (props) => {
    const {
        getProducts, getParticularProducts, openEditBox
    } = props;
    const [itemData, setItemData] = useState("");
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getProductsData();

    }, [])

    const getProductsData = () => {
        setLoading(true)
        let token = JSON.parse(sessionStorage.getItem('token'));

        axios.get(`${baseURL}/institutionProduct/${props?.totalData?.institutionProductId}`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            setItemData(res.data.data)
            setLoading(false)
        }).catch(err=>{
            setLoading(false)
          })
    }
    const deleteItem = () =>{
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this product",
            icon: "warning",
            buttons: [
              'Do not cancel it!',
              'Yes, Delete it!'
            ],
            dangerMode: true,
          }).then(function(isConfirm) {
            if (isConfirm) {
                deleteProduct()
            } else {
            //   swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
          })
    }

    const deleteProduct = () => {
        setLoading(true)
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.delete(`${baseURL}/institutionProduct/${props?.totalData?.institutionProductId}`, {
            headers: { 'x-access-token': token }
        }).then((res) => {

            // swal({
            //   title: "success",
            //   text: "product added successfully",
            //   icon: "success",
            // });
            toast.success("Product deleted successfully");
            props.onProductClick()
            setLoading(false)
            getProducts()

        }).catch((res) => {
            toast.error(res?.response.data.error.message)
            setLoading(false)


        })
    }
    const multiple = () => {
        openEditBox();
        getParticularProducts(props?.totalData?.institutionProductId);
    }

    return (
        <>

            {/* <button type="button" className="myadbtn" onClick={() => props.onProductClick()}><img src={del_alt_fill} /></button> */}
            <section className="productdetailslist mt-3">
                <div className="productdetailsimage"><img src={`${NEW_URL}/${itemData.imageUrl}`} alt={''} /></div>
                <div className="productlistdetails-in">
                    <div className="headeing">
                        <h2>{itemData.productName}</h2>
                        <ul className="categoryproduct">
                            <li>{itemData.institutionCategoryName}</li>
                            <li>{itemData?.subInstitutionCategory?.name}</li>
                            <li>{itemData?.productType?.name}</li>
                        </ul>
                        <ul className="metaproduct mt-3">
                            {
                                itemData?.metaTags?.map(product => {
                                    return (
                                        <li>{product}</li>

                                    )

                                })}
                            <li>{itemData.institutionCategoryName}</li> <li>{itemData.subInstitutionCategory?.name}</li>
                        </ul>
                        <p className="mt-3">Intrests Rate : <span>{itemData.interestRate}%</span></p>
                    </div>
                    <div className="mybtn">
                        <button type="button" className="myadbtn" onClick={() => multiple()}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg> </button>
                        <button type="button" className="myadbtn" onClick={() => deleteItem()}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg>
</button>
                        {/* <button type="button" className="myadbtn"onClick={() =>{setAddBlogScreen(true) ; changeHideState()} }><img src={del_alt_fill} /></button> */}

                    </div>
                </div>
                <div className="lowerproductdescription">
                    <h3>Description</h3>
                    {/* <p><strong>1. Think about the who, what, when, where, why and how before writing.</strong>
                                                                            This method is often used by journalists to provide facts in their stories and it is the first step in crafting a product description.</p>
                    <ul>
                        <li>Who is this product, a lifestyle demographic (i.e. new mothers or car enthusiasts) or some other defined group of people.</li>
                        <li>What are the product’s basic details? This includes attributes succtions.</li>
                    </ul> */}
                    <ul>
                        <li> {itemData.description}</li>
                    </ul>


                    <div className="disgrid grid-2 martop40">
                        <div className="left-block">
                            <h3>Benefits</h3>
                            <ul>
                                <li> {itemData.benefits}</li>
                            </ul>
                        </div>
                        <div className="right-block">
                            <h3>Features</h3>
                            <ul>
                                <li> {itemData.features}</li>
                            </ul>
                        </div>
                    </div>
                    <h3 className="martop40">Link to product</h3>
                    <a href={itemData.externalLink}>{itemData.externalLink}</a>
                </div>
            </section>
            {loading && <Loader />}
        </>

    );
};

export default ItemDetails;
