import React from 'react'
import { useEffect } from 'react';
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";
import InputMask from 'react-input-mask';

const OpsPaymentJsx = (props) => {
  const {
    data,
    activeTab,
    onTabClick,
    searchData,
    onInputChange,
    formError,
    onTouchHandler,
    formSubmitButtonDisable,
    onSearchButtonClick
  } = props
  useEffect(() => {

    window.scrollTo(0, 0);
  }, [])

  const getMuskPattern = () => 'a9a 9a9'

  return (
    <>
      <section className="entiredashboard admindashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">
              <div className="dashboardleftpanel martop40">
                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <section classNames="addexpertformsec">
                  <div className="myadssection">
                    <h2 className="h2_style martop30">Manage Payment</h2>
                    <p>Use this section to manage the pending payments. </p>
                    <p>Obtain a filtered list based on the criteria displayed below.</p>
                  </div>
                  <div className="myexpertnav myaccountnav qnanav martop40">
                    <ul>
                      <li className={`${activeTab === 'expert' ? 'active' : ''}`} onClick={() => onTabClick('expert')}>Expert</li>
                      <li className={`${activeTab === 'institute' ? 'active' : ''}`} onClick={() => onTabClick('institute')}>Institution</li>
                    </ul>
                  </div>
                  <div className="myexpertformarea searcharea martop40">
                    <div className="input-container form-group">
                      <input type="text" className="form-control input" placeholder=" " name="firstName"
                        value={searchData.firstName}
                        onChange={onInputChange}
                        onBlur={onTouchHandler} />
                      <div className="placeholder">First Name</div>
                    </div>
                    <div className="input-container form-group">
                      <input type="text" className="form-control input" placeholder=" " name="lastName"
                        value={searchData.lastName}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                      />
                      <div className="placeholder">Last Name</div>
                    </div>
                    <div className="input-container form-group">
                    <InputMask mask={getMuskPattern()} value={searchData.postalCode} name={'postalCode'} onChange={onInputChange} onBlur={onTouchHandler}>
                            {(inputProps) => <input {...inputProps} className="input form-control" placeholder=" " disableUnderline />}
                          </InputMask>
                      <div className="placeholder">Postal Code</div>
                    </div>
                    <div className="input-container form-group">
                      <select className="input form-control form-select" placeholder=" " name="status" onClick={onInputChange}>
                        <option value="all" selected={searchData.status == 'all'}>All</option>
                        <option value="1">Active withoutpayment</option>
                        <option value="2">Suspended</option>
                        <option value="3">Cancelled</option>
                      </select>
                      <div className="placeholder">Status</div>
                    </div>
                    <div className="input-container form-group">
                      <button disabled={formSubmitButtonDisable} type="submit" className="ctaonebutton" onClick={()=> onSearchButtonClick()}>Search</button>
                    </div>
                  </div>
                  {
                    data?.map(data=>{
                      return <div className="paymentdis mt-5">
                      <h3>{data.expertName}</h3>
                      <p><b>Payment due since : <span>{data.dueDate}</span></b></p>
                      <div className="d-flex justify-content-between">
                        <button type="button" className="ctaonebutton">Profile</button>
                        <button type="button" className="ctaplanbutton">Suspended</button>
                      </div>
                      <p className="mt-3">{`Current status: ${data.currentStatus}`}</p>
                      <button type="button" className="ctaplanbutton">Resend Invoice with Payment link</button>
                    </div>
                    })
                  }
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>

  );
};

export default OpsPaymentJsx;
