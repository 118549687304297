import React from 'react';
import UnverifiedSpecialInformationsJsxclass from '../unverifiedSpecialInformation/specialInformation';
import SpecialInformationsJsxclass from './specialInformation';

export const SpecialInformationFlow = () =>{
    const flowType = localStorage.getItem('flowType');

    return(
        <>
        {
            flowType !== 'admin' ? 
            <SpecialInformationsJsxclass /> :
            <UnverifiedSpecialInformationsJsxclass />
        }
        </>
    )
}