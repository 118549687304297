import React from 'react'
import macd from "../../../../assets/images/macd.jpg";
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import location from "../../../../assets/images/location.svg";
import tag from "../../../../assets/images/tag.svg";
import bed from "../../../../assets/images/bed.svg";
import bath from "../../../../assets/images/bath.svg";
import sqft from "../../../../assets/images/sqft.svg";
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import Sidebar from "../../../../components/dashboard/institute/sidebar/sidebar.js";

const MyListinsJsx = () => {
  return (
    <>
      <section className="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">
            <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">My Workspace</h2>
                    <h5 className="h5_style">This is your maintenance section. Use this workspace to create and manage your : </h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre}  alt={''}/><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" className="modal-window">
                      <div>
                        <a  title="Close" className="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="myexpertnav martop60">
                  <ul>
                    <li>My Ads</li>
                    <li className="active">My Lisitings</li>
                    <li>My Products</li>
                    <li>My Offers</li>
                    <li>My Services</li>
                  </ul>
                </div>
                <div className="addexpert martop40">
                  <div className="addexpertleft">
                    <p>Quickly add your real estate listings by entering MLS numbers </p>
                    <p>All added listings appear on public profile page</p>
                    <div className="userguide martop20">
                      <span className="plantooltip custooltip">
                        <img src={planpricingtooltip}  alt={''} className="tooltipim" /> How to create a listing?
                                                        <div className="tooltipcontent">
                          <ul className="tcon">
                            <li className="tconitem">1. Enter multiple MLS numbers of the properties separated by commas</li>
                            <li className="tconitem">2. Click on "Submit"</li>
                            <li className="tconitem">3. Check the properties you want to display on the public profile page.
                                                                   
                            </li>
                            <li className="tconitem">4. Click "Save".</li>
                            <li className="tconitem">5. All the saved listings appear on your public profile page.</li>

                          </ul>
                        </div>
                      </span>

                    </div>
                  </div>
                  <div className="addexpertright">
                    <button type="button" className="ctaonebutton">My Listings</button>
                  </div>
                </div>
                <div className="myadssection">
                  <h2 className="h2_style martop30">My Listings</h2>
                  <p>More visibility, more chances of sale! Show your customers what you are selling.</p>
                  <p>Below are your active listings. You can display up to 9 listings with your current plan.</p>
                </div>
                <div className="mylistingsection">
                  <h5 className="listingtitle">My Active Listings</h5>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="listinginner">
                        <div className="listinginnerimage">
                          <img src={macd}  alt={''} />
                        </div>
                        <div className="listingde">
                          <h3>2710 North Park Dr</h3>
                          <button type="button" className="myadbtn"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg>
</button>
                        </div>
                        <div className="listingdetails">
                          <ul>
                            <li><span className="icon"><img src={location}  alt={''}/></span> Brampton, Ontario</li>
                            <li><span className="icon"><img src={tag}  alt={''} /></span> $40</li>
                            <li><span className="icon"><img src={bed} alt={''} /></span> 3+0 BEDROOMS</li>
                            <li><span className="icon"><img src={bath} alt={''} /></span> 3 BATHROOMS</li>
                            <li><span className="icon"><img src={sqft} alt={''} /></span> 1500-2000 SQFT</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="listinginner">
                        <div className="listinginnerimage">
                          <img src={macd}  alt={''}/>
                        </div>
                        <div className="listingde">
                          <h3>2710 North Park Dr</h3>
                          <button type="button" className="myadbtn"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg>
</button>
                        </div>
                        <div className="listingdetails">
                          <ul>
                            <li><span className="icon"><img src={location} alt={''} /></span> Brampton, Ontario</li>
                            <li><span className="icon"><img src={tag} alt={''} /></span> $40</li>
                            <li><span className="icon"><img src={bed} alt={''} /></span> 3+0 BEDROOMS</li>
                            <li><span className="icon"><img src={bath} alt={''} /></span> 3 BATHROOMS</li>
                            <li><span className="icon"><img src={sqft}  alt={''}/></span> 1500-2000 SQFT</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="listinginner">
                        <div className="listinginnerimage">
                          <img src={macd}  alt={''}/>
                        </div>
                        <div className="listingde">
                          <h3>2710 North Park Dr</h3>
                          <button type="button" className="myadbtn"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg>
</button>
                        </div>
                        <div className="listingdetails">
                          <ul>
                            <li><span className="icon"><img src={location}  alt={''}/></span> Brampton, Ontario</li>
                            <li><span className="icon"><img src={tag} alt={''} /></span> $40</li>
                            <li><span className="icon"><img src={bed} alt={''} /></span> 3+0 BEDROOMS</li>
                            <li><span className="icon"><img src={bath}  alt={''}/></span> 3 BATHROOMS</li>
                            <li><span className="icon"><img src={sqft}  alt={''}/></span> 1500-2000 SQFT</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="listinginner">
                        <div className="listinginnerimage">
                          <img src={macd}  alt={''}/>
                        </div>
                        <div className="listingde">
                          <h3>2710 North Park Dr</h3>
                          <button type="button" className="myadbtn"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg>
</button>
                        </div>
                        <div className="listingdetails">
                          <ul>
                            <li><span className="icon"><img src={location}  alt={''}/></span> Brampton, Ontario</li>
                            <li><span className="icon"><img src={tag}  alt={''}/></span> $40</li>
                            <li><span className="icon"><img src={bed}  alt={''}/></span> 3+0 BEDROOMS</li>
                            <li><span className="icon"><img src={bath}  alt={''}/></span> 3 BATHROOMS</li>
                            <li><span className="icon"><img src={sqft}  alt={''}/></span> 1500-2000 SQFT</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="createlisting">
                  <h5 className="text-center">Create New Listing</h5>
                  <div className="userguide text-center">
                    <span className="plantooltip custooltip">
                      <img src={planpricingtooltip} className="tooltipim"  alt={''}/> How to create a listing?
                         <div className="tooltipcontent">
                        <ul className="tcon">
                          <li className="tconitem">1. Enter multiple MLS numbers of the properties separated by commas.</li>
                          <li className="tconitem">2. Click on "Submit".</li>
                          <li className="tconitem">3. Check the properties you want to display on the public profile page.
                            
                          </li>
                          <li className="tconitem">4. Click "Save".</li>
                          <li className="tconitem">5. All saved listings appear on your public profile page.</li>
                        </ul>
                       
                      </div>
                    </span>
                  </div>
                  <div className="mls">
                  <div className="mt-4 input-container form-group">
                          <input type="text" className="input form-control" placeholder=" " />
                          
                          <label className="placeholder">Enter MLS Numbers *</label>
                  </div>
                  <button type="button" className="ctaonebutton">Submit</button>
                  </div>
                </div>
                <div className="martop40">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <div className="listinginner">
                          <div className="listingde">
                            <label className="container-checkbox" for="premium">
                              <input type="checkbox" id="premium" />
                              <span className="checkmark"></span>
                            </label>
                            <h3>E1234567</h3>
                            <div className="listingdetails">
                              <ul>
                                <li><span className="icon"><img src={location} alt={''} /></span> Brampton, Ontario</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="listinginner">
                          <div className="listingde">
                            <label className="container-checkbox" for="premium">
                              <input type="checkbox" id="premium" />
                              <span className="checkmark"></span>
                            </label>
                            <h3>E1234567</h3>
                            <div className="listingdetails">
                              <ul>
                                <li><span className="icon"><img src={location} alt={''} /></span> Brampton, Ontario</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default MyListinsJsx;
