import React, { Component } from "react";
import ForgotPasswordPage from "./forgotPassword.jsx"

import axios from "axios";
import swal from "sweetalert";
import {
  emailRegex
} from '../../../shared/helper.js'
import { Loader } from "../loader/loader.jsx";
import {
  baseURL,
} from "../../../constants/applicationConstants.js";

export class forgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: ""
      },
      isProcessing: false,
      formError: {},
      formSubmitButtonDisable: true,
      isLoading: false
    };
  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };
  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {

    if (name === "email") {
      return emailRegex.test(value);
    }
  };

  isFromSubmissionBlock = () => {
    let { email } = this.state.formData;
    if (email) {
      if (
        !this.inputRegexValidation("email", email)
      ) {
        this.setState({
          formSubmitButtonDisable: true,
        });
      } else {
        this.setState({
          formSubmitButtonDisable: false,
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };

  formSubmitHandler = (e) => {
    let flowType = localStorage.getItem("flowType");
    let caseName = localStorage.getItem("requestType")

    this.setState({
      isLoading: true
    })

    e.preventDefault();
    let data = {
      category: "institute",
      requestType: "forgot-password"
    };
    if (caseName === "forgot-password") {
      data.requestType = "forgot-password"
    }
    let dataExpert = {
      category: "expert",
      requestType: "forgot-password"
    };
    let dataCustomer = {
      category: "customer",
      requestType: "forgot-password"
    };
    if (caseName === "forgot-password") {
      dataExpert.requestType = "forgot-password"
    }
    // return
    axios.post(`${baseURL}/forgotPassword/${this.state.formData.email}/verify`, flowType === "expert" ? dataExpert : flowType === "customer" ? dataCustomer : data).then((res) => {

      // sessionStorage.setItem("auth", JSON.stringify(res?.data?.data));
      sessionStorage.setItem("email", JSON.stringify(res.data?.data?.email));
      if (res.data?.data?.userType == "institute-user") {
        sessionStorage.setItem("instituteUSER", true);

      }      //   sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.instituteId));
      // swal({
      //   title: "success",
      //   text: "You have logged-in successfully",
      //   icon: "success",
      // });
      this.setState({
        isLoading: false
      })

      this.props.history.push("/otpGeneration");
    }).catch((res) => {
      swal({
        title: "error",
        text: res?.response.data.error.message,
        icon: "error",
      });
      this.setState({
        isLoading: false
      })

    })

  };


  // handleSubmit = () => {
  //   this.props.history.push("/otpGeneration");
  // }
  onBackClick = () => {
    this.props.history.goBack()
  }
  render() {

    return (
      <div>
        <ForgotPasswordPage
          handleSubmit={this.handleSubmit}
          onBackClick={this.onBackClick}

          formData={this.state.formData}
          onInputChangeHandler={this.onInputChangeHandler}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          formError={this.state.formError}
          formSubmitHandler={this.formSubmitHandler}
          isLoading={this.state.isLoading}
        />
        {this.state.isLoading && <Loader />}

      </div>
    );
  }
}


export default forgotPass;
