import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';

const PaymentSummeryView = (props) => {
  const { t } = useTranslation();
  const {paymentData,clickContinue} = props
  
    return (
        <section className="loginscreeninstitute registrationinstitute">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
              
                <div className="detailsleft justify-content-start">
                  <h1 className="text-decoration-underline d-block width100">{t("PAYMENTSUMMARY.TITLE")} </h1>
                  <h2 className="h2_style text-decoration-underline d-block">{t("PAYMENTSUMMARY.TAGLINE")}</h2>
                </div>
              </div>
              <div className="passtext martop60">
                    <h2 className="h4_style martop60 color214860">{t("PAYMENTSUMMARY.TAGLINEAFTER")}</h2>
                    <h5 className="h5_style martop30 color214860">{t("PAYMENTSUMMARY.TITLETWO")}</h5>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext martop60 paddingbot60">
                <div className="planbuttonouter">
                  <h2 className="h2_style">{t("PAYMENTSUMMARY.TITLEMAIN")}</h2>
              
                </div>
                <div className="signout">
                  <img src={Sign_out_squre}  alt={' '}  />
                  <span className="title">Logout</span>
                </div>
                <div className="paymentsummery">
                    <li>{t("PAYMENTSUMMARY.STATUS")}<span>{paymentData?.status}</span></li>
                    <li>{t("PAYMENTSUMMARY.TRANSACTION")}<span>{paymentData?.transactionId}</span></li>
                    <li>{t("PAYMENTSUMMARY.DATETRANSACTION")}<span>{format(new Date(paymentData?.transactionDate), 'dd-MMM-yyyy')}</span></li>
                    <li>{t("PAYMENTSUMMARY.AMOUNTTRANSACTION")}:<span>${paymentData?.amountPaid}</span></li>
                    <li>{t("PAYMENTSUMMARY.TRANSACTIONPAID")} {paymentData?.cardLast4Digits} (last four digits) </li>
                </div>                
                <Link to={{ pathname: paymentData?.invoicePdf }} className="mt-4 mb-4 ctatwobutton downcta text-center" target="_blank"  rel="noreferrer" >Download Invoice</Link>
                {/* <button type="submit" className="mt-4 ctatwobutton">Continue</button> */}
                <button type="submit" onClick={clickContinue} className="mt-4 mb-4 ctaonebutton">Continue</button>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
}

export default PaymentSummeryView;