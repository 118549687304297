import React from 'react';
import mailsend from "../../../assets/images/mailsend.svg";
import envfooter from "../../../assets/images/envfooter.svg";
import footerfacebook from "../../../assets/images/footerfacebook.svg";
import footerlinkedin from "../../../assets/images/linkedin-in.svg";
import footerinsta from "../../../assets/images/footerinsta.svg";
import logo from "../../../assets/images/RimiblogoFinalTransparentfinal.png";
import { Link } from "react-router-dom";

export const FooterJSX =() =>{
    return (
        <footer>
     <div className="container">
       <div className="row">
         <div className="col-lg-4 col-md-6 col-sm-6">
           <div className="footerone">
            <div className="footerlogo"><Link to={'/'} ><img src={logo} alt={''}/></Link></div>
             <p className="text-white d-none d-sm-block">We help you achieve financial stability by connecting you with verified domain experts at no cost. Search experts & institutions near you. Ask questions, see latest offers, and explore the best financial products.</p>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 col-sm-6">
           <div className="footertwo">
             <h3>Services</h3>
            <ul>
              <li><Link to={'/AskTheExpert'}>Ask the Experts</Link></li>
              <li><Link to={'/searchExpert'}>Search the Experts</Link></li>
              <li><Link to={'/searchInstitute'}>Search the Institutions</Link></li>
              <li><Link to={'/privacyPolicy'}>Privacy Policy</Link></li>
              <li><Link to={'/termsCondition'}>Terms & Condition</Link></li>
            </ul>
            {/* <h3 className="mt-4">Downloads</h3>
            <div className="footer-logo"><img src={appimage} /></div> */}
           </div>
         </div>
         <div className="col-lg-4 col-md-6 col-sm-6">
           <div className="footerone">
           <h3>Contact</h3>
           <ul className="conde">
              <li><span className="footericon"><img src={mailsend} alt={''}/></span>#51- 3065 Ridgeway Dr., Mississauga ON L5L5M6</li>
              <li><span className="footericon"><img src={envfooter} alt={''}/></span><a href="mailto:contact@rimib.com">contact@rimib.com</a></li>
            </ul>
            <h3 className="mt-3">Follow</h3>
            <ul className="socialin d-flex mb-4">
              <li><a href="https://www.facebook.com/profile.php?id=100094245098596" target="_blank"  rel="noreferrer" ><img src={footerfacebook} alt={''} /></a></li>
              {/* <li><a href="https://twitter.com/_rimib" target="_blank"  rel="noreferrer" ><img src={footertwitter} alt={''} /></a></li> */}
              <li><a href="https://www.linkedin.com/company/rimib/about/" target="_blank"  rel="noreferrer" ><img src={footerlinkedin} alt={''} /></a></li>
              <li><a href="https://www.instagram.com/_rimib/" target="_blank"  rel="noreferrer" ><img src={footerinsta} alt={''} /></a></li>
            </ul>
           </div>
         </div>
       </div>
     </div>
     <div className="copyright text-center">© {new Date().getFullYear()} Rimib. All rights reserved. Designed by <a href="https://prosofos.com/" target="_blank"  rel="noreferrer" >ProSofos</a></div>
   </footer>
    )
}
