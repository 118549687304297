import React from 'react';
import { Component } from 'react';
import { ExpertAdsJSX } from './expertAds.jsx';

export default class ExpertAds extends Component{
    render(){
        return(
            <ExpertAdsJSX />
        )
    }
}