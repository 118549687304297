import React from 'react'
import { Component } from 'react';
import ChangePlaneJsx from './changePlane.jsx'
import axios from "axios";
import { baseURL } from "../../../../../constants/applicationConstants"
import swal from "sweetalert";

export default class ChangePlane extends Component{

    constructor(props) {
        super(props);
        this.state = {
          planDetail: null,
          bilingPeriod: 'Annual Billing',
          planType: 'Elite',
          isInstitutionExpet: false,
          isProcessing: false,
          termAndCondition: false
        }
      }
    
      componentDidMount = () => {
        const planData = JSON.parse(sessionStorage.getItem('planData'));
        const instituteId = JSON.parse(sessionStorage.getItem("instituteId"));
        if (planData) {
          this.setState({
            bilingPeriod: planData.period,
            planType: planData.planType,
            isInstitutionExpet: planData.isInstitutionExpet,
            termAndCondition: planData.termAndCondition
          })
        }
        this.setState({ isProcessing: true })
    
        axios.get(`${baseURL}/subscriptionPlans?subscriberType=institute&instituteId=${instituteId}`).then(res => {
          this.setState({ planDetail: res.data.data, isProcessing: false })
        })
        let token = JSON.parse(sessionStorage.getItem('token'));
        if (!token) {
          this.props.history.push("/");
        }
      }
    
      onBilingButtonClick = (data) => {
        this.setState({ bilingPeriod: data })
      }
    
      onPlanTypeButtonClick = (plan) => {
        // this.setState({ planType: plan })
      }
    
      onInstitutionExpertClick = (data) => {
        this.setState({ isInstitutionExpet: data })
      }
    
      onTermsAndConditionClick = (value) => {
        this.setState({ termAndCondition: value })
      }
    
      onNextButtonClick = () => {
        const planTypeData = this.state.planDetail?.filter(data => data.planName === this.state.planType);
    
        let plantypeID = ''
        let rate = ''
        planTypeData?.[0].subscriptionPlanTypes?.map(data => {
          if (data.planType === "monthly" && this.state.bilingPeriod === 'Monthly Billing') {
            plantypeID = data.subscriptionPlanTypeId
            rate = data.price
          }
          else if (data.planType === "anually" && this.state.bilingPeriod === 'Annual Billing') {
            plantypeID = data.subscriptionPlanTypeId
            rate = data.price
          }
        })
    
        swal({
            title: "success",
            text: 'Are you sure you want to change the plan?',
            icon: "success",
          });
          this.setState({
                    isLoading:false
                })
        
    
        // sessionStorage.setItem('planData', JSON.stringify({ planType: this.state.planType, rate, period: this.state.bilingPeriod, isInstitutionExpet: this.state.isInstitutionExpet, termAndCondition: this.state.termAndCondition }));
        // sessionStorage.setItem('planTypeID', JSON.stringify(plantypeID))
        // sessionStorage.setItem('subscriptionPlanId', JSON.stringify(planTypeData?.[0].subscriptionPlanId))
        // const type = localStorage.getItem('flowName');
        // this.props.history.push(getRoute(type, 'plans'))
      }

    render(){
        return(
            <div>
                <ChangePlaneJsx 
                bilingPeriod={this.state.bilingPeriod}
                onBilingButtonClick={this.onBilingButtonClick}
                planType={this.state.planType}
                onPlanTypeButtonClick={this.onPlanTypeButtonClick}
                isInstitutionExpet={this.state.isInstitutionExpet}
                onInstitutionExpertClick={this.onInstitutionExpertClick}
                termAndCondition={this.state.termAndCondition}
                onTermsAndConditionClick={this.onTermsAndConditionClick}
                onNextButtonClick={this.onNextButtonClick}
                planDetail={this.state.planDetail}
                />
            </div>
        )
    }
}