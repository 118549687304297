import React, { Component } from "react";
import PlansSummaryPage from "./plansSummary.jsx"
import { getRoute } from '../../../../shared/helper';
import axios from "axios";
import { baseURL } from "../../../../constants/applicationConstants.js";
import { toast, ToastContainer } from "react-toastify";
import { Loader } from "../../../common/loader/loader.jsx";
import swal from "sweetalert";

export class plansSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      promoCode: '',
      discount: 0,
      promotionId: null,
      isProcessing: false
    }
  }

  onPromoCodeChnage = (e) => {
    this.setState({ promoCode: e.target.value })
  }

  onApplyButtonClick = () => {
    this.setState({ isProcessing: true });
    const payload = {
      promoCode: this.state.promoCode
    }
    axios.post(`${baseURL}/promotion/${this.state.promoCode}`, payload).then(res => {
      this.setState({ discount: res.data.data.discountPercentage, isProcessing: false, promotionId: res.data.data.subscriptionPlanPromotionId })
    }).catch((err) => {
      toast.error(err.response.data.error.message);
      this.setState({ isProcessing: false })
    })
  }

  onContinueButtonClick = () => {
    const type = localStorage.getItem('flowName');
    this.props.history.push(getRoute(type, 'plansSummary'))
  }

  onInvoiceButtonClick = () => {
    this.setState({ isProcessing: true });
    let flowName = localStorage.getItem('AdminFlow');
    const adminToken = sessionStorage.getItem('adminOldToken');

    let subscriptionPlanId = JSON.parse(sessionStorage.getItem("subscriptionPlanId"));
    let token = JSON.parse(sessionStorage.getItem("token"));
    let planTypeID = JSON.parse(sessionStorage.getItem("planTypeID"));

    let subURL = '';
    let instituteId;
    if (flowName === 'institute') {
      subURL = 'institute'
      instituteId = JSON.parse(sessionStorage.getItem("instituteId"));
    }
    else {
      subURL = 'expert'
      instituteId = JSON.parse(sessionStorage.getItem("expertId"));
    }

    const payload = {
      "subscriptionPlanTypeId": planTypeID,
      "tokenValidate": token,
      "subscriptionPlanPromotionId": this.state.promotionId,
      "subscriptionPlanId": subscriptionPlanId
    }

    axios.post(`${baseURL}/${subURL}/${instituteId}/subscriptionInvoice`, payload).then(res => {
      this.setState({ isProcessing: false });
      swal({
        title: "success",
        text: res.data.data.status,
        icon: "success",
      }).then(res => {
        if (res) {
          // back to institute if coming from institute //////////////////
          let comingFromInstitute = sessionStorage.getItem("comingFromInstitute")
          if (comingFromInstitute && comingFromInstitute) {
            // alert("from ins")
            // return
            let oldToken = localStorage.getItem("instituteExpertToken")
            sessionStorage.setItem("token", oldToken);
            localStorage.removeItem("instituteExpertToken");
            localStorage.removeItem("adInsExpert");
            localStorage.removeItem("instituteExpert");
            // localStorage.removeItem("AdminFlow");
            localStorage.setItem('flowName', 'institute')
            localStorage.setItem('flowType', 'institute')
            sessionStorage.removeItem("resumeOnBoard");

            sessionStorage.removeItem("expertId");
            sessionStorage.removeItem("description");
            sessionStorage.removeItem("expertData");
            sessionStorage.removeItem("subscriptionPlanId");
            sessionStorage.removeItem("planTypeID");
            sessionStorage.removeItem("planData");
            this.props.history.push("/dashBoard");
            sessionStorage.removeItem("comingFromInstitute");

          } else {
            // alert("from admin")
            // return
            sessionStorage.setItem("token", JSON.stringify(adminToken));
            sessionStorage.removeItem("adminOldToken")
            //sessionStorage.removeItem("loginData")
            sessionStorage.removeItem("expertId");
            sessionStorage.removeItem("description");
            sessionStorage.removeItem("expertData");
            sessionStorage.removeItem("subscriptionPlanId");
            sessionStorage.removeItem("planTypeID");
            sessionStorage.removeItem("planData");
            sessionStorage.removeItem("resumeOnBoard");

            this.props.history.push('./admin/opsDashboardHome')
          }

        }
      })
    }).catch((err) => {
      toast.error(err.response.data.error.message);
      this.setState({ isProcessing: false })
    })
  }

  onViaCard = () => {
    this.setState({ isProcessing: true });
    let flowName = localStorage.getItem('AdminFlow');

    let subscriptionPlanId = JSON.parse(sessionStorage.getItem("subscriptionPlanId"));
    let token = JSON.parse(sessionStorage.getItem("token"));
    let planTypeID = JSON.parse(sessionStorage.getItem("planTypeID"));

    let subURL = '';
    let instituteId=null;
    let expertId=null;
    if (flowName === 'institute') {
      subURL = 'institute'
      instituteId = JSON.parse(sessionStorage.getItem("instituteId"));
    }
    else {
      subURL = 'expert'
      expertId = JSON.parse(sessionStorage.getItem("expertId"));
    }

    const payload = {
      "subscriptionPlanTypeId": planTypeID,
      "tokenValidate": token,
      "subscriptionPlanPromotionId": this.state.promotionId,
      "instituteId": instituteId ?? undefined,
      "expertId": expertId ?? undefined,
    }

    axios.post(`${baseURL}/subscriptionPlan/${subscriptionPlanId}/paidByInvoiceLink`, payload).then(resp => {
      this.setState({ isProcessing: false });
      swal({
        title: "succes",
        text: "You will be redirected to stripe payment page to complete the payment.",
        icon: "success",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(res => {
        if (res) {
          
          window.location.href = resp?.data?.data?.invoiceUrl
        }
      })
    }).catch((err) => {
      toast.error(err.response.data.error.message);
      this.setState({ isProcessing: false })
    })
  }


  render() {
    return (
      <div>
        <PlansSummaryPage
          promoCode={this.state.promoCode}
          onPromoCodeChnage={this.onPromoCodeChnage}
          onApplyButtonClick={this.onApplyButtonClick}
          onContinueButtonClick={this.onContinueButtonClick}
          discount={this.state.discount}
          onInvoiceButtonClick={this.onInvoiceButtonClick}
          onViaCard={this.onViaCard}
        />
        <ToastContainer />
        {this.state.isProcessing && <Loader />}
      </div>
    );
  }
}

export default plansSummary;
