import { useTranslation } from "react-i18next";
import React from 'react';

export const ServiceSection = (props) => {
  const { t } = useTranslation();
  const { profileData } = props;

  return <section className="service-offer">
    <div className="container">
      <div className="heading">
        <h2>{t("PPVINSTITUTE.SERVICETITLE")}</h2>
        <h3 className="mt-3">{t("PPVINSTITUTE.SERVICESUBTITLE")}</h3>
        <h4>{t("PPVINSTITUTE.SERVICESUBSUBTITLE")}</h4>
      </div>
      <div className="serviceoffer-inner">
        <div className="disgrid grid-4">

          {
            profileData?.instituteServices?.map(service => {
              return <li>
                <img src={`/${(service?.institutionService?.name).trim()}.svg`}  alt={' '} />
                <h5>{service?.institutionService?.name}</h5>
              </li>
            })
          }
        </div>
      </div>
    </div>
  </section>

}