import React from "react";
import PasswordSuccessPage from "./passwordSuccess.jsx"
import { useHistory } from "react-router";

const PassSuccess = () => {
  const history = useHistory();
  const handleSubmit = () => {
    localStorage.removeItem("flowName")
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("customerId");
    sessionStorage.removeItem("email");

    sessionStorage.removeItem("instituteUSER");

    localStorage.removeItem("requestType");
    localStorage.removeItem("flowType")

    history.push("/");
    window.location.reload()
  }
  const onBackClick = () => {
    history.goBack()
  }
      return (
        <div>
          <PasswordSuccessPage
            handleSubmit={handleSubmit}
            onBackClick={onBackClick}
          />
        </div>
      );
    
  }
  
  export default PassSuccess;
  