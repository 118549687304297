import React from 'react'
import { Component } from 'react';
import axios from 'axios';
import BlogsJsx from './blogs.jsx';
import { BLOG_URL_TOKEN,BLOG_URL } from '../../../constants/applicationConstants.js';


export default class Blogs extends Component{
    constructor(props) {
        super(props);
        this.state = {
          blogData: null,
          isProcessing: false,
          topOffers:null,
          page: 1,
          blogType: 'Featured',
          quoteData: {
            type: null,
            name: null,
            email: null,
            phone: null,
            postalCode: null
          },
          formError: {},
          expertData: null,
          productData: null,
          offerData: null
        }
      }
      getType = {
        Featured: 'main_news',
        Trending: "trending",
        Latest: "featured",
        Glossary: "glossory"
      }

      componentDidMount = () => {
        this.getBlogData();
      }

      getBlogData = () => {
        var data = new FormData();
        data.append('limit', '36');
        data.append('device_token', '20.204.66.244');
        //   data.append('expertid', 'admin');
        data.append('paged', 1)
        data.append('type', this.getType[this.state.blogType])
        this.setState({ isProcessing: true })
    
        axios.post(`${BLOG_URL}`, data,
          {
            headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
          }).then(res => {
            this.setState({ blogData: res.data, isProcessing: false });
          }).catch(err=>{
            this.setState({isProcessing: false})
          })
      }

    render(){
        return(
            <div>
                <BlogsJsx blogData={this.state.blogData}/>
            </div>
        )
    }
}