import React, { Component } from "react";
import MyListinsJsx from "./myListing.jsx";

export class MyListinsJsxclass extends Component {
  componentDidMount = () => {
    let token = JSON.parse(sessionStorage.getItem('token'));
    if (!token){
      this.props.history.push("/");   
     }
  }
    render() {
      return (
        <div>
          <MyListinsJsx/>
        </div>
      );
    }
  }

  export default MyListinsJsxclass;
