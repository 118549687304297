import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import question from "../../../../assets/images/question.svg"
import Sidebar from "../sidebar/sidebar.js"; 
import AccountSetting from "../accountSetting/accountSetting.js"
import ManageUser from "../../dashboardCommon/manageUser/manageUser.js"
import ChangePassword from "../../../common/changePassword/changePassword.js"
import MyPlansJsxclass from '../../dashboardCommon/myPlane/myPlane';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'

const MyAccountsJsx = (props) => {


  const {
    activeTab,
    onTabClick
  } = props;


  const data = JSON.parse(sessionStorage.getItem("auth"))

  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <section classNames="addexpertformsec">
                  <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">My Account</h2>
                      <h5 className="h5_style">Use this section to change your account settings & password
                      Also, you can add multiple user
Just toggle between different tabs as per your need. </h5>
                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={question} alt={''} /><span className="title">Help</span>
                        </a>
                      </div>
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre}  alt={''}/><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a  title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="myexpertnav myaccountnav martop40">
                    <ul>
                     
                      <li className={`${activeTab === 'acntStng' ? 'active' : ''}`} onClick={() => onTabClick('acntStng')}>Account Settings</li>
                      {data.isPrimaryUser && <li className={`${activeTab === 'mngUser' ? 'active' : ''}`} onClick={() => onTabClick('mngUser')}>Manage Users</li>}
                      <li className={`${activeTab === 'myPlans' ? 'active' : ''}`} onClick={() => onTabClick('myPlans')}>My Plan</li>
                      <li className={`${activeTab === 'newPass' ? 'active' : ''}`} onClick={() => onTabClick('newPass')}>Change Password</li>
                    </ul>
                  </div>
                  {
                    activeTab === 'acntStng' ? <AccountSetting /> : activeTab === 'mngUser' ? <ManageUser /> : activeTab === 'newPass' ? <div className="accountchangepass"><ChangePassword /></div> : <MyPlansJsxclass />
                  }
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default MyAccountsJsx;
