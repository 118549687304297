import React from 'react'
import dashboard from "../../../../assets/images/dashboard.svg";
import myblog from "../../../../assets/images/myblog.svg";
import myaccount from "../../../../assets/images/myaccount.svg";
import logoutIcon from "../../../../assets/images/logout.svg";
import { Link } from "react-router-dom";
import { NEW_URL } from '../../../../constants/applicationConstants';
import swal from "sweetalert";
import QnA from "../../../../assets/images/Qna.svg";
import myad from "../../../../assets/images/myad.svg";
import interest from "../../../../assets/images/interest.svg";
import { useHistory } from "react-router-dom";

const SideBarsJsx = (props) => {
  const history = useHistory();

  const {
    response
  } = props;

  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("legalName"); 
        sessionStorage.removeItem("isLoggedIn");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  // {t("PRINT.TITLE")}
  return (
    <>
     <div className="dashboardleftpanel martop40">
                <div className="userdetails"> 
                  <div className="dashboardlogo"><img  src={`${NEW_URL}/${response?.imageUrl}`}  alt={''}></img></div>
                 <div className="responsename">{`${response?.firstName} ${response?.lastName}`} 
                  {/* <Link to={`/customerPpv/${response?.slugUrl}`} target="_blank"  rel="noreferrer"  className="viewprofilebtn">View Profile</Link> */}
                 </div> 
                </div>
                <div className="dashboardlink martop40">
                  <ul>
                  <li className={`${window.location.href?.toLowerCase().includes('customerDashboard') ? 'active' : ""}`}><Link to={'/customerDashboard'}><img src={dashboard}  alt={''}/>Home</Link></li>
                  <li className={`${window.location.href?.toLowerCase().includes('customerAskTheExpert') ? 'active' : ""}`}><Link to={'/customerAskTheExpert'}><img src={myad} alt={''} />Ask The Expert</Link></li>
                    {/* <li className={`${window.location.href?.toLowerCase().includes('customerQna') ? 'active' : ""}`}><Link to={'/customerQna'}><img src={QnA}  alt={''}/>QnA</Link></li> */}
                    <li className={`${window.location.href?.toLowerCase().includes('customerBlogs') ? 'active' : ""}`}><Link to={'/customerBlogs'}><img src={myblog} alt={''} />Blogs</Link></li>
                    <li className={`${window.location.href?.toLowerCase().includes('customerInterest') ? 'active' : ""}`}><Link to={'/customerInterest'}><img src={interest} alt={''} />Interests</Link></li>
                    {/* <li className={`${window.location.href?.toLowerCase().includes('myads') ? 'active' : ""}`}><Link to={'/myAds'}><img src={myad} alt={''} />My Ads</Link></li> */}
                    
                    <li className={`${window.location.href?.toLowerCase().includes('customeraccounts') ? 'active' : ""}`}><Link to={'/customerAccounts'}><img src={myaccount} alt={''} />Account Settings</Link></li>
                    {/* <li className={`${window.location.href?.toLowerCase().includes('expertPayment') ? 'active' : ""}`}><Link to={'/expertPayment'}><img src={mypayment}  alt={''}/>My Payment</Link></li> */}
                    <li onClick={logout}><a ><img src={logoutIcon} alt={''} />Logout</a></li>
                  </ul>
                </div>
              </div>
    </>

  );
};

export default SideBarsJsx;